import React, { useEffect, useState, useCallback } from "react";
import { Drawer, Form, Input, Select, Button, Tabs, Checkbox, AutoComplete, List, Tooltip, Tag } from 'antd';
import $ from 'jquery';
import { FiEdit, FiPlus, FiTrash } from "react-icons/fi";
import { debounce } from 'lodash'
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';

import SectionTitle from "../../components/section-title";
import ApiConfig from "../../services/ApiConfig";
import ColorPickerInput from '../../common/color-picker-input';
import UploderInput from '../../common/uploader-input';
import PlayerTab from '../../components/players';
import TeamsTab, { PlayerNameWithPicture } from '../../components/teams';
import apiClient from '../../services/axios'
import apiFormDataClient from '../../services/axios-formdata'
import { getDateFormat, getFullName } from '../../utils';
import RemoveModal from "../../components/common/RemoveModal";
import ConfirmationModal from "../../common/confirm-modal";
import PageLoader from "../../components/page-loader";

const ManageTeams = () => {

  const [loading, setLoading] = useState(true);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [pVisible, setpVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [tabKey, setTabKey] = useState("1");
  const [playTypes, setPlayTypes] = useState(["All"]);
  const [teams, setTeams] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [search, setSearch] = useState('');

  const columns = [{
    title: "Name",
    key: "name",
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  }, {
    title: "Logo",
    dataIndex: "logo",
    name: "logo",
    render: (text, record) => {
      return <div className="team_logo"><img src={text || '/logos/logo.png'} alt={record.name} /></div>
    }
  }, {
    title: "Enrolled",
    dataIndex: "enrolled",
    name: "enrolled",
    render: (text, record) => {
      return record?.enrolled?.map((ee, k) => (
        <div key={k.toString()} data-key={ee.name} onClick={() => onSelectLeague(record, ee)} aria-hidden className="play_types"><Tooltip visible={false} arrowPointAtCenter={false} className='t-tip' title="Manage Players"><span className='p-name'>{ee.name}</span> <FiEdit className='ml-2' /></Tooltip></div>
      ))
    }
  }, {
    title: "Total Players",
    dataIndex: "players",
    name: "players",
    onFilter: (value, record) => record?.players?.length < 5,
    filters: [
      { text: '> 5 players', value: '5' },
    ],
    render: (text, record) => record?.players?.length || '-'
  }, {
    title: "Color",
    dataIndex: "jersey_color",
    name: "jersey_color",
    render: (text, record) => {
      return <div style={{ background: text }} className='jersey_colors h-8 w-8 shadow-outline rounded-full -ml-3' />
    }
  },
  {
    title: "Managers",
    dataIndex: "managers",
    name: "managers",
    render: (text, record) => record?.players?.filter(e => e.is_captain).map(e => <div>{getFullName(e.player)}</div>)
  },
  {
    title: "Owner",
    dataIndex: "owner",
    name: "owner",
    render: (text, record) => <PlayerNameWithPicture {...record?.owned_by} />
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (text, record, idx) => {
      return (
        <>
          <Button onClick={() => { setSelectedItem(record); setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />
          <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} />
        </>
      )
    }
  }]
  const [jerseyUpdated,setJerseyUpdated] = useState(false)

  const playerColumns = [
    {
      title: "Profile",
      dataIndex: "avatar",
      name: "avatar",
      render: (text, record) => {
        return <div className="team_logo"><img src={record?.player?.profile_pic || '/images/user.svg'} alt={record.name} /></div>
      }
    },
    {
      title: "Player Name",
      dataIndex: "name",
      name: "name",
      render: (text, record) => {
        return <div className="pl-name">
          {record.is_captain && <Tag color="#12344d">Manager</Tag>}
          {getFullName(record?.player)}
        </div>
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      name: "email",
      render: (text, record) => record?.player?.email
    },
    // {
    //   title: "Enrolled",
    //   dataIndex: "enrolled",
    //   name: "enrolled",
    //   render: (text, record) => {
    //     const enrollName = $('.tab-blk .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn').eq(tabKey).text();
    //     console.log('enrollName', enrollName);
    //     return <div onClick={() => onSelectLeague(record, { name: enrollName })} aria-hidden className="play_types"><Tooltip visible={false} arrowPointAtCenter={false} className='t-tip' title="Manage Players"><span className='p-name'>{enrollName}</span> <FiEdit className='ml-2' /></Tooltip></div>
    //   }
    // },
    // {
    //   title: "Sports Event",
    //   dataIndex: "play_types",
    //   name: "play_types",
    //   render: (text, record) => {
    //     return record?.enrolled?.map(ee => (
    //       <div className="play_types">{ee.name} <FiEdit className='ml-2' /></div>
    //     ))
    //   }
    // },
    {
      title: "Jersey No",
      dataIndex: "jersey_no",
      name: "jersey_no"
    },
    {
      title: "Starting Lineup",
      dataIndex: "is_starting_lineup",
      name: "is_starting_lineup",
      render: (text) => text ? "Yes" : "No"
    },
    {
      title: "Status",
      dataIndex: "is_player_approved",
      name: "is_player_approved",
      render: (text, record) => (
        <div onClick={() => onApprovePlayer(record)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          <Tag color={text ? "#12344d" : "#e40"}>{text ? 'Confirmed' : 'Pending'}</Tag>
          {!text && <FiEdit size={18} className="ml-1" />}
        </div>
      )
    },
    {
      title: "Joined At",
      dataIndex: "created_at",
      name: "created_at",
      render: (text) => getDateFormat({ date: text })
    },
    {
      title: "Last Modified",
      dataIndex: "jersey_updated_at",
      name: "jersey_updated_at",
      render: (text,record) => getDateFormat({ date: record?.jersey_updated_at??record?.created_at })
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <>
            {(currentTeam?.is_member && currentTeam.role !== 'Captain' && currentTeam.role !== 'Owner') && '-'}
            {(!currentTeam?.is_member || currentTeam.role === 'Captain' || currentTeam.role === 'Owner') && <div className="action_btns">
              {!currentTeam?.is_member && <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />}
              <Button onClick={() => onSelectPlayer(record)} className='btn_edit' type="link" icon={<FiEdit />} />
            </div>}
          </>
        )
      }
    }
  ]

  const onSelect = (item) => {
    setSelectedItem({
      ...item
    });
    setVisible(true)
  }

  const loadTeams = async () => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(ApiConfig.player.teams);
      setTeams([...data])
      if (!selectedTeam && data.length > 0) {
        setSelectedTeam(data[0]._id);
        setCurrentTeam({ ...data[0] });
      }
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  }

  const onApprovePlayer = async (record) => {
    const ok = await ConfirmationModal("Are you sure you want confirm this player?")
    // console.log('record', record, ok);
    if (ok) {
      await apiFormDataClient.put(ApiConfig.player.updatePlayer.replace('team_id', selectedTeam) + `/${record._id}`, { is_player_approved: true });
      const idx = currentTeam.players.findIndex(ee => ee._id === record._id)
      if (idx >= 0) {
        currentTeam.players[idx].is_player_approved = true;
      }
      setCurrentTeam({ ...currentTeam })
    }
  }

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.player.leagues);
    setLeagues([...data]);
    setPlayTypes([...playTypes, ...data.map(e => e.name)])
  }

  const onSelectLeague = (item, enroll) => {
    console.log('enroll', enroll);
    $('.tab-blk .ant-tabs-tab').eq(1).trigger('click');
    setCurrentTeam(item)
    setSelectedTeam(item._id)
    setVisible(false);
    setTimeout(() => {
      $('.player-tabs .ant-tabs-tab .ant-tabs-tab-btn').filter((k, e) => $(e).text() === enroll.name).trigger('click')
    }, 100)
  }

  const onSelectPlayer = (item) => {
    setSelectedItem({
      ...item
    });
    setpVisible(true)
  }

  const onDelete = async () => {
    setDeletePopupVisibe(false);
    if (tabKey === '1') {
      await apiClient.delete(ApiConfig.player.teams + `/${selectedItem._id}`);
    } else {
      await apiClient.delete(ApiConfig.player.removePlayer.replace('team_id', selectedTeam) + `/${selectedItem._id}`);
    }
    const teamDatas = await loadTeams();
    const idx = teamDatas.findIndex(ee => ee._id === selectedTeam);
    if (idx >= 0) {
      setCurrentTeam(teamDatas[idx]);
    }
  }

  const FormElements = ({ initialValues }) => {
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [coachSearching, setCoachSearching] = useState(false);
    const [players, setPlayers] = useState([]);
    const [coaches, setCoaches] = useState([]);
    const [logo, setLogo] = useState(null);
    const [owner, setOwner] = useState(null)
    const [coach, setCoach] = useState(null)
    const [isLogoDeleted, setIsLogoDeleted] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
      const enrolled = [];
      if (values.enrolled.indexOf('All') === -1) {
        for (let cnt in values.enrolled) {
          enrolled.push(leagues.filter(ee => ee.name === values.enrolled[cnt])[0]?._id);
        }
      } else {
        leagues.forEach(e => {
          enrolled.push(e._id);
        })
      }
      console.log(values);
      const formdata = new FormData();
      formdata.append('name', values.name);
      formdata.append('jersey_color', values.jersey_color);
      formdata.append('enrolled', JSON.stringify(enrolled));
      if (logo && typeof logo !== 'string') formdata.append('logo', logo);
      if (isLogoDeleted) formdata.append('is_logo_deleted', "true");
      if (values.owner) {
        formdata.append('owner', values.owner)
      }
      if (values.coach) {
        formdata.append('coach', values.coach);
      }
      try {
        setLoading(true);
        if (selectedItem?._id) {
          await apiFormDataClient.put(ApiConfig.player.teams + `/${selectedItem._id}`, formdata);
        } else {
          await apiFormDataClient.post(ApiConfig.player.teams, formdata);
        }
        setLoading(false);
        form.setFieldsValue({
          ...{}
        });
        setVisible(false);
        loadTeams();
      } catch (error) {
        setLoading(false);
      }
    }

    const onFileChange = (file) => {
      console.log('file', file);
      if (file) {
        setLogo(file)
      } else {
        setIsLogoDeleted(true)
      }
    }

    const onChangeEnrolled = (values) => {
      console.log(values);
      if (values.indexOf('All') >= 0) {
        const tmp = [];
        leagues.forEach(e => {
          tmp.push(e.name);
        })
        form.setFieldsValue({
          ...form.getFieldsValue(),
          enrolled: tmp
        })
        console.log(tmp);
      }
    }

    const onSelectOwner = (value = '') => {
      const tmp = players.filter(e => value.indexOf(e.email) >= 0)[0];
      form.setFieldsValue({
        ...form.getFieldsValue(),
        owner: tmp._id
      });
    };

    const onSelectCoach = (value = '') => {
      const tmp = coaches.filter(e => value.indexOf(e.email) >= 0)[0];
      form.setFieldsValue({
        ...form.getFieldsValue(),
        coach: tmp._id
      });
    };

    const geOptions = (options = []) => {
      return options.map(opt => {
        return {
          value: `${getFullName(opt)}<${opt?.email}>`,
          label: (
            <List.Item>
              <List.Item.Meta
                avatar={!opt?.profile_pic ? <UserOutlined /> : <img style={{ width: "30px" }} src={opt?.profile_pic} alt="" />}
                title={getFullName(opt)}
                description={opt?.email}
              />
            </List.Item>
          )
        }
      })
    }

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
      debounce((nextValue, type) => handleSearch(nextValue, type), 500),
      [], // will be created only once initially
    );

    const handleSearch = async (value = '', type) => {
      if (value.length < 2) {
        if (type === 1) {
          setPlayers([...[]])
        } else {
          setCoaches([...[]])
        }
        return;
      };
      try {
        if (type === 1) {
          setSearching(true);
          const { data } = await apiClient.get(`${ApiConfig.player.playerSearch}?q=${value}`);
          setPlayers([...data]);
          setSearching(false);
        } else {
          setCoachSearching(true);
          const { data } = await apiClient.get(`${ApiConfig.player.playerSearch}?q=${value}`);
          setCoaches([...data]);
          setCoachSearching(false);
        }
      } catch (error) {
        setSearching(false);
        setCoachSearching(false);
      }
    }

    useEffect(() => {
      console.log('initialValues', initialValues);
      if (initialValues) {
        setPlayers([...[initialValues?.owned_by]])
        const ownerNm = `${getFullName(initialValues?.owned_by)}<${initialValues?.owned_by?.email}>`;
        const coachNm = `${getFullName(initialValues?.coached_by)}<${initialValues?.coached_by?.email}>`;
        form.setFieldsValue({
          name: initialValues.name,
          owner: initialValues?.owned_by?._id,
          coach: initialValues?.coached_by?._id,
          jersey_color: initialValues.jersey_color,
          enrolled: initialValues?.enrolled?.map(e => e.name) || []
        })
        setOwner(ownerNm);
        setCoach(coachNm);
        setLogo(initialValues.logo)
      } else {
        // if (!window.teamTabKey) window.teamTabKey = '0';
        // const entrolledArr = window.teamTabKey === '0' ? playTypes.filter(e => e !== 'All') : [$('.tab-blk .ant-tabs .ant-tabs-tab-btn').eq(parseInt(window.teamTabKey)).text()]
        // form.setFieldsValue({
        //   enrolled: entrolledArr
        // })
      }
      // eslint-disable-next-line
    }, [initialValues])

    return (
      <>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            ...initialValues,
            jersey_color: "#e40000"
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Team Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a name" />
          </Form.Item>
          <Form.Item
            name="enrolled"
            label="Enroll"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select allowClear onChange={onChangeEnrolled} mode="multiple" placeholder="Select enroll" options={playTypes.map(ee => ({ label: ee, value: ee }))} />
          </Form.Item>
          <Form.Item
            label="Jersey Color"
            name="jersey_color"
          >
            <ColorPickerInput placeholder="Select Color" />
          </Form.Item>
          <Form.Item
            name="logo"
            label="Team Logo"
            className="upload-img-input"
            help="Recommended size: 128px x 128px and max file size: 500KB"
          >
            <UploderInput url={logo} accept="image/*" onChange={onFileChange} />
          </Form.Item>
          <Form.Item className='loader-form-block'>
            <Form.Item
              name="owner"
              label="Team Owner"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <AutoComplete
                options={geOptions(players)}
                onSelect={onSelectOwner}
                onSearch={(e) => debouncedSearch(e, 1)}
                value={owner}
                onBlur={(e) => {
                  if (!e.target.value && initialValues?.owned_by) {
                    setPlayers([...[initialValues?.owned_by]])
                    setOwner(`${getFullName(initialValues?.owned_by)}<${initialValues?.owned_by?.email}>`)
                  }
                }}
                onChange={(e) => setOwner(e)}
                dropdownClassName="mobile-autocomplete"
              >
                <Input placeholder="Search player by name or email" />
              </AutoComplete>
              {searching && (
                <Form.Item className='loader-form-item' noStyle>
                  <span className="input-loader">
                    <LoadingOutlined />
                  </span>
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item className='loader-form-block'>
            <Form.Item
              name="coach"
              label="Team Coach"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <AutoComplete
                options={geOptions(coaches)}
                onSelect={onSelectCoach}
                onSearch={(e) => debouncedSearch(e, 2)}
                value={coach}
                onBlur={(e) => {
                  if (!e.target.value && initialValues?.coached_by) {
                    setPlayers([...[initialValues?.coached_by]])
                    setCoach(`${getFullName(initialValues?.coached_by)}<${initialValues?.coached_by?.email}>`)
                  }
                }}
                onChange={(e) => setCoach(e)}
                dropdownClassName="mobile-autocomplete"
              >
                <Input placeholder="Search coach by name or email" />
              </AutoComplete>
              {coachSearching && (
                <Form.Item className='loader-form-item' noStyle>
                  <span className="input-loader">
                    <LoadingOutlined />
                  </span>
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
          <div className="form-footer">
            <Button loading={loading} type="primary" htmlType="submit">{selectedItem?._id ? 'Update' : 'Add'} Team</Button>
          </div>
        </Form>
        {
          initialValues?.enrolled?.length > 0 && <div className="leagues-list">
            {
              initialValues?.enrolled?.map(ee => (
                <div key={ee.name} onClick={() => onSelectLeague(currentTeam, ee)} className='league-item'>{ee.name} <FiEdit className='ml-2' /></div>
              ))
            }
          </div>
        }
      </>
    )
  }

  const FormPlayerElements = ({ initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [players, setPlayers] = useState([]);

    const geOptions = (options = []) => {
      return options.map(opt => {
        return {
          value: `${getFullName(opt)}<${opt.email}>`,
          label: (
            <List.Item>
              <List.Item.Meta
                avatar={!opt.profile_pic ? <UserOutlined /> : <img style={{ width: "30px" }} src={opt.profile_pic} alt="" />}
                title={getFullName(opt)}
                description={opt.email}
              />
            </List.Item>
          )
        }
      })
    }

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
      debounce((nextValue, type) => handleSearch(nextValue), 500),
      [], // will be created only once initially
    );

    const onSubmitPlayer = async (values) => {
      try {
        console.log(values,initialValues,jerseyUpdated);
        const enrolled = [];
        if (values.enrolled.indexOf('All') === -1) {
          for (let cnt in values.enrolled) {
            enrolled.push(leagues.filter(ee => ee.name === values.enrolled[cnt])[0]?._id);
          }
        } else {
          leagues.forEach(e => {
            enrolled.push(e._id);
          })
        }
        values.enrolled = enrolled;
        const payload = {
          players: [values]
        }
        setLoading(true);
        if (selectedItem?._id) {
          delete values.player;
          delete values.team;
          // eslint-disable-next-line 
          if(values?.jersey_no != initialValues?.jersey_no) values.jerseyUpdated = true
          await apiFormDataClient.put(ApiConfig.player.updatePlayer.replace('team_id', selectedTeam) + `/${selectedItem._id}`, values);
        } else {
          await apiFormDataClient.post(ApiConfig.player.addPlayer.replace('team_id', selectedTeam), payload);
        }
        setLoading(false);
        form.setFieldsValue({
          ...{}
        });
        setpVisible(false);
        const teamDatas = await loadTeams();
        const idx = teamDatas.findIndex(ee => ee._id === selectedTeam);
        if (idx >= 0) {
          console.log('teamDatas[idx]', teamDatas[idx]);
          setCurrentTeam(teamDatas[idx]);
        }
        setTimeout(() => {
          $('.tab-blk .ant-tabs-tab-btn').eq(1).trigger('click')
        }, 100)
      } catch (error) {
        setLoading(false);
      }
    }

    const onSelect = (value = '') => {
      const tmp = players.filter(e => value.indexOf(e.email) >= 0)[0];
      form.setFieldsValue({
        ...form.getFieldsValue(),
        player: tmp._id
      });
    };

    const handleSearch = async (value = '') => {
      if (value.length < 2) {
        setPlayers([...[]])
        return;
      };
      try {
        setSearching(true);
        const { data } = await apiClient.get(`${ApiConfig.player.playerSearch}?q=${value}`);
        setPlayers([...data]);
        setSearching(false);
      } catch (error) {
        setSearching(false);
      }
    }

    const onValuesChange = (changedValues) => {
      if (changedValues.is_captain) {
        form.setFieldsValue({
          ...form.getFieldValue(),
          is_starting_lineup: true
        })
      }
    }

    useEffect(() => {
      console.log('initialValues', initialValues);
      if (initialValues) {
        form.setFieldsValue({
          ...initialValues,
          player: getFullName(initialValues?.player),
          enrolled: initialValues?.enrolled?.map(e => e.name)
        })
      } else {
        const tabnm = $('.player-tabs .ant-tabs-tab-active .ant-tabs-tab-btn').text();
        form.setFieldsValue({
          enrolled: [tabnm]
        })
      }
      // eslint-disable-next-line
    }, [initialValues])

    return (
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          ...initialValues,
          is_captain: false,
          is_starting_lineup: false,
          team: selectedTeam
        }}
        onValuesChange={onValuesChange}
        onFinish={onSubmitPlayer}
      >
        <Form.Item
          name="team"
          label="Team"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select disabled options={teams.map(e => ({ label: e.name, value: e?._id }))} placeholder="Select Team" />
        </Form.Item>
        <Form.Item
          name="enrolled"
          label="League"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select mode="multiple" options={playTypes.map(e => ({ label: e, value: e }))} placeholder="Select league" />
        </Form.Item>
        {!selectedItem?._id && <Form.Item className='loader-form-block'>
          <Form.Item
            name="player"
            label="Select Player"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <AutoComplete
              options={geOptions(players)}
              onSelect={onSelect}
              onSearch={(e) => debouncedSearch(e)}
              dropdownClassName="mobile-autocomplete"
            >
              <Input placeholder="Search player by name or email" />
            </AutoComplete>
            {searching && (
              <Form.Item className='loader-form-item' noStyle>
                <span className="input-loader">
                  <LoadingOutlined />
                </span>
              </Form.Item>
            )}
          </Form.Item>
        </Form.Item>}
        {selectedItem?._id && <Form.Item
          name="player"
          label="Player Name"
        >
          <Input disabled />
        </Form.Item>}
        <Form.Item
          name="jersey_no"
          label="Jersey Number"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input maxLength={3} placeholder="Enter a jersey number" onChange={()=>setJerseyUpdated(true)}/>
        </Form.Item>
        <Form.Item
          name="is_starting_lineup"
          valuePropName='checked'
        >
          <Checkbox>Add to Starting Lineup</Checkbox>
        </Form.Item>
        <Form.Item
          name="is_captain"
          valuePropName='checked'
        >
          <Checkbox>Team Manager</Checkbox>
        </Form.Item>
        <div className="form-footer">
          <Button loading={loading} type="primary" htmlType="submit">{selectedItem?._id ? 'Update Player' : 'Add Player'}</Button>
        </div>
      </Form>
    )
  }

  const onAdd = () => {
    setSelectedItem(null);
    if (tabKey === '1') {
      setVisible(true)
    } else {
      setpVisible(true);
    }
  }

  const onChangeTab = (key) => {
    setTabKey(key);
  }

  const onChangeTeam = (e) => {
    setSelectedTeam(e);
    setCurrentTeam(teams.filter(ee => ee._id === e)[0]);
  }

  const FilterBlock = () => (
    <div className='top-filter-block'>
      <div className='filter-label'>Select Team</div>
      <Select
        value={selectedTeam}
        onChange={onChangeTeam}
        options={teams.map(e => ({ label: e.name, value: e._id }))}
        placeholder="Select Team"
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>


  )

  useEffect(() => {
    loadTeams().then(data => {
      setSelectedTeam(data[0]?._id)
      setCurrentTeam(data[0]);
    });
    loadLeagues();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="teams_player_page">
      <SectionTitle
        title="Manage Teams"
        subtitle="List of Teams"
        right={
          <>
            {tabKey === '1' && <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Team</span>
            </button>}
            {tabKey === '2' && <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Player</span>
            </button>}
          </>
        }
      />
      {loading && <PageLoader />}
      {!loading && <>
        <RemoveModal description="Are you sure you want to delete?" visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
        <Tabs className="tab-blk" onChange={onChangeTab} tabBarExtraContent={tabKey === '2' ? <FilterBlock /> : <div className="ant-item"><Input value={search} onChange={e => setSearch(e.target.value)} style={{ minWidth: "300px" }} placeholder="Search team by name.." /></div>}>
          <Tabs.TabPane tab="Teams" key="1">
            <TeamsTab loading={loading} playTypes={playTypes} teams={search ? teams.filter(e => e.name.toLowerCase().indexOf(search?.toLowerCase()) >= 0) : teams} columns={columns} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Players" key="2">
            <PlayerTab playTypes={playTypes} currentTeam={currentTeam} playerColumns={playerColumns} />
          </Tabs.TabPane>
        </Tabs>
        <Drawer
          title={`${selectedItem?._id ? 'Update' : 'Add'} Team`}
          visible={visible}
          onClose={() => setVisible(false)}
        >
          {visible && <FormElements initialValues={selectedItem} />}
        </Drawer>
        <Drawer
          title={`${selectedItem?._id ? 'Update' : 'Add'} Player`}
          visible={pVisible}
          onClose={() => setpVisible(false)}
        >
          {pVisible && <FormPlayerElements initialValues={selectedItem} />}
        </Drawer>
      </>}
    </div>
  )
}

export default ManageTeams;