import React, { useEffect, useState } from "react";

const PageLoader = ({ className = '' }) => {

  const [ht, setHt] = useState(window.innerHeight - 150);

  useEffect(() => {
    setHt(window.innerHeight - 150);
  }, [])

  return (
    <div style={{ height: ht }} className={`page-loader ${className}`}>
      <div className="page-loader-overlay" />
      <img alt="loader" src="/images/loader.svg" />
    </div>
  )
}

export default PageLoader;