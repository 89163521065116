import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiUser, FiLogIn } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import getMenuItems from '../../services/menu'

const AccountLinks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const items = [
    // {
    //   url: '/',
    //   icon: <FiMail size={18} className="stroke-current" />,
    //   name: 'Inbox',
    //   badge: {
    //     number: 2,
    //     color: 'bg-red-500 text-white'
    //   }
    // },
    // {
    //   url: '/',
    //   icon: <FiStar size={18} className="stroke-current" />,
    //   iconColor: 'default',
    //   name: 'Messages',
    //   badge: {
    //     number: 3,
    //     color: 'bg-blue-500 text-white'
    //   }
    // },
    {
      url: '/user-profile',
      icon: <FiUser size={18} className="stroke-current" />,
      name: 'Profile',
      link: true,
      badge: null
    },
    {
      url: '/login',
      icon: <FiLogIn size={18} className="stroke-current" />,
      name: 'Logout',
      link: false,
      badge: null
    }
  ]

  const logout = async () => {
    await apiClient.delete(ApiConfig.auth.logout);
    localStorage.clear();
    dispatch({
      type: 'clear',
      payload: getMenuItems()
    })
    setTimeout(() => {
      history.replace('/login');
    }, 100)
  }

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li key={i} className="dropdown-item">
            {item?.link && <Link
              to={item.url}
              className="flex flex-row txt-black items-center justify-start h-10 w-full px-2">
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                <span
                  className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
              )}
            </Link>}
            {
              !item?.link && (
                // eslint-disable-next-line
                <a
                  aria-hidden
                  className='flex txt-black flex-row items-center justify-start h-10 w-full px-2'
                  onClick={() => logout()}>
                  {item.icon}
                  <span className="mx-2">{item.name}</span>
                </a>
              )
            }
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AccountLinks
