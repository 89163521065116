import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// import store from 'store';
import {
  Table,
  Tag,
  Tabs,
  DatePicker,
  Button,
  Drawer,
  Form,
  InputNumber,
  Modal,
  Tooltip,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
//import $ from 'jquery';
import SectionTitle from "../../components/section-title";
import ApiConfig from "../../services/ApiConfig";
import apiClient from "../../services/axios";
import { getDateFormat, getFullName } from "../../utils";
// import ConfirmationModal from '../../common/confirm-modal'
import { TeamNameWithLogo } from "../../components/teams";
import ConfirmationModal from "../../common/confirm-modal";

export const renderMobileTable = (columns) => {
  return columns.filter(
    (column) =>
      column.key === "actions" ||
      column.key === "team" ||
      column.key === "date" ||
      column.key === "season" ||
      column.key === "league" ||
      column.key === "tier_type" ||
      column.key === "tier" ||
      column.key === "round" ||
      column.key === "status"
  );
};

export const renderDesktopTable = (columns) => {
  return columns.filter(
    (column) =>
      column.key === "team" ||
      column.key === "date" ||
      column.key === "season" ||
      column.key === "league" ||
      column.key === "tier_type" ||
      column.key === "tier" ||
      column.key === "round" ||
      column.key === "status" ||
      column.key === "actions"
  );
};

const MatchesPage = () => {
  const locationRef = useLocation();
  const history = useHistory();
  const [selectedMatch, setSelectedMatch] = useState(null);
  // const [leagues, setLeagues] = useState([]);
  // const [seasons, setSeasons] = useState([]);
  const [listing, setListing] = useState(false);
  const [count, setCount] = useState(0);
  const [matchFilterApplied, setMatchFilterApplied] = useState(null);
  const [matches, setMatches] = useState([]);
  // const [matcheTeams, setMatchTeams] = useState([]);
  const [filters, setFilters] = useState({
    season: undefined,
    league: undefined,
    from: "",
    to: "",
  });
  console.log(listing);

  const [matchstats, setmatchstats] = useState({});
  const [openmatchstats, setopenmatchstats] = useState(false);

  const [columns, setColumns] = useState([
    {
      title: "Game",
      dataIndex: "team",
      key: "team",
      filterSearch: true,
      filters: [],
      fixed: "left",
      width: "350px",
      render: (text, gl) => {
        return (
          <>
            <span
              class={`pl-team-name pl-team${
                gl?.home?.team?._id === gl?.won_by?.team._id ? " w-team" : ""
              }`}
            >
              {gl?.home?.team?.name}({gl?.home?.score})
            </span>
            vs.
            <span
              class={`pl-team-name pl-team pl-away${
                gl?.away?.team?._id === gl?.won_by?.team._id ? " w-team" : ""
              }`}
            >
              {gl?.away?.team?.name}({gl?.away?.score})
            </span>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "200px",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (text) =>
        getDateFormat({ date: text, format: "MMM DD, YYYY hh:mm a" }),
    },
    {
      title: "Season",
      dataIndex: "season",
      key: "season",
      filters: [],
      filterSearch: true,
      width: "160px",
      render: (text, record) => record?.sports_event?.name,
    },
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      filterSearch: true,
      filters: [],
      width: "120px",
      render: (text, record) => record?.league?.name,
    },
    {
      title: "Type",
      dataIndex: "tier_type",
      key: "tier_type",
      width: "100px",
      filters: ["Regular", "CO-ED"].map((ee) => ({ text: ee, value: ee })),
      // render: (text, record) => record?.tier_type
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      width: "100px",
      filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
        (ee) => ({ text: `Tier ${ee}`, value: ee.toString() })
      ),
      filterSearch: true,
      render: (text, record) =>
        record?.tier
          ? record?.tier === "N/A"
            ? `Tier ${record?.home?.tier} & ${record?.away?.tier}`
            : `Tier ${record?.tier}`
          : "-",
    },
    {
      title: "Round",
      dataIndex: "round",
      key: "round",
      width: "100px",
      render: (text, record) => {
        if (record?.round_no === 0) return "Playoffs";
        if (record?.round_no === -1) return "N/A";
        return `Round ${record.round_no}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      filters: [
        "Confirmed",
        "Scheduled",
        "Assigned",
        "Started",
        "Completed",
        "Cancelled",
      ].map((ee) => ({ text: ee, value: ee.toString() })),
      render: (text) => <Tag color="#262626">{text}</Tag>,
    },
    {
      title: "Actions",
      width: "80px",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <>
            <Button
              className="m-act"
              onClick={() => onSelectMatch(record)}
              icon={<EditOutlined />}
            />
            {/* <Button className='m-act ml-2' onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} /> */}
          </>
        );
      },
    },
  ]);

  const [columns2] = useState([
    {
      title: "Actions",
      width: "80px",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <>
            <Button
              className="m-act"
              onClick={() => onSelectMatch(record)}
              icon={<EditOutlined />}
            />
            {/* <Button className='m-act ml-2' onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} /> */}
          </>
        );
      },
    },
    {
      title: "Game",
      dataIndex: "team",
      key: "team",
      filterSearch: true,
      filters: [],
      width: "350px",
      render: (text, gl) => {
        return (
          <>
            <span
              class={`pl-team-name pl-team${
                gl?.home?.team?._id === gl?.won_by?.team._id ? " w-team" : ""
              }`}
            >
              {gl?.home?.team?.name}({gl?.home?.score})
            </span>
            vs.
            <span
              class={`pl-team-name pl-team pl-away${
                gl?.away?.team?._id === gl?.won_by?.team._id ? " w-team" : ""
              }`}
            >
              {gl?.away?.team?.name}({gl?.away?.score})
            </span>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "200px",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (text) =>
        getDateFormat({ date: text, format: "MMM DD, YYYY hh:mm a" }),
    },
    {
      title: "Season",
      dataIndex: "season",
      key: "season",
      filters: [],
      filterSearch: true,
      width: "160px",
      render: (text, record) => record?.sports_event?.name,
    },
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      filterSearch: true,
      filters: [],
      width: "120px",
      render: (text, record) => record?.league?.name,
    },
    {
      title: "Type",
      dataIndex: "tier_type",
      key: "tier_type",
      width: "100px",
      filters: ["Regular", "CO-ED"].map((ee) => ({ text: ee, value: ee })),
      // render: (text, record) => record?.tier_type
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      width: "100px",
      filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
        (ee) => ({ text: `Tier ${ee}`, value: ee.toString() })
      ),
      filterSearch: true,
      render: (text, record) =>
        record?.tier
          ? record?.tier === "N/A"
            ? `Tier ${record?.home?.tier} & ${record?.away?.tier}`
            : `Tier ${record?.tier}`
          : "-",
    },
    {
      title: "Round",
      dataIndex: "round",
      key: "round",
      width: "100px",
      render: (text, record) => {
        if (record?.round_no === 0) return "Playoffs";
        if (record?.round_no === -1) return "N/A";
        return `Round ${record.round_no}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      filters: [
        "Confirmed",
        "Scheduled",
        "Assigned",
        "Started",
        "Completed",
        "Cancelled",
      ].map((ee) => ({ text: ee, value: ee.toString() })),
      render: (text) => <Tag color="#262626">{text}</Tag>,
    },
  ]);

  const isMobile = window.innerWidth < 500;
  const isDesktop = window.innerWidth > 500;
  // eslint-disable-next-line no-unused-vars
  let gameColumns = renderDesktopTable(columns);
  if (isMobile) {
    gameColumns = renderMobileTable(columns2);
  }
  if (isDesktop) {
    gameColumns = renderDesktopTable(columns);
  }

  useEffect(() => {
    loadLeagues();
    loadSeasons();
    loadTeams();
    // const user = store.get('user.data');
    // if (user?.role !== 'admin') {
    // columns.pop();
    setColumns([...columns]);
    // }
    // eslint-disable-next-line
  }, []);

  const onSelectMatch = (item) => {
    setSelectedMatch(item);
  };

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.leagues);
    columns[3].filters = data
      .filter((e) => e.is_active)
      .map((ee) => ({ text: ee.name, value: ee._id }));
    setColumns([...columns]);
    // setLeagues([...data]);
  };

  const loadSeasons = async () => {
    const { data } = await apiClient.get(ApiConfig.seasons);
    columns[2].filters = data
      .filter((e) => e.is_active)
      .map((ee) => ({ text: ee.name, value: ee._id }));
    setColumns([...columns]);
    // setSeasons([...data]);
  };
  const markplayerdnp = async (stats, key, dnp) => {
    console.log(stats,key,dnp,'stats,key,dnp',matchstats )
    const matchstatstemp = { ...matchstats };
    let payload = { ...stats, key: key, dnp: dnp };
    const ind = matches.findIndex((item) => item._id === stats.matchid);
    const list = [...matches];
    const inds = list[ind][stats.team].players.findIndex((item) => item.stats._id === stats._id);
    matchstatstemp[stats.team].players[inds].stats.dnp = dnp;
    list[ind][stats.team].players[inds].stats.dnp = dnp;
    setMatches([...list]);
    setmatchstats({ ...matchstatstemp });
    const { data } = await apiClient.post(ApiConfig.matches.updatednp, payload);
    console.log(data);
  };

  const MatchView = ({ match, key }) => {
    const [columns, setColumns] = useState([]);

    useEffect(() => {
      // const stats = (
      //   Object.keys(match?.home?.players[0]?.stats || {}) || []
      // ).filter((e) => ["_id", "created_at", "updated_at"].indexOf(e) === -1);
      // console.log(stats,'statsstats')
      // const allowedStatsHeaders = ['name','blk','stl','reb','pts','ast']
      const dnpdisbalechecker = (stats) => {
        return (
          stats.pts ||
          stats.oreb ||
          stats.dreb ||
          stats.stl ||
          stats.blk ||
          stats.ast
        );
      };
      const columns =
        match?.status === "Completed"
          ? [
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>
                    Players
                  </span>
                ),
                dataIndex: "name",
                fixed: "left",
                width: isMobile ? "100px" : undefined,
                key: "name",
                render: (record, stats, key) => (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            width: isMobile ? "100px" : undefined,
                          }
                        : {
                            cursor: dnpdisbalechecker(stats)
                              ? "auto"
                              : "pointer",
                          }
                    }
                    onDoubleClick={(e) =>
                      dnpdisbalechecker(stats)
                        ? undefined
                        : markplayerdnp(stats, key, !Boolean(stats?.dnp))
                    }
                  >
                    <Tooltip
                      title={
                        dnpdisbalechecker(stats)
                          ? ""
                          : Boolean(stats?.dnp)
                          ? "Double click to unmark player DNP"
                          : "Double click to mark player DNP"
                      }
                    >{`${record.split(" ")?.[0]} ${
                      record.split(" ")?.[1]?.[0]
                    }.`}</Tooltip>
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>PTS</span>
                ),
                dataIndex: "pts",
                key: "pts",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "DNP" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>REB</span>
                ),
                dataIndex: "reb",
                key: "reb",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>AST</span>
                ),
                dataIndex: "ast",
                key: "ast",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>STL</span>
                ),
                dataIndex: "stl",
                key: "stl",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>BLK</span>
                ),
                dataIndex: "blk",
                key: "blk",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>PF</span>
                ),
                dataIndex: "pf",
                key: "pf",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record ?? 0}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>TF</span>
                ),
                dataIndex: "tf",
                key: "tf",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record ?? 0}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>TO</span>
                ),
                dataIndex: "to",
                key: "to",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>OREB</span>
                ),
                dataIndex: "oreb",
                key: "oreb",
                render: (record, stats) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(stats?.dnp) ? "" : record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>DREB</span>
                ),
                dataIndex: "dreb",
                key: "dreb",
                render: (record, stats) =>
                  Boolean(stats?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {record}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>FG</span>
                ),
                dataIndex: "fgm",
                key: "fgm",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {record.fgm}/{record.fga}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>FG%</span>
                ),
                dataIndex: "fga",
                key: "fga",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {Boolean(record.fga)
                        ? parseFloat(record.fgm / record.fga).toFixed(3)
                        : parseFloat(0).toFixed(3)}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>2P</span>
                ),
                dataIndex: "2ptm",
                key: "2ptm",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {Boolean(record["2ptm"]) ? record["2ptm"] : 0}/
                      {Boolean(record["2pta"]) ? record["2pta"] : 0}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>2P%</span>
                ),
                dataIndex: "2pta",
                key: "2pta",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {Boolean(record["2pta"])
                        ? parseFloat(record["2ptm"] / record["2pta"]).toFixed(3)
                        : parseFloat(0).toFixed(3)}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>3P</span>
                ),
                dataIndex: "3ptm",
                key: "3ptm",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {Boolean(record["3ptm"]) ? record["3ptm"] : 0}/
                      {Boolean(record["3pta"]) ? record["3pta"] : 0}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>3P%</span>
                ),
                dataIndex: "3pta",
                key: "3pta",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {Boolean(record["3pta"])
                        ? parseFloat(record["3ptm"] / record["3pta"]).toFixed(3)
                        : parseFloat(0).toFixed(3)}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>FT</span>
                ),
                dataIndex: "ftm",
                key: "ftm",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {record.ftm}/{record.fta}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>FT%</span>
                ),
                dataIndex: "fta",
                key: "fta",
                render: (text, record) =>
                  Boolean(record?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {Boolean(record.fta)
                        ? parseFloat(record.ftm / record.fta).toFixed(3)
                        : parseFloat(0).toFixed(3)}
                    </div>
                  ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>EF</span>
                ),
                dataIndex: "ef",
                key: "ef",
                render: (record, stats) =>
                  Boolean(stats?.dnp) ? (
                    <></>
                  ) : (
                    <div style={isMobile ? { fontSize: "12px" } : undefined}>
                      {record}
                    </div>
                  ),
              },
            ]
          : [
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>
                    Players
                  </span>
                ),
                dataIndex: "name",
                fixed: "left",
                width: isMobile ? "100px" : undefined,
                key: "name",
                render: (record) => (
                  <div
                    style={
                      isMobile
                        ? {
                            fontSize: "12px",
                            width: isMobile ? "100px" : undefined,
                          }
                        : undefined
                    }
                  >
                    {`${record.split(" ")?.[0]} ${
                      record.split(" ")?.[1]?.[0]
                    }.`}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>2P</span>
                ),
                dataIndex: "2ptm",
                key: "2ptm",
                render: (text, record) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(record["2ptm"]) ? record["2ptm"] : 0}/
                    {Boolean(record["2pta"]) ? record["2pta"] : 0}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>3P</span>
                ),
                dataIndex: "3ptm",
                key: "3ptm",
                render: (text, record) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {Boolean(record["3ptm"]) ? record["3ptm"] : 0}/
                    {Boolean(record["3pta"]) ? record["3pta"] : 0}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>TO</span>
                ),
                dataIndex: "to",
                key: "to",
                render: (record) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {record}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>PF</span>
                ),
                dataIndex: "pf",
                key: "pf",
                render: (record) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {record ?? 0}
                  </div>
                ),
              },
              {
                title: (
                  <span style={{ fontWeight: "700", color: "#000" }}>TF</span>
                ),
                dataIndex: "tf",
                key: "tf",
                render: (record) => (
                  <div style={isMobile ? { fontSize: "12px" } : undefined}>
                    {record ?? 0}
                  </div>
                ),
              },
            ];
      // /{(record.pts+record.rebs+record.ast+record.stl+record.blk+record.fga+record.fgm)-(record.to+record.ftm+record.fgm)}
      setColumns([...columns]);
      // eslint-disable-next-line
    }, []);

    return (
      <div
        key={key}
        className="match-detail-view"
        style={
          isMobile
            ? { marginLeft: "-20px", padding: "0px", width: "calc(95vw)" }
            : undefined
        }
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            key="1"
            tab={
              <div
                className={`mteam-name${
                  match?.home?.team?._id === match?.won_by?.team._id
                    ? " w-team"
                    : ""
                }`}
              >
                {isMobile ? (
                  <span>
                    {<TeamNameWithLogo {...match?.home?.team} />} (
                    {match?.home?.score || 0})
                  </span>
                ) : (
                  <Tag color="green">
                    {<TeamNameWithLogo {...match?.home?.team} />} (
                    {match?.home?.score || 0})
                  </Tag>
                )}
              </div>
            }
          >
            <div className="mteam-item">
              {match?.jerseyviolation?.home ? (
                <>
                  <div
                    style={{
                      fontSize: "20px",
                      color: "#AA4A44",
                      fontWeight: "700",
                    }}
                  >
                    Stats Violation
                  </div>
                  <div
                    style={{
                      color: "#AA4A44",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "22px",
                      fontColor: "#000",
                    }}
                  >
                    {Boolean(match?.jerseyviolation?.homemessage)
                      ? match?.jerseyviolation?.homemessage
                      : "Insufficient Players: The team roster lacks the minimum requirement of 5 players."}
                  </div>
                </>
              ) : (
                <Table
                  pagination={false}
                  dataSource={match?.home?.players?.map((e) => ({
                    name: getFullName(e.player),
                    ...e.stats,
                    pf: e.personal_fouls,
                    tf: e.technical_fouls,
                    matchid: match._id,
                    team: "home",
                  })).sort((a,b)=>a.dnp===b.dnp?((a.pts<b.pts)?0:-1):((a.dnp>b.dnp)?0:-1))}
                  columns={columns}
                  style={
                    isMobile
                      ? { marginLeft: "-3px", width: "calc(95vw)" }
                      : undefined
                  }
                />
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="2"
            tab={
              <div
                className={`mteam-name${
                  match?.away?.team?._id === match?.won_by?.team._id
                    ? " w-team"
                    : ""
                }`}
              >
                {isMobile ? (
                  <span>
                    {<TeamNameWithLogo {...match?.away?.team} />} (
                    {match?.away?.score || 0})
                  </span>
                ) : (
                  <Tag color="blue">
                    {<TeamNameWithLogo {...match?.away?.team} />} (
                    {match?.away?.score || 0})
                  </Tag>
                )}
              </div>
            }
          >
            <div className="mteam-item">
              {match?.jerseyviolation?.away ? (
                <>
                  <div
                    style={{
                      fontSize: "20px",
                      color: "#AA4A44",
                      fontWeight: "700",
                    }}
                  >
                    Stats Violation
                  </div>
                  <div
                    style={{
                      color: "#AA4A44",
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "22px",
                      fontColor: "#000",
                    }}
                  >
                    {/* <span style={{marginTop:'-70px',marginRight:'-145px', fontSize:'20px',color:'#AA4A44',textDecoration:'underline'}}>Stats Violation</span> */}
                    {Boolean(match?.jerseyviolation?.awaymessage)
                      ? match?.jerseyviolation?.awaymessage
                      : "Insufficient Players: The team roster lacks the minimum requirement of 5 players."}
                  </div>
                </>
              ) : (
                <Table
                  pagination={false}
                  dataSource={match?.away?.players?.map((e) => ({
                    name: e.is_me ? (
                      <span style={{ color: "#4bb543" }}>
                        {getFullName(e.player)}(You)
                      </span>
                    ) : (
                      getFullName(e.player)
                    ),
                    ...e.stats,
                    pf: e.personal_fouls,
                    tf: e.technical_fouls,
                    matchid: match._id,
                    team: "away",
                  }))}
                  columns={columns}
                />
              )}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  };
  const standingsColumnsTitleStyle = isMobile
    ? { color: "#000000", fontSize: "12px", fontWeight: "500" }
    : { color: "#000000", fontSize: "18px", fontWeight: "700" };
  const logColumns = [
    {
      title: <span style={standingsColumnsTitleStyle}>Game</span>,
      // dataIndex: "team",
      // key: "team",
      width: isMobile ? "200px" : "40%",
      //filterSearch: true,
      //filters: [],
      fixed: "left",
      render: (record) => {
        return (
          <div style={{ display: "flex", justifyContent: "normal" }}>
            <span>{TeamNameWithLogo(record.home.team)}</span>{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
                padding: "2px 5px",
              }}
            >
              {" "}
              vs{" "}
            </span>{" "}
            <span>{TeamNameWithLogo(record.away.team)}</span>
          </div>
        );
      },
    },
    {
      title: <span style={standingsColumnsTitleStyle}>Status</span>,
      dataIndex: "status",
      key: "status",
      width: isMobile ? "100px" : undefined,
      //width: "80px",
      //filters: ["Confirmed", "Scheduled", "Assigned", "Started", "Completed", "Cancelled"].map(ee => ({ text: ee, value: ee.toString() })),
      render: (text, record) => (
        <Tag
          style={{ minWidth: "80px", width: "80px", textAlign: "center" }}
          onClick={(e) => {
            console.log("338hel");
            setmatchstats(record);
            setopenmatchstats(true);
          }}
          color={
            text === "Completed"
              ? (record?.home?.players?.length > 0 ||
                  record?.away?.players?.length > 0) &&
                !record?.restrict_publish
                ? "#006400"
                : "orange"
              : text === "Cancelled"
              ? "#AA4A44"
              : text === "Started"
              ? "orange"
              : text === "Assigned"
              ? "orange"
              : text === "Scheduled"
              ? "darkblue"
              : "#262626"
          }
        >
          {text === "Scheduled"
            ? text
            : (record?.home?.players?.length > 0 ||
                record?.away?.players?.length > 0) &&
              !record?.restrict_publish
            ? text === "Completed"
              ? "View Stats"
              : text === "Started"
              ? "Live Stats"
              : text
            : "Pending"}
        </Tag>
      ),
    },
    {
      title: <span style={standingsColumnsTitleStyle}>Season</span>,
      dataIndex: "season",
      key: "season",
      width: isMobile ? "150px" : undefined,
      //width: "200px",
      //sorter: true,
      //sortDirections: ["ascend", "descend"],
      render: (text,record) =>
        <div>{record?.sports_event?.name}</div>,
    },
    {
      title: <span style={standingsColumnsTitleStyle}>League</span>,
      dataIndex: "league",
      key: "league",
      width: isMobile ? "150px" : undefined,
      //width: "200px",
      //sorter: true,
      //sortDirections: ["ascend", "descend"],
      render: (text,record) =>
        <div>{record?.league?.name}</div>,
    },
    {
      title: <span style={standingsColumnsTitleStyle}>Date</span>,
      dataIndex: "date",
      key: "date",
      width: isMobile ? "150px" : undefined,
      //width: "200px",
      //sorter: true,
      //sortDirections: ["ascend", "descend"],
      render: (text) =>
        getDateFormat({ date: text, format: "MMM DD, YYYY hh:mm a" }),
    },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>Season</span>,
    //   dataIndex: "season",
    //   key: "season",
    //   width: isMobile ? "100px" : undefined,
    //   //filters: [],
    //   //filterSearch: true,
    //   //width: "120px",
    //   render: (text, record) => record?.sports_event?.name,
    // },
    // {
    //   title: <span style={standingsColumnsTitleStyle}>League</span>,
    //   dataIndex: "league",
    //   key: "league",
    //   width: isMobile ? "100px" : undefined,
    //   //filterSearch: true,
    //   //filters: [],
    //   //width: "160px",
    //   render: (text, record) => record?.league?.name,
    // },
    // {
    //   title:  <span style={standingsColumnsTitleStyle}>Type</span>,
    //   dataIndex: "tier_type",
    //   key: "tier_type",
    //   width: "50px",
    //   //filters: ["Regular", "CO-ED"].map(ee => ({ text: ee, value: ee }))
    //   // render: (text, record) => record?.tier_type
    // },
    {
      title: <span style={standingsColumnsTitleStyle}>Tier</span>,
      dataIndex: "tier",
      key: "tier",
      width: isMobile ? "100px" : undefined,
      //width: "100px",
      //filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(ee => ({ text: `Tier ${ee}`, value: ee.toString() })),
      //filterSearch: true,
      render: (text, record) =>
        record?.tier
          ? record?.tier === "N/A"
            ? `Tier ${record?.home?.tier} & ${record?.away?.tier}`
            : `Tier ${record?.tier}`
          : "-",
    },
    // {
    //   title:  <span style={standingsColumnsTitleStyle}>Round</span>,
    //   dataIndex: "round",
    //   key: "round",
    //   width: "100px",
    //   render: (text, record) => {
    //     if (record?.round_no === 0) return 'Playoffs'
    //     if (record?.round_no === -1) return 'N/A'
    //     return `Round ${record.round_no}`
    //   }
    // },
  ];

  const loadMatches = async (filters = "") => {
    setListing(true);
    const { data } = await apiClient.get(ApiConfig.matches.matchLogs + filters);
    setCount(data.count);
    // const teams = [];
    // const tmp = data?.results?.map(ee => {
    //   if (teams.findIndex(tt => tt.value === ee.away.team._id) === -1) {
    //     teams.push({
    //       text: ee.away.team.name,
    //       value: ee.away.team._id
    //     })
    //   }
    //   if (teams.findIndex(tt => tt.value === ee.home.team._id) === -1) {
    //     teams.push({
    //       text: ee.home.team.name,
    //       value: ee.home.team._id
    //     })
    //   }
    //   return { ...ee, key: ee._id }
    // })
    // console.log('teams', teams);
    // if (!filters) {
    //   columns[0].filters = teams;
    //   // setMatchTeams(teams);
    //   setColumns([...columns])
    // }
    const tmp = data?.results?.map((ee) => ({ ...ee, key: ee._id }));
    setMatches([...tmp]);
    setListing(false);
  };

  const loadTeams = async () => {
    const { data } = await apiClient.get(ApiConfig.player.teams);
    columns[0].filters = data?.map((ee) => ({ text: ee.name, value: ee._id }));
    setColumns([...columns]);
  };

  const showAll = () => {
    setMatchFilterApplied(false);
    history.replace();
    loadMatches();
  };

  // const onDeleteMatch = async (record) => {
  //   const ok = await ConfirmationModal("Are you sure you want to delete?");
  //   if (ok) {
  //     await apiClient.delete(ApiConfig.matches.list + `/${record._id}`);
  //     loadMatches();
  //   }
  // }

  // const onSearch = (search) => {
  //   console.log('search', search);
  //   setFilters({ ...filters, team_search: search })
  // }

  useEffect(() => {
    const tmp = {};
    console.log("locationRef.state?.match_id", locationRef.state?.match_id);
    Object.keys(filters).forEach((ee) => {
      if (filters[ee]) {
        tmp[ee] =
          ee === "from" || ee === "to"
            ? getDateFormat({ date: filters[ee], format: "YYYY-MM-DD" })
            : filters[ee];
      }
    });
    let params = new URLSearchParams(tmp).toString();
    params = params ? "?" + params : "";
    if (locationRef.state?.match_id) {
      setMatchFilterApplied(locationRef.state?.match_id);
      if (!params) {
        params = `?match_id=${locationRef.state?.match_id}`;
      } else {
        params += `&match_id=${locationRef.state?.match_id}`;
      }
    }
    loadMatches(params);
    // eslint-disable-next-line
  }, [filters]);

  const RightContext = () => (
    <div className="mright-content picker-comp">
      {!matchFilterApplied ? (
        <>
          {/* <Select value={filters.league} onChange={(e) => setFilters({ ...filters, league: e })} allowClear options={leagues.filter(e => e.is_active).map(e => ({ label: e.name, value: e._id }))} className="select-item" placeholder="Select League" />
        <Select value={filters.season} onChange={(e) => setFilters({ ...filters, season: e })} allowClear options={seasons.filter(e => e.is_active).map(e => ({ label: e.name, value: e._id }))} className="ml-2  select-item" placeholder="Select Season" /> */}
          {/* <Input style={{ borderRadius: "4px", minWidth: "300px" }} placeholder='Search teams by name' onChange={(e) => onSearch(e.target.value)} /> */}
          <DatePicker.RangePicker
            onChange={(e) =>
              setFilters({ ...filters, from: e ? e[0] : "", to: e ? e[1] : "" })
            }
            value={[filters.from, filters.to]}
            className="ml-2"
            format="MMM DD, YYYY"
          />
        </>
      ) : (
        <div className="act-btn">
          <Button onClick={showAll} type="primary">
            Show all matches
          </Button>
        </div>
      )}
    </div>
  );

  const UpdateScore = () => {
    const [adding, setAdding] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
      console.log("values", values);
      const ok = await ConfirmationModal(
        "Are you sure you want to update the scores for this match?"
      );
      if (ok) {
        setAdding(true);
        await apiClient.put(
          ApiConfig.matches.updateDetails + `/${selectedMatch._id}`,
          { scores: values, status: "Completed" }
        );
        setSelectedMatch(null);
        setAdding(false);
        loadMatches();
      }
    };

    return (
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        initialValues={{
          home: selectedMatch?.home?.score,
          away: selectedMatch?.away?.score,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="home"
          label={selectedMatch?.home?.team?.name}
          rules={[{ required: true, message: "This field is required" }]}
        >
          <InputNumber
            controls={false}
            maxLength={3}
            placeholder="Enter a jersey number"
          />
        </Form.Item>
        <Form.Item
          name="away"
          label={selectedMatch?.away?.team?.name}
          rules={[{ required: true, message: "This field is required" }]}
        >
          <InputNumber
            controls={false}
            maxLength={3}
            placeholder="Enter a jersey number"
          />
        </Form.Item>
        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button loading={adding} type="primary" htmlType="submit">
            Update Scores
          </Button>
        </div>
      </Form>
    );
  };

  // const handleTableChange = (newPagination, filterObj = {}, sorter) => {
  //   console.log(newPagination, filters, sorter);
  //   for (const key in filterObj) {
  //     filterObj[key] = Array.isArray(filterObj[key]) ? JSON.stringify(filterObj[key]) : filterObj[key]
  //   }
  //   if (sorter?.field) {
  //     filterObj[`sort_${sorter.field}`] = sorter.order === "ascend" ? 1 : -1
  //   }
  //   setFilters({ ...filters, ...filterObj });
  // }
  console.log(matches, "matchesmatches");
  return (
    <div className="matches-page">
      <SectionTitle
        title="Games"
        subtitle={
          !matchFilterApplied ? (
            <>
              List of Games<span>({count})</span>
            </>
          ) : (
            "Match Log"
          )
        }
        right={<RightContext />}
      />
      <Table
        //loading={listing}
        columns={logColumns}
        scroll={{
          y: 400,
        }}
        pagination={{
          total: count,
          defaultPageSize: 20,
          onChange: (current, pageSize) => {
            console.log(current, pageSize);
            filters.offset = (current - 1) * pageSize;
            filters.limit = pageSize;
            setFilters({ ...filters })
          },
          onShowSizeChange: (current, pageSize) => {
            console.log(current, pageSize);
            filters.offset = (current - 1) * pageSize;
            filters.limit = pageSize;
            setFilters({ ...filters })
          }
        }}
        // scroll={{
        //   y: window.innerHeight - 300,
        //   x: window.innerWidth - 292,
        // }}
        dataSource={matches}
        //className="matches-list"
        //onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: (e) => {
        //       if ($(e.target).closest('.m-act').length === 0) {
        //         $(e.target).closest('tr.ant-table-row').find('.ant-table-row-expand-icon').trigger('click')
        //       }
        //     }
        //   }
        // }}
        // expandable={{
        //   rowExpandable: (record) =>
        //     (record?.home?.players?.length > 0 ||
        //       record?.away?.players?.length > 0) &&
        //     !record?.restrict_publish,
        //   //...(matchFilterApplied && { expandedRowKeys: [matchFilterApplied] }),
        //   expandedRowRender: (record, key) => (
        //     <MatchView key={key} match={record} />
        //   ),
        // }}
      />
      <Drawer
        title="Update Game"
        visible={!!selectedMatch}
        onClose={() => setSelectedMatch(null)}
      >
        <UpdateScore />
      </Drawer>
      <Modal
        //title={getModalTitle(modalMode)}
        visible={openmatchstats}
        //closable={showClosable}
        footer={null}
        width={isMobile ? "100%" : "65%"}
        style={
          isMobile
            ? { maxWidth: "calc(100vw - 10px)", backgroundColor: "green" }
            : undefined
        }
        // style={modalMode === modalModeTypes(1)? {top: 20} : null}
        destroyOnClose
        onCancel={() => {
          setopenmatchstats(false);
          setmatchstats({});
        }}
      >
        <MatchView match={matchstats} />
      </Modal>
    </div>
  );
};

export default MatchesPage;
