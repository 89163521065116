/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Empty from '../../../layouts/empty'


const MobileFooter = ({ pageName, CallFooterFunc }) => {

  return (
    <Empty>
      <div className="fixed-footer">
        <div className="container">
          <div className="row" style={{ display: 'flex' }}>
            <div className="col-lg-3 column-style">
              <center>
                <input type="image" style={{ filter: pageName === 'standings' ? 'brightness(1)' : 'brightness(0.50)' }} onClick={() => CallFooterFunc('standings')} src="homepage_images/standings.png" className="footer-img" />
                <span style={{ color: pageName === 'standings' ? '#ffffff' : 'gray' }}>Standings</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <input type="image" style={{ filter: pageName === 'scores' ? 'brightness(1)' : 'brightness(0.50)' }} onClick={() => CallFooterFunc('scores')} src="homepage_images/scores.png" className="footer-img" />
                <span style={{ color: pageName === 'scores' ? '#ffffff' : 'gray' }}>Scores</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <input type="image" style={{ filter: pageName === 'playoffs' ? 'brightness(1)' : 'brightness(0.50)' }} onClick={() => CallFooterFunc('playoffs')} src="homepage_images/playoffs.png" className="footer-img" />
                <span style={{ color: pageName === 'playoffs' ? '#ffffff' : 'gray' }}>Playoffs</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <input type="image" style={{ filter: pageName === 'seasons' ? 'brightness(1)' : 'brightness(0.50)' }} onClick={() => CallFooterFunc('seasons')} src="homepage_images/seasons.png" className="footer-img" />
                <span style={{ color: pageName === 'seasons' ? '#ffffff' : 'gray' }}>Seasons</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <input type="image" style={{ filter: pageName === 'leagues' ? 'brightness(1)' : 'brightness(0.50)' }} onClick={() => CallFooterFunc('leagues')} src="homepage_images/leagues.png" className="footer-img" />
                <span style={{ color: pageName === 'leagues' ? '#ffffff' : 'gray' }}>Leagues</span>
              </center>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="fixed-footer">
        <div className="container">
          <div className="row" style={{ display: 'flex' }}>
            <div className="col-lg-3 column-style">
              <center>
                <img onClick={() => CallFooterFunc('standings')} src="homepage_images/standings.png" className="footer-img" />
                <span>Standings</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <img onClick={() => CallFooterFunc('scores')} src="homepage_images/scores.png" className="footer-img" />
                <span>Scores</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <img onClick={() => CallFooterFunc('playoffs')} src="homepage_images/playoffs.png" className="footer-img" />
                <span>Playoffs</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <img onClick={() => CallFooterFunc('seasons')} src="homepage_images/seasons.png" className="footer-img" />
                <span>Seasons</span>
              </center>
            </div>
            <div className="col-lg-3 column-style">
              <center>
                <img onClick={() => CallFooterFunc('leagues')} src="homepage_images/leagues.png" className="footer-img" />
                <span>Leagues</span>
              </center>
            </div>
          </div>
        </div>
      </div> */}
    </Empty >
  )
}

export default MobileFooter;