import { Tabs } from 'antd';
import React from 'react'

export const Customization = () => {
  const questions = [
    {
      question: 'How to create an account?',
      answer: (
        <div>
          <ul>
            <li>1. Navigate to <a href='https://www.tcbl.ca'>TCBL</a></li>
            <li>2. Click Register at the top right </li>
            <ul>
              <li>a. Enter your first name</li>
              <li>b. Enter your last name</li>
              <li>c. Enter your email address</li>
              <li>d. Create a password</li>
            </ul>
            <li>3. Click Register</li>
            <li>4. Check your email address</li>
            <li>5. Enter the verification code</li>
          </ul>
        </div>
      )
    },
    {
      question: 'How to create a team?',
      answer: (
        <div>
          <ul>
            <li>1. Navigate to <a href='https://www.tcbl.ca'>TCBL</a></li>
            <li>2. Click Signin</li>
            <li>3. Click Manage Teams on the left navigation pane</li>
            <li>4. Click Add Team</li>
            <ul>
              <li>a. Enter your Team Name</li>
              <li>b. Select which league you would like to enroll</li>
              <li>c. Select your Jersey Colour</li>
              <li>d. Upload your team logo</li>
            </ul>
            <li>5. Click Add Team</li>
          </ul>
        </div>
      )
    },
    {
      question: 'How to add players to your team?',
      answer: (
        <div>
          <ul>
            <li>1. Navigate to <a href='https://www.tcbl.ca'>TCBL</a></li>
            <li>2. Click Signin</li>
            <li>3. Click Manage Teams on the left navigation pane</li>
            <li>4. Click the Players tab</li>
            <li>5. Use the Select Team dropdown menu and pick the team</li>
            <li>6. Click Add Player</li>
            <ul>
              <li>a. Select the League where you want to add the player</li>
              <li>b. Enter the player’s email address to search</li>
              <li>c. Enter the player’s Jersey number</li>
              <li>d. Optional: Add the player on your starting lineup or as a captain of your team</li>
              <ul>
                <li>i. Add to Starting Lineup</li>
                <li>ii. Team Manager</li>
              </ul>
            </ul>
            <li>7. Click Send Player Request</li>
            <li>8. Inform the player you have added them to your team and an invitation has been sent</li>
            <li>9. Advise the player to log into the TCBL Player Portal and accept the invitation</li>
          </ul>
        </div>
      )
    },
    {
      question: 'How to register for a season?',
      answer: (
        <div>
          <ul>
            <li>1. Navigate to <a href='https://www.tcbl.ca'>TCBL</a></li>
            <li>2. Click Signin</li>
            <li>3. Click Registration on the left navigation pane</li>
            <li>4. Click the Upcoming tab</li>
            <li>5. Select the Sports Event you want to register your team</li>
            <li>6. Click Register</li>
            <li>7. Select the Team you want to Register</li>
            <li>8. Click Proceed to Payment</li>
            <li>9. e-Transfer the fees to tcblbasketball@gmail.com</li>
            <li>10. Enter the confirmation code</li>
          </ul>
        </div>
      )
    },
    {
      question: 'How to pay?',
      answer: (
        <div>
          <ul>
            <li>1. Follow the registration process</li>
            <li>2. e-Transfer the league fees to tcblbasketball@gmail.com</li>
            <li>3. Enter the confirmation code</li>
          </ul>
        </div>
      )
    },
  ]
  return (
    <div className='faq-ques'>
      <Tabs tabPosition={window.innerWidth > 600 ? "left" : "top"} defaultActiveKey='1'>
        {
          questions.map((ques, key) => (
            <Tabs.TabPane tab={ques.question} key={(key + 1).toString()}>{ques.answer}</Tabs.TabPane>
          ))
        }
      </Tabs>
    </div>
  )
}

export default Customization;