import React from 'react'
import { Button, Form, Input, Upload, message, Select } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const Option = Select

const HomepageBanner = ({ onSubmit, activeBannerImage }) => {

  const props = {
    name: 'file',
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      return isJpgOrPng;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    },
    onChange: (e) => {
      if (e.file.status === 'done') {
        activeBannerImage(e.file.originFileObj)
        window.scheduleFile = e.file.originFileObj;
      }
    },
    onRemove: () => {
      window.scheduleFile = null
    }
  }


  return (
    <>

      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
      >
        <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
          <div className="w-full lg:w-4/4">
            <Form.Item
              label="Type"
              name="type"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                showSearch
                placeholder="Select a type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                <Option key="Banner" value="Banner">Banner</Option>
                <Option key="Landing_Page" value="Landing_Page">Landing Page</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Heading"
              name="banner_heading1"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the banner heading 1" />
            </Form.Item>
            <Form.Item
              label="Sub Heading"
              name="banner_sub_heading1"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input placeholder="Enter the banner heading 2" />
            </Form.Item>
            <Form.Item
              label="Heading"
              name="banner_image1"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Upload.Dragger
                {...props}
                multiple={false}
                maxCount={1}
                accept="image/*"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </Form.Item>
            <div className="form-footer">
              <Button htmlType="submit" type="primary">Save</Button>
            </div>
          </div>
        </div>
      </Form>

    </>
  )
}
export default HomepageBanner
