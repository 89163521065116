import React, { useEffect, useState } from 'react'
// import { useLocation, useHistory } from 'react-router-dom';
// import store from 'store';
import { Table,Button, Drawer, Form, InputNumber, message, Select,DatePicker,notification } from 'antd' //DatePicker, Row, Col Tag, Tabs, ,Radio, Checkbox,
import { EditOutlined } from '@ant-design/icons'
// import $ from 'jquery';
// import SectionTitle from '../../components/section-title'
import ApiConfig from '../../services/ApiConfig'
import apiClient from '../../services/axios'
import { getDateFormat } from '../../utils' //getFullName
// import ConfirmationModal from '../../common/confirm-modal'
// import { TeamNameWithLogo } from '../../components/teams'
import ConfirmationModal from '../../common/confirm-modal';
import moment from 'moment';
const Option = Select
const roundArray = [
  {
    "value": -1,
    "label": "N/A"
  },
  {
    "value": 0,
    "label": "Playoffs"
  },
  {
    "value": 1,
    "label": "Round 1"
  },
  {
    "value": 2,
    "label": "Round 2"
  },
  {
    "value": 3,
    "label": "Round 3"
  },
  {
    "value": 4,
    "label": "Round 4"
  },
  {
    "value": 5,
    "label": "Round 5"
  },
  {
    "value": 6,
    "label": "Round 6"
  },
  {
    "value": 7,
    "label": "Round 7"
  },
  {
    "value": 8,
    "label": "Round 8"
  },
  {
    "value": 9,
    "label": "Round 9"
  },
  {
    "value": 10,
    "label": "Round 10"
  }

]


export const renderMobileTable = columns => {
  return columns.filter(
    column => column.key === "actions" || column.key === "team" || column.key === "date" || column.key === "season" || column.key === "league" || column.key === "tier_type" || column.key === "tier" || column.key === "round" || column.key === "status"
  );
};

export const renderDesktopTable = columns => {
  return columns.filter(
    column => column.key === "team" || column.key === "date" || column.key === "season" || column.key === "league" || column.key === "tier_type" || column.key === "tier" || column.key === "round" || column.key === "status" || column.key === "actions"
  );
};

const MatchesPage = () => {
  // const locationRef = useLocation();
  // const history = useHistory()
  const [selectedMatch, setSelectedMatch] = useState(null);
  // const [leagues, setLeagues] = useState([]);
  // const [seasons, setSeasons] = useState([]);
  const [listing, setListing] = useState(false)
  // const [count, setCount] = useState(0)
  // const [matchFilterApplied, setMatchFilterApplied] = useState(null);
  const [todaymatches, settodayMatches] = useState([]);
  const [recentmatches, setrecentMatches] = useState([]);
  const [upcomingmatches, setupcomingMatches] = useState([]);
  const [courtsList, setcourtsList] = useState([]);
  const [courtselected, setcourtselected] = useState('');
  const [allCourtList, setAllCourtList] = useState([]);
  // const [matcheTeams, setMatchTeams] = useState([]);
  // const [filters, setFilters] = useState({
  //   season: undefined,
  //   league: undefined,
  //   from: "",
  //   to: ""
  // })

  const [columns, setColumns] = useState([
    {
      title: "Game Time",
      dataIndex: "date",
      key: "date",
      width: "50px",
      fixed: "left",
      filterSearch: false,
      // sorter: true,
      // sortDirections: ["ascend", "descend"],
      render: (text) => {return (<><span>{getDateFormat({ date: text, format: 'hh:mm a' })}</span>{text && <span> - {moment(text).add(45,'minutes').format('hh:mm a')}</span>}</>)}
    },
  {
    title: "Home",
    dataIndex: "team",
    key: "team",
    // filterSearch: true,
    // filters: [],
    width: "30px",
    render: (text, gl) => {
      return (
          <span>{gl?.home?.team?.name}</span>
      )
    }
  },
  {
    title: "Home Tier",
    dataIndex: "tier",
    key: "tier",
    // filterSearch: true,
    // filters: [],
      filterSearch: false,
    width: "20px",
    render: (text, gl) => {
      return (
        <>
          <span>{gl?.home?.tier}</span>
        </>
      )
    }
  },
  {
  title: "Away",
  dataIndex: "team",
  key: "team",
  filterSearch: false,
  // filterSearch: true,
  // filters: [],
  width: "30px",
  render: (text, gl) => {
    return (
      <>
        <span>{gl?.away?.team?.name}</span>
      </>
    )
  }
},
  {
    title: "Away Tier",
    dataIndex: "tier",
    key: "tier",
    // filterSearch: true,
    // filters: [],
    width: "20px",
    render: (text, gl) => {
      return (
        <>
          <span>{gl?.away?.tier}</span>
        </>
      )
    }
  },
  {
    title: "Season",
    dataIndex: "season",
    key: "season",
    // filters: [],
    // filterSearch: true,
    width: "30px",
    render: (text, record) => record?.sports_event?.name
  }, 
  {
    title: "League",
    dataIndex: "league",
    key: "league",
    // filterSearch: true,
    // filters: [],
    width: "30px",
    render: (text, record) => record?.league?.name
  }, 
  // {
  //   title: "Type",
  //   dataIndex: "tier_type",
  //   key: "tier_type",
  //   width: "100px",
  //   filters: ["Regular", "CO-ED"].map(ee => ({ text: ee, value: ee }))
  //   // render: (text, record) => record?.tier_type
  // }, {
  //   title: "Tier",
  //   dataIndex: "tier",
  //   key: "tier",
  //   width: "100px",
  //   filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(ee => ({ text: `Tier ${ee}`, value: ee.toString() })),
  //   filterSearch: true,
  //   render: (text, record) => record?.tier ? record?.tier === 'N/A' ? `Tier ${record?.home?.tier} & ${record?.away?.tier}` : `Tier ${record?.tier}` : '-'
  // },
  // {
  //   title: "Round",
  //   dataIndex: "round",
  //   key: "round",
  //   width: "100px",
  //   render: (text, record) => {
  //     if (record?.round_no === 0) return 'Playoffs'
  //     if (record?.round_no === -1) return 'N/A'
  //     return `Round ${record.round_no}`
  //   }
  // },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   width: "100px",
  //   filters: ["Confirmed", "Scheduled", "Assigned", "Started", "Completed", "Cancelled"].map(ee => ({ text: ee, value: ee.toString() })),
  //   render: (text) => <Tag color="#262626">{text}</Tag>
  // },
  // {
  //   title: "Actions",
  //   width: "80px",
  //   dataIndex: "actions",
  //   key: "actions",
  //   render: (text, record) => {
  //     return (
  //       <>
  //         <Button className='m-act' onClick={() => onSelectMatch(record)} icon={<EditOutlined />} />
  //         {/* <Button className='m-act ml-2' onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} /> */}
  //       </>
  //     )
  //   }
  // }
  ])

  const [columns2] = useState([
    {
      title: "Actions",
      width: "80px",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <>
            <Button className='m-act' onClick={() => onSelectMatch(record)} icon={<EditOutlined />} />
            {/* <Button className='m-act ml-2' onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} /> */}
          </>
        )
      }
    },
    {
      title: "Game",
      dataIndex: "team",
      key: "team",
      // filterSearch: true,
      // filters: [],
      width: "350px",
      render: (text, gl) => {
        return (
          <>
            <span class={`pl-team-name pl-team${gl?.home?.team?._id === gl?.won_by?.team._id ? ' w-team' : ''}`}>{gl?.home?.team?.name}({gl?.home?.score})</span>
            vs.
            <span class={`pl-team-name pl-team pl-away${gl?.away?.team?._id === gl?.won_by?.team._id ? ' w-team' : ''}`}>{gl?.away?.team?.name}({gl?.away?.score})</span>
          </>
        )
      }
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "200px",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      render: (text) => getDateFormat({ date: text, format: 'MMM DD, YYYY hh:mm a' })
    }, {
      title: "Season",
      dataIndex: "season",
      key: "season",
      // filters: [],
      // filterSearch: true,
      width: "160px",
      render: (text, record) => record?.sports_event?.name
    }, {
      title: "League",
      dataIndex: "league",
      key: "league",
      // filterSearch: true,
      // filters: [],
      // width: "120px",
      render: (text, record) => record?.league?.name
    }, 
    // {
    //   title: "Type",
    //   dataIndex: "tier_type",
    //   key: "tier_type",
    //   width: "100px",
    //   // filters: ["Regular", "CO-ED"].map(ee => ({ text: ee, value: ee }))
    //   // render: (text, record) => record?.tier_type
    // }, {
    //   title: "Tier",
    //   dataIndex: "tier",
    //   key: "tier",
    //   width: "100px",
    //   filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(ee => ({ text: `Tier ${ee}`, value: ee.toString() })),
    //   filterSearch: true,
    //   render: (text, record) => record?.tier ? record?.tier === 'N/A' ? `Tier ${record?.home?.tier} & ${record?.away?.tier}` : `Tier ${record?.tier}` : '-'
    // },
    // {
    //   title: "Round",
    //   dataIndex: "round",
    //   key: "round",
    //   width: "100px",
    //   render: (text, record) => {
    //     if (record?.round_no === 0) return 'Playoffs'
    //     if (record?.round_no === -1) return 'N/A'
    //     return `Round ${record.round_no}`
    //   }
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: "100px",
    //   filters: ["Confirmed", "Scheduled", "Assigned", "Started", "Completed", "Cancelled"].map(ee => ({ text: ee, value: ee.toString() })),
    //   render: (text) => <Tag color="#262626">{text}</Tag>
    // }

  ])


  const isMobile = window.innerWidth < 500;
  const isDesktop = window.innerWidth > 500;
  let gameColumns = renderDesktopTable(columns);
  if (isMobile) {
    gameColumns = renderMobileTable(columns2);
  }
  if (isDesktop) {
    gameColumns = renderDesktopTable(columns);
  }
  //console.log(gameColumns,'gameColumns')

  useEffect(() => {
    // loadLeagues();
    // loadSeasons();
    // loadTeams();
    // const user = store.get('user.data');
    // if (user?.role !== 'admin') {
    // columns.pop();
    setColumns([...columns])
    loadCourts()
    // }
    // eslint-disable-next-line
  }, [])

  const onSelectMatch = (item) => {
    setSelectedMatch(item);
  }

  // const loadLeagues = async () => {
  //   const { data } = await apiClient.get(ApiConfig.leagues);
  //   columns[3].filters = data.filter(e => e.is_active).map(ee => ({ text: ee.name, value: ee._id }))
  //   setColumns([...columns])
  //   // setLeagues([...data]);
  // }

  // const loadSeasons = async () => {
  //   const { data } = await apiClient.get(ApiConfig.seasons);
  //   columns[2].filters = data.filter(e => e.is_active).map(ee => ({ text: ee.name, value: ee._id }))
  //   setColumns([...columns])
  //   // setSeasons([...data]);
  // }

  // const MatchView = ({ match, key }) => {
  //   const [columns, setColumns] = useState([])

  //   useEffect(() => {
  //     const stats = (Object.keys(match?.home?.players[0]?.stats || {}) || []).filter(e => ["_id", "created_at", "updated_at"].indexOf(e) === -1);
  //     setColumns(["name", ...stats].map(ee => ({ title: ee.toUpperCase(), dataIndex: ee, key: ee })))
  //     // eslint-disable-next-line
  //   }, [])

  //   return (
  //     <div key={key} className="match-detail-view">
  //       <Tabs defaultActiveKey="1">
  //         <Tabs.TabPane key="1" tab={<div className={`mteam-name${match?.home?.team?._id === match?.won_by?.team._id ? ' w-team' : ''}`}>{<TeamNameWithLogo {...match?.home?.team} />}<Tag className="ml-2" color="#262626">{match?.home?.score || 0}</Tag></div>}>
  //           <div className='mteam-item'>
            
  //             <Table
  //               pagination={false}
  //               dataSource={match?.home?.players?.map(e => ({
  //                 name: getFullName(e.player),
  //                 ...e.stats
  //               }))}
  //               columns={columns}
  //             />
  //           </div>
  //         </Tabs.TabPane>
  //         <Tabs.TabPane key="2" tab={<div className={`mteam-name${match?.away?.team?._id === match?.won_by?.team._id ? ' w-team' : ''}`}>{<TeamNameWithLogo {...match?.away?.team} />}<Tag className="ml-2" color="#262626">{match?.away?.score || 0}</Tag></div>}>
  //           <div className='mteam-item'>
              
  //             <Table
  //               pagination={false}
  //               dataSource={match?.away?.players?.map(e => ({
  //                 name: e.is_me ? <span style={{ color: "#4bb543" }}>{getFullName(e.player)}(You)</span> : getFullName(e.player),
  //                 ...e.stats
  //               }))}
  //               columns={columns}
  //             />
  //           </div>
  //         </Tabs.TabPane>
  //       </Tabs>
  //     </div>
  //   )
  // }
  const dayspliter = (matches) =>{
    const matchlist = []
    matchlist[0]= [matches[0]]
    let j=0
    for(let i =1;i<matches.length;i++){
      if(moment(matches[i]?.date).format('YYYY-MM-DD') === moment(matchlist[j]?.[0]?.date).format('YYYY-MM-DD')){
        matchlist[j].push((matches[i]))
      }
      else {
        matchlist[j+1] = [matches[i]]
        j+=1
      }
    }
    return matchlist
  }

  const loadMatches = async (court = []) => {
    setListing(true)
    const courts = {}
    const courtsarray = []
    const toDate = moment().add(7, 'days').format('YYYY-MM-DD')
    const fromDate = moment().add(-2, 'days').format('YYYY-MM-DD')
    const query = `?from=${fromDate}&to=${toDate}&limit=100`
    const { data } = await apiClient.get(ApiConfig.matches.matchLogs + query);
    // eslint-disable-next-line no-unused-vars
    let v1=data?.results?.forEach((item)=>{
      if(!courts[item?.court?._id]) {
        courts[item?.court?._id]=item?.court?.name
        courtsarray.push(item?.court)
      }
    });
    setcourtsList([...courtsarray])
    //setCount(data.count);
    //console.log(data,'datadata')
    const todaymatch = data?.results?.filter((item)=>{
      return moment(item?.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && ((court.length)?court:courtsarray?.[0]?._id) === item?.court?._id
    })
    const recentmatch = dayspliter(data?.results?.filter((item)=>{
      return moment(item?.date) <= moment() && ((court.length)?court:courtsarray?.[0]?._id) === item?.court?._id
    }).sort(function(a, b){return new Date(a.date) - new Date(b.date)})?.map(ee => ({ ...ee, key: ee._id })))
    const upcomingmatch = dayspliter(data?.results?.filter((item)=>{
      return moment(item?.date) >= moment() && ((court.length)?court:courtsarray?.[0]?._id) === item?.court?._id
    }).sort(function(a, b){return new Date(a.date) - new Date(b.date)})?.map(ee => ({ ...ee, key: ee._id })))
    // console.log(todaymatch,'todaymatch',recentmatch,'recentmatch',upcomingmatch,'upcomingmatch')
    // console.log(dayspliter(data?.results),'hello')
    // ?from=2023-08-18&to=2023-09-20
    // const teams = [];
    // const tmp = data?.results?.map(ee => {
    //   if (teams.findIndex(tt => tt.value === ee.away.team._id) === -1) {
    //     teams.push({
    //       text: ee.away.team.name,
    //       value: ee.away.team._id
    //     })
    //   }
    //   if (teams.findIndex(tt => tt.value === ee.home.team._id) === -1) {
    //     teams.push({
    //       text: ee.home.team.name,
    //       value: ee.home.team._id
    //     })
    //   }
    //   return { ...ee, key: ee._id }
    // })
    // console.log('teams', teams);
    // if (!filters) {
    //   columns[0].filters = teams;
    //   // setMatchTeams(teams);
    //   setColumns([...columns])
    // }
    //const tmp = data?.results?.map(ee => ({ ...ee, key: ee._id }))
    const todaymatchlist = todaymatch?.map(ee => ({ ...ee, key: ee._id }))??[]
    // const recentmatchlist = recentmatch?.map(ee => ({ ...ee, key: ee._id }))??[]
    // const upcomingmatchlist = upcomingmatch?.map(ee => ({ ...ee, key: ee._id }))??[]
    // console.log(upcomingmatchlist,'upcomingmatchlist')
    setrecentMatches([...recentmatch])
    setupcomingMatches([...upcomingmatch])
    settodayMatches([...todaymatchlist]);
    setListing(false)
  }

  // const loadTeams = async () => {
  //   const { data } = await apiClient.get(ApiConfig.player.teams);
  //   columns[0].filters = data?.map(ee => ({ text: ee.name, value: ee._id }))
  //   setColumns([...columns])
  // }

  // const showAll = () => {
  //   setMatchFilterApplied(false);
  //   history.replace()
  //   loadMatches()
  // }

  // const onDeleteMatch = async (record) => {
  //   const ok = await ConfirmationModal("Are you sure you want to delete?");
  //   if (ok) {
  //     await apiClient.delete(ApiConfig.matches.list + `/${record._id}`);
  //     loadMatches();
  //   }
  // }

  // const onSearch = (search) => {
  //   console.log('search', search);
  //   setFilters({ ...filters, team_search: search })
  // }

  useEffect(() => {
    // const tmp = {};
    // console.log('locationRef.state?.match_id', locationRef.state?.match_id);
    // Object.keys(filters).forEach(ee => {
    //   if (filters[ee]) {
    //     tmp[ee] = ee === 'from' || ee === 'to' ? getDateFormat({ date: filters[ee], format: "YYYY-MM-DD" }) : filters[ee]
    //   }
    // })
    // let params = new URLSearchParams(tmp).toString()
    // params = params ? '?' + params : '';
    // if (locationRef.state?.match_id) {
    //   setMatchFilterApplied(locationRef.state?.match_id);
    //   if (!params) {
    //     params = `?match_id=${locationRef.state?.match_id}`
    //   } else {
    //     params += `&match_id=${locationRef.state?.match_id}`
    //   }
    // }
    // loadMatches(params)
    loadMatches()
    // eslint-disable-next-line
  }, [])

  // const RightContext = () => (
  //   <div className="mright-content picker-comp">
  //     {!matchFilterApplied ? <>
  //       {/* <Select value={filters.league} onChange={(e) => setFilters({ ...filters, league: e })} allowClear options={leagues.filter(e => e.is_active).map(e => ({ label: e.name, value: e._id }))} className="select-item" placeholder="Select League" />
  //       <Select value={filters.season} onChange={(e) => setFilters({ ...filters, season: e })} allowClear options={seasons.filter(e => e.is_active).map(e => ({ label: e.name, value: e._id }))} className="ml-2  select-item" placeholder="Select Season" /> */}
  //       {/* <Input style={{ borderRadius: "4px", minWidth: "300px" }} placeholder='Search teams by name' onChange={(e) => onSearch(e.target.value)} /> */}
  //       <DatePicker.RangePicker onChange={e => setFilters({ ...filters, from: e ? e[0] : "", to: e ? e[1] : "" })} value={[filters.from, filters.to]} className="ml-2" format="MMM DD, YYYY" />
  //     </> :
  //       <div className='act-btn'>
  //         <Button onClick={showAll} type="primary">Show all matches</Button>
  //       </div>
  //     }
  //   </div>
  // )
  // eslint-disable-next-line no-unused-vars
  const Addgame = () => {
    
    const [form] = Form.useForm();
    
  const [leagueSelected, setLeagueSelected] = useState(null);
  const [matchDet, setMatchDet] = useState({});
  const [noTier, setNoTier] = useState(false)
  //const [visible, setVisible] = useState(false);
  const [cVisible, setcVisible] = useState(false);
  const [tierType, setTierType] = useState("Regular");
  const [isCompleted, setIsCompleted] = useState(false);
  const [cLoading, setcLoading] = useState(false);
    const onValuesChange = (changedValues, values) => {
      setLeagueSelected(values.league)
      if (changedValues.sports_event) {
        const tvalues = form.getFieldsValue();
        form.setFieldsValue({
          ...tvalues,
          home: undefined,
          away: undefined,
          tier: undefined,
          away_tier: undefined,
          home_tier: undefined,
        })
        const seasonItem = matchDet.seasons.filter(ee => ee._id === changedValues.sports_event)[0];
        if (seasonItem) {
          setMatchDet({
            ...matchDet,
            homeTeams: [],
            awayTeams: [],
            tiers: [],
            leagues: seasonItem.leagues?.map(ee => ({ label: ee?.league?.name, value: ee?.league._id }))
          })
        }
      }
      if (changedValues.league) {
        const tvalues = form.getFieldsValue();
        form.setFieldsValue({
          ...tvalues,
          home: undefined,
          away: undefined,
          tier: undefined,
          away_tier: undefined,
          home_tier: undefined,
        })
        const seasonItem = matchDet.seasons.filter(ee => ee._id === values.sports_event)[0];
        if (seasonItem) {
          const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === changedValues.league)[0];
          if (leagueItem) {
            if (leagueItem.tiers?.filter(e => !e.tier).length > 0) {
              setNoTier(true);
              setMatchDet({
                ...matchDet,
                tiers: [],
                homeTeams: leagueItem?.tiers[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
                awayTeams: leagueItem?.tiers[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
              })
            } else {
              setNoTier(false);
              setMatchDet({
                ...matchDet,
                teams: [],
                awayTeams: [],
                homeTeams: [],
                tiers: leagueItem.tiers?.filter(e => e.tier).map(ee => ({ tier: ee.tier, type: ee.type }))
              })
            }
          }
        }
      }
      if (changedValues.home_tier || changedValues.away_tier) {
        const seasonItem = matchDet.seasons.filter(ee => ee._id === values.sports_event)[0];
        if (seasonItem) {
          const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === values.league)[0];
          if (leagueItem) {
            const tierItem = leagueItem.tiers.filter(ee => ee.type === tierType && ee.tier === (changedValues.home_tier || changedValues.away_tier))[0];
            const vv = form.getFieldsValue();
            form.setFieldsValue({
              ...vv,
              [changedValues.home_tier ? 'home' : 'away']: undefined,
            })
            setMatchDet({
              ...matchDet,
              [changedValues.home_tier ? 'home' : 'away']: undefined,
              [changedValues.home_tier ? 'homeTeams' : 'awayTeams']: tierItem.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
            })
          }
        }
      }
      if ('is_completed' in changedValues) {
        setIsCompleted(changedValues.is_completed)
      }
      if ('tier_type' in changedValues) {
        setTierType(changedValues.tier_type)
        matchDet.awayTeams = [];
        matchDet.homeTeams = [];
        const tvalues = form.getFieldsValue();
        form.setFieldsValue({
          ...tvalues,
          home: undefined,
          away: undefined,
          tier: undefined,
          away_tier: undefined,
          home_tier: undefined,
        })
        const seasonItem = matchDet.seasons.filter(ee => ee._id === values?.sports_event)[0];
        if (seasonItem) {
          const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === values?.league)[0];
          if (leagueItem) {
            if (leagueItem.tiers?.filter(e => !e.tier && e.type === changedValues.tier_type).length > 0) {
              setNoTier(true);
              matchDet.tiers = [];
              matchDet.homeTeams = leagueItem.tiers?.filter(e => !e.tier && e.type === changedValues.tier_type)[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
              matchDet.awayTeams = leagueItem.tiers?.filter(e => !e.tier && e.type === changedValues.tier_type)[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
            } else {
              matchDet.tiers = leagueItem.tiers?.filter(e => e.tier).map(ee => ({ tier: ee.tier, type: ee.type }))
              setNoTier(false);
            }
          }
        }
        setMatchDet({ ...matchDet })
      }
    }
    const onFinish = async (values) => {
      try {
        if (values.home === values.away) {
          notification.error({
            message: "Home team can not be same as the away team."
          })
          return;
        }
        setcLoading(true);
        if (values.home_tier === '0' || !values.home_tier) values.home_tier = null
        if (values.away_tier === '0' || !values.away_tier) values.away_tier = null
        values.home = {
          team: values.home,
          score: values.home_score,
          tier: values.home_tier
        }
        values.away = {
          team: values.away,
          score: values.away_score,
          tier: values.away_tier
        }
        // console.log("values", moment(values?.date).format('MMM DD, YYYY hh:mm a'));
        // console.log("values", moment.tz(values?.date, 'YYYY-MM-DD HH:mm:ss', 'UTC').format());
        const getDateVal = values.date.format('YYYY-MM-DD');
        const getTimeVal = values.date.format('HH:mm:ss');
        const lastTime = '000+00:00'
        const finaldateVal = getDateVal + 'T' + getTimeVal + '.' + lastTime
        const updatePayload = {
          "court": values?.court,
          "sports_event": values?.sports_event,
          "league": values?.league,
          "tier_type": values?.tier_type,
          "no_standings": values?.no_standings,
          "home": {
            "team": values?.home?.team,
            "tier": values?.home?.tier,
            "score": values?.home?.score,
          },
          "away": {
            "team": values?.away?.team,
            "tier": values?.away?.tier,
          },
          "round_no": values?.round_no,
          // "date": moment(values?.date).format('MMM DD, YYYY hh:mm a'),
          // "date": values.date.format('YYYY-MM-DD HH:mm:ss'),
          "date": finaldateVal,
          "home_tier": values?.home_tier,
          "away_tier": values?.away_tier,
        }
        console.log(updatePayload)
        if (selectedMatch?._id) {
          await apiClient.put(ApiConfig.matches.updateScheduledMatch + `/${selectedMatch._id}`, values);
        } else {
          await apiClient.post(ApiConfig.matches.list, values);
        }
        setSelectedMatch(null);
        loadMatches();
        setcLoading(false);
      } catch (error) {
        setcLoading(false);
      }
      setcVisible(false)
    }
    const disabledDate = (current) => {
      const leagueItem = matchDet?.leagues?.filter(ee => ee.value === leagueSelected)[0]
      return current && getDateFormat({ date: current, format: "ddd" }) !== leagueItem?.label?.slice(0, 3)
    }
    return (
      <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              initialValues={{ tier_type: "Regular" }}
              onValuesChange={onValuesChange}
              onFinish={onFinish}
            >
              <div className="flex-list wt">
                {/* <Form.Item
                  name="court"
                  label="Court"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet.courts} placeholder="Select court" />
                </Form.Item> */}
                <Form.Item
                  //hidden={true}
                  label="Game Date & Time"
                  name="date"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    disabled={!leagueSelected}
                    showNow={false}
                    showTime
                    minuteStep={5}
                    showSecond={false}
                    format="MMM DD, YYYY hh:mm a"
                  />
                </Form.Item>
                
                
                <Form.Item
                  name="home"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.homeTeams} placeholder="Select home team" />
                </Form.Item>
                {!noTier && <Form.Item
                  name="home_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                {isCompleted && <Form.Item
                  name="home_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
                
                
                <Form.Item
                  name="away"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.awayTeams} placeholder="Select away team" />
                </Form.Item>
                {!noTier && <Form.Item
                  name="away_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                {isCompleted && <Form.Item
                  name="away_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
                <Form.Item
                  label="Season"
                  name="sports_event"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet?.seasons?.map(ee => ({ label: ee.name, value: ee._id }))} placeholder="Select Season" />
                </Form.Item>
                <Form.Item
                  label="League"
                  name="league"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet.leagues} placeholder="Select league" />
                </Form.Item>
              </div>
              {/* <div className="flex-list bb">
                <Form.Item
                  name="tier_type"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Radio.Group>
                    <Radio value="Regular">Regular</Radio>
                    <Radio value="CO-ED">CO-ED</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="is_completed"
                  valuePropName="checked"
                >
                  <Checkbox>Mark as completed</Checkbox>
                </Form.Item>
                <Form.Item
                  name="no_standings"
                  valuePropName="checked"
                >
                  <Checkbox>No Standings</Checkbox>
                </Form.Item>
              </div> */}
              {/* <Form.Item
                label="Home Team"
                className="no_form_input"
              /> */}
              {/* <div className="flex-list">
                {!noTier && <Form.Item
                  name="home_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                <Form.Item
                  name="home"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.homeTeams} placeholder="Select home team" />
                </Form.Item>
                {isCompleted && <Form.Item
                  name="home_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
              </div> */}
              {/* <Form.Item
                label="Away Team"
                className="no_form_input"
              /> */}
              {/* <div className="flex-list">
                {!noTier && <Form.Item
                  name="away_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                <Form.Item
                  name="away"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.awayTeams} placeholder="Select away team" />
                </Form.Item>
                {isCompleted && <Form.Item
                  name="away_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
              </div> */}
              <div className="flex-list">
                <Form.Item
                hidden={true}
                  label="Round"
                  name="round_no"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {/* <Select disabled={!leagueSelected} options={rounds.map(e => ({ label: e === '-1' ? 'N/A' : `${e === '0' ? 'Playoffs' : `${e} Round`}`, value: e }))} placeholder="Select round" /> */}
                  <Select
                    // showSearch/
                    placeholder="Select from list"
                    optionFilterProp="children"
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  // }
                  >
                    {roundArray?.map((mval, index) => (
                      <Option key={mval?.value} value={mval?._id}>{mval?.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
                
              </div>
              {cVisible && <div className="form-footer" style={{ textAlign: "center" }}>
                <Button loading={cLoading} type="primary" className="h40" htmlType="submit">{selectedMatch?._id ? 'Update' : 'Create'} Game</Button>
              </div>}
            </Form>
    )
  }

  const UpdateScore = () => {
    const [adding, setAdding] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
      const ok = await ConfirmationModal("Are you sure you want to update the scores for this match?");
      if (ok) {
        setAdding(true);
        await apiClient.put(ApiConfig.matches.updateDetails + `/${selectedMatch._id}`, { scores: values, status: "Completed" });
        setSelectedMatch(null);
        setAdding(false);
        loadMatches();
      }
    }

    return (
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        initialValues={{
          home: selectedMatch?.home?.score,
          away: selectedMatch?.away?.score
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="home"
          label={selectedMatch?.home?.team?.name}
          rules={[{ required: true, message: "This field is required" }]}
        >
          <InputNumber controls={false} maxLength={3} placeholder="Enter a jersey number" />
        </Form.Item>
        <Form.Item
          name="away"
          label={selectedMatch?.away?.team?.name}
          rules={[{ required: true, message: "This field is required" }]}
        >
          <InputNumber controls={false} maxLength={3} placeholder="Enter a jersey number" />
        </Form.Item>
        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button loading={adding} type="primary" htmlType="submit">Update Scores</Button>
        </div>
      </Form>
    )
  }

  // const handleTableChange = (newPagination, filterObj = {}, sorter) => {
  //   console.log(newPagination, filters, sorter);
  //   for (const key in filterObj) {
  //     filterObj[key] = Array.isArray(filterObj[key]) ? JSON.stringify(filterObj[key]) : filterObj[key]
  //   }
  //   if (sorter?.field) {
  //     filterObj[`sort_${sorter.field}`] = sorter.order === "ascend" ? 1 : -1
  //   }
  //   setFilters({ ...filters, ...filterObj });
  // }
  const loadCourts = async () => {
    try {
      const { data } = await apiClient.get(ApiConfig.courts);
      setAllCourtList([...data].map(ee => ({ ...ee, ...ee.address,label:ee.name,value:ee._id })))
      //console.log(data?.[0]?._id,'datadata')
      setcourtselected(data?.[0]?._id)
    } catch (error) {
      message.error(error)
    }
  }
  useEffect(()=>{
    loadMatches(courtselected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[courtselected])
  return (
    <div>
      {/* <SectionTitle title="Game Schedule" subtitle={
        <>Game Schedule will be updated weekly</>
        // <span>({count})</span>
      } 
      // right={<RightContext />} 
      /> */}
      <div style={{textAlign:'center', fontWeight:'700', fontSize:'16px', margin:'20px 0px 0px 0px'}}>Game Schedule</div>
      <div style={{textAlign:'center', fontWeight:'300', fontSize:'16px', margin:'0px 0px 20px 0px'}}>Game Schedule will be updated weekly</div>
      <span style={{display:'inline-block',width:'15%',verticalAlign:'top'}}>
        {/* <div style={{margin:'5px 0px', fontWeight:'700'}}>Court View</div> */}
        <div style={{margin:'5px 0px 5px 10px', fontWeight:'700',width:'88%',textAlign:'center'}}>Courts
        <Select options={allCourtList} placeholder="+" showArrow={false} style={{width:'100%'}} onSelect={(e)=>setcourtselected(e)}/>
        </div>
        
        <div style={{verticalAlign:'top'}}>
        {courtsList.map((courtItem)=>{
          return <div><Button type="primary" style={{fontSize:'10px', margin:'5px 0px 5px 10px',width:'88%',textAlign:'left'}} onClick={()=>setcourtselected(courtItem?._id)}>{courtItem?.name}</Button></div>
        })}
        </div>
      </span>
      <span style={{display:'inline-block',width:'75%'}}>
      {/* {!!todaymatches.length && */}
      {true &&
      <div>
        
        <div style={{textAlign:"center", fontWeight:'700', fontSize:'16px', margin:'20px 0px'}}>Today's Matches</div>
      
        <div style={{fontWeight:'700', fontSize:'16px', margin:'20px 0px'}}>{moment(todaymatches?.[0]?.date).format("YYYY-MM-DD")} | {allCourtList?.find((item)=>item?._id===courtselected)?.name}</div>
      {/* <div><Addgame/></div> */}
      <Table
        loading={listing}
        columns={gameColumns}
        // pagination={{
        //   total: count,
        //   defaultPageSize: 20,
        //   onChange: (current, pageSize) => {
        //     console.log(current, pageSize);
        //     filters.offset = (current - 1) * pageSize;
        //     filters.limit = pageSize;
        //     setFilters({ ...filters })
        //   },
        //   onShowSizeChange: (current, pageSize) => {
        //     console.log(current, pageSize);
        //     filters.offset = (current - 1) * pageSize;
        //     filters.limit = pageSize;
        //     setFilters({ ...filters })
        //   }
        // }}
        scroll={{
          y: window.innerHeight - 300,
          x: window.innerWidth - window.innerWidth/4,
        }}
        dataSource={todaymatches}
        //className="matches-list"
        // onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: (e) => {
        //       console.log(e);
        //       if ($(e.target).closest('.m-act').length === 0) {
        //         $(e.target).closest('tr.ant-table-row').find('.ant-table-row-expand-icon').trigger('click')
        //       }
        //     }
        //   }
        // }}
        // expandable={{
        //   rowExpandable: (record) => (record?.home?.players?.length > 0 || record?.away?.players?.length > 0),
        //   ...(matchFilterApplied && { expandedRowKeys: [matchFilterApplied] }),
        //   expandedRowRender: (record, key) => <MatchView key={key} match={record} />
        // }}
      />
      </div>}
      {Boolean(recentmatches?.[0]?.[0]) && 
      // {true &&
      <div style={{textAlign:"center", fontWeight:'700', fontSize:'16px', margin:'20px 0px'}}>Recent Matches</div>}
      {Boolean(recentmatches?.[0]?.[0]) && 
      // {true &&
      recentmatches.map((item)=>
      <div>
        
      
        <div style={{fontWeight:'700', fontSize:'16px', margin:'20px 0px'}}>{moment(item?.[0]?.date).format("YYYY-MM-DD")} | {allCourtList?.find((item)=>item?._id===courtselected)?.name}</div>         
      <Table
        loading={listing}
        columns={gameColumns}
        // pagination={{
        //   total: count,
        //   defaultPageSize: 20,
        //   onChange: (current, pageSize) => {
        //     console.log(current, pageSize);
        //     filters.offset = (current - 1) * pageSize;
        //     filters.limit = pageSize;
        //     setFilters({ ...filters })
        //   },
        //   onShowSizeChange: (current, pageSize) => {
        //     console.log(current, pageSize);
        //     filters.offset = (current - 1) * pageSize;
        //     filters.limit = pageSize;
        //     setFilters({ ...filters })
        //   }
        // }}
        scroll={{
          y: window.innerHeight - 300,
          x: window.innerWidth - window.innerWidth/4,
        }}
        dataSource={item}
        //className="matches-list"
        // onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: (e) => {
        //       console.log(e);
        //       if ($(e.target).closest('.m-act').length === 0) {
        //         $(e.target).closest('tr.ant-table-row').find('.ant-table-row-expand-icon').trigger('click')
        //       }
        //     }
        //   }
        // }}
        // expandable={{
        //   rowExpandable: (record) => (record?.home?.players?.length > 0 || record?.away?.players?.length > 0),
        //   ...(matchFilterApplied && { expandedRowKeys: [matchFilterApplied] }),
        //   expandedRowRender: (record, key) => <MatchView key={key} match={record} />
        // }}
      />
      </div>
      )
      }
      {/* {Boolean(upcomingmatches?.[0]?.[0]) &&  */}
      {true &&
      <div style={{textAlign:"center", fontWeight:'700', fontSize:'16px', margin:'20px 0px'}}>Upcoming Matches</div>}
      {/* {Boolean(upcomingmatches?.[0]?.[0]) && */}
      {true &&
      upcomingmatches.map((item)=>
      <div>
        
        
      
        <div style={{fontWeight:'700', fontSize:'16px', margin:'20px 0px'}}>{moment(item?.[0]?.date).format("YYYY-MM-DD")} | {allCourtList?.find((item)=>item?._id===courtselected)?.name}</div>         
      <Table
        loading={listing}
        columns={gameColumns}
        // pagination={{
        //   total: count,
        //   defaultPageSize: 20,
        //   onChange: (current, pageSize) => {
        //     console.log(current, pageSize);
        //     filters.offset = (current - 1) * pageSize;
        //     filters.limit = pageSize;
        //     setFilters({ ...filters })
        //   },
        //   onShowSizeChange: (current, pageSize) => {
        //     console.log(current, pageSize);
        //     filters.offset = (current - 1) * pageSize;
        //     filters.limit = pageSize;
        //     setFilters({ ...filters })
        //   }
        // }}
        scroll={{
          y: window.innerHeight - 300,
          x: window.innerWidth - window.innerWidth/4,
        }}
        dataSource={item??[]}
        //className="matches-list"
        // onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: (e) => {
        //       console.log(e);
        //       if ($(e.target).closest('.m-act').length === 0) {
        //         $(e.target).closest('tr.ant-table-row').find('.ant-table-row-expand-icon').trigger('click')
        //       }
        //     }
        //   }
        // }}
        // expandable={{
        //   rowExpandable: (record) => (record?.home?.players?.length > 0 || record?.away?.players?.length > 0),
        //   ...(matchFilterApplied && { expandedRowKeys: [matchFilterApplied] }),
        //   expandedRowRender: (record, key) => <MatchView key={key} match={record} />
        // }}
      />
      </div>
      )
      }
      </span>
      <Drawer
        title="Update Game"
        visible={!!selectedMatch}
        onClose={() => setSelectedMatch(null)}
      >
        <UpdateScore />
      </Drawer>
    </div>
  )
}

export default MatchesPage
