/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";


const HalfTimeComponent = ({ itemsApi, teamsApi }) => {

  const [maxNumber, setmaxNumber] = useState(null);

  const teamsLogo = (values, type) => {
    const filter = teamsApi?.filter(x => x?._id === values)[0]?.logo
    let defaultLogo = '/logos/logo.png'
    if (filter !== null) {
      defaultLogo = filter
    }
    return defaultLogo
  }

  const teamsName = (values, type) => {
    const filter = teamsApi?.filter(x => x?._id === values)[0]?.name
    return filter
  }

  useEffect(() => {
    if (itemsApi !== null) {
      callsorting(itemsApi?.teams[0].score, itemsApi?.teams[1].score)
    }
  }, [itemsApi])


  const callsorting = (values1, values2) => {
    const arrayinfo = [{ scores: values1 }, { scores: values2 }]
    let objMax = arrayinfo.reduce((max, curren) => max.scores > curren.scores ? max : curren);
    setmaxNumber(objMax?.scores)
  }

  return (
    <>
      <table className="scores-table-width">
        <tr>
          <td className="scores-td-logo-width">
            <div className="scores-logo-image"><img src={teamsLogo(itemsApi?.teams.filter(x => x.score === maxNumber)[0]?.team)} alt={itemsApi?.teams.filter(x => x.score === maxNumber)[0]?.team} /></div>
          </td>
          <td className="scores-td-home-team-width">&nbsp;&nbsp;&nbsp; {teamsName(itemsApi?.teams.filter(x => x.score === maxNumber)[0]?.team)}</td>
          <td className="scores-td-home-score-width">
            {itemsApi?.teams.filter(x => x.score === maxNumber)[0]?.score}

          </td>
          <td className="scores-td-won-score-width">
            <>
              Half Time
            </>
          </td>
        </tr>
        <tr className="scores-hr-line">
          <td className="scores-td-logo-width">
            <div className="scores-logo-image"><img src={teamsLogo(itemsApi?.teams.filter(x => x.score !== maxNumber)[0]?.team)} alt={itemsApi?.teams[1]?.team} /></div>
          </td>
          <td className="scores-td-home-team-width">&nbsp;&nbsp;&nbsp; {teamsName(itemsApi?.teams.filter(x => x.score !== maxNumber)[0]?.team)}</td>
          <td className="scores-td-home-score-width">
            {itemsApi?.teams.filter(x => x.score !== maxNumber)[0]?.score}
          </td>
          <td className="scores-td-won-score-width">
            <>
            </>
          </td>
        </tr>
      </table>
    </>
  )
}

export default HalfTimeComponent;