import React, { useEffect, useState } from 'react'
import { Button, Select, Tag, DatePicker, Form, Input, Row, Col, message } from 'antd'
//import SectionTitle from '../../components/section-title';
//import PageLoader from '../../components/page-loader';
import $ from 'jquery'
import { TeamNameWithLogo } from "../../components/teams";
import apiClient from '../../services/axios';
import ApiConfig from '../../services/ApiConfig';
import { getDateFormat} from '../../utils';
// import moment from "moment-timezone";
//import ConfirmationModal from "../../common/confirm-modal";
import moment from 'moment';

const ScoreKeeperPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [courtList, setCourtList] = useState([]);
  const [matches, setMatches] = useState([]);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [scoreboardDet, setScoreboard] = useState({
    court: null,
    date: getDateFormat(),
    slot: null,
    start: false
  })
  console.log(loading)
  console.log(currentMatch,'currentMatch')
  const onUpdate = (val, key) => {
    if (key === 'date') {
      loadMatches(val)
    }
    if(key === 'match') {
      const matchesList = matches.find(tt => {
        // return tt.sports_event._id === scoreboardDet.season && tt.court._id === scoreboardDet.court;
        return tt._id === val;
      });
      setSelectedMatch({...matchesList})
    }
    scoreboardDet[key] = val;
    setScoreboard({ ...scoreboardDet })
  }
  const colorfinder =(value)=>{
    let color = 'green'
    switch (value) {
      case 'Completed':
        color = 'green'
        break;
      case 'Scheduled':
        color = 'blue'
        break;
      default:
        color = 'green'
        break;
    }
    return color
  }
  const MatchView = ({ home, away, date, status }) => {
    return (
      <>
      <div className='match-view'>
        <Tag color="#12344d">{getDateFormat({ date, format: "hh:mm a" })}</Tag>
        {/* <Tag color="#12344d">{formattedTimeZone(date)}</Tag> */}
        <TeamNameWithLogo {...home} />
        <div className='match_vs'>vs</div>
        <TeamNameWithLogo {...away} />
      </div>
      
      <span style={{position:'relative',float:'right',marginTop:'-35px'}}><Tag color={colorfinder(status)} style={{width:'100px',textAlign:'center'}}> {status}</Tag></span>
      </>
    )
  }

  

  const loadMatches = async (date = scoreboardDet.date) => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(ApiConfig.matches.newlist + '?date=' + getDateFormat({ date, format: 'YYYY-MM-DD' }));
      setCourtList(data.courts || []);
      // setSeasons(data.seasons || []);
      setMatches(data.matches || []);
      //await checkScorerDetails();
    } catch (error) {
      setLoading(false);
    }
  }

  const getMatches = () => {
    const tmp = matches.filter(tt => {
      // return tt.sports_event._id === scoreboardDet.season && tt.court._id === scoreboardDet.court;
      return tt.court._id === scoreboardDet.court;
    });
    console.log(tmp,'tmptmp')
    return tmp;
  }
  const [showScoreUpdater,setShowScoreUpdater] = useState(false)
  
  const [scorechanged,setscorechanged] = useState(false)
  console.log(selectedMatch,'selectedMatchselectedMatch',scoreboardDet.match)
  const onProceed = async () => {
    // setLoading(true);
    // await apiClient.put(ApiConfig.matches.updateScorer + `/${scoreboardDet.match}`);
    // checkScorerDetails();
    let matchdetails = JSON.parse(localStorage.getItem('scores'))?.[scoreboardDet.match]?? {awayhalf:null,awayfinal:null,homehalf:null,homefinal:null}
    console.log(matchdetails,'matchdetails',scoreboardDet,selectedMatch)
    if((selectedMatch?.home?.score !== matchdetails?.homefinal && Boolean(matchdetails?.homefinal)) || (selectedMatch?.home?.halfscore !== matchdetails?.homehalf && Boolean(matchdetails?.homehalf)) || (selectedMatch?.away?.score !== matchdetails?.awayfinal && Boolean(matchdetails?.awayfinal)) || (selectedMatch?.away?.halfscore !== matchdetails?.awayhalf && Boolean(matchdetails?.awayhalf))) {
      setscorechanged(true)
      localStorage.setItem('scores', JSON.stringify({...(JSON.parse(localStorage.getItem('scores'))),[scoreboardDet.match]:{...matchdetails,selectedMatch:{...selectedMatch}}}))
    }
    if((selectedMatch?.home?.score !== matchdetails?.homefinal && Boolean(matchdetails?.homefinal)) || (selectedMatch?.home?.halfscore !== matchdetails?.homehalf && Boolean(matchdetails?.homehalf)) || (selectedMatch?.away?.score !== matchdetails?.awayfinal && Boolean(matchdetails?.awayfinal)) || (selectedMatch?.away?.halfscore !== matchdetails?.awayhalf && Boolean(matchdetails?.awayhalf))) {
      form.setFieldsValue({...matchdetails})
      setShowScoreUpdater(true)
    }
    else if(selectedMatch?.status === 'Completed') {
      console.log(selectedMatch,'came ere116')
      //const bool = await ConfirmationModal(`Are you sure want to update a ${selectedMatch?.status} Match`);
      form.setFieldsValue({homefinal:selectedMatch?.home?.score,homehalf:selectedMatch?.home?.halfscore??undefined,awayfinal:selectedMatch?.away?.score,awayhalf:selectedMatch?.away?.halfscore??undefined})
      // if(bool) {
        setShowScoreUpdater(true)
      // }
    } else {
      setShowScoreUpdater(true)
    }
    
  }
  console.log(JSON.parse(localStorage.getItem('scores')),'formform')


  const onClickPage = (e) => {
    if ($(e.target).closest('.swap-player').length > 0) {
      return;
    }
    if ($(e.target).closest('.scoreboard-left, .scoreboard-right, .score-block, .score-popup-content, .chng-pl-no, .pop-score, .reb_popup, .ant-drawer, .mobile-autocomplete .sketch-picker-comp').length > 0) {
      return;
    }
    // console.log(e);
    if (currentMatch?.home?.players) {
      currentMatch.home.team.openPicker = false;
      currentMatch.home.players.forEach(pp => {
        pp.swap = false;
      })
    }
    if (currentMatch?.away?.players) {
      currentMatch.away.team.openPicker = false;
      currentMatch.away.players.forEach(pp => {
        pp.swap = false;
      })
    }
    if (currentMatch?.home?.players || currentMatch?.away?.players) {
      setCurrentMatch({ ...currentMatch })
    }
  }

  useEffect(() => {
    loadMatches();
    if(!Boolean(localStorage.getItem('scores'))) {
      localStorage.setItem('scores', JSON.stringify({hello:'hello'}));
    }
    window.$ = $;
    // eslint-disable-next-line
  }, [])
  
    console.log(JSON.parse(localStorage.getItem('scores')),'tempdata')
  

  const updateMatchScore = async (values) =>{
    console.log(values,'valuesvalues',typeof(values?.homefinal))
    if((parseInt(values?.homefinal??0) >= parseInt(values?.homehalf??0)) && (parseInt(values?.awayfinal??0) >= parseInt(values?.awayhalf??0))) {
    const payload = {home:{score:values?.homefinal,halfscore:values?.homehalf,team:selectedMatch?.home?.team?._id},away:{score:values?.awayfinal,halfscore:values?.awayhalf??undefined,team:selectedMatch?.away?.team?._id}}
    await apiClient.put(ApiConfig.matches.updateScheduledMatchNew + `/${scoreboardDet.match}`, payload);
    //localStorage.setItem('scores', JSON.stringify({...(JSON.parse(localStorage.getItem('scores'))),[scoreboardDet.match]:undefined}))
    setShowScoreUpdater(false)
    form.setFieldsValue({awayhalf:null,awayfinal:null,homehalf:null,homefinal:null})
    loadMatches(scoreboardDet.date)
  } else {
    message.error("Final Score cannot be lesser than score at half time")
  } 
  }
console.log(selectedMatch,'selectedMatch')
const onChangeValue = ()=>{
  localStorage.setItem('scores', JSON.stringify({...(JSON.parse(localStorage.getItem('scores'))),[scoreboardDet.match]:form.getFieldsValue()}))
  setscorechanged(true)
}
const resetScores = ()=>{
  setscorechanged(false)
  form.setFieldsValue({homefinal:selectedMatch?.home?.score,homehalf:selectedMatch?.home?.halfscore??undefined,awayfinal:selectedMatch?.away?.score,awayhalf:selectedMatch?.away?.halfscore??undefined})
  localStorage.setItem('scores', JSON.stringify({...(JSON.parse(localStorage.getItem('scores'))),[scoreboardDet.match]:{homefinal:selectedMatch?.home?.score,homehalf:selectedMatch?.home?.halfscore??undefined,awayfinal:selectedMatch?.away?.score,awayhalf:selectedMatch?.away?.halfscore??undefined}}))
}
console.log(scorechanged,'scorechanged')
  return (
    <div onClick={onClickPage} className="scorekeeper-page">
      {showScoreUpdater?<div style={{textAlign:'center'}}>{selectedMatch?.home?.team?.name} vs {selectedMatch?.away?.team?.name} | {moment(selectedMatch?.date).format('YYYY-MM-DD | hh:mm A')}</div>:null}
      {/* {loading && <PageLoader className='score-loader1' />} */}
      {(!showScoreUpdater) && <div className='form-block'>
        <div className='form-items'>
          <div className='form-item'>
            <div className='form-label'>Date</div>
            <DatePicker allowClear={false} disabled={!scoreboardDet.date} format="ddd, DD MMM YYYY" onChange={(e) => onUpdate(e, 'date')} value={scoreboardDet.date} />
          </div>
          <div className='form-item'>
            <div className='form-label'>Court/Gym</div>
            <Select onChange={(e) => onUpdate(e, 'court')} value={scoreboardDet.court} options={courtList.map(ee => ({ label: ee.name, value: ee._id }))} placeholder="Select court/gym" />
          </div>
          <div className='form-item'>
            <div className='form-label'>Available Matches</div>
            <Select disabled={!scoreboardDet.court} onChange={(e) => onUpdate(e, 'match')} value={scoreboardDet.match} options={getMatches()?.sort((a,b)=>moment(a?.date).format("H:mm") > moment(b?.date).format("H:mm")?1:-1).map(ee => ({ label: <MatchView date={ee.date} home={ee?.home?.team} away={ee?.away?.team} status={ee?.status}/>, value: ee._id }))} placeholder="Select match" />
          </div>
          <div className='form-action'>
            <Button onClick={() => onProceed()} disabled={!(scoreboardDet.court && scoreboardDet.match)} type="primary">ENTER SCORE</Button>
          </div>
        </div>
      </div>}
      {showScoreUpdater &&
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <Form 
        form={form}
        
        onFinish={(values)=>updateMatchScore(values)}
        requiredMark={false}
        labelAlign='left' labelCol= {{span: 24}} wrapperCol= {{span: 12}} 
          style={{
        maxWidth: 600, border:'1px solid #000', padding:'20px', borderRadius:'20px',width:'100%', background:"linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)",color:'#ffffff'
      }}>
          
          <div style={{textAlign: 'center',fontSize:'18px',color:'#ffffff',fontWeight:'700',marginBottom:'10px',marginTop:'5px'}}>{selectedMatch?.home?.team?.name.toUpperCase()} (HOME)</div>
          <Row gutter={24}>
          <Col xs={12} sm={12} m={12}>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Form.Item label={<span style={{color:'#ffffff'}}>HALF TIME</span>} name="homehalf" style={{display:'block',textAlign:'center'}} type='number' onChange={onChangeValue}>
            <Input style={{minHeight:'100px',fontSize:'26px',maxHeight:'100px',minWidth:'100px',maxWidth:'100px',textAlign:'center',borderColor:'#063225',borderRadius:'10px'}} type='number'/>
          </Form.Item>
          </div>
          </Col>
          <Col xs={12} sm={12} m={12}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Form.Item label={<span style={{color:'#ffffff'}}>FINAL</span>} name="homefinal" style={{display:'block',textAlign:'center'}} rules={[
        {
          required: true,
          message: <div style={{textAlign:'center', width:'100px'}}>Required Field</div>
        },
      ]}>
            <Input style={{minHeight:'100px',maxHeight:'100px',fontSize:'26px',minWidth:'100px',textAlign:'center',maxWidth:'100px',borderColor:'#063225',borderRadius:'10px'}} type='number' onChange={onChangeValue}/>
          </Form.Item>
          </div>
          </Col>
          </Row>
          <div style={{textAlign: 'center',fontSize:'18px',color:'#fffff',fontWeight:'700',marginBottom:'10px',marginTop:'5px'}}>{selectedMatch?.away?.team?.name.toUpperCase()} (AWAY)</div>
          <Row gutter={24}>
          <Col xs={12} sm={12} m={12}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Form.Item label={<span style={{color:'#ffffff'}}>HALF TIME</span>} name="awayhalf" style={{display:'block',textAlign:'center'}}>
            <Input style={{minHeight:'100px',maxHeight:'100px',fontSize:'26px',minWidth:'100px',maxWidth:'100px',borderColor:'#063225',textAlign:'center',borderRadius:'10px'}} type='number'  onChange={onChangeValue}/>
          </Form.Item>
          </div>
          </Col>
          <Col xs={12} sm={12} m={12}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Form.Item label={<span style={{color:'#ffffff'}}>FINAL</span>} name="awayfinal" required style={{display:'block',textAlign:'center'}} rules={[
        {
          required: true,
          message: <div style={{textAlign:'center', width:'100px'}}>Required Field</div>
        }]}>
            <Input style={{minHeight:'100px',maxHeight:'100px',fontSize:'26px',minWidth:'100px',maxWidth:'100px',borderColor:'#063225',textAlign:'center',borderRadius:'10px'}} type='number'  onChange={onChangeValue}/>
          </Form.Item>
          </div>
          </Col>
          </Row>
          <div style={{display:'flex',justifyContent:"space-between"}}>
          <Button onClick={()=>{setShowScoreUpdater(false)}} style={{width:'120px',borderRadius:'10px',maxWidth:'200px',backgroundColor:'#efcc00',borderColor:'#efcc00',marginTop:'50px',color:'#8B0000'}} type='primary'>Back</Button>
          <Button disabled={!scorechanged} onClick={resetScores} style={{width:'120px',borderRadius:'10px',maxWidth:'200px',backgroundColor:'#efcc00',borderColor:'#efcc00',marginTop:'50px',color:'#8B0000'}} type='primary'>Reset</Button>
          <Button htmlType='submit' style={{width:'120px',borderRadius:'10px',maxWidth:'200px',backgroundColor:'#efcc00',borderColor:'#efcc00',marginTop:'50px',color:'#8B0000'}} type='primary'>Submit</Button>
          
          </div>
        </Form>
      </div>

      }
    </div>
  )
}
export default ScoreKeeperPage
