/* eslint-disable jsx-a11y/alt-text */
import React from "react";


const AssignedComponent = ({ itemsApi, teamsApi }) => {

  const teamsLogo = (values, type) => {
    const filter = teamsApi?.filter(x => x?._id === values)[0]?.logo
    let defaultLogo = '/logos/logo.png'
    if (filter !== null) {
      defaultLogo = filter
    }
    return defaultLogo
  }

  const teamsName = (values, type) => {
    const filter = teamsApi?.filter(x => x?._id === values)[0]?.name
    return filter
  }

  return (
    <>
      <table className="scores-table-width">
        <tr>
          <td className="scores-td-logo-width">
            <div className="scores-logo-image"><img src={teamsLogo(itemsApi?.teams[0]?.team)} alt={itemsApi?.teams[0]?.team} /></div>
          </td>
          <td className="scores-td-home-team-width">&nbsp;&nbsp;&nbsp; {teamsName(itemsApi?.teams[0]?.team)}</td>
          <td className="scores-td-home-score-width">

          </td>
          <td className="scores-td-won-score-width">
            <>
              Starting
            </>
          </td>
        </tr>
        <tr className="scores-hr-line">
          <td className="scores-td-logo-width">
            <div className="scores-logo-image"><img src={teamsLogo(itemsApi?.teams[1]?.team)} alt={itemsApi?.teams[1]?.team} /></div>
          </td>
          <td className="scores-td-home-team-width">&nbsp;&nbsp;&nbsp; {teamsName(itemsApi?.teams[1]?.team)}</td>
          <td className="scores-td-home-score-width">

          </td>
          <td className="scores-td-won-score-width">
            <>
            </>
          </td>
        </tr>
      </table>
    </>
  )
}

export default AssignedComponent;