import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Drawer, Form, Input, Select, Table } from 'antd'
import { FiTrash, FiPlus, FiEdit } from 'react-icons/fi'

import SectionTitle from '../../components/section-title'
import { getDateFormat, getTimingsByIntervals, hoursToMinutes, minutesToHours } from '../../utils'
import RemoveModal from '../../components/common/RemoveModal'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const Courts = () => {
  const dateFormat = 'ddd, DD MMM, YYYY'
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [courtList, setCourtList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const timeIntervals = getTimingsByIntervals(5).map(e => ({ label: e, value: hoursToMinutes(e) }));
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);

  const columns = React.useMemo(() => [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => (key + 1).toString()
    },
    {
      title: "Court Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Permit Duration",
      dataIndex: "permit_duration",
      key: "duration",
      width: "140px",
      render: (text, record) => {
        return getDateFormat(record?.permit_duration?.from) + ' - ' + getDateFormat(record?.permit_duration?.to)
      }
    },
    {
      title: "Permit Day",
      dataIndex: "permit_day",
      key: "permit_day",
      width: "160px",
      render: (text, record) => {
        return (
          <div className='permit_day'>
            {record?.permit_day_time?.map(e => weekDays[e.day])?.map(tt => <><span>{tt}</span><br /></>)}
          </div>
        )
      }
    },
    {
      title: "Permit Time",
      dataIndex: "permit_time",
      key: "permit_time",
      width: "200px",
      render: (text, record) => {
        const timings = record?.permit_day_time?.map(e => e.timings);
        return (
          <div className='permit_day'>
            {timings.map(tm => <><span>{
              tm.map(tt => `${minutesToHours(tt.from)} - ${minutesToHours(tt.to)}`)
            }</span><br /></>)}
          </div>
        )
      }
    },
    {
      title: "Street Address",
      dataIndex: "street",
      key: "street",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Province",
      dataIndex: "provine",
      key: "provine",
    },
    {
      title: "Postal Code",
      dataIndex: "postal_code",
      key: "postal_code",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "120px",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />
            <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          </div>
        )
      }
    },
    // eslint-disable-next-line
  ], [])

  const onAdd = () => {
    setSelectedItem({
      id: null,
      name: null,
      street: null,
      city: null,
      provine: null,
      postal_code: null,
      permit_day: null,
      permit_duration: [],
    });
    setVisible(true);
  }

  const onDelete = async () => {
    await apiClient.delete(`${ApiConfig.courts}/${selectedItem._id}`);
    loadCourts();
    setDeletePopupVisibe(false);
  }

  const onSelect = (item) => {
    setSelectedItem({
      id: item._id,
      name: item.name,
      street: item.street,
      city: item.city,
      provine: item.provine,
      postal_code: item.postal_code,
      permit_day: item?.permit_day,
      permit_duration: [getDateFormat(item?.permit_duration.from, true), getDateFormat(item?.permit_duration?.to, true)],
      permit_day_time: item.permit_day_time
    });
    setVisible(true)
  }

  const FormElements = ({ initialValues }) => {

    const [permitDayTime, setPermitDayTime] = useState([])
    const [submiting, setSubmitting] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
      if (initialValues.permit_day_time) {
        const temp = [];
        initialValues.permit_day_time.forEach(pp => {
          temp.push({
            day: weekDays[pp.day],
            from: pp?.timings[0]?.from,
            to: pp?.timings[0]?.to,
            show: true
          })
        })
        console.log('temp', temp);
        setPermitDayTime([...temp])
      } else {
        const temp = [];
        weekDays.forEach(w => {
          temp.push({
            day: w,
            from: timeIntervals[0].value,
            to: timeIntervals[timeIntervals.length - 1].value,
            show: w === 'Sunday'
          })
        })
        setPermitDayTime([...temp])
      }
    }, [initialValues])

    const onSelectDay = (week) => {
      console.log(week);
      const idx = permitDayTime.findIndex(e => e.day === week);
      if (idx >= 0) {
        permitDayTime[idx].show = !permitDayTime[idx].show;
      }
      setPermitDayTime([...permitDayTime])
    }

    const onChangeTime = (item, time, type) => {
      const idx = permitDayTime.findIndex(e => e.day === item.day);
      permitDayTime[idx][type] = time;
      setPermitDayTime([...permitDayTime])
    }

    const onSubmit = async (val) => {
      console.log(val);
      const { name, permit_duration, street, city, provine, postal_code } = val;
      const payload = {
        name,
        permit_duration: {
          from: getDateFormat({ date: permit_duration[0], format: 'YYYY-MM-DD' }),
          to: getDateFormat({ date: permit_duration[1], format: 'YYYY-MM-DD' })
        },
        address: { street, city, provine, postal_code }
      };
      const permit_day_time = permitDayTime.filter(e => e.show).map(e => ({
        day: weekDays.indexOf(e.day),
        timings: [{
          from: e.from,
          to: e.to
        }]
      }))
      payload.permit_day_time = permit_day_time;
      setSubmitting(true);
      if (selectedItem.id) {
        await apiClient.put(`${ApiConfig.courts}/${selectedItem.id}`, payload);
      } else {
        await apiClient.post(ApiConfig.courts, payload);
      }
      loadCourts();
      setSubmitting(false);
      setVisible(false);
    }

    return (
      <div className='form-elements'>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{ ...initialValues }}
          onFinish={onSubmit}
        >
          <div className='form-title'>Court Details</div>
          <Form.Item
            label="Court Name"
            name="name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a court name" />
          </Form.Item>
          <Form.Item
            label="Permit Duration"
            name="permit_duration"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <DatePicker.RangePicker format={dateFormat} />
          </Form.Item>
          <Form.Item
            name="permit_day"
            label="Permit Day"
            className='time_range'
          >
            {weekDays.map((week, k) => {
              return (
                <>
                  <Button key={k.toString()} onClick={() => onSelectDay(week)} className={`mr-3${permitDayTime?.filter(pp => pp.day === week)[0]?.show ? ' selected' : ''}`}  shape="circle">{week?.slice(0, 2)}</Button>
                </>
              )
            })}
            {
              permitDayTime.filter(e => e.show).map((pp, k) => (
                <Form.Item
                  key={k.toString()}
                  name="time_range"
                  className="time_range_block"
                  label={pp?.day?.slice(0, 2)}
                >
                  <Form.Item>
                    <Select showSearch value={pp.from} onChange={(e) => onChangeTime(pp, e, 'from')} options={timeIntervals} placeholder="From" />
                  </Form.Item>
                  <Form.Item>
                    <Select showSearch value={pp.to} onChange={(e) => onChangeTime(pp, e, 'to')} options={timeIntervals} placeholder="To" />
                  </Form.Item>
                </Form.Item>
              ))
            }
            {permitDayTime.filter(e => e.show).length === 0 && <div style={{ marginTop: "6px" }} className='ant-form-item-explain-error'>This field is required</div>}
          </Form.Item>
          <div className='form-title'>Permit Location</div>
          <Form.Item
            label="Street Address"
            name="street"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a street address" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a city" />
          </Form.Item>
          <Form.Item
            label="Provine"
            name="provine"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select allowClear={false} options={["ON"].map(e => ({ label: e, value: e }))} placeholder="Select Provine" />
          </Form.Item>
          <Form.Item
            label="Postal Code"
            name="postal_code"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a postal code" />
          </Form.Item>
          <div className="form-footer">
            <Button loading={submiting} type="primary" htmlType="submit">{selectedItem?.id ? 'Update' : 'Add'} Court</Button>
          </div>
        </Form>
      </div>
    )
  }

  const loadCourts = async () => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(ApiConfig.courts);
      setCourtList([...data].map(ee => ({ ...ee, ...ee.address })))
      console.log('data', data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadCourts();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="court-page">
      <RemoveModal description="Are you sure you want to delete?" visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
      <SectionTitle
        title="Gym(Courts)"
        subtitle="List of Courts"
        right={
          <>
            <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Court</span>
            </button>
          </>
        } />
      <Table
        loading={loading}
        pagination={false}
        dataSource={courtList}
        columns={columns}
      />
      <Drawer
        title={`${selectedItem?.id ? 'Update' : 'Add'} Court`}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        {visible && <FormElements initialValues={{ permit_day: "Sunday", provine: "ON", ...selectedItem }} />}
      </Drawer>
    </div>
  )
}
export default Courts
