import React, { useEffect } from "react"
import { Table, Tabs } from "antd"

const { TabPane } = Tabs

const PlayerTab = ({ currentTeam, playerColumns, playTypes = [], onChangePlayerTab }) => {

  const onChangeTab = (tab) => {
    if (onChangePlayerTab) onChangePlayerTab(tab);
  }

  useEffect(() => {
    console.log(currentTeam);
  }, [currentTeam])

  const getPlayers = (type) => {
    const temp = currentTeam?.players || [];
    if (type === 'All') return temp;
    const tTmp = [];
    temp.forEach(ee => {
      const enrolled = currentTeam?.enrolled?.filter(et => {
        if (typeof ee?.enrolled[0] === 'string') {
          return ee?.enrolled?.indexOf(et._id) >= 0
        }
        return ee?.enrolled?.findIndex(tt => et._id === tt._id) >= 0
      });
      ee.enrolled = enrolled;
      tTmp.push(ee);
    })
    return tTmp.filter(ee => ee?.enrolled?.map(e => e.name)?.indexOf(type) >= 0)
  }

  return (
    <>
      <Tabs className="player-tabs" onChange={onChangeTab} defaultActiveKey="0">
        {
          currentTeam?.enrolled?.map((ee, k) => (
            <TabPane tab={ee.name} key={k.toString()}>
              <Table
                pagination={false}
                dataSource={getPlayers(ee.name)}
                // columns={ee === 'All' ? playerColumns : playerColumns?.filter(ee => ee?.name !== 'play_types')}
                columns={playerColumns?.filter(ee => ee?.name !== 'play_types')}
              />
            </TabPane>
          ))
        }
      </Tabs>
    </>
  )
}

export default PlayerTab