import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { FiAlertCircle } from 'react-icons/fi'

const RemoveModal = ({
  visible,
  title,
  description,
  onSubmit,
  onCancel,
  loading,
}) => {
  useEffect(() => {
  }, [title, loading])
  return (
    <Modal className="removal-modal" title={null} visible={visible} onCancel={onCancel} footer={null}>
      <div className="font-size-16">
        <FiAlertCircle style={{ fontSize: "18px", color: "#faad15", paddingRight: "8px" }} color="#faad15" /> <span>{description}</span>
      </div>
      <div className="text-right">
        <Button onClick={onCancel}>No</Button>
        <Button type="primary" danger onClick={onSubmit} loading={loading}>Yes, Go ahead</Button>
      </div>
    </Modal>
  )
}

export default RemoveModal
