/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { getDateFormat2 } from "../../../utils";


const FinalPlayComponent = ({ itemsApi, teamsApi }) => {

  const teamsLogo = (values, type) => {
    const filter = teamsApi?.filter(x => x?._id === values)[0]?.logo
    let defaultLogo = '/logos/logo.png'
    if (filter !== null) {
      defaultLogo = filter
    }
    return defaultLogo
  }


  const teamsName = (values, type) => {
    const filter = teamsApi?.filter(x => x?._id === values)[0]?.name
    return filter
  }


  return (
    <>
      <table className="scores-table-width">
        <tr>
          <td className="scores-td-logo-width">
            <div className="scores-logo-image"><img src={teamsLogo(itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.team)} alt={itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.team} /></div>
          </td>
          <td className="scores-td-home-team-width" style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp; {teamsName(itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.team)}</td>
          <td className="scores-td-home-score-width" style={{ fontWeight: 'bold' }}>

            {itemsApi?.status !== 'Scheduled' && (
              <>{itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.score}</>
            )}
          </td>
          <td className="scores-td-won-score-width">
            <>
              {itemsApi?.status === 'Completed' && (<>Final</>)}
              {itemsApi?.status === 'Assigned' && (<>Starting</>)}
              {itemsApi?.status === 'Started' && itemsApi?.is_halftime_taken === true && (<>Half Time</>)}
              {itemsApi?.status === 'Scheduled' && (<>{getDateFormat2(itemsApi?.date)}</>)}
            </>
          </td>
        </tr>
        <tr className="scores-hr-line">
          <td className="scores-td-logo-width">
            <div className="scores-logo-image"><img src={teamsLogo(itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0].team)} alt={itemsApi?.teams.filter(a => a.team !== itemsApi?.won_by?.team)[0]?.team} /></div>
          </td>
          <td className="scores-td-home-team-width">&nbsp;&nbsp;&nbsp; {teamsName(itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0]?.team)}</td>
          <td className="scores-td-home-score-width">
            {itemsApi?.status !== 'Scheduled' && (
              <>{itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0]?.score}</>
            )}
          </td>
          <td className="scores-td-won-score-width">
            <>

            </>
          </td>
        </tr>
      </table>
    </>
  )
}

export default FinalPlayComponent;