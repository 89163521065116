import React from 'react'
import $ from 'jquery';
import moment from 'moment';

import {
  FiGrid,
  FiUser,
  FiCompass,
  FiUsers,
  FiMonitor,
} from 'react-icons/fi'

import { AiOutlineTrophy, AiOutlineSchedule } from 'react-icons/ai'
// import { IoMdBasketball } from 'react-icons/io'
import { RiTeamLine } from 'react-icons/ri'
import { BiCrown, BiDetail, BiMessageEdit } from 'react-icons/bi'
// import { GiBasketballBasket } from 'react-icons/gi'
import { HiOutlineInboxIn } from 'react-icons/hi'

export function getMenuItems() {
  window.$ = $;
  window.moment = moment;
  return [
    {
      title: 'Applications',
      items: [
        {
          url: '/dashboard',
          icon: <FiCompass size={20} />,
          title: 'Dashboard',
          items: [],
          roles: ["admin", "player"]
        },
        {
          url: '/courts',
          icon: <FiGrid size={20} />,
          title: 'Gym (Courts)',
          items: [],
          roles: ['admin']
        },
        // {
        //   url: '/tournaments',
        //   icon: <IoMdBasketball size={20} />,
        //   title: 'Tournaments',
        //   items: []
        // },
        {
          url: '/leagues',
          icon: <AiOutlineTrophy size={20} />,
          title: 'Manage Leagues',
          items: [],
          roles: ['admin']
        },
        // {
        //   url: '/seasons',
        //   icon: <BiBook size={20} />,
        //   title: 'Seasons',
        //   items: [],
        //   roles: ['admin']
        // },
        {
          url: '/users',
          icon: <FiUsers size={20} />,
          title: 'Manage Users',
          items: [],
          roles: ['admin']
        },
        {
          url: '/manage-teams',
          icon: <RiTeamLine size={20} />,
          title: 'Manage Teams',
          items: [],
          roles: ["admin"]
        },
        {
          url: '/manage-website',
          icon: <RiTeamLine size={20} />,
          title: 'Manage Website',
          items: [],
          roles: ["admin"]
        },
        {
          url: '/marketing',
          icon: <BiMessageEdit size={20} />,
          title: 'Marketing',
          items: [],
          roles: ["admin"]
        },
        {
          url: '/registrations',
          icon: <BiCrown size={20} />,
          title: 'Registrations',
          items: [],
          roles: ["admin"]
        },
        {
          url: '/scorekeeper',
          icon: <FiMonitor size={20} />,
          title: 'Manage Scores',
          items: [],
          roles: ['scorer']
        },
        {
          url: '/scorekeepermanagerlive',
          icon: <FiMonitor size={20} />,
          title: 'Live Stats',
          items: [],
          roles: ["scorer"]
        },
        {
          url: '/scorekeepermn',
          icon: <FiMonitor size={20} />,
          title: 'Video Stats',
          items: [],
          roles: ["manager"]
        },
        {
          url: '/scorekeepermanagernew',
          icon: <FiMonitor size={20} />,
          title: 'Video Stats',
          items: [],
          roles: ["managernew"]
        },
        {
          url: '/schedules',
          icon: <AiOutlineSchedule size={20} />,
          title: 'Schedules',
          items: [],
          roles: ['admin']
        },
        {
          url: '/player/invitations',
          icon: <HiOutlineInboxIn size={20} />,
          title: 'Invitations',
          items: [],
          roles: ["player"]
        },
        {
          url: '/matches',
          icon: <BiDetail size={20} />,
          title: 'Game Logs',
          items: [],
          roles: ["player", "admin","manager"]
        },
        // {
        //   url: '/matchesm',
        //   icon: <BiDetail size={20} />,
        //   title: 'Game Logs',
        //   items: [],
        //   roles: ["manager"]
        // },
        {
          url: '/matchesmn',
          icon: <BiDetail size={20} />,
          title: 'Game Logs',
          items: [],
          roles: ["managernew"]
        },
        {
          url: '/player/teams',
          icon: <RiTeamLine size={20} />,
          title: 'Manage Teams',
          items: [],
          roles: ["player"]
        },
        {
          url: '/player/registration',
          icon: <BiCrown size={20} />,
          title: 'Registration',
          items: [],
          roles: ["player"]
        },
        {
          url: '/user-profile',
          icon: <FiUser size={20} />,
          title: 'Profile',
          items: [],
          roles: ["admin", "player", "scorer"]
        },
      ]
    }
  ]
}

export default getMenuItems;