import React, { useEffect, useState } from 'react'
import { Table, Button, Tabs, Tag } from 'antd'

import SectionTitle from '../../../components/section-title';
import ApiConfig from '../../../services/ApiConfig';
import apiClient from '../../../services/axios';
import { getDateFormat, getFullName } from '../../../utils';
import ConfirmationModal from '../../../common/confirm-modal';

const InvitePage = () => {
  const statusMap = {
    "Accepted": "#4BB543",
    "Rejected": "#E40000",
    "Pending": "#4C94BE",
    "New": "#262626"
  }
  const [loading, setLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [invitationsSent, setInvitationsSent] = useState([]);

  const onReject = async (record) => {
    const bool = await ConfirmationModal("Are you sure want reject the invite?")
    if (bool) {
      await apiClient.put(`${ApiConfig.player.invites}/${record._id}`, { status: "Rejected" })
      loadInvites();
    }
  }

  const onAccept = async (record) => {
    const bool = await ConfirmationModal("Are you sure want accept the invite?")
    if (bool) {
      await apiClient.put(`${ApiConfig.player.invites}/${record._id}`, { status: "Accepted" })
      loadInvites();
    }
  }

  const onCancel = async (record) => {
    const bool = await ConfirmationModal("Are you sure want cancel the invite?")
    if (bool) {
      await apiClient.delete(`${ApiConfig.player.invites}/${record._id}`)
      loadInvites();
    }
  }

  const columns = [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          !record.status ? <div className="action_btns">
            <Button className='btn_edit btn_reject' onClick={() => onReject(record)} type="ghost">Reject</Button>
            <Button className='btn_edit ml-2' onClick={() => onAccept(record)} type="primary">Accept</Button>
          </div> : '-'
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <Tag color={statusMap[(text)] || '#262626'}>{text || 'New'}</Tag>
        )
      }
    },
    {
      title: "Requested Team",
      dataIndex: "team",
      key: "team",
      render: (text, record) => record?.team?.name
    }, {
      title: "Invited By",
      dataIndex: "invited_by",
      key: "invited_by",
      render: (text, record, key) => {
        return (
          <div key={key.toString()} className='p-name'>
            {getFullName(record.invited_by)}<br />
            <span style={{ color: "#757575" }}>{record?.invited_by?.email}</span>
          </div>
        )
      }
    }, {
      title: "Invited At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => getDateFormat({ date: text, isTime: true })
    }
  ];

  const columnsSent = [{
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => {
      return (
        !record.status ? <div className="action_btns">
          <Button className='btn_edit' onClick={() => onCancel(record)} type="primary">Cancel</Button>
        </div> : '-'
      )
    }
  }, {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text) => {
      return (
        <Tag color={statusMap[(text || 'Pending')]}>{text || 'Pending'}</Tag>
      )
    }
  }, {
    title: "Requested Team",
    dataIndex: "team",
    key: "team",
    render: (text, record) => record?.team?.name
  }, {
    title: "Invited To",
    dataIndex: "invited_to",
    key: "invited_to",
    render: (text, record, key) => {
      return (
        <div key={key.toString()} className='p-name'>
          {getFullName(record.invited_to)}<br />
          <span style={{ color: "#757575" }}>{record?.invited_to?.email}</span>
        </div>
      )
    }
  }, {
    title: "Invited At",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => getDateFormat({ date: text, isTime: true })
  }];

  const loadInvites = async () => {
    setLoading(true);
    const { data } = await apiClient.get(ApiConfig.player.invites);
    setLoading(false);
    setInvitations(data.invites_received)
    setInvitationsSent(data.invites_sent)
  }

  useEffect(() => {
    loadInvites();
  }, [])

  return (
    <>
      <SectionTitle
        title="Invitations"
        subtitle="List of invitations"
      />
      <Tabs onChange={() => loadInvites()}>
        <Tabs.TabPane key="2" tab="Invites Received">
          <Table pagination={false} loading={loading} columns={columns} dataSource={invitations} />
        </Tabs.TabPane>
        <Tabs.TabPane key="1" tab="Invites Sent">
          <Table pagination={false} loading={loading} columns={columnsSent} dataSource={invitationsSent} />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}

export default InvitePage
