import React, { useState, useEffect } from 'react'
import SectionTitle from '../../../src/components/section-title'
import { Table, Drawer, Button, Tag, message } from 'antd'
import { FiPlus, FiEdit, FiTrash, FiEye } from "react-icons/fi";
import ApiConfig from '../../services/ApiConfig'
import apiClient from '../../services/axios'
import { getDateFormat } from "../../utils";
import apiFormDataClient from '../../services/axios-formdata'
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RemoveModal from "../../components/common/RemoveModal";
import AddCampaign from "./AddCampaign";
import DraftCampaign from "./DraftCampaign";
import ViewCampaign from "./ViewCampaign";
import PageLoader from "../../components/page-loader";


const EmailMarketing = () => {

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cVisible, setcVisible] = useState(false);
  const [ViewDesign, setViewDesign] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [marketingApiRes, setmarketingApiRes] = useState(null);
  const [customEmail, setCustomEmail] = useState([]);

  const showTextEditor = (values) => {
    setcVisible(true);
    setSelectedItem(values)
  }



  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: 'date',
      render: (text) => getDateFormat({ date: text, format: 'MMM DD, YYYY' })
    },
    {
      title: "Subject",
      render: (record, text) => (
        <>
          {record?.subject}
        </>
      )
    },
    {
      title: "Status",
      render: (record, text) => (
        <>
          {record?.is_draft && (
            <>
              <Tag color='#e40'> Draft</Tag>
            </>
          )}
          {!record?.is_draft && record?.is_sent && (
            <>
              <Tag color='#4BB543'> Sent</Tag>
            </>
          )}
          {!record?.is_draft && !record?.is_sent && (
            <>
              <Tag color='purple'> Sending</Tag>
            </>
          )}
        </>
      )
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Button hidden={record?.is_draft !== true} onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />
            <Button hidden={record?.is_draft === true} onClick={() => { setSelectedItem(record); setViewDesign(true); }} type="link" icon={<FiEye />} />
            <Button hidden={record?.is_draft !== true} onClick={() => showTextEditor(record)} className='btn_edit' type="link" icon={<FiEdit />} />

          </div>
        )
      }
    }
  ]

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.marketing.list);
        setLoading(false);
        setmarketingApiRes(response?.data?.marketingList)
      } catch (error) {
        setLoading(false);
        return [];
      }
    })();
    // eslint-disable-next-line
  }, [])


  const loadMarketingInfo = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.marketing.list);
      setLoading(false);
      setmarketingApiRes(response?.data?.marketingList)
    } catch (error) {
      setLoading(false);
      return [];
    }
  }

  const OnfinishCampaign = async (values) => {
    setVisible(false)
    setcVisible(false)
    try {
      setLoading(true);
      apiFormDataClient.post(ApiConfig.marketing.sendEmailCampaign, values).then(()=>{message.success('Emails Sent Successfully');loadMarketingInfo()});
      if (selectedItem?.is_draft === true) {
        onDelete2()
      }
      setSelectedItem(null)
      loadMarketingInfo()
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  const showAddCampaign = () => {
    setVisible(true)
  }

  const onDelete = async () => {
    setDeletePopupVisibe(false);
    await apiClient.delete(ApiConfig.marketing.deletecampaign + `/${selectedItem._id}`);
    loadMarketingInfo()
  }

  const onDelete2 = async () => {
    setDeletePopupVisibe(false);
    await apiClient.delete(ApiConfig.marketing.deletedraftcampaign + `/${selectedItem._id}`);
    loadMarketingInfo()
  }


  return (
    <>
      {loading && <PageLoader />}
      {!loading && <>
        <RemoveModal description="Are you sure you want to delete?" visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
        <SectionTitle title="Marketing" subtitle="Email Campaign" right={
          <>
            <button
              onClick={showAddCampaign} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Create Campaign</span>
            </button>
          </>
        } />
        <Table
          className="upcoming_table"
          dataSource={marketingApiRes}
          columns={columns}
          pagination={false}
        />

        <Drawer
          destroyOnClose
          title="Create Email Campaign"
          className="full-drawer"
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <AddCampaign
            onFinish={OnfinishCampaign}
            setCustomEmail={setCustomEmail}
            customEmail={customEmail}
          />
        </Drawer>
        <Drawer
          destroyOnClose
          title="Email Campaign"
          className="full-drawer"
          visible={cVisible}
          onClose={() => setcVisible(false)}
        >
          {cVisible && (
            <>
              <DraftCampaign
                currentItem={selectedItem}
                onFinish={OnfinishCampaign}
              />
            </>
          )}
        </Drawer>
        <Drawer
          destroyOnClose
          title={selectedItem?.subject}
          className="full-drawer"
          visible={ViewDesign}
          onClose={() => setViewDesign(false)}
        >
          {ViewDesign && (
            <>
              <ViewCampaign
                currentItem={selectedItem}
              />
            </>
          )}
        </Drawer>
      </>
      }
    </>
  )
}
export default EmailMarketing
