import React, { useState, useEffect } from 'react'
import SectionTitle from '../../../src/components/section-title'
import { Table, Drawer, Button, Switch } from 'antd'
import { FiPlus, FiTrash, } from "react-icons/fi";
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import apiFormDataClient from '../../services/axios-formdata'
import PageLoader from "../../components/page-loader";
import RemoveModal from '../../components/common/RemoveModal';
import HomepageBanner from '../../../src/pages/website-manager/HomepageBanner'


const WebsiteManager = () => {

  const [visible, setVisible] = useState(false);
  const [websiteData, setwebsiteData] = useState(null);
  const [bannerimage1, setbannerimage1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [statusPopupVisible, setstatusPopupVisible] = useState(false);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);

  const showBannerDrawer = () => {
    setVisible(true)
  }

  const SelectedBannerImage = (values) => {
    setbannerimage1(values)
  }

  const OnFinishUpload = async (values) => {
    setVisible(false)
    const formdata = new FormData();
    formdata.append('type', values.type);
    formdata.append('banner_heading1', values.banner_heading1);
    formdata.append('banner_sub_heading1', values.banner_sub_heading1);
    formdata.append('banner_image1', bannerimage1);
    try {
      setLoading(true);
      await apiFormDataClient.post(ApiConfig.website.saveWebsiteInfo, formdata);
      setLoading(false);
      loadWebsiteInfo();
    } catch (error) {
      setLoading(false);
    }
  }

  const loadWebsiteInfo = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(ApiConfig.website.getWebsiteInfo);
      setwebsiteData(response?.data?.bannerList)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return [];
    }
  }


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(ApiConfig.website.getWebsiteInfo);
        setLoading(false);
        setwebsiteData(response?.data?.bannerList)
      } catch (error) {
        setLoading(false);
        return [];
      }
    })();

    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: "Type",
      key: "type",
      dataIndex: 'type',
    },
    {
      title: "Banner Heading",
      key: "banner_heading1",
      dataIndex: 'banner_heading1',
    },
    {
      title: "Banner Sub Heading",
      key: "banner_sub_heading1",
      dataIndex: 'banner_sub_heading1',
    },
    {
      title: "Banner Image",
      dataIndex: "banner_image1",
      name: "banner_image1",
      render: (text, record) => {
        return <div className="team_logo" style={{ width: '200px' }}><img src={text || '/logos/logo.png'} alt={record.banner_heading1} />
        </div>
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Switch checked={record.is_active} onChange={(e, event) => onToggleLeague(e, record, event)} />
            <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} className='btn_edit' type="link" icon={<FiTrash />} />
          </div>
        )
      }
    }
  ]

  const onToggleLeague = (e, record) => {
    setSelectedItem(record);
    setstatusPopupVisible(true);
  }

  const onDelete = async () => {
    setLoading(true);
    setDeletePopupVisibe(false);
    await apiClient.patch(`${ApiConfig.website.deleteWebsiteInfo}/${selectedItem._id}`);
    setLoading(false);
    loadWebsiteInfo()
  }

  const OnstatusChange = async () => {
    setLoading(true);
    setstatusPopupVisible(false);
    await apiClient.patch(`${ApiConfig.website.updateWebsiteInfo}/${selectedItem._id}`);
    setLoading(false);
    loadWebsiteInfo()
  }

  // const components = {
  //   body: {
  //     row: DraggableBodyRow,
  //   },
  // };
  // const moveRow = useCallback(
  //   (dragIndex, hoverIndex) => {
  //     const dragRow = data[dragIndex];
  //     setData(
  //       update(data, {
  //         $splice: [
  //           [dragIndex, 1],
  //           [hoverIndex, 0, dragRow],
  //         ],
  //       }),
  //     );
  //   },
  //   [data],
  // );


  return (
    <>
      {loading && <PageLoader />}
      {!loading && <>
        {/* <RemoveModal description={selectedItem?.is_active ? 'Are you sure you want to mark this banner to hide?' : 'Are you sure you want to show the banner?'} visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} /> */}
        <RemoveModal description={selectedItem?.is_active ? 'Are you sure you want to mark this banner to hide?' : 'Are you sure you want to show the banner?'} visible={statusPopupVisible} onSubmit={OnstatusChange} onCancel={() => setstatusPopupVisible(false)} />
        <RemoveModal description="Are you sure, you want to delete this banner" visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
        <SectionTitle title="Website" subtitle="Homepage" right={
          <>
            <button
              hidden={websiteData?.filter(x => x.type === 'Banner')?.length === 3 && websiteData?.filter(x => x.type === 'Landing_Page')?.length === 1}
              onClick={showBannerDrawer} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Banner Info</span>
            </button>
          </>
        } />
        <Table
          className="upcoming_table"
          dataSource={websiteData}
          columns={columns}
          pagination={false}
        />

        <Drawer
          maskClosable={false}
          title="Add Homepage Banner"
          className="register_form"
          visible={visible}
          onClose={() => setVisible(false)}
        >
          {visible && <HomepageBanner onSubmit={OnFinishUpload} activeBannerImage={SelectedBannerImage} />}
        </Drawer>
      </>
      }
    </>
  )
}
export default WebsiteManager
