import React from 'react'
import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <div className="uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start w-full whitespace-no-wrap text-white">
      <Link
        //to={process.env.REACT_APP_WEBSITE}
        to={{ pathname: process.env.REACT_APP_WEBSITE }} target="_blank" 
        className="flex flex-row items-center justify-start space-x-2">
        <img src={`/logos/${process.env.REACT_APP_BUSSINESS_NAME}.png`} alt="" />
      </Link>
    </div>
  )
}

export default Logo
