import React, { useEffect, useState } from "react";
import store from 'store'
import { Button, Card, Checkbox, DatePicker, Drawer, Form, InputNumber, message, Modal, notification, Radio, Select, Table, Tabs, Tag, Tooltip, Upload } from "antd";
import { InboxOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import $ from "jquery";
// import moment from "moment-timezone";  
import leagues from '../../json/data/leagus.json';
import courts from '../../json/data/courts.json';
import teams from '../../json/data/teams.json';
// import matches from '../../json/data/matches.json';
import SectionTitle from "../../components/section-title";
import { getDateFormat, getTimingsByIntervals } from "../../utils";
import apiFormDataClient from "../../services/axios-formdata";
import ApiConfig from "../../services/ApiConfig";
import apiClient from "../../services/axios";
import PageLoader from "../../components/page-loader";
import ConfirmationModal from "../../common/confirm-modal";

const Option = Select

export const renderMobileTable = columns => {
  return columns.filter(
    column => column.key === "actions" || column.key === "match" || column.key === "date" || column.key === "season" || column.key === "league" || column.key === "tier_type" || column.key === "tier" || column.key === "round" || column.key === "status"
  );
};

export const renderDesktopTable = columns => {
  return columns.filter(
    column => column.key === "match" || column.key === "date" || column.key === "season" || column.key === "league" || column.key === "tier_type" || column.key === "tier" || column.key === "round" || column.key === "status" || column.key === "actions"
  );
};

const SchedulePage = () => {


  const loggedInUser = store.get('user.data');
  const dateFormat = 'ddd, DD MMM, YYYY';
  const [form] = Form.useForm();
  // const tiers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
  // const rounds = ["-1", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const timings = getTimingsByIntervals(60);
  const [matchDet, setMatchDet] = useState({});
  const [count, setCount] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState(null);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(getDateFormat());
  const [loading, setLoading] = useState(false);
  const [cLoading, setcLoading] = useState(false);
  const [showCalender, setShowCalender] = useState(true);
  const [visible, setVisible] = useState(false);
  const [cVisible, setcVisible] = useState(false);
  const [league, setLeague] = useState(null);
  const [leagueSelected, setLeagueSelected] = useState(null);
  const [tierType, setTierType] = useState("Regular");
  const [listing, setListing] = useState(false)
  const [noTier, setNoTier] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false);
  const [gameVisible, setGameVisible] = useState(false);
  const [gameSheetFiles, setGameSheetFiles] = useState([]);
  const [gameSheetErrors, setGameSheetErrors] = useState([]);
  const [lastUploadHistory, setLastUploadHistory] = useState({});
  // const [isManualTiers, setIsManualTiers] = useState(false);
  const [isRemoveLeagueSchedule, setIsRemoveLeagueSchedule] = useState(false)
  const [matches, setMatches] = useState([]);
  const [selectedTab, setselectedTab] = useState('1');
  const [showMatchesMobile, setshowMatchesMobile] = useState(true)
  const [filters, setFilters] = useState({
    season: undefined,
    league: undefined,
    from: "",
    to: ""
  })
  console.log(filters,'filtersfilters')

  const roundArray = [
    {
      "value": 1,
      "label": "Round 1"
    },
    {
      "value": 2,
      "label": "Round 2"
    },
    {
      "value": 3,
      "label": "Round 3"
    },
    {
      "value": 4,
      "label": "Round 4"
    },
    {
      "value": 5,
      "label": "Round 5"
    },
    {
      "value": 6,
      "label": "Round 6"
    },
    {
      "value": 7,
      "label": "Round 7"
    },
    {
      "value": 8,
      "label": "Round 8"
    },
    {
      "value": 9,
      "label": "Round 9"
    },
    {
      "value": 10,
      "label": "Round 10"
    }

  ]


  console.log("selectedTab", selectedTab)

  const callback = (key) => {
    if (key === '3') {
      setshowMatchesMobile(true)
    }
    else if (key === '4') {
      setshowMatchesMobile(false)
    }
    setselectedTab(key)
  }
  const [checkedMatches,setCheckedMatches]=useState([])
  console.log(checkedMatches,'checkedMatchescheckedMatches')

  const [matchColumnsDesktop, setmatchColumnsDesktop] = useState([{
    title: "Game",
    dataIndex: "match",
    key: "match",
    width: "350px",
    filters: [],
    filterSearch: true,
    render: (text, gl) => {
      return (
        <>
        {(gl?.status === "Scheduled") && <Checkbox valuePropName="checked" style={{marginRight:'5px'}} 
        onChange={(e)=>{
          console.log(e.target.checked,'evenet',gl)
          if(e.target.checked) {
            const array = checkedMatches
            console.log(array,'array150')
            array.push((gl?._id))
            console.log(array,'array151')
            setCheckedMatches([...array])
          } else {
            const array = [...checkedMatches]
            const index = array.indexOf(5);
            if (index > -1) { 
              array.splice(index, 1);
            }
            setCheckedMatches([...array])
          }
      }}
        />}
          <span class={`pl-team-name pl-team${gl?.home?.team?._id === gl?.won_by?.team._id ? ' w-team' : ''}`}>{gl?.home?.team?.name}({gl?.home?.score})</span>
          vs.
          <span class={`pl-team-name pl-team pl-away${gl?.away?.team?._id === gl?.won_by?.team._id ? ' w-team' : ''}`}>{gl?.away?.team?.name}({gl?.away?.score})</span>
        </>
      )
    }
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: "200px",
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (text) => getDateFormat({ date: text, format: 'MMM DD, YYYY hh:mm a' })
    // render: (text) => (
    //   <>
    //     {/* {getDateFormat({ date: text, format: 'MMM DD, YYYY hh:mm a' })} */}
    //     {formattedTimeZone(text)}
    //   </>
    // ),
    // render: (text) => formattedTimeZone(text)
  }, {
    title: "Season",
    dataIndex: "season",
    key: "season",
    filters: [],
    filterSearch: true,
    width: "160px",
    render: (text, record) => record?.sports_event?.name
  }, {
    title: "League",
    dataIndex: "league",
    width: "120px",
    key: "league",
    filters: [],
    filterSearch: true,
    render: (text, record) => record?.league?.name
  }, {
    title: "Type",
    dataIndex: "tier_type",
    key: "tier_type",
    width: "100px",
    filters: ["Regular", "CO-ED"].map(ee => ({ text: ee, value: ee }))
  }, {
    title: "Tier",
    dataIndex: "tier",
    key: "tier",
    width: "100px",
    filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(ee => ({ text: `Tier ${ee}`, value: ee.toString() })),
    filterSearch: true,
    render: (text, record) => record?.tier ? record?.tier === 'N/A' ? `Tier ${record?.home?.tier} & ${record?.away?.tier}` : `Tier ${record?.tier}` : '-'
  }, {
    title: "Round",
    dataIndex: "round",
    key: "round",
    width: "100px",
    render: (text, record) => {
      if (record?.round_no === 0) return 'Playoffs'
      if (record?.round_no === -1) return 'N/A'
      return `Round ${record.round_no}`
    }
  }, {
    title: "status",
    dataIndex: "status",
    key: "status",
    width: "100px",
    render: (text) => <Tag color="#262626">{text}</Tag>
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "100px",
    render: (text, record) => <>
      {/* {!(record.status === 'Completed' || record.status === 'Started') ? <Button onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} /> : '-'} */}
      {<Button onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} />}
      {record.status === 'Scheduled' && <Button className="ml-2" onClick={() => onEditMatch(record)} icon={<EditOutlined />} />}
    </>
  }])



  const matchColumnsMobile = [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "100px",
      render: (text, record) => <>
        {(record?.home?.players?.length === 0 || record?.away?.players?.length === 0) ? <Button onClick={() => onDeleteMatch(record)} icon={<DeleteOutlined />} /> : '-'}
        {record.status === 'Scheduled' && <Button className="ml-2" onClick={() => onEditMatch(record)} icon={<EditOutlined />} />}
      </>
    },
    {
      title: "Game",
      dataIndex: "match",
      key: "match",
      width: "350px",
      render: (text, gl) => {
        return (
          <>
            <span class={`pl-team-name pl-team${gl?.home?.team?._id === gl?.won_by?.team._id ? ' w-team' : ''}`}>{gl?.home?.team?.name}({gl?.home?.score})</span>
            vs.
            <span class={`pl-team-name pl-team pl-away${gl?.away?.team?._id === gl?.won_by?.team._id ? ' w-team' : ''}`}>{gl?.away?.team?.name}({gl?.away?.score})</span>
          </>
        )
      }
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "200px",
      render: (text) => getDateFormat({ date: text, format: 'MMM DD, YYYY hh:mm a' })
    }, {
      title: "Season",
      dataIndex: "season",
      key: "season",
      width: "160px",
      render: (text, record) => record?.sports_event?.name
    }, {
      title: "League",
      dataIndex: "league",
      width: "120px",
      key: "league",
      render: (text, record) => record?.league?.name
    }, {
      title: "Type",
      dataIndex: "tier_type",
      key: "tier_type",
      width: "100px",
      filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(ee => ({ text: `Tier ${ee}`, value: ee.toString() })),
      filterSearch: true,
    }, {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
      width: "100px",
      render: (text, record) => record?.tier ? record?.tier === 'N/A' ? `Tier ${record?.home?.tier} & ${record?.away?.tier}` : `Tier ${record?.tier}` : '-'
    }, {
      title: "Round",
      dataIndex: "round",
      key: "round",
      width: "100px",
      render: (text, record) => {
        if (record?.round_no === 0) return 'Playoffs'
        if (record?.round_no === -1) return 'N/A'
        return `Round ${record.round_no}`
      }
    }, {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (text) => <Tag color="#262626">{text}</Tag>
    },
  ]


  const isMobile = window.innerWidth < 500;
  const isDesktop = window.innerWidth > 500;
  let matchcolumn = renderDesktopTable(matchColumnsDesktop);
  if (isMobile) {
    matchcolumn = renderMobileTable(matchColumnsMobile);
  }
  if (isDesktop) {
    matchcolumn = renderDesktopTable(matchColumnsDesktop);
  }
  useEffect(() => {
    loadLeagues();
    loadSeasons();
    loadTeams();
    setmatchColumnsDesktop([...matchColumnsDesktop])
    // eslint-disable-next-line
  }, [])

  

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.leagues);
    matchColumnsDesktop[3].filters = data.filter(e => e.is_active).map(ee => ({ text: ee.name, value: ee._id }))
    setmatchColumnsDesktop([...matchColumnsDesktop])
    // setLeagues([...data]);
  }

  const loadSeasons = async () => {
    const { data } = await apiClient.get(ApiConfig.seasons);
    matchColumnsDesktop[2].filters = data.filter(e => e.is_active).map(ee => ({ text: ee.name, value: ee._id }))
    setmatchColumnsDesktop([...matchColumnsDesktop])
    // setSeasons([...data]);
  }

  const loadTeams = async () => {
    const { data } = await apiClient.get(ApiConfig.player.teams);
    matchColumnsDesktop[0].filters = data?.map(ee => ({ text: ee.name, value: ee._id }))
    setmatchColumnsDesktop([...matchColumnsDesktop])
  }



  const historyColumns = [
    {
      "title": "Row",
      "dataIndex": "row",
      "key": "row"
    },
    {
      "title": "Game",
      "dataIndex": "match",
      "key": "match",
      "render": (text) => <div dangerouslySetInnerHTML={{ __html: text }} />
    },
    {
      "title": "Status",
      "dataIndex": "status",
      "key": "status",
      "sorter": (a, b) => {
        const a1 = a?.errors?.length === 0 ? 2 : 1;
        const b1 = b?.errors?.length === 0 ? 2 : 1;
        return a1 - b1;
      },
      "render": (text, record) => {
        return (
          <Tag color={record?.errors?.length === 0 ? '#4BB543' : '#E40000'}>{record?.errors?.length === 0 ? 'Success' : 'Failure'}</Tag>
        )
      }
    },
    {
      "title": "Reason",
      "dataIndex": "errors",
      "key": "errors",
      "render": (text, record) => record?.errors?.join(', ') || 'N/A'
    }
  ]


  console.log(matchDet,"matchDetmatchDet")
  const onEditMatch = async (record) => {
    setSelectedMatch({ ...record })
    //setMatchDet({...matchDet})
    //console.log(record,'recordmatchDet',matchDet)
    setcVisible(true);
  }

  const props = {
    name: 'file',
    beforeUpload: (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      return isJpgOrPng;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    },
    onChange: (e) => {
      if (e.file.status === 'done') {
        window.scheduleFile = e.file.originFileObj;
      }
    },
    onRemove: () => {
      window.scheduleFile = null
    }
  }

  const gameProps = {
    name: 'file',
    beforeUpload: () => {
      return true;
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    },
    onChange: (e) => {
      if (e.file.status !== 'removed') {
        window.xlsxFile = e.file.originFileObj;
        setGameSheetFiles([{
          name: e.file.name,
          uid: 'uid',
          status: 'done'
        }])
      }
    },
    onRemove: () => {
      window.xlsxFile = null;
      setGameSheetFiles([...[]])
    }
  }

  const ScheduleCalendar = () => {
    const [schedules, setSchedules] = useState([]);

    const onDragOver = (e) => {
      window.cellDetail = null;
      const val = $(e.target).closest('.cal-schedule-item').attr('data-index');
      window.cellDetail = val;
      e.preventDefault();
    }

    const onDragEnd = (e, match) => {
      if (window.cellDetail) {
        const sId = parseInt(window?.cellDetail?.split('-')[0]);
        const cId = parseInt(window?.cellDetail?.split('-')[1]);
        schedules.forEach(ts => {
          if (ts.id === sId) {
            ts.courts.forEach(cs => {
              if (cs.id === cId) {
                cs.away = match.away
                cs.home = match.home;
              }
            })
          }
        })
        setSchedules([...schedules])
      }
      window.cellDetail = null;
    }

    useEffect(() => {
      const temp = [];
      timings.forEach((tt, k) => {
        const tmp = {
          id: (k + 1),
          slot_time: tt,
          courts: [],
        };
        tmp.courts = courts.map((cc, itr) => ({
          id: itr + 1,
          court: cc,
          home: null,
          away: null
        }))
        temp.push(tmp);
      })
      // temp[1] = {
      //   slot_time: temp[1].slot_time,
      //   court: { name: "Royal Crown" },
      //   home: {
      //     "name": "Torronto Venoms",
      //     "logo": "/logos/Barcelona-Logo.png",
      //   },
      //   away: {
      //     "name": "Blackout",
      //     "logo": "/logos/Barcelona-Logo.png",
      //   }
      // }
      setSchedules([...temp])
    }, [])

    return (
      <div className="schedule-calendar">
        <div className="left-cal">
          <div className="teams-list">
            <div className="team-list-title">Round 1 - Matches</div>
            {
              matches[0]?.rounds.map((ee, k) => {
                return (
                  <div key={k.toString()} className="team-item">
                    <div className="team-item-title">{ee.name.replace('Round', 'Tier')}</div>
                    <div className="team-item-list">
                      {ee.matches.map(tt => (
                        // <div draggable onDragEnd={(e) => onDragEnd(e, pp)} className="team-item-val">
                        //   <div className="t-avatar"><img alt={pp.name} src={pp.logo} /></div>
                        //   <div className="t-name">{pp.name}</div>
                        // </div>
                        <div draggable onDragEnd={(e) => onDragEnd(e, tt)} className="teams-schedule">
                          <div className="team-home">
                            <div className="team-item-val">
                              <div className="t-avatar"><img alt={tt?.home?.name || ''} src={tt?.home?.logo} /></div>
                              <div className="t-name">{tt?.home?.name}</div>
                            </div>
                          </div>
                          <div className="team-vs-team">vs</div>
                          <div className="team-away">
                            <div className="team-item-val">
                              <div className="t-avatar"><img alt={tt?.away?.name || ''} src={tt?.away?.logo} /></div>
                              <div className="t-name">{tt?.away?.name}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="right-cal">
          <div className="r-calender">
            <div className="left-calender">
              <div className="cal-timings">
                <div className="cal-time c-s" />
                {
                  timings.map((tt, k) => (
                    <div className="cal-time" key={k.toString()}>{tt}</div>
                  ))
                }
              </div>
            </div>
            <div className="right-calender">
              <div className="cal-courts">
                {
                  courts.map((tt, k) => (
                    <div className="cal-court" key={k.toString()}><Tooltip title={tt.name}>{tt.name}</Tooltip></div>
                  ))
                }
              </div>
              <div className="cal-schedule-list">
                {
                  schedules.map((time, itr) => (
                    <div key={itr.toString()} className="cal-schedules">
                      {
                        time?.courts?.map((tt, k) => (
                          <div onDragOver={onDragOver} data-index={`${time.id}-${tt.id}`} className="cal-schedule-item" key={k.toString()}>
                            {
                              (tt?.home || tt?.away) && (
                                <div className="teams-schedule">
                                  <div className="team-home">
                                    <div className="team-item-val">
                                      {tt?.home && <div className="t-avatar"><img alt={tt?.home?.name || ''} src={tt?.home?.logo} /></div>}
                                      <div className={`t-name ${tt?.home?.name ? '' : 'no-name'}`}>{tt?.home?.name || 'Yet to choose'}</div>
                                    </div>
                                  </div>
                                  <div className="team-vs-team">vs</div>
                                  <div className="team-away">
                                    <div className="team-item-val">
                                      {tt?.away && <div className="t-avatar"><img alt={tt?.away?.name || ''} src={tt?.away?.logo} /></div>}
                                      <div className={`t-name ${tt?.away?.name ? '' : 'no-name'}`}>{tt?.away?.name || 'Yet to choose'}</div>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const RoundList = () => {
    return (
      <>
        <div className="rounds-list">
          <Card>
            <div className="rounds-content">
              <div className="rounds-list-title">Round 1</div>
              <div className="rounds-list-items">
                <div className="rounds-list-item">
                  <div className="r-home">{teams[0].name}</div>
                  <div className="r-vs">vs</div>
                  <div className="r-away">{teams[1].name}</div>
                </div>
                <div className="rounds-list-item">
                  <div className="r-home">{teams[2].name}</div>
                  <div className="r-vs">vs</div>
                  <div className="r-away">{teams[3].name}</div>
                </div>
                <div className="rounds-list-item">
                  <div className="r-home">{teams[4].name}</div>
                  <div className="r-vs">vs</div>
                  <div className="r-away">{teams[5].name}</div>
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div className="rounds-content">
              <div className="rounds-list-title">Round 2</div>
              <div className="rounds-list-items">
                <div className="rounds-list-item">
                  <div className="r-home">{teams[0].name}</div>
                  <div className="r-vs">vs</div>
                  <div className="r-away">{teams[1].name}</div>
                </div>
                <div className="rounds-list-item">
                  <div className="r-home">{teams[2].name}</div>
                  <div className="r-vs">vs</div>
                  <div className="r-away">{teams[3].name}</div>
                </div>
                <div className="rounds-list-item">
                  <div className="r-home">{teams[4].name}</div>
                  <div className="r-vs">vs</div>
                  <div className="r-away">{teams[5].name}</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </>
    )
  }

  const Picker = () => (
    <div className="picker-comp">
      <DatePicker value={scheduleDate} onChange={(e) => setScheduleDate(e)} format={dateFormat} />
    </div>
  )

  const LeagueTab = () => {
    const [activeLeagueTabKey, setActiveLeagueTabKey] = useState('1')
    return (
      <Tabs tabBarExtraContent={(
        <>
          {/* <Select defaultActiveFirstOption className="select-item" placeholder="Select tier" options={leagues[parseInt(activeLeagueTabKey) - 1]?.tiers?.map(e => ({ label: `Tier ${e}`, value: e }))} /> */}
          <Select defaultActiveFirstOption className="select-item" placeholder="Select round" options={[1, 2, 3, 4, 5, 6, 7].map(e => ({ label: `Round ${e}`, value: e }))} />
          <Button onClick={() => setShowCalender(!showCalender)} style={{ marginTop: "-2px", marginLeft: "8px", height: "38px" }} type="primary">{showCalender ? 'Hide' : 'Show'} Calender View</Button>
        </>
      )} onChange={(e) => setActiveLeagueTabKey(e)}>
        {
          leagues.map((e, k) => (
            <Tabs.TabPane tab={`${e.day} League`} key={((k + 1).toString())}>
              {((activeLeagueTabKey === (k + 1).toString()) && showCalender) && <ScheduleCalendar />}
              {((activeLeagueTabKey === (k + 1).toString()) && !showCalender) && <RoundList />}
            </Tabs.TabPane>
          ))
        }
      </Tabs>
    )
  }

  const onUpload = async () => {
    try {
      if (!window.scheduleFile && !isRemoveLeagueSchedule) {
        notification.error({
          message: "Please select the game schedule image"
        })
        return;
      }
      setcLoading(true);
      const formdata = new FormData();
      formdata.append('league', league);
      if (isRemoveLeagueSchedule) formdata.append('is_removed', true)
      if (window.scheduleFile) formdata.append('image', window.scheduleFile);
      await apiFormDataClient.post('/settings/upload-schedule', formdata);
      setcLoading(false);
      setVisible(false);
    } catch (error) {
      setcLoading(false);
      setVisible(false);
    }
  }

  const onUploadGames = async () => {
    try {
      setcLoading(true);
      const formdata = new FormData();
      if (window.xlsxFile) formdata.append('file', window.xlsxFile);
      await apiFormDataClient.post('/matches/upload-games', formdata);
      setcLoading(false);
      setGameVisible(false);
      setGameSheetErrors([...[]])
    } catch (error) {
      setcLoading(false);
      setGameVisible(false);
      if (error?.response?.data?.errors?.length > 0) {
        setGameSheetErrors(error?.response?.data?.errors)
      }
    }
  }

  const onDownloadFile = async () => {
    const { data } = await apiClient.get(`${ApiConfig.matches.downloadSampleFile}?league=${league}`);
    var arr = data.file.data;
    console.log(data);
    var byteArray = new Uint8Array(arr);
    var a = window.document.createElement('a');
    // a.target = "_blank";

    a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    // a.href = data.url;
    a.download = 'create_games_sample.xlsx';

    // Append anchor to body.
    document.body.appendChild(a)
    a.click();


    // Remove anchor from body
    document.body.removeChild(a)

  }

  const onFinish = async (values) => {
    try {
      console.log(values);
      if (values.home === values.away) {
        notification.error({
          message: "Home team can not be same as the away team."
        })
        return;
      }
      setcLoading(true);
      if (values.home_tier === '0' || !values.home_tier) values.home_tier = null
      if (values.away_tier === '0' || !values.away_tier) values.away_tier = null
      values.home = {
        team: values.home,
        score: values.home_score,
        tier: values.home_tier
      }
      values.away = {
        team: values.away,
        score: values.away_score,
        tier: values.away_tier
      }
      console.log("values", values);
      // console.log("values", moment(values?.date).format('MMM DD, YYYY hh:mm a'));
      // console.log("values", moment.tz(values?.date, 'YYYY-MM-DD HH:mm:ss', 'UTC').format());
      const getDateVal = values.date.format('YYYY-MM-DD');
      const getTimeVal = values.date.format('HH:mm:ss');
      const lastTime = '000+00:00'
      const finaldateVal = getDateVal + 'T' + getTimeVal + '.' + lastTime
      console.log("finaldateVal", finaldateVal);
      values.round_no = typeof(values.round_no)==='string'?(values.round_no):(values.round_no.value)
      const updatePayload = {
        "court": values?.court,
        "sports_event": values?.sports_event,
        "league": values?.league,
        "tier_type": values?.tier_type,
        "no_standings": values?.no_standings,
        "home": {
          "team": values?.home?.team,
          "tier": values?.home?.tier,
          "score": values?.home?.score,
        },
        "away": {
          "team": values?.away?.team,
          "tier": values?.away?.tier,
        },
        "round_no": values?.round_no,
        // "date": moment(values?.date).format('MMM DD, YYYY hh:mm a'),
        // "date": values.date.format('YYYY-MM-DD HH:mm:ss'),
        "date": finaldateVal,
        "home_tier": values?.home_tier,
        "away_tier": values?.away_tier,
      }
      console.log("updatePayload", updatePayload);
      if (selectedMatch?._id) {
        await apiClient.put(ApiConfig.matches.updateScheduledMatch + `/${selectedMatch._id}`, values);
      } else {
        await apiClient.post(ApiConfig.matches.list, values);
      }
      setSelectedMatch(null);
      loadMatches();
      setcLoading(false);
    } catch (error) {
      setcLoading(false);
    }
    setcVisible(false)
  }
  // const [disabledDate,setdisabledDates] = useState([])

  // useEffect(()=>{
  //   const leagueItem = matchDet?.leagues?.filter(ee => ee.value === leagueSelected)[0]
  //   //return current && getDateFormat({ date: current, format: "ddd" }) !== leagueItem?.label?.slice(0, 3)
  //   setdisabledDates(leagueItem)
  // },[matchDet])


  const disabledDate = (current) => {
    const leagueItem = matchDet?.leagues?.filter(ee => ee.value === leagueSelected)[0]
    console.log(matchDet,'matchDetmatchDet',leagueSelected,selectedMatch,leagueItem)
    return current && getDateFormat({ date: current, format: "ddd" }) !== leagueItem?.label?.slice(0, 3)
  }
  console.log(disabledDate(),'disabledDatedisabledDate')

  const loadMatches = async (params = '') => {
    setListing(true)
    const { data } = await apiClient.get(ApiConfig.matches.list + params)
    setCount(data.count);
    setMatches([...data.results]);
    setListing(false)
  }

  const onDeleteMatch = async (record) => {
    const ok = await ConfirmationModal("Are you sure you want to delete?");
    if (ok) {
      await apiClient.delete(ApiConfig.matches.list + `/${record._id}`);
      loadMatches();
    }
  }

  const onValuesChange = (changedValues, values) => {
    console.log('changedValues', changedValues);
    setLeagueSelected(values.league)
    if (changedValues.sports_event) {
      const tvalues = form.getFieldsValue();
      form.setFieldsValue({
        ...tvalues,
        home: undefined,
        away: undefined,
        tier: undefined,
        away_tier: undefined,
        home_tier: undefined,
      })
      const seasonItem = matchDet.seasons.filter(ee => ee._id === changedValues.sports_event)[0];
      if (seasonItem) {
        setMatchDet({
          ...matchDet,
          homeTeams: [],
          awayTeams: [],
          tiers: [],
          leagues: seasonItem.leagues?.map(ee => ({ label: ee?.league?.name, value: ee?.league._id }))
        })
      }
    }
    if (changedValues.league) {
      const tvalues = form.getFieldsValue();
      form.setFieldsValue({
        ...tvalues,
        home: undefined,
        away: undefined,
        tier: undefined,
        away_tier: undefined,
        home_tier: undefined,
      })
      const seasonItem = matchDet.seasons.filter(ee => ee._id === values.sports_event)[0];
      if (seasonItem) {
        const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === changedValues.league)[0];
        if (leagueItem) {
          if (leagueItem.tiers?.filter(e => !e.tier).length > 0) {
            setNoTier(true);
            setMatchDet({
              ...matchDet,
              tiers: [],
              homeTeams: leagueItem?.tiers[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
              awayTeams: leagueItem?.tiers[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
            })
          } else {
            setNoTier(false);
            setMatchDet({
              ...matchDet,
              teams: [],
              awayTeams: [],
              homeTeams: [],
              tiers: leagueItem.tiers?.filter(e => e.tier).map(ee => ({ tier: ee.tier, type: ee.type }))
            })
          }
        }
      }
    }
    if (changedValues.home_tier || changedValues.away_tier) {
      const seasonItem = matchDet.seasons.filter(ee => ee._id === values.sports_event)[0];
      if (seasonItem) {
        const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === values.league)[0];
        if (leagueItem) {
          const tierItem = leagueItem.tiers.filter(ee => ee.type === tierType && ee.tier === (changedValues.home_tier || changedValues.away_tier))[0];
          const vv = form.getFieldsValue();
          form.setFieldsValue({
            ...vv,
            [changedValues.home_tier ? 'home' : 'away']: undefined,
          })
          setMatchDet({
            ...matchDet,
            [changedValues.home_tier ? 'home' : 'away']: undefined,
            [changedValues.home_tier ? 'homeTeams' : 'awayTeams']: tierItem.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
          })
        }
      }
    }
    if ('is_completed' in changedValues) {
      setIsCompleted(changedValues.is_completed)
    }
    if ('tier_type' in changedValues) {
      setTierType(changedValues.tier_type)
      matchDet.awayTeams = [];
      matchDet.homeTeams = [];
      const tvalues = form.getFieldsValue();
      form.setFieldsValue({
        ...tvalues,
        home: undefined,
        away: undefined,
        tier: undefined,
        away_tier: undefined,
        home_tier: undefined,
      })
      const seasonItem = matchDet.seasons.filter(ee => ee._id === values?.sports_event)[0];
      if (seasonItem) {
        const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === values?.league)[0];
        if (leagueItem) {
          if (leagueItem.tiers?.filter(e => !e.tier && e.type === changedValues.tier_type).length > 0) {
            setNoTier(true);
            matchDet.tiers = [];
            matchDet.homeTeams = leagueItem.tiers?.filter(e => !e.tier && e.type === changedValues.tier_type)[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
            matchDet.awayTeams = leagueItem.tiers?.filter(e => !e.tier && e.type === changedValues.tier_type)[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
          } else {
            matchDet.tiers = leagueItem.tiers?.filter(e => e.tier).map(ee => ({ tier: ee.tier, type: ee.type }))
            setNoTier(false);
          }
        }
      }
      setMatchDet({ ...matchDet })
    }
  }

  const loadLastUploadHistory = async () => {
    const { data } = await apiClient.get(ApiConfig.matches.lastUploadHistory);
    console.log('data', data);
    setLastUploadHistory({ ...data })
  }

  useEffect(() => {
    loadMatches();
    loadLastUploadHistory();
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data: leagueList } = await apiClient.get(ApiConfig.leagues);
        const { data: courtList } = await apiClient.get(ApiConfig.courts);
        const { data: seasonList } = await apiClient.get(ApiConfig.matches.quickMatchCreateDetails);
        setMatchDet({
          leaguesFull: leagueList.filter(ee => ee.is_active).map(e => ({ label: e.name, value: e._id })),
          courts: courtList.map(e => ({ label: e.name, value: e._id })),
          seasons: seasonList,
          leagues: [],
          teams: [],
          tiers: []
        })
        const tTmp = leagueList.filter(ee => ee.is_active);
        setLeague(tTmp[0]?._id)
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
    console.log('LeagueTab', LeagueTab);
    // eslint-disable-next-line
  }, [])


  // const formattedTimeZone = (values) => {
  //   const formattedEST1 = moment(values).utc().format('DD MMM YYYY, hh:mm A');
  //   return formattedEST1
  // }

  useEffect(() => {
    if (selectedMatch) {
      // setSelectedDate(currentDateFormatted)
      console.log(selectedMatch,'selectedMatch1000')
      form.setFieldsValue({
        court: selectedMatch?.court._id,
        sports_event: selectedMatch?.sports_event._id,
        league: selectedMatch?.league._id,
        home_tier: selectedMatch?.home?.tier,
        home: selectedMatch?.home?.team._id,
        away: selectedMatch?.away?.team._id,
        away_tier: selectedMatch?.away?.tier,
        round_no: roundArray?.filter(x => x?.value === selectedMatch.round_no),
        no_standings: selectedMatch.no_standings,
        date: getDateFormat(selectedMatch?.date, true),
        tier_type: selectedMatch.tier_type
      })
      setTierType(selectedMatch.tier_type);
      const seasonItem = matchDet.seasons.filter(ee => ee._id === selectedMatch?.sports_event._id)[0];
      let payload = {};
      if (seasonItem) {
        const leagueItem = seasonItem.leagues.filter(ee => ee?.league?._id === selectedMatch?.league._id)[0];
        if (leagueItem) {
          setLeagueSelected(selectedMatch?.league._id)
          if (leagueItem.tiers?.filter(e => !e.tier).length > 0) {
            setNoTier(true);
            payload = {
              ...matchDet,
              tiers: [],
              leagues: seasonItem.leagues?.map(ee => ({ label: ee?.league?.name, value: ee?.league._id })),
              homeTeams: leagueItem?.tiers[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
              awayTeams: leagueItem?.tiers[0]?.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id }))
            }
          } else {
            setNoTier(false);
            payload = {
              ...matchDet,
              ...payload,
              ...{
                leagues: seasonItem.leagues?.map(ee => ({ label: ee?.league?.name, value: ee?.league._id })),
                teams: [],
                tiers: leagueItem.tiers?.filter(e => e.tier).map(ee => ({ tier: ee.tier, type: ee.type }))
              }
            }
          }
          const homeTierItem = leagueItem.tiers.filter(ee => ee.tier === (selectedMatch?.home?.tier))[0];
          const awayTierItem = leagueItem.tiers.filter(ee => ee.tier === (selectedMatch?.away?.tier))[0];
          payload = {
            ...matchDet,
            ...payload,
            ...{
              homeTeams: homeTierItem.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
              awayTeams: awayTierItem.registered_teams?.map(ee => ({ label: ee?.name, value: ee._id })),
            }
          }
          console.log('payload', payload);
          setMatchDet({ ...payload })
        }
      }
    } else {
      form.resetFields();
      form.setFieldsValue({
        court: undefined,
        sports_event: undefined,
        tier_type: "Regular",
        league: undefined,
        home: undefined,
        away: undefined,
        tier: undefined,
        away_tier: undefined,
        home_tier: undefined,
        round_no: undefined,
        date: undefined
      })
    }
    // eslint-disable-next-line
  }, [cVisible])
  useEffect(() => {
    const tmp = {};
    Object.keys(filters).forEach(ee => {
      if (filters[ee]) {
        tmp[ee] = ee === 'from' || ee === 'to' ? getDateFormat({ date: filters[ee], format: "YYYY-MM-DD" }) : filters[ee]
      }
    })
    let params = new URLSearchParams(tmp).toString()
    params = params ? '?' + params : '';
    
    loadMatches(params)
    // eslint-disable-next-line
  }, [filters])
  

  const handleTableChange = (newPagination, filterObj = {}, sorter) => {
    console.log(newPagination, filters, sorter);
    for (const key in filterObj) {
      filterObj[key] = Array.isArray(filterObj[key]) ? JSON.stringify(filterObj[key]) : filterObj[key]
    }
    if (sorter?.field) {
      filterObj[`sort_${sorter.field}`] = sorter.order === "ascend" ? 1 : -1
    }
    if(Boolean(filterObj['match'])) {
      filterObj['team'] = filterObj['match']
    }
    setFilters({ ...filters, ...filterObj });
  }
  const sendMail = async ()=>{
    const data = await apiClient.post(ApiConfig.matches.sendMails,{checkedMatches:[...new Set(checkedMatches)]});
    console.log(data,'data')
  }

  return (
    <div className="schedule-page">
      <SectionTitle title="Schedules" subtitle="Manage Schedules" right={<Picker />} />
      {loading && <PageLoader />}
      {!loading && <>
        <>
          {showMatchesMobile && (
            <>
              <div className="button-mobile-view">
                <div className="container">
                  <div className="row">
                  <div className="col-lg-12 justify-content-center">
                      {checkedMatches?.length? <Button className="ml-2 hide-mobile-view-matches-btn" onClick={() => sendMail()} type="primary" hidden={loggedInUser?.role === 'manager'}>Send Mail</Button>:<></>}
                    </div>
                    <div className="col-lg-12 justify-content-center">
                      <Button className="mobile-view-matches-btn ml-2" onClick={() => setVisible(true)} type="primary" hidden={loggedInUser?.role === 'manager'}>Upload Schedules</Button>
                    </div>
                    <div className="col-lg-12 justify-content-center">
                      <Button className="mobile-view-matches-btn ml-2" onClick={() => setGameVisible(true)} type="primary" hidden={loggedInUser?.role === 'manager'}>Upload Games</Button>
                    </div>
                    <div className="col-lg-12 justify-content-center">
                      <Button className="mobile-view-matches-btn ml-2" onClick={() => { setcVisible(true); setSelectedMatch(null) }} type="primary">Create Game</Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
        <Tabs onChange={callback} defaultActiveKey="3" tabBarExtraContent={<>
          {checkedMatches?.length ? <Button className="ml-2 hide-mobile-view-matches-btn" onClick={() => sendMail()} type="primary" hidden={loggedInUser?.role === 'manager'}>Send Mail</Button>:<></>}
          <Button className="ml-2 hide-mobile-view-matches-btn" onClick={() => setVisible(true)} type="primary" hidden={loggedInUser?.role === 'manager'}>Upload Schedules</Button>
          <Button onClick={() => setGameVisible(true)} className="ml-2 hide-mobile-view-matches-btn" type="primary" hidden={loggedInUser?.role === 'manager'}>Upload Games</Button>
          <Button onClick={() => { setcVisible(true); setSelectedMatch(null) }} className="ml-2 hide-mobile-view-matches-btn" type="primary">Create Game</Button>
        </>}>
          {/* <Tabs.TabPane key="1" tab="Leagues">
            <LeagueTab />
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane key="2" tab="Tournaments">
          </Tabs.TabPane> */}
          <Tabs.TabPane key="3" tab="Matches">
            <Table
              loading={listing}
              columns={matchcolumn}
              pagination={{
                total: count,
                defaultPageSize: 20,
                onChange: (current, pageSize) => {
                  console.log(current, pageSize);
                  filters.offset = (current - 1) * pageSize;
                  filters.limit = pageSize;
                  setFilters({ ...filters })
                  //loadMatches(`?offset=${offset}&limit=${limit}`)
                },
                onShowSizeChange: (current, pageSize) => {
                  console.log(current, pageSize);
                  filters.offset = (current - 1) * pageSize;
                  filters.limit = pageSize;
                  setFilters({ ...filters })
                  //loadMatches(`?offset=${offset}&limit=${limit}`)
                }
              }}
              onChange={handleTableChange}
              scroll={{
                y: window.innerHeight - 360,
                x: window.innerWidth - 292,
              }}
              dataSource={matches}
            />
          </Tabs.TabPane>
          {loggedInUser?.role !== 'manager' && (
            <>
              <Tabs.TabPane key="4" tab="Upload History">
                <div className="m-title">
                  <div className="fleft">
                    <div><b>Last uploaded filename: </b> <span>{lastUploadHistory?.file_name || '-'}</span></div>
                    <div><b>Uploaded at: </b> <span>{lastUploadHistory?.uploaded_at ? getDateFormat({ date: lastUploadHistory?.uploaded_at, format: "dddd, MMM DD YYYY, hh:mm a" }) : '-'}</span></div>
                  </div>
                  <div className="fright">
                    <div><b>No. of rows processed: </b> <span>{lastUploadHistory?.no_of_rows_processed || '-'}</span></div>
                    <div><b>No. of rows succeed: </b> <span>{lastUploadHistory?.no_of_rows_succeed || '-'}</span></div>
                  </div>
                </div>
                <Table
                  columns={historyColumns}
                  pagination={false}
                  dataSource={lastUploadHistory?.errors || []}
                />
              </Tabs.TabPane>
            </>)}
        </Tabs>
      </>}

      <Drawer
        visible={gameVisible}
        title="Upload Games"
        className="upload-drawer"
        onClose={() => {
          setGameVisible(false);
          setGameSheetFiles([...[]])
        }}
      >
        {
          gameSheetErrors.length === 0 && (
            <>
              <Upload.Dragger
                {...gameProps}
                multiple={false}
                maxCount={1}
                fileList={gameSheetFiles}
                className="dragger-ht"
                accept=".xlsx, .xls"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                  <i>Note: Maximum 100 games only allowed per upload.</i>
                </p>
              </Upload.Dragger>
              <Button onClick={onDownloadFile} style={{ color: "#1890ff", paddingLeft: "0px", marginTop: "8px" }} type="link">Click here to download the sheet for</Button>
              <Select value={league} className="select-league" onChange={(val) => setLeague(val)} style={{ width: "200px" }} placeholder="Select league" options={matchDet?.leaguesFull} />
              {
                gameVisible && <div className="form-footer" style={{ textAlign: "center" }}>
                  <Button loading={cLoading} onClick={onUploadGames} type="primary" className="h40">Upload games</Button>
                </div>
              }
            </>
          )
        }
        {
          gameSheetErrors.length > 0 && (
            <>
              <p className="reason-t">Last uploading failed due to the below reasons.</p>
              <Table
                columns={[{ title: "Row", dataIndex: "row", key: "row" }, { title: "Reason", dataIndex: "errors", key: "errors", render: (text, record) => record?.errors?.join(', ') }]}
                pagination={false}
                dataSource={gameSheetErrors}
              />
              {gameVisible && <div className="form-footer" style={{ textAlign: "center" }}>
                <Button onClick={() => { setGameSheetErrors(([...[]])); setGameSheetFiles([...[]]); window.xlsxFile = null; }} type="primary" className="h40">Upload another file</Button>
              </div>}
            </>
          )
        }
      </Drawer>

      <Modal
        visible={visible}
        title="Upload Game Schedules"
        okText="Upload"
        onCancel={() => {
          setVisible(false);
          setIsRemoveLeagueSchedule(false);
        }}
        okButtonProps={{ loading: cLoading }}
        onOk={onUpload}
      >
        <div className="formitem">
          <div className="form-label">Select League</div>
          <Select value={league} onChange={(e) => setLeague(e)} defaultActiveFirstOption style={{ width: "100%", marginBottom: "12px" }} className="select-league" options={matchDet?.leaguesFull || []} />
        </div>
        <div className="formitem">
          <Checkbox onChange={(e) => setIsRemoveLeagueSchedule(e.target.checked)} value={isRemoveLeagueSchedule}>Remove Schedule for this league</Checkbox>
        </div>
        {!isRemoveLeagueSchedule && <Upload.Dragger
          {...props}
          multiple={false}
          maxCount={1}
          accept="image/*"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>}
      </Modal>

      {isDesktop && (
        <>
          <Drawer
            visible={cVisible}
            className="full-drawer"
            title="Create Games"
            footer={null}
            onClose={() => { setcVisible(false); setIsCompleted(false); setSelectedMatch(null); setLeagueSelected(false); setIsCompleted(false) }}
          >
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              initialValues={{ tier_type: "Regular" }}
              onValuesChange={onValuesChange}
              onFinish={onFinish}
            >
              <div className="flex-list wt">
                <Form.Item
                  name="court"
                  label="Court"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet.courts} placeholder="Select court" />
                </Form.Item>
                <Form.Item
                  label="Sports Event"
                  name="sports_event"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet?.seasons?.map(ee => ({ label: ee.name, value: ee._id }))} placeholder="Select sport event" />
                </Form.Item>
                <Form.Item
                  label="League"
                  name="league"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet.leagues} placeholder="Select league" />
                </Form.Item>
              </div>
              <div className="flex-list bb">
                <Form.Item
                  name="tier_type"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Radio.Group>
                    <Radio value="Regular">Regular</Radio>
                    <Radio value="CO-ED">CO-ED</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="is_completed"
                  valuePropName="checked"
                >
                  <Checkbox>Mark as completed</Checkbox>
                </Form.Item>
                <Form.Item
                  name="no_standings"
                  valuePropName="checked"
                >
                  <Checkbox>No Standings</Checkbox>
                </Form.Item>
              </div>
                <Form.Item
                  name="notify_manager"
                  valuePropName="checked"
                >
                  <Checkbox>Notify Manager(s)</Checkbox>
                </Form.Item>
              <Form.Item
                label="Home Team"
                className="no_form_input"
              />
              <div className="flex-list">
                {!noTier && <Form.Item
                  name="home_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                <Form.Item
                  name="home"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.homeTeams} placeholder="Select home team" />
                </Form.Item>
                {isCompleted && <Form.Item
                  name="home_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
              </div>
              <Form.Item
                label="Away Team"
                className="no_form_input"
              />
              <div className="flex-list">
                {!noTier && <Form.Item
                  name="away_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                <Form.Item
                  name="away"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.awayTeams} placeholder="Select away team" />
                </Form.Item>
                {isCompleted && <Form.Item
                  name="away_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
              </div>
              <div className="flex-list">
                <Form.Item
                  label="Round"
                  name="round_no"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {/* <Select disabled={!leagueSelected} options={rounds.map(e => ({ label: e === '-1' ? 'N/A' : `${e === '0' ? 'Playoffs' : `${e} Round`}`, value: e }))} placeholder="Select round" /> */}
                  <Select
                    // showSearch/
                    placeholder="Select from list"
                    optionFilterProp="children"
                  // filterOption={(input, option) =>
                  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  // }
                  >
                    {roundArray?.map((mval, index) => (
                      <Option key={mval?.value} value={mval?._id}>{mval?.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Game Date & Time"
                  name="date"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <DatePicker
                  
                    disabledDate={disabledDate}
                    disabled={!leagueSelected}
                    showNow={false}
                    showTime
                    minuteStep={5}
                    showSecond={false}
                    format="MMM DD, YYYY hh:mm a"
                  />
                </Form.Item>
              </div>
              {cVisible && <div className="form-footer" style={{ textAlign: "center" }}>
                <Button loading={cLoading} type="primary" className="h40" htmlType="submit">{selectedMatch?._id ? 'Update' : 'Create'} Game</Button>
              </div>}
            </Form>
          </Drawer>
        </>)}

      {isMobile && (
        <>
          <Drawer
            visible={cVisible}
            className="mobile-view"
            title="Create Games"
            footer={null}
            onClose={() => { setcVisible(false); setIsCompleted(false); setSelectedMatch(null); setLeagueSelected(false); setIsCompleted(false) }}
          >
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              initialValues={{ tier_type: "Regular" }}
              onValuesChange={onValuesChange}
              onFinish={onFinish}
            >
              <div className="flex-list wt flex-list-mobile-view">
                <Form.Item
                  name="court"
                  label="Court"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet.courts} placeholder="Select court" />
                </Form.Item>
                <Form.Item
                  label="Sports Event"
                  name="sports_event"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet?.seasons?.map(ee => ({ label: ee.name, value: ee._id }))} placeholder="Select sport event" />
                </Form.Item>
                <Form.Item
                  label="League"
                  name="league"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select options={matchDet.leagues} placeholder="Select league" />
                </Form.Item>
              </div>
              <div className="flex-list bb">
                <Form.Item
                  name="tier_type"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Radio.Group>
                    <Radio value="Regular">Regular</Radio>
                    <Radio value="CO-ED">CO-ED</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="is_completed"
                  valuePropName="checked"
                >
                  <Checkbox>Mark as completed</Checkbox>
                </Form.Item>
                <Form.Item
                  name="no_standings"
                  valuePropName="checked"
                >
                  <Checkbox>No Standings</Checkbox>
                </Form.Item>
              </div>
              <Form.Item
                label="Home Team"
                className="no_form_input"
              />
              <div className="flex-list">
                {!noTier && <Form.Item
                  name="home_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                <Form.Item
                  name="home"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.homeTeams} placeholder="Select home team" />
                </Form.Item>
                {isCompleted && <Form.Item
                  name="home_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
              </div>
              <Form.Item
                label="Away Team"
                className="no_form_input"
              />
              <div className="flex-list">
                {!noTier && <Form.Item
                  name="away_tier"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet?.tiers?.filter(ee => ee.type === tierType).map(e => ({ label: e === "0" ? 'N/A' : `${e.tier} Tier`, value: e.tier })) || []} placeholder="Select tier" />
                </Form.Item>}
                <Form.Item
                  name="away"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Select disabled={!leagueSelected} options={matchDet.awayTeams} placeholder="Select away team" />
                </Form.Item>
                {isCompleted && <Form.Item
                  name="away_score"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber controls={false} disabled={!leagueSelected} placeholder="Enter score" />
                </Form.Item>}
              </div>
              <div className="flex-list">
                <Form.Item
                  label="Round"
                  name="round_no"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {/* <Select disabled={!leagueSelected} options={rounds.map(e => ({ label: e === '-1' ? 'N/A' : `${e === '0' ? 'Playoffs' : `${e} Round`}`, value: e }))} placeholder="Select round" /> */}
                  <Select
                    showSearch
                    placeholder="Select from list"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {roundArray?.map((mval, index) => (
                      <Option key={mval?.value} value={mval?._id}>{mval?.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Game Date & Time"
                  name="date"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <DatePicker disabledDate={disabledDate} disabled={!leagueSelected} showNow={false} showTime minuteStep={5} showSecond={false} format="MMM DD, YYYY hh:mm a" />
                </Form.Item>
              </div>
              {cVisible && <div className="form-footer" style={{ textAlign: "center" }}>
                <Button loading={cLoading} type="primary" className="h40" htmlType="submit">{selectedMatch?._id ? 'Update' : 'Create'} Game</Button>
              </div>}
            </Form>
          </Drawer>
        </>)}
    </div >
  )
}

export default SchedulePage
