import React, { useState, useRef, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import style from './style.module.scss'

const ColorPicker = ({ value, onChange, placeholder }) => {
  const ref = useRef()
  const [showColorPicker, setShowColorPicker] = useState(false)

  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      setShowColorPicker(false)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [])

  return (
    <div className={style.colorPickerBlock} ref={ref}>
      <div className="d-flex flex-row">
        <div
          className={`${style.colorBox} rounded-left border`}
          style={{ backgroundColor: value, borderColor: value }}
        />
        <button
          type="button"
          className="btn btn-light btn-block"
          onClick={() => setShowColorPicker(true)}
        >
          {placeholder || "Change Color"}
        </button>
        {showColorPicker && (
          <div className={`${style.colorPickerPopup}`}>
            <SketchPicker color={value} onChange={color => onChange(color.hex)} />
          </div>
        )}
      </div>
    </div>
  )
}

const ColorPickerInput = ({ placeholder, value, name = "", onChange = null, onBlur = null, required }) => {

  const [color, setColor] = useState(value);

  useEffect(() => {
    setColor(value);
  }, [value])

  useEffect(() => {
    if (onChange) onChange(color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color])

  return (
    <div className={`${style.colorPicker} color-picker-input`}>
      <ColorPicker
        value={color}
        placeholder={placeholder}
        onChange={clr => setColor(clr)}
      />
      <input
        maxLength="7"
        className={[style.colorInput, style.formInput, required ? style.errorInput : '',].join(' ')}
        onBlur={onBlur}
        name={name}
        onChange={e => setColor(e.target.value)}
        value={color}
      />
    </div>
  )
}

export default ColorPickerInput