/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  Menu,
  Carousel,
  Button,
  Tabs,
  Table,
  Select,
  Drawer,
  Breadcrumb,
  Collapse,
  Modal,
  Spin
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import store from "store";
import { FiMenu } from "react-icons/fi";
import Empty from "../../layouts/empty";
import apiClient from "../../services/axios";
import ApiConfig from "../../services/ApiConfig";
import Customization from "../../components/documentation/faq";
import MobileFooter from "./Footer";
import LeftSideBarWebsite from "../../components/left-sidebar-website";
import FinalPlay from "./FinalPlay";
import ScheduledPlay from "./ScheduledPlay";
import StartedHalfTime from "./StartedHalfTime";
import AssignedPlay from "./AssignedPlay";
import DesktopView from "./DesktopView";
import SectionTitle from "../../components/section-title";
import PageLoader from "../../components/page-loader";
import { getDateFormat } from "../../utils";

const { Panel } = Collapse;
const Option = Select;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const TeamsLogo = (props) => {
  const { logo, name } = props || {};
  return <div className="team_name_logo" style={{ width: '150px !important' }}>
    <img src={logo || '/logos/logo.png'} alt="" />
    <span className="ml-2">{name}</span>
  </div>
}

const LandingPage = () => {
  const history = useHistory();
  const [current, setCurrent] = React.useState("home");
  const [isAuth, setIsAuth] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [matches, setMatches] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [season, setSeason] = useState(null);
  const [teamsInfo, setteamsInfo] = useState(null);
  const [activeKey, setactiveKey] = useState(1);
  const [leagueId, setleagueId] = useState(null);
  const [leagueName, setleagueName] = useState(null);
  const [isMobile, setisMobile] = useState(null);
  const [isDesktop, setisDesktop] = useState(null);
  const [currentTier, setcurrentTier] = useState(null);
  const [currentSeason, setcurrentSeason] = useState(null);
  const [currentPage, setcurrentPage] = useState("standings");
  const [homepageBanner, sethomepageBanner] = useState(null);
  const [openLeagues, setopenLeagues] = useState(false);
  const [openSeasons, setopenSeasons] = useState(false);
  const [sLoading, setsLoading] = useState(false);
  const [standings, setStandings] = useState({
    tiers: [],
    seasons: [],
  });
  const [leagues, setLeagues] = useState(store.get("leagues") || []);
  const [coedlLeagues, setCoedLeagues] = useState(
    store.get("coed-leagues") || []
  );
  const [tableHeight] = useState(window.innerHeight - 300);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions?.width > '480') {
      setisDesktop(true)
      setisMobile(false)
    }
    else if (windowDimensions?.width < '480') {
      setisMobile(true)
      setisDesktop(false)
    }
  }, [windowDimensions]);

  const getLeagues = (type) => {
    if (type === "Regular") {
      return leagues.map((ee) => ({
        label: ee.name,
        key: ee.name.toLowerCase().replace(" ", "-"),
      }));
    } else {
      return coedlLeagues.map((ee) => ({
        label: ee.name,
        key: `${ee.name.toLowerCase().replace(" ", "-")}-coed`,
      }));
    }
  };

  const DesktopNavBarItems = [
    {
      label: "Home",
      key: "home",
    },
    {
      label: "Game Schedule",
      key: "game-schedules",
    },
    {
      label: "Leagues",
      key: "league",
      children: [...getLeagues("Regular")],
    },
    {
      label: "U10/U15 GAME",
      key: "game",
      value:
        "https://www.amilia.com/store/en/tcyba/shop/programs/85296?subCategoryIds=4278015",
    },
    {
      label: "CO-ED",
      key: "coed",
      children: [...getLeagues("CO-ED")],
    },
    {
      label: "Rules & Regulations",
      key: "rules-and-regulations",
    },
    {
      label: "FAQ",
      key: "support",
    },
  ];

  const MobileNavBarItems = [
    {
      label: "Home",
      key: "home",
    },
    {
      label: "Game Schedule",
      key: "game-schedules",
    },
    {
      label: "Leagues",
      key: "league",
    },
    {
      label: "U10/U15 GAME",
      key: "game",
      value:
        "https://www.amilia.com/store/en/tcyba/shop/programs/85296?subCategoryIds=4278015",
    },
    {
      label: "CO-ED",
      key: "coed",
      children: [...getLeagues("CO-ED")],
    },
    {
      label: "Rules & Regulations",
      key: "rules-and-regulations",
    },
    {
      label: "FAQ",
      key: "support",
    },
  ];

  let NavbarColumns = DesktopNavBarItems;
  if (isMobile) {
    NavbarColumns = MobileNavBarItems;
  }
  if (isDesktop) {
    NavbarColumns = DesktopNavBarItems;
  }

  const [visible, setVisible] = useState(false);

  const { config } = useSelector(
    (state) => ({
      config: state.config,
      leftSidebar: state.leftSidebar,
    }),
    shallowEqual
  );
  const { name } = { ...config };

  const redirectUrl = (item) => {
    const url = item.value;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const standingsDesktopColumns = [
    {
      title: "Rank",
      key: "rank",
      width: "40px",
      dataIndex: "rank",
      render: (text, record, k) => k + 1,
    },
    {
      title: "Team",
      width: "100px",
      render: (record, text) => <div style={{ textAlign: "center" }}>
        <div className="">
          <TeamsLogo name={record?.name} logo={record?.logo} />
        </div>
      </div>,
    },
    // { title: "Team", key: "name", width: "180px", dataIndex: "name" },
    {
      title: "Wins",
      key: "win",
      width: "40px",
      dataIndex: "win",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Losses",
      key: "loss",
      width: "40px",
      dataIndex: "loss",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    { title: "PCT", key: "pct", width: "40px", dataIndex: "pct" },
    // { title: "STRK", key: "streak", dataIndex: "streak" },
    { title: "PF", key: "pf", width: "35px", dataIndex: "pf" },
    { title: "PA", key: "pa", width: "35px", dataIndex: "pa" },
    { title: "Diff", key: "diff", width: "50px", dataIndex: "diff" },
  ];

  const standingsMobileColumns = [
    {
      title: "Rank",
      key: "rank",
      width: "50px",
      dataIndex: "rank",
      render: (text, record, k) => k + 1,
    },
    {
      title: "Team",
      width: "200px",
      render: (record, text) => <div style={{ textAlign: "center" }}>
        <div className="">
          <TeamsLogo name={record?.name} logo={record?.logo} />
        </div>
      </div>,
    },
    // { title: "Team", key: "name", width: "180px", dataIndex: "name" },
    {
      title: "W",
      key: "win",
      width: "50px",
      dataIndex: "win",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "L",
      key: "loss",
      width: "50px",
      dataIndex: "loss",
      render: (text) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    { title: "PCT", key: "pct", width: "40px", dataIndex: "pct" },
    // { title: "STRK", key: "streak", dataIndex: "streak" },
    { title: "PF", key: "pf", width: "35px", dataIndex: "pf" },
    { title: "PA", key: "pa", width: "35px", dataIndex: "pa" },
    { title: "Diff", key: "diff", width: "50px", dataIndex: "diff" },
  ];

  const onClick = (e) => {
    console.log(e);
    if (e.key.indexOf("no-leagues found") >= 0) {
      return;
    } else if (e.key === "home") {
      history.push("/");
    } else if (e.key === "game") {
      history.push("/");
    } else if (e.key.indexOf("-league") >= 0) {
      history.push("tcbl-leagues?league=" + e.key);
    } else {
      history.push(e.key);
    }
    setCurrent(e.key);
  };

  const logout = async () => {
    localStorage.clear();
    setIsAuth(false);
    await apiClient.delete(ApiConfig.auth.logout);
  };

  const onClickMenuItem = (item) => {
    console.log("item", item);
  };

  const Standings = ({ teams = [] }) => {
    return (
      <>
        {isDesktop && (
          <>
            {/* <div className="st-title">Standings</div> */}
            <Table
              pagination={false}
              dataSource={teams.map((e) => ({ ...e, ...e?.standings }))}
              columns={standingsDesktopColumns}
            />
          </>
        )}
        {isMobile && (
          <>
            <Table
              pagination={false}
              dataSource={teams.map((e) => ({ ...e, ...e?.standings }))}
              columns={standingsMobileColumns}
              scroll={{ y: tableHeight }}
            />
          </>
        )}
      </>
    );
  };

  const loadSchedules = async () => {
    const { data } = await apiClient.get("/settings/get-schedules");
    setSchedules([...data]);
  };

  const loadSeasons = async (season) => {
    try {
      setsLoading(true);
      const leagueNm = window.location.href
        .split("?league=")[1]
        ?.split("-")
        ?.join(" ");
      let url = "/settings/get-seasons?league=" + (leagueNm || null);
      if (season) {
        url += "&season=" + season;
      }
      const { data } = await apiClient.get(url);
      setsLoading(false);
      setSeasons([...data.seasons]);
      setSeason(season || data.seasons[0]?.value);
      setcurrentSeason(data?.data[0]?._id);
      setcurrentTier(data?.data[0]?.tiers[0]?.tier)
      setStandings({
        seasons: data.data,
      });
      if (data?.data[0]?.tiers?.filter((ee) => ee.tier)?.length > 0) {
        const tier = data?.data[0]?.tiers[0];
        loadMatches(tier?.tier, season || data.seasons[0]?.value);
      } else {
        loadMatches(null, season || data.seasons[0]?.value);
      }
    } catch (error) {
      setsLoading(false);
    }
  };

  const loadLeagues = async () => {
    const { data } = await apiClient.get("/settings/get-leagues?type=Regular");
    store.set("leagues", data);
    if (data.length === 0) {
      setLeagues([{ name: "No leagues found" }]);
    } else {
      setLeagues([...data]);
    }
  };

  const loadCoedLeagues = async () => {
    const { data } = await apiClient.get("/settings/get-leagues?type=CO-ED");
    store.set("coed-leagues", data);
    if (data.length === 0) {
      setCoedLeagues([{ name: "No leagues found" }]);
    } else {
      setCoedLeagues([...data]);
    }
  };

  const loadBanners = async () => {
    const response = await apiClient.get("/settings/get-banner");
    sethomepageBanner(response?.data?.bannerList);
  };

  const loadTeamsInfo = async () => {
    const response = await apiClient.get("/settings/get-teams-logo");
    setteamsInfo(response?.data?.teamsInfo);
  };

  const onChangeSeason = (e) => {
    setcurrentSeason(e);
    setSeason(e);
    loadSeasons(e);
  };

  const onChanageTab = async (e) => {
    setcurrentTier(e);
    loadMatches(e, season);
  };

  const onChanageDesktopTab = async (e) => {
    setactiveKey(e);
    loadMatches(currentTier, season);
  };

  const loadMatches = async (tier, seasonId) => {
    const leagueNm = window.location.href
      .split("?league=")[1]
      ?.split("-")
      ?.join(" ");
    if (tier === null) {
      const params = `season=${seasonId}&&league=${leagueNm}&tier_type=Regular`;
      const { data } = await apiClient.get(
        `/settings/get-matches-mobile-without-tier?${params}`
      );
      setMatches([...data]);
    } else {
      const params = `season=${seasonId}&tier=${tier}&league=${leagueNm}&tier_type=Regular`;
      const { data } = await apiClient.get(
        `/settings/get-matches-mobile-with-tier?${params}`
      );
      setMatches([...data]);
    }
  };

  useEffect(() => {
    const leagueNm = window.location.href.split("?league=")[1];
    const getLeaguesInfo = getLeagues("Regular");
    const responseData = getLeaguesInfo?.filter((x) => x.key === leagueNm)[0]
      ?.label;
    const responseData2 = leagues?.filter((a) => a.name === responseData)[0]
      ?._id;
    setleagueId(responseData2);
    setIsAuth(!!localStorage.getItem("user.accessToken"));
    if (window.location.pathname === "/league") {
      history.push("tcbl-leagues?league=sunday-league");
    } else if (window.location.pathname === "/") {
      setCurrent("home");
    } else if (window.location.pathname === "/game") {
      setCurrent("home");
    } else {
      setCurrent(window.location.pathname.replace("/", ""));
    }
    loadSchedules();
    loadLeagues();
    loadCoedLeagues();
    loadBanners();
    loadTeamsInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.location.search.indexOf("league=") >= 0) {
      loadSeasons();
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const oncloseDrawer = (values) => {
    setVisible(false);
    if (values) {
      onClickPushUrl({ key: values });
    } else {
      //window.open("/", "_self")
    }
  };

  const onClickPushUrl = (e) => {
    window.open(e.key, "_self");
  };

  const onCallButtonClick = (values) => {
    if (values === "leagues") {
      showLeaguesModal();
      setcurrentPage(values);
    } else if (values === "seasons") {
      showSeasonsModal();
      setcurrentPage(values);
    } else {
      setcurrentPage(values);
    }
  };

  const onChange = (key) => {
    console.log("key", key);
  };


  const uniqueTags = [];
  // eslint-disable-next-line array-callback-return
  matches.map((img) => {
    if (uniqueTags.indexOf(img.matchDate) === -1) {
      uniqueTags.push(img.matchDate);
    }
  });

  const showLeaguesModal = () => {
    setopenLeagues(true);
  };

  const showSeasonsModal = () => {
    setopenSeasons(true);
  };

  const handleOk = async (e) => {
    const getLeaguesInfo = getLeagues("Regular");
    const res = getLeaguesInfo?.filter((x) => x.label === leagueName)[0].key;
    if (res === "monday-league") {
      history.push("tcbl-leagues?league=monday-league");
    } else if (res === "tuesday-league") {
      history.push("tcbl-leagues?league=tuesday-league");
    } else if (res === "wednesday-league") {
      history.push("tcbl-leagues?league=wednesday-league");
    } else if (res === "thursday-league") {
      history.push("tcbl-leagues?league=thursday-league");
    } else if (res === "friday-league") {
      history.push("tcbl-leagues?league=friday-league");
    } else if (res === "saturday-league") {
      history.push("tcbl-leagues?league=saturday-league");
    } else if (res === "sunday-league") {
      history.push("tcbl-leagues?league=sunday-league");
    }

    try {
      setsLoading(true);
      let url = "/settings/get-seasons?league=" + (res || null);
      if (season) {
        url += "&season=" + season;
      }
      const { data } = await apiClient.get(url);
      setsLoading(false);
      setSeasons([...data.seasons]);
      setSeason(season || data.seasons[0]?.value);

      setStandings({
        seasons: data.data,
      });
      if (data?.data[0]?.tiers?.filter((ee) => ee.tier)?.length > 0) {
        const tier = data?.data[0]?.tiers[0];
        loadMatches(tier?.tier, season || data.seasons[0]?.value);
      } else {
        loadMatches(null, season || data.seasons[0]?.value);
      }
      setcurrentPage("standings");
      setopenLeagues(false);
    } catch (error) {
      setsLoading(false);
    }
  };

  const handleCancel = (e) => {
    setcurrentPage("standings");
    setopenLeagues(false);
  };

  const onchangeLeague = (values) => {
    const info = leagues?.filter((x) => x._id === values)[0];
    setleagueId(info?._id);
    setleagueName(info?.name);
  };

  const handleSeasonsCancel = (e) => {
    setcurrentPage("standings");
    setopenSeasons(false);
  };

  const onchangeSeasonInfo = (values) => {
    setcurrentSeason(values);
  };

  const handleSeasonsOk = async (e) => {
    try {
      setsLoading(true);
      const leagueNm = window.location.href
        .split("?league=")[1]
        ?.split("-")
        ?.join(" ");
      let url = "/settings/get-seasons?league=" + (leagueNm || null);
      if (currentSeason) {
        url += "&season=" + currentSeason;
      }
      const { data } = await apiClient.get(url);
      setsLoading(false);
      setSeasons([...data.seasons]);
      setSeason(currentSeason || data.seasons[0]?.value);
      setStandings({
        seasons: data.data,
      });
      if (data?.data[0]?.tiers?.filter((ee) => ee.tier)?.length > 0) {
        const tier = data?.data[0]?.tiers[0];
        setcurrentTier(data?.data[0]?.tiers[0]?.tier)
        loadMatches(tier?.tier, currentSeason || data.seasons[0]?.value);
      } else {
        loadMatches(null, currentSeason || data.seasons[0]?.value);
      }
      setcurrentPage("standings");
      setopenLeagues(false);
    } catch (error) {
      setsLoading(false);
    }
  };

  const tierValueRef = standings?.seasons[0]?.tiers[0]?.tier


  return (
    <Empty>
      <div className="landing-page" onClick={() => oncloseDrawer()}>
        <section className="top-sec">
          {isMobile && (
            <>
              <div className="top-bar show-mobile">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisible(true);
                  }}
                  className="mx-4 m-menu-btn mp-7"
                >
                  <FiMenu size={35} />
                </button>
                <Link
                  to="/"
                  className="flex flex-row items-center justify-start space-x-2"
                >
                  <img
                    style={{ width: "60px" }}
                    src="/logos/logo.png"
                    alt="logo"
                  />
                </Link>
                <div onClick={(e) => e.stopPropagation()}>
                  <Drawer
                    closeIcon={<FiMenu size={25} />}
                    maskClosable={false}
                    onClose={() => {
                      oncloseDrawer();
                    }}
                    visible={visible}
                    placement={"left"}
                    style={{ width: visible ? "280px" : "0px" }}
                    title={
                      <>
                        <Link
                          to="/"
                          className="flex flex-row items-center justify-start space-x-2"
                        >
                          <img
                            style={{ width: "42px" }}
                            src="/logos/logo.png"
                            alt="logo"
                          />
                          <span>{name}</span>
                        </Link>
                      </>
                    }
                  >
                    <LeftSideBarWebsite
                      itemList={NavbarColumns}
                      closeDrawer={oncloseDrawer}
                    />
                  </Drawer>
                </div>

                <div className="top-nav">
                  <div className="t-act">
                    {!isAuth && (
                      <>
                        <Button type="ghost">
                          <Link className="link" to="/register">
                            Register
                          </Link>
                        </Button>
                        <Button className="ml-3" type="primary">
                          <Link className="link" to="/login">
                            Signin
                          </Link>
                        </Button>
                      </>
                    )}
                    {isAuth && (
                      <>
                        <Button onClick={logout} type="ghost">
                          <Link className="link" to="/">
                            Logout
                          </Link>
                        </Button>
                        <Button className="ml-3" type="primary">
                          <Link className="link" to="/dashboard">
                            Go to Account
                          </Link>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {isDesktop && (
            <>
              <div className="top-bar hidden-mobile">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.replace("/");
                    setCurrent("home");
                  }}
                  className="logo"
                >
                  <img alt="logo" src="/logos/logo.png" />
                </div>
                <div className="top-nav">
                  <div className="t-act">
                    {!isAuth && (
                      <>
                        <Button type="ghost">
                          <Link className="link" to="/register">
                            Register
                          </Link>
                        </Button>
                        <Button className="ml-3" type="primary">
                          <Link className="link" to="/login">
                            Signin
                          </Link>
                        </Button>
                      </>
                    )}
                    {isAuth && (
                      <>
                        <Button onClick={logout} type="ghost">
                          <Link className="link" to="/">
                            Logout
                          </Link>
                        </Button>
                        <Button className="ml-3" type="primary">
                          <Link className="link" to="/dashboard">
                            Go to Account
                          </Link>
                        </Button>
                      </>
                    )}
                  </div>
                  <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                  >
                    {
                      // eslint-disable-next-line
                      NavbarColumns.map((e) => {
                        if (e.key === "game") {
                          return (
                            <Menu.Item
                              onClick={() => {
                                history.replace("/");
                                redirectUrl(e);
                              }}
                              key={e.key}
                            >
                              {e.label}
                            </Menu.Item>
                          );
                        }
                        if (e?.children?.length === 0 || !e?.children) {
                          return (
                            <Menu.Item
                              onClick={() => onClickMenuItem(e)}
                              key={e.key}
                            >
                              {e.label}
                            </Menu.Item>
                          );
                        } else if (e?.children?.length > 0) {
                          return (
                            <Menu.SubMenu key={e.key} title={e.label}>
                              {
                                // eslint-disable-next-line
                                e.children.map((cc) => {
                                  if (
                                    cc?.children?.length === 0 ||
                                    !cc?.children
                                  ) {
                                    return (
                                      <Menu.Item
                                        onClick={() => onClickMenuItem(cc)}
                                        key={cc.key}
                                      >
                                        {cc.label}
                                      </Menu.Item>
                                    );
                                  } else if (cc?.children?.length > 0) {
                                    return (
                                      <Menu.SubMenu
                                        key={cc.key}
                                        title={cc.label}
                                      >
                                        {cc.children.map((dd) => (
                                          <Menu.Item
                                            onClick={() => onClickMenuItem(dd)}
                                            key={dd.key}
                                          >
                                            {dd.label}
                                          </Menu.Item>
                                        ))}
                                      </Menu.SubMenu>
                                    );
                                  }
                                })
                              }
                            </Menu.SubMenu>
                          );
                        }
                      })
                    }
                  </Menu>
                </div>
              </div>
            </>
          )}

          {current === "home" && (
            <Carousel autoplay>
              {homepageBanner
                ?.filter((x) => x.type === "Banner")
                ?.map((item, i) => (
                  <>
                    <div
                      className={`caurousel-item${current === "home" ? "" : " c-ht"
                        }`}
                    >
                      <img src={item?.banner_image1} alt="" />
                      <div className="tcbl-text">
                        <div className="tcbl-content">
                          <div className="t-c">
                            <span>{item?.banner_heading1}</span>
                          </div>
                          <div className="b-c">{item?.banner_sub_heading1}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </Carousel>
          )}
        </section>

        {current === "home" && (
          <section className="homepage">
            {homepageBanner
              ?.filter((x) => x.type === "Landing_Page")
              ?.map((item, i) => (
                <>
                  <div className="sec-block">
                    <div className="sec-title">{item?.banner_heading1}</div>
                    <div className="sec-content">
                      <div className="news-block">
                        <div className="image-block">
                          <img src={item?.banner_image1} alt="news" />
                        </div>
                        <div className="news-text">
                          {item?.banner_sub_heading1}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </section>
        )}

        {current.indexOf("-league") >= 0 && (
          <>
            {isMobile && (
              <>
                <section className="without-carousel leagues-page">
                  <div className="sec-block">
                    {sLoading && <PageLoader />}
                    {!sLoading && (
                      <>
                        {/* {currentPage !== 'playoffs' && ( */}
                        <>
                          <div className="breadcrumb-style">
                            <Breadcrumb
                              separator="|"
                              style={{ margin: "16px 0" }}
                            >
                              <Breadcrumb.Item>
                                {window.location.href
                                  .split("?league=")[1]
                                  ?.indexOf("coed") >= 0
                                  ? `${window.location.href
                                    .split("?league=")[1]
                                    ?.split("-")
                                    ?.join(" ")
                                    .replace("coed", "")}(COED)`
                                  : window.location.href
                                    .split("?league=")[1]
                                    ?.split("-")
                                    ?.join(" ")}
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>
                                {
                                  seasons?.filter(
                                    (x) => x.value === currentSeason
                                  )[0]?.label
                                }
                              </Breadcrumb.Item>
                            </Breadcrumb>
                          </div>
                        </>
                        {/* // )} */}
                        {currentPage === "standings" && (
                          <>
                            {standings.seasons.map((se) => (
                              <>
                                <div className="sec-content">
                                  {se?.tiers?.filter((ee) => ee.tier).length >
                                    0 && (
                                      <>
                                        <Tabs
                                          onChange={(e) => {
                                            console.log("hi");
                                            onChanageTab(e);
                                          }}
                                          activeKey={currentTier}
                                          centered="true"
                                        >
                                          {se?.tiers
                                            ?.filter((ee) => ee.tier)
                                            .map((ti, ke) => (
                                              <Tabs.TabPane
                                                className="tabpanestyle"
                                                key={ti.tier}
                                                tab={`${ti.tier === "CO-ED"
                                                  ? ti.tier
                                                  : `Tier ${ti.tier}`
                                                  }`}
                                              >
                                                <Standings
                                                  teams={ti?.registered_teams}
                                                />
                                              </Tabs.TabPane>
                                            ))}
                                        </Tabs>
                                      </>
                                    )}
                                  {se?.tiers?.filter((ee) => !ee.tier).length >
                                    0 && (
                                      <Standings
                                        className=""
                                        teams={se?.tiers[0]?.registered_teams}
                                      />
                                    )}
                                </div>
                              </>
                            ))}
                            {standings.seasons.length === 0 && (
                              <div className="coming_soon">COMING SOON...</div>
                            )}
                          </>
                        )}
                        {currentPage === "scores" && (
                          <>
                            {standings?.seasons.map((se) => (
                              <>
                                <div className="sec-content">
                                  {tierValueRef !== null && (
                                    <>
                                      <Tabs
                                        onChange={(e) => {
                                          onChanageTab(e);
                                        }}
                                        activeKey={currentTier}
                                      >
                                        {se?.tiers
                                          ?.filter((ee) => ee.tier)
                                          .map((ti, ke) => (
                                            <Tabs.TabPane
                                              className="tabpanestyle"
                                              key={ti.tier}
                                              tab={`${ti.tier === "CO-ED"
                                                ? ti.tier
                                                : `Tier ${ti.tier}`
                                                }`}
                                            >
                                              <div
                                                className="matcheslist"
                                                style={{
                                                  overflow: "scroll",
                                                  height: tableHeight,
                                                }}
                                              >
                                                <Collapse
                                                  accordion
                                                  onChange={(e) => onChange(e)}
                                                >
                                                  {uniqueTags?.map((match, key) => (
                                                    <>
                                                      <Panel
                                                        header={getDateFormat(
                                                          match
                                                        )}
                                                        key={key}
                                                      >
                                                        <>
                                                          {matches
                                                            ?.filter(
                                                              (x) =>
                                                                x?.matchDate ===
                                                                match
                                                            )
                                                            ?.map(
                                                              (items, index) => (
                                                                <>
                                                                  {items?.status ===
                                                                    "Completed" && (
                                                                      <>
                                                                        <FinalPlay
                                                                          itemsApi={
                                                                            items
                                                                          }
                                                                          teamsApi={
                                                                            teamsInfo
                                                                          }
                                                                        />
                                                                      </>
                                                                    )}
                                                                  {items?.status ===
                                                                    "Scheduled" && (
                                                                      <>
                                                                        <ScheduledPlay
                                                                          itemsApi={
                                                                            items
                                                                          }
                                                                          teamsApi={
                                                                            teamsInfo
                                                                          }
                                                                        />
                                                                      </>
                                                                    )}
                                                                  {items?.status ===
                                                                    "Started" &&
                                                                    items?.is_halftime_taken ===
                                                                    true && (
                                                                      <>
                                                                        <StartedHalfTime
                                                                          itemsApi={
                                                                            items
                                                                          }
                                                                          teamsApi={
                                                                            teamsInfo
                                                                          }
                                                                        />
                                                                      </>
                                                                    )}

                                                                  {items?.status ===
                                                                    "Assigned" && (
                                                                      <>
                                                                        <AssignedPlay
                                                                          itemsApi={
                                                                            items
                                                                          }
                                                                          teamsApi={
                                                                            teamsInfo
                                                                          }
                                                                        />
                                                                      </>
                                                                    )}
                                                                </>
                                                              )
                                                            )}
                                                        </>
                                                      </Panel>
                                                    </>
                                                  ))}
                                                </Collapse>
                                              </div>
                                            </Tabs.TabPane>
                                          ))}
                                      </Tabs>
                                    </>
                                  )}

                                  {tierValueRef === null && (
                                    <>
                                      <div
                                        className="matcheslist"
                                        style={{
                                          overflow: "scroll",
                                          height: tableHeight,
                                        }}
                                      >
                                        <Collapse
                                          accordion
                                          onChange={(e) => onChange(e)}
                                        >
                                          {uniqueTags?.map((match, key) => (
                                            <>
                                              <Panel
                                                header={getDateFormat(
                                                  match
                                                )}
                                                key={key}
                                              >
                                                <>
                                                  {matches
                                                    ?.filter(
                                                      (x) =>
                                                        x?.matchDate ===
                                                        match
                                                    )
                                                    ?.map(
                                                      (items, index) => (
                                                        <>
                                                          {items?.status ===
                                                            "Completed" && (
                                                              <>
                                                                <FinalPlay
                                                                  itemsApi={
                                                                    items
                                                                  }
                                                                  teamsApi={
                                                                    teamsInfo
                                                                  }
                                                                />
                                                              </>
                                                            )}
                                                          {items?.status ===
                                                            "Scheduled" && (
                                                              <>
                                                                <ScheduledPlay
                                                                  itemsApi={
                                                                    items
                                                                  }
                                                                  teamsApi={
                                                                    teamsInfo
                                                                  }
                                                                />
                                                              </>
                                                            )}
                                                          {items?.status ===
                                                            "Started" &&
                                                            items?.is_halftime_taken ===
                                                            true && (
                                                              <>
                                                                <StartedHalfTime
                                                                  itemsApi={
                                                                    items
                                                                  }
                                                                  teamsApi={
                                                                    teamsInfo
                                                                  }
                                                                />
                                                              </>
                                                            )}

                                                          {items?.status ===
                                                            "Assigned" && (
                                                              <>
                                                                <AssignedPlay
                                                                  itemsApi={
                                                                    items
                                                                  }
                                                                  teamsApi={
                                                                    teamsInfo
                                                                  }
                                                                />
                                                              </>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                </>
                                              </Panel>
                                            </>
                                          ))}
                                        </Collapse>
                                      </div>
                                    </>
                                  )}

                                </div>
                              </>
                            ))}
                          </>
                        )}
                        {currentPage === "leagues" && (
                          <>
                            a
                            <Modal
                              title="Leagues"
                              centered={true}
                              visible={openLeagues}
                              onOk={handleOk}
                              onCancel={handleCancel}
                            >
                              <Select
                                className="form-control"
                                defaultValue={leagueId}
                                placeholder="Select a leagues"
                                optionFilterProp="children"
                                onChange={(e) => onchangeLeague(e)}
                                style={{ width: "100%" }}
                              >
                                {leagues?.map((mval, index) => (
                                  <Option key={mval?._id} value={mval?._id}>
                                    {mval?.name}
                                  </Option>
                                ))}
                              </Select>
                            </Modal>
                          </>
                        )}
                        {currentPage === "seasons" && (
                          <>
                            a
                            <Modal
                              title="Seasons"
                              centered={true}
                              visible={openSeasons}
                              onOk={handleSeasonsOk}
                              onCancel={handleSeasonsCancel}
                            >
                              {!!seasons[0] ? <Select
                                className="form-control"
                                placeholder="Select a seasons"
                                optionFilterProp="children"
                                defaultValue={currentSeason}
                                onChange={(e) => onchangeSeasonInfo(e)}
                                style={{ width: "100%" }}
                              >
                                {seasons?.map((mval, index) => (
                                  <Option key={mval?.value} value={mval?.value}>
                                    {mval?.label}
                                  </Option>
                                ))}
                              </Select> : <div style={{ textAlign: "center" }}><Spin /></div>}
                            </Modal>
                          </>
                        )}
                        {currentPage === "playoffs" && (
                          <div className="coming_soon coming_soon_section">
                            COMING SOON...
                          </div>
                        )}
                        {standings?.seasons?.length === 0 && (
                          <div className="coming_soon coming_soon_section">
                            COMING SOON...
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </section>
                <MobileFooter
                  pageName={currentPage}
                  CallFooterFunc={onCallButtonClick}
                />
              </>
            )}
            {isDesktop && (
              <>
                <section className="without-carousel leagues-page">
                  <div className="sec-block">
                    <div className="sec-title">
                      <Tabs defaultActiveKey={activeKey} centered="true" onChange={(e) => {
                        onChanageDesktopTab(e);
                      }}>
                        <Tabs.TabPane key={1} tab="STANDINGS">
                          {sLoading && <PageLoader />}
                          {!sLoading && (
                            <>
                              {standings?.seasons?.map((se) => (
                                <>
                                  <div className="breadcrumb-style">
                                    <Breadcrumb
                                      separator="|"
                                      style={{ margin: "16px 0" }}
                                    >
                                      <Breadcrumb.Item>
                                        {window.location.href
                                          .split("?league=")[1]
                                          ?.indexOf("coed") >= 0
                                          ? `${window.location.href
                                            .split("?league=")[1]
                                            ?.split("-")
                                            ?.join(" ")
                                            .replace("coed", "")
                                            ?.toUpperCase()}(COED)`
                                          : window.location.href
                                            .split("?league=")[1]
                                            ?.split("-")
                                            ?.join(" ")
                                            ?.toUpperCase()}
                                      </Breadcrumb.Item>
                                      <Breadcrumb.Item>
                                        {seasons.length > 0 && (
                                          <div className="seas-name">
                                            <Select
                                              onChange={(e) => onChangeSeason(e)}
                                              className="role_filter"
                                              // defaultValue={seasons[0].value}
                                              defaultValue={currentSeason}
                                              options={seasons}
                                            />
                                          </div>
                                        )}
                                      </Breadcrumb.Item>
                                    </Breadcrumb>
                                  </div>

                                  <div className="sec-content">
                                    {se?.tiers?.filter((ee) => ee.tier)?.length >
                                      0 && (
                                        <Tabs
                                          onChange={(e) => onChanageTab(e)}
                                          activeKey={currentTier}
                                        >
                                          {se?.tiers
                                            ?.filter((ee) => ee.tier)
                                            .map((ti, ke) => (
                                              <Tabs.TabPane
                                                key={ti.tier}
                                                tab={`${ti.tier === "CO-ED"
                                                  ? ti.tier
                                                  : `Tier ${ti.tier}`
                                                  }`}
                                              >
                                                <Standings
                                                  teams={ti?.registered_teams}
                                                />
                                              </Tabs.TabPane>
                                            ))}
                                        </Tabs>
                                      )}
                                    {se?.tiers?.filter((ee) => !ee.tier).length >
                                      0 && (
                                        <Standings
                                          teams={se?.tiers[0]?.registered_teams}
                                        />
                                      )}
                                  </div>
                                </>
                              ))}
                              {standings.seasons.length === 0 && (
                                <div className="coming_soon">COMING SOON...</div>
                              )}
                            </>
                          )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key={2} tab="SCORES">
                          {/* <div className="coming_soon coming_soon_section">
                            COMING SOON...
                          </div> */}

                          {standings?.seasons.map((se) => (
                            <>
                              <div className="breadcrumb-style">
                                <Breadcrumb
                                  separator="|"
                                  style={{ margin: "16px 0" }}
                                >
                                  <Breadcrumb.Item>
                                    {window.location.href
                                      .split("?league=")[1]
                                      ?.indexOf("coed") >= 0
                                      ? `${window.location.href
                                        .split("?league=")[1]
                                        ?.split("-")
                                        ?.join(" ")
                                        .replace("coed", "")
                                        ?.toUpperCase()}(COED)`
                                      : window.location.href
                                        .split("?league=")[1]
                                        ?.split("-")
                                        ?.join(" ")
                                        ?.toUpperCase()}
                                  </Breadcrumb.Item>
                                  <Breadcrumb.Item>
                                    {seasons.length > 0 && (
                                      <div className="seas-name">
                                        <Select
                                          onChange={(e) => onChangeSeason(e)}
                                          className="role_filter"
                                          // defaultValue={seasons[0].value}
                                          defaultValue={currentSeason}
                                          options={seasons}
                                        />
                                      </div>
                                    )}
                                  </Breadcrumb.Item>
                                </Breadcrumb>
                              </div>
                              <div className="sec-content">
                                {tierValueRef !== null && (
                                  <>
                                    <Tabs
                                      onChange={(e) => {
                                        onChanageTab(e);
                                      }}
                                      activeKey={currentTier}
                                    >
                                      {se?.tiers
                                        ?.filter((ee) => ee.tier)
                                        .map((ti, ke) => (
                                          <Tabs.TabPane
                                            className="tabpanestyle"
                                            key={ti.tier}
                                            tab={`${ti.tier === "CO-ED"
                                              ? ti.tier
                                              : `Tier ${ti.tier}`
                                              }`}
                                          >
                                            <div className="container">
                                              <div className="row">
                                                <div className="col-lg-12">
                                                  {uniqueTags
                                                    ?.map((match, key) => (
                                                      <>
                                                        <br />
                                                        <span style={{ float: 'left', fontSize: '18px', fontWeight: '600' }}>
                                                          {getDateFormat(
                                                            match
                                                          )}
                                                        </span>
                                                        <br />
                                                        <br />
                                                        <div className="container">
                                                          <div className="row row-width-style">
                                                            {matches
                                                              ?.filter(
                                                                (x) =>
                                                                  x?.matchDate ===
                                                                  match
                                                              )
                                                              ?.map(
                                                                (items, index) => (
                                                                  <>
                                                                    <div className="col-lg-6 col-width-style">
                                                                      <DesktopView
                                                                        itemsApi={
                                                                          items
                                                                        }
                                                                        teamsApi={
                                                                          teamsInfo
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </>
                                                                ))}
                                                          </div>
                                                        </div>
                                                      </>
                                                    ))}


                                                </div>
                                              </div>
                                            </div>
                                          </Tabs.TabPane>
                                        ))}
                                    </Tabs>
                                  </>
                                )}
                                {tierValueRef === null && (
                                  <>

                                    <div className="container">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          {uniqueTags
                                            ?.map((match, key) => (
                                              <>
                                                <br />
                                                <span style={{ float: 'left', fontSize: '18px', fontWeight: '600' }}>
                                                  {getDateFormat(
                                                    match
                                                  )}
                                                </span>
                                                <br />
                                                <br />
                                                <div className="container">
                                                  <div className="row row-width-style">
                                                    {matches
                                                      ?.filter(
                                                        (x) =>
                                                          x?.matchDate ===
                                                          match
                                                      )
                                                      ?.map(
                                                        (items, index) => (
                                                          <>
                                                            <div className="col-lg-6 col-width-style">
                                                              <DesktopView
                                                                itemsApi={
                                                                  items
                                                                }
                                                                teamsApi={
                                                                  teamsInfo
                                                                }
                                                              />
                                                            </div>
                                                          </>
                                                        ))}
                                                  </div>
                                                </div>
                                              </>
                                            ))}


                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          ))}

                        </Tabs.TabPane>
                        <Tabs.TabPane key={3} tab="PLAYOFF">
                          <div className="coming_soon coming_soon_section">
                            COMING SOON...
                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </section>
              </>
            )}
          </>
        )}
        {current === "rules-and-regulations" && (
          <section className="without-carousel rules-page">
            <div className="sec-block">
              <div className="sec-title">League Rules & Regulations</div>
              <div className="sec-subtitle">FIBA Modification</div>
              <div className="sec-block-1">
                <SectionTitle subtitle="League Rules" />
                <Tabs className="rules-tab">
                  <Tabs.TabPane key="1" tab="Time-Out">
                    <p>Time can be called on the floor</p>
                    <p>2 Time-Outs each half</p>
                    <p>
                      1 Time-Out each team in overtime period, unused time-outs
                      may not be carried over into OT
                    </p>
                    <p>Can call time-out between free throws</p>
                    <p>
                      The last 2 mins of the game, if time out is called on the
                      court and live, inbound from the same spot
                    </p>
                    <p>
                      If the time-out is called at dead ball situation, advance
                      to the frontcourt.
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="2" tab="Penalty(Bonus)">
                    <p>Penalty at team fouls 8th each half. 2 free throws.</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="3" tab="Game Length">
                    <p>Two 20mins half</p>
                    <p>Last 20 seconds in 1st half STOP TIME.</p>
                    <p>
                      Last 2 mins in the second half STOP TIME. (within 10
                      points or 10 points)
                    </p>
                    <p>
                      Last 1 min in the second half STOP TIME on made basket!!
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="4" tab="Shot Clock">
                    <p>24 seconds for all tier 1</p>
                    <p>30 seconds for all other tiers</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="5" tab="Substitution">
                    <p>
                      Before 1st free throw or after the last free throw made.
                      (as per FIBA rules)
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="6" tab="Overtime">
                    <p>
                      2 minutes overtime. (Start the overtime with possession
                      arrow.)
                    </p>
                    <p>
                      The last 20 seconds STOP TIME on the dead ball and made
                      basket.
                    </p>
                    <p>
                      If the score still ties after the overtime, we will do the
                      jump ball to start 2nd overtime.
                    </p>
                    <p>
                      {" "}
                      After 2nd overtime, 1st team scores win. (Only applied in
                      regular-season games)
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    key="7"
                    tab="Who will be allowed playing playoffs"
                  >
                    <p>
                      Play a minimum of 4 regular-season games to be eligible to
                      play playoffs.
                    </p>
                    <p> Has to be on the team roster.</p>
                    <p>
                      The final roster must be submitted before their 3rd week.
                      (email at tcblbasketball@gmail.com ) for those players who
                      been submitted late. especially for low tiers, league need
                      to review and run with all other teams captains from that
                      tier to decide if that player is allowed to be added. In
                      special case, we will need votes from the captains in that
                      tier to make decision. if this player is from high tier
                      such as tier 1-3. league or the captains have the right to
                      say no
                    </p>
                    <p> please do not wechat or text the roster. ONLY Email.</p>
                    <p>
                      game will be forfeited if team using non registered
                      players. no exception.
                    </p>
                    <p>
                      after deadline, in special case, such as injury, if team
                      need to add new player due to injury , team can submit the
                      request , league will have to run the votes with all other
                      captains in that tier to make decision
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="8" tab="Bench rules">
                    <p>
                      {" "}
                      Only registered players and designated coaches may sit on
                      the bench. Non-registered players, non-registered coaches
                      (Teams must register their coach before their week2), and
                      fans are not permitted to sit on the bench.
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="9" tab="Tiebreakers for playoff purposes">
                    <p>Tie breakers will be based on point differential</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="10" tab="Late/Forfeits">
                    <p>
                      If a team fails to put 5 players on the court within 15
                      minutes of the start time that team will be forced to
                      forfeit the game:
                    </p>
                    <p>
                      {" "}
                      The game clock will start running as scheduled and 2
                      points per min will be given to the offending team to
                      start the game if the offending team is ready to play.
                      (applied to regular-season games and playoffs)
                    </p>
                    <p>
                      After 15 minutes have elapsed and the offending team is
                      unable to dress 5 players then the game will be forfeited.
                      If your team forfeits a match during the season, the
                      following rules apply:
                    </p>
                    <p>
                      Game recorded as loss, warning issued and the team is
                      issued a fine 150$ EMT to tcblbasketball@gmail.com before
                      your next game .
                    </p>
                    Note: If you know in advance that your team is going to
                    forfeit a game, please inform the league by email
                    tcblbasketball@gmail.com by Friday mid-night to avoid the
                    above penalty.
                  </Tabs.TabPane>
                  <Tabs.TabPane key="11" tab="Team Size">
                    <p>
                      Teams can have a maximum of 15 players on their roster.
                    </p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="12" tab="Payment">
                    <p>
                      Registration fees must be paid before game 1 (emt to
                      tcblbasketball@gmail.com) or cash at the game 1
                    </p>
                  </Tabs.TabPane>
                </Tabs>
              </div>
              <div className="sec-block-2">
                <SectionTitle subtitle="Zero Tolerance" />
                <Tabs className="rules-tab">
                  <Tabs.TabPane key="1" tab="7 teams Format">
                    <p>You play 6 season games</p>
                    <p>Top 4 teams make the playoffs</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="2" tab="8 teams Format">
                    <p>You play every team once (7 season games)</p>
                    <p>
                      Everyone makes the playoffs. (8th game will be your first
                      round of playoffs)
                    </p>
                    <p>Then Semi and Finals</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="3" tab="9 teams Format">
                    <p>You play every team once (8 season games)</p>
                    <p>The top 8 teams make the playoffs</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="4" tab="10 teams Format">
                    <p>You play 7 season games</p>
                    <p>The playoffs format:</p>
                    <p>
                      The bottom 4 teams play first to decide who is 7th and 8th
                    </p>
                    <p>Then 8 teams playoffs format</p>
                  </Tabs.TabPane>
                  <Tabs.TabPane key="5" tab="12 teams Format">
                    <p>You get to play 7 season games</p>
                    <p>The playoffs format:</p>
                    <p>
                      The bottom 8 teams play first to decide who is 5th 6th 7th
                      and 8th.
                    </p>
                    <p>Then 8 teams playoffs format</p>
                  </Tabs.TabPane>
                </Tabs>
              </div>
              <div className="sec-block-3">
                <SectionTitle subtitle="Zero Tolerance" />
                <p>
                  <b>Zero Tolerance for FIGHTING:</b> Any fighting, harassment,
                  racial slurs, and verbal abuse against players, staff,
                  referees, and spectators will not be tolerated. Officials have
                  the authority to issue technical/ flagrant fouls and eject
                  players, coaches, and fans from the game. Any ejected player
                  will be issued an automatic 1 (one) game suspension and each
                  case will be reviewed. TCBL reserves the right to ban repeat
                  offenders with no refund.
                </p>
                <p>
                  <b>Absolutely</b> no fighting is allowed in TCBL, if any
                  members are fighting, the involved players will be suspended
                  for 3 games without any refund. TCBL reserve the right to
                  review each case with on-site staff and referee to suspend
                  more games or remove the team/players out of the league with
                  no refund.
                </p>
                <p>
                  <b>
                    Officials have been instructed to eject a player who throws
                    a punch, whether or not it connected or an elbow which made
                    contact above shoulder level. If elbow contact is shoulder
                    level or below, it shall be left to the discretion of the
                    official as to whether the player is ejected. Even if a
                    punch or an elbow goes undetected by the officials during
                    the game, but is detected during a review of a videotape,
                    that player will be penalized.
                  </b>{" "}
                  (warning or 1 game suspend)
                </p>
              </div>
            </div>
          </section>
        )}
        {current === "support" && (
          <section className="without-carousel faq-page">
            <div className="sec-block">
              <div className="sec-title">Frequently asked questions</div>
              <Customization />
            </div>
          </section>
        )}
        {current === "game-schedules" && (
          <section className="without-carousel game-page">
            <div className="sec-block mt0">
              <div className="sec-title">Game Schedule</div>
              <div className="sec-subtitle">
                Game Schedule will be updated weekly
              </div>
            </div>
            {schedules.map((item, k) => (
              <div key={k.toString()} className="sec-block sec-block-game">
                <div className="sec-subtitle">{item.league_name} Games</div>
                <div className="sec-content">
                  <div className="news-block">
                    <div className="image-block">
                      <img src={item.image} alt="news" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
        )}
      </div>
    </Empty>
  );
};

export default LandingPage;
