import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { v4 as uuidV4 } from 'uuid'
import store from 'store'

import Logo from '../../components/login-2/logo'
import Footer from '../../components/login-2/footer'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import ConfirmationModal from '../../common/confirm-modal'

const VerifyEmail = () => {
  let history = useHistory()

  const FormElem = () => {
    const [loading, setLoading] = useState(false);
    const onSubmit = async (values) => {
      setLoading(true);
      const clientId = localStorage.getItem('device-id');
      const email = localStorage.getItem('entered-email');
      if (!clientId) {
        const uniqueId = uuidV4()
        localStorage.setItem('device-id', uniqueId)
      }
      values.uuid = localStorage.getItem('device-id');
      values.email = email;
      try {
        delete values.confirm_password;
        const response = await apiClient.post(ApiConfig.auth.verifyEmail, values);
        const { access_token: accessToken, user } = response.data;
        store.set(`user.data`, user);
        store.set(`user.accessToken`, accessToken);
        history.replace('/dashboard')
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    const resendCode = async () => {
      const ok = await ConfirmationModal("Do you want to resend the verification code?")
      if (ok) {
        const email = localStorage.getItem('entered-email');
        await apiClient.post(ApiConfig.auth.forgotPassword, { email, new_user: true });
      }
    }

    useEffect(() => {
      const token = store.get('user.accessToken');
      if (token) {
        console.log('token', token);
        history.replace('/dashboard')
      }
    }, [])

    return (
      <div className="flex flex-col">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
        >
          <Form.Item
            label={(
              <div className="code-label">
                <span>Verification Code</span>
                <Button onClick={() => resendCode()} type="link">Resend Code?</Button>
              </div>
            )}
            name="verification_code"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input maxLenth={6} placeholder="Enter the code" />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} htmlType="submit" type="primary">Verify & Register</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  return (
    <>
      <div className="w-full flex flex-row h-screen overflow-hidden auth-page-2">
        <div className="hidden lg:flex lg:flex-col w-3/4 text-white p-8 items-start justify-between relative bg-login-2">
          <Logo />
        </div>
        <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
          <p className="text-2xl font-bold text-blue-500 mb-4">
            Verify your email
          </p>
          <FormElem />
          <div className="w-full mt-5">
            <span>
              <Link className="link" to="/login">
                Back to Login?
              </Link>
            </span>
          </div>
          <div className='footer-elem'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyEmail
