import { Drawer, Table, Form, Input, Button, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import { FiEdit, FiPlus, FiTrash, FiRefreshCcw } from 'react-icons/fi';
import SectionTitle from '../../components/section-title'
import RemoveModal from '../../components/common/RemoveModal';
import apiClient from '../../services/axios';
import ApiConfig from '../../services/ApiConfig';
import { getDateFormat, getFullName } from '../../utils';

const Users = () => {
  const [form] = Form.useForm();
  const userMaping = {
    "1": "Admin",
    "2": "Manager",
    "3": "Player",
    "4": "Scorekeeper"
  }
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabId, setTabId] = useState("1");
  const [admins, setAdmins] = useState([]);
  const [players, setPlayers] = useState([]);
  const [scorers, setScorers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      name: "name",
      render: (text, record) => getFullName(record),
      sorter: (a, b) => getFullName(a).localeCompare(getFullName(b)),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      name: "demailay"
    },
    {
      title: "Status",
      dataIndex: "is_active",
      name: "is_active",
      onFilter: (value, record) => value === '1' ? record?.is_active : !record?.is_active,
      filters: [
        { text: 'Active', value: "1" },
        { text: 'Inactive', value: "2" },
      ],
      render: (text) => <Tag color={text ? '#4BB543' : '#e40'}>{text ? 'Active' : 'Inactive'}</Tag>
    },
    {
      title: "Verified",
      dataIndex: "is_verified",
      name: "is_verified",
      onFilter: (value, record) => value === '1' ? record?.is_verified : !record?.is_verified,
      filters: [
        { text: 'Verified', value: "1" },
        { text: 'Not Verified', value: "2" },
      ],
      render: (text) => <Tag color={text ? '#12344d' : '#e40'}>{text ? 'Yes' : 'No'}</Tag>
    },
    {
      title: "Joined At",
      dataIndex: "created_at",
      name: "created_at",
      render: (text) => getDateFormat(text)
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={record.is_active ? <FiTrash /> : <FiRefreshCcw />} />
            <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          </div>
        )
      }
    }
  ];

  const onSelect = (item) => {
    // item.start_date = getDateFormat(item.start_date, true);
    setSelectedItem({
      ...item
    });
    setVisible(true)
  }

  const onDelete = async () => {
    setDeletePopupVisibe(false);
    await apiClient.patch(`${ApiConfig.users}/${selectedItem._id}`);
    loadUsers(selectedItem.role)
  }

  const FormElements = ({ initialValues }) => {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
      console.log(values);
      setLoading(true);
      try {
        if (tabId === '1') {
          values.role = 'admin';
        } else if (tabId === '2') {
          values.role = 'manager';
        } else if (tabId === '3') {
          values.role = 'player';
        }
        else if (tabId === '4') {
          values.role = 'scorer';
        }
        const role = values.role;
        if (selectedItem?._id) {
          delete values.role;
          await apiClient.put(`${ApiConfig.users}/${selectedItem?._id}`, values);
        } else {
          await apiClient.post(ApiConfig.users, values);
        }
        loadUsers(role);
        setLoading(false);
        setVisible(false);
      } catch (error) {
        setLoading(false);
      }
    }

    return (
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email Address"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a email address" />
        </Form.Item>
        <div className="form-footer">
          <Button loading={loading} type="primary" htmlType="submit">{selectedItem?._id ? 'Update' : 'Add'} {userMaping[tabId]}</Button>
        </div>
      </Form>
    )
  }

  const onAdd = () => {
    setSelectedItem(null);
    form.setFieldsValue({
      first_name: '',
      last_name: '',
      email: ''
    })
    setVisible(true)
  }

  const TabTitle = ({ title, count }) => (
    <div className='tab-title-with-count'>{title} <Tag color="#12344d">{count}</Tag></div>
  )

  const loadUsers = async (role) => {
    setLoading(true);
    const { data } = await apiClient.get(`${ApiConfig.users}?role=${role}`)
    setLoading(false);
    if (role === 'admin') {
      setAdmins([...data]);
    } else if (role === 'manager') {
      setManagers([...data]);
    }
    else if (role === 'player') {
      setPlayers([...data]);
    } else if (role === 'scorer') {
      setScorers([...data]);
    }
  }

  const getUsers = (users = []) => {
    return users.filter(e => {
      e.full_name = getFullName(e);
      return e.full_name.toLowerCase().indexOf(search?.toLowerCase()) >= 0 || e.email.toLowerCase().indexOf(search?.toLowerCase()) >= 0
    })
  }

  useEffect(() => {
    loadUsers('admin');
    loadUsers('manager');
    loadUsers('player');
    loadUsers('scorer');    
  }, [])

  return (
    <>
      <RemoveModal description={selectedItem?.is_active ? 'Are you sure you want to mark this player inactive?' : 'Are you sure you want to active the player?'} visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
      <SectionTitle
        title="Users"
        subtitle="List of Users"
        right={<div className="ant-item"><Input value={search} onChange={e => setSearch(e.target.value)} style={{ minWidth: "300px" }} allowClear placeholder="Search team by name, email.." /></div>}
      />
      <Tabs
        onChange={(t) => setTabId(t)}
        tabBarExtraContent={
          <>
            <button onClick={onAdd} style={{ marginTop: '-5px' }} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add {userMaping[tabId]}
              </span>
            </button>
          </>
        }>
        <Tabs.TabPane tab={<TabTitle title="Admins" count={admins.filter(e => e.is_active).length} />} key="1">
          <Table
            loading={loading}
            pagination={false}
            dataSource={getUsers(admins)}
            columns={columns}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<TabTitle title="Managers" count={managers.filter(e => e.is_active).length} />} key="2">
          <Table
            loading={loading}
            pagination={false}
            dataSource={getUsers(managers)}
            columns={columns}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<TabTitle title="Players" count={players.filter(e => e.is_active).length} />} key="3">
          <Table
            loading={loading}
            pagination={{
              pageSize,
              onShowSizeChange: (c, size) => {
                setPageSize(size);
              }
            }}
            dataSource={getUsers(players)}
            columns={columns}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<TabTitle title="Scorekeepers" count={scorers.filter(e => e.is_active).length} />} key="4">
          <Table
            loading={loading}
            pagination={false}
            dataSource={getUsers(scorers)}
            columns={columns}
          />
        </Tabs.TabPane>
      </Tabs>
      <Drawer
        title={`Add ${userMaping[tabId]}`}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {visible && <FormElements initialValues={selectedItem} />}
      </Drawer>
    </>
  )
}
export default Users
