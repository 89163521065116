import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  //Checkbox,
  Drawer,
  List,
  AutoComplete,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Select,
  Table,
  Tabs,
  Tag,
  DatePicker,
  message,
  Tooltip,
} from "antd";
import { debounce } from "lodash";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
//import SectionTitle from '../../components/section-title';
import PageLoader from "../../components/page-loader";
import $ from "jquery";
import { FiX } from "react-icons/fi";
import { MdGroupAdd } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaExchangeAlt, FaCheck, FaTimes } from "react-icons/fa";
import { SketchPicker } from "react-color";
import {
  CheckOutlined,
  PlusOutlined,
  MinusOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  LoadingOutlined,
  UserAddOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
// import ConfirmationModal from '../../common/confirm-modal';
import { TeamNameWithLogo } from "../../components/teams";
import apiClient from "../../services/axios";
import ApiConfig from "../../services/ApiConfig";
import { getDateFormat, getFullName, getShortName } from "../../utils";
import ConfirmationModal from "../../common/confirm-modal";
import moment from "moment";

const ScoreKeeperPage = () => {
  const [loading, setLoading] = useState(false);
  const [teamSwap, setTeamSwap] = useState(false);
  const [scoreType, setScoreType] = useState(false);
  const [playerAddInfo, setPlayerAddInfo] = useState({ visible: false });
  const [pfModalInfo, setPfModalInfo] = useState({ visible: false });
  const [rebModalInfo, setRebModalInfo] = useState({ visible: false });
  const [toModalInfo, setToModalInfo] = useState({ visible: false });
  const [astModalInfo, setAstModalInfo] = useState({ visible: false });
  const [courtList, setCourtList] = useState([]);
  const [matches, setMatches] = useState([]);
  const [remainingPlayerInfo, setRemainingPlayerInfo] = useState({
    type: "",
    visible: false,
  });
  const [open, setOpen] = useState(false);
  const [openaway, setOpenaway] = useState(false);
  const [openawayplayergetin, setopenawayplayergetin] = useState(false);
  const [openhomeplayergetin, setopenhomeplayergetin] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [numberChange, setNumberChange] = useState(false);
  const [scoreboardDet, setScoreboard] = useState({
    court: null,
    date: getDateFormat(),
    slot: null,
    start: false,
  });
  const { config } = useSelector(
    (state) => ({
      config: state.config,
    }),
    shallowEqual
  );
  let { collapsed } = { ...config };

  const dispatch = useDispatch();

  const [adding, setAdding] = useState(false);
  const [searching, setSearching] = useState(false);
  const [players, setPlayers] = useState([]);
  const [form] = Form.useForm();

  const onClosePopup = (type) => {
    setSelectedMember(null);
    const idx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === selectedMember.jersey_no
    );
    if (idx >= 0) {
      currentMatch[type].players[idx].selected = false;
      setCurrentMatch({ ...currentMatch });
    }
  };

  const onSaveMatchScores = async (payload) => {
    await apiClient.put(
      ApiConfig.matches.updateMatchScore + `/${currentMatch._id}`,
      payload
    );
  };

  const setLastClickedAction = (ptsType) => {
    const idx = currentMatch[selectedMember.type].players.findIndex(
      (pl) => pl._id === selectedMember._id
    );
    if (idx >= 0) {
      currentMatch[selectedMember.type].players[idx].lastClicked = ptsType;
      selectedMember.lastClicked = ptsType;
      setSelectedMember({ ...selectedMember });
    }
  };

  const onPlusScore = async (points, ptsType = "") => {
    if (scoreType) {
      onMinusScore(points, ptsType);
      return;
    }
    setLastClickedAction(ptsType);
    if (currentMatch[currentMatch.selectedType]) {
      currentMatch[currentMatch.selectedType].score += points;
      const idx = currentMatch[selectedMember.type].players.findIndex(
        (pl) => pl._id === selectedMember._id
      );
      if (idx >= 0 && currentMatch[selectedMember.type].players[idx].stats) {
        if (ptsType === "ftm") {
          selectedMember.stats.fta += 1;
        }
        if (ptsType === "2ptm") {
          selectedMember.stats["2pta"] += 1;
        }
        if (ptsType === "3ptm") {
          selectedMember.stats["3pta"] += 1;
        }
        selectedMember.stats[ptsType] += 1;
      }
      setCurrentMatch({ ...currentMatch });
      setSelectedMember({ ...selectedMember });
      if (ptsType === "2ptm" || ptsType === "3ptm") {
        setAstModalInfo({
          visible: true,
          type: selectedMember.type,
        });
      }
      const payload = {
        type: ptsType,
        player: selectedMember?.player?._id,
        team: currentMatch[selectedMember.type]?.team?._id,
      };
      onSaveMatchScores(payload);
    }
  };

  const onMinusScore = async (points, ptsType = "") => {
    setLastClickedAction(ptsType);
    const idx = currentMatch[selectedMember.type].players.findIndex(
      (pl) => pl._id === selectedMember._id
    );
    if (idx >= 0) {
      if (
        ptsType === "fta" &&
        currentMatch[selectedMember.type].players[idx].stats["fta"] -
          currentMatch[selectedMember.type].players[idx].stats["ftm"] ===
          0
      )
        return;
      if (
        ptsType === "2pta" &&
        currentMatch[selectedMember.type].players[idx].stats["2pta"] -
          currentMatch[selectedMember.type].players[idx].stats["2ptm"] ===
          0
      )
        return;
      if (
        ptsType === "3pta" &&
        currentMatch[selectedMember.type].players[idx].stats["3pta"] -
          currentMatch[selectedMember.type].players[idx].stats["3ptm"] ===
          0
      )
        return;
    }
    const ok = await ConfirmationModal(
      "Are you sure you want to undo the last action?"
    );
    if (ok) {
      if (currentMatch[currentMatch.selectedType]) {
        currentMatch[currentMatch.selectedType].score -= points;
        if (currentMatch[currentMatch.selectedType].score < 0) {
          currentMatch[currentMatch.selectedType].score = 0;
        }
        if (idx >= 0 && currentMatch[selectedMember.type].players[idx].stats) {
          if (ptsType === "ftm") {
            selectedMember.stats.fta -= 1;
          }
          if (ptsType === "2ptm") {
            selectedMember.stats["2pta"] -= 1;
          }
          if (ptsType === "3ptm") {
            selectedMember.stats["3pta"] -= 1;
          }
          selectedMember.stats[ptsType] -= 1;
        }
        setCurrentMatch({ ...currentMatch });
        setSelectedMember({ ...selectedMember });
        const payload = {
          type: ptsType,
          player: selectedMember?.player?._id,
          team: currentMatch[selectedMember.type]?.team?._id,
          undo: true,
        };
        onSaveMatchScores(payload);
      }
    }
  };
  console.log(currentMatch?.home?.players, "currentMatch?.home?.players");
  const [teamhomefoul, setteamhomefoul] = useState(0);
  const [teamawayfoul, setteamawayfoul] = useState(0);
  console.log(teamawayfoul, teamhomefoul);
  useEffect(() => {
    var home = 0,
      away = 0;
    const v1 = currentMatch?.home?.players.forEach((item) => {
      home += item?.personal_fouls;
    });
    const v2 = currentMatch?.away?.players.forEach(
      (item) => (away += item?.personal_fouls)
    );
    console.log(v1, v2);
    setteamhomefoul(home);
    setteamawayfoul(away);
    setScoreboard({ ...scoreboardDet, match: null });
    // eslint-disable-next-line
  }, [currentMatch]);
  const onAddOtherDetails = async (type) => {
    // currentMatch[currentMatch.selectedType].score = currentMatch[currentMatch.selectedType].score;
    // setCurrentMatch({ ...currentMatch });
    setLastClickedAction(type);
    const payload = {
      type,
      player: selectedMember?.player?._id,
      team: currentMatch[selectedMember.type]?.team?._id,
    };
    const idx = currentMatch[selectedMember.type].players.findIndex(
      (pl) => pl._id === selectedMember._id
    );
    if (idx >= 0 && scoreType) {
      if (
        type === "fta" &&
        currentMatch[selectedMember.type].players[idx].stats["fta"] -
          currentMatch[selectedMember.type].players[idx].stats["ftm"] ===
          0
      )
        return;
      if (
        type === "2pta" &&
        currentMatch[selectedMember.type].players[idx].stats["2pta"] -
          currentMatch[selectedMember.type].players[idx].stats["2ptm"] ===
          0
      )
        return;
      if (
        type === "3pta" &&
        currentMatch[selectedMember.type].players[idx].stats["3pta"] -
          currentMatch[selectedMember.type].players[idx].stats["3ptm"] ===
          0
      )
        return;
    }
    if ((type === "2pta" || type === "3pta") && !scoreType) {
      setRebModalInfo({
        visible: true,
        type: selectedMember.type,
      });
    }
    if (type === "to" && !scoreType) {
      setToModalInfo({
        visible: true,
        type: selectedMember.type,
      });
    }
    if (scoreType) {
      if (
        idx >= 0 &&
        currentMatch[selectedMember.type].players[idx].stats[type] === 0
      )
        return;
      const ok = await ConfirmationModal(
        "Are you sure you want to undo the last action?"
      );
      if (ok) {
        payload.undo = true;
      } else {
        return;
      }
    }

    if (idx >= 0 && currentMatch[selectedMember.type].players[idx].stats) {
      // currentMatch[selectedMember.type].players[idx].stats[type] += 1
      selectedMember.stats[type] += payload.undo ? -1 : 1;
    }
    setSelectedMember({ ...selectedMember });
    onSaveMatchScores(payload);
  };

  const PopoverContent = ({ popVisible }) => {
    const [no, setNo] = useState(selectedMember?.jersey_no);
    console.log(
      currentMatch?.[selectedMember?.type]?.players,
      no,
      "currentMatch[selectedMember.type]?.players"
    );
    const onSubmitNum = async () => {
      const payload = {
        player: selectedMember?.player?._id,
        team: currentMatch[selectedMember.type]?.team?._id,
      };
      const jIdx = currentMatch[selectedMember.type]?.players?.findIndex(
        (pl) => pl.jersey_no === no
      );
      console.log(jIdx, "jIdx");
      if (jIdx >= 0) {
        return notification.error({
          message: "The player with this jersey_no is already exists",
        });
      }
      setSelectedMember({
        ...selectedMember,
        jersey_no: no,
      });
      const idx = currentMatch[selectedMember.type]?.players?.findIndex(
        (pl) => pl._id === selectedMember._id
      );
      if (idx >= 0) {
        currentMatch[selectedMember.type].players[idx].jersey_no = no;
        payload.jersey_no = no;
        try {
          await apiClient.put(
            ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
            payload
          );
          setNumberChange(false);
          setTimeout(() => {
            setCurrentMatch({
              ...currentMatch,
            });
          }, 10);
        } catch (error) {}
      }
    };

    useEffect(() => {
      if (popVisible) {
        $(".score-popup-content").fadeOut(0);
        $(".score-popup-content").fadeIn(200);
      }
    }, [popVisible]);

    if (popVisible) {
      return (
        <>
          <div className="score-popup-content">
            <div className="top-block">
              <Button
                onClick={() => setScoreType(!scoreType)}
                className={`plus-minus-btn${
                  !scoreType ? " plus-btn" : " minus-btn"
                }`}
                icon={!scoreType ? <PlusOutlined /> : <MinusOutlined />}
              />
              <div className="avatar-block">
                <Popover
                  title="Change Player Number"
                  overlayClassName="chng-pl-no"
                  visible={numberChange}
                  trigger="click"
                  onVisibleChange={(v) => setNumberChange(v)}
                  placement="left"
                  content={
                    <div className="change-player-no">
                      {
                        <Input
                          maxLength={3}
                          onChange={(e) => setNo(e.target.value)}
                          suffix={
                            <CheckOutlined onClick={() => onSubmitNum()} />
                          }
                          value={no}
                        />
                      }
                    </div>
                  }
                >
                  <div className="image-block">
                    {selectedMember?.player?.profile_pic && (
                      <img src={selectedMember?.player?.profile_pic} alt="" />
                    )}
                    {!selectedMember?.player?.profile_pic && (
                      <span>{getShortName(selectedMember?.player)}</span>
                    )}
                    <div className="jersey-no">{selectedMember?.jersey_no}</div>
                  </div>
                </Popover>
                <div
                  className="close_icon"
                  onClick={() => onClosePopup(selectedMember.type)}
                >
                  <FiX size={20} />
                </div>
              </div>
            </div>
            <div className="score-block">
              <div className="score-item">
                <div className="score-type">
                  <div className="score-h">2 PTS</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      onClick={(e) => onPlusScore(2, "2ptm", 1, e)}
                      className={`mr-1${
                        selectedMember?.lastClicked === "2ptm" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["2ptm"]
                          : 0}
                      </span>
                    </Button>
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      onClick={(e) => onAddOtherDetails("2pta")}
                      className={`${
                        selectedMember?.lastClicked === "2pta" ? "clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["2pta"] -
                            selectedMember?.stats["2ptm"]
                          : 0}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">3 PTS</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      onClick={(e) => onPlusScore(3, "3ptm", 1, e)}
                      className={`mr-1${
                        selectedMember?.lastClicked === "3ptm" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["3ptm"]
                          : 0}
                      </span>
                    </Button>
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      onClick={(e) => onAddOtherDetails("3pta")}
                      className={`${
                        selectedMember?.lastClicked === "3pta" ? "clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["3pta"] -
                            selectedMember?.stats["3ptm"]
                          : 0}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">FREE THROW</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      onClick={(e) => onPlusScore(1, "ftm", e)}
                      className={`mr-1${
                        selectedMember?.lastClicked === "ftm" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.ftm}
                      </span>
                    </Button>
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      onClick={(e) => onAddOtherDetails("fta")}
                      className={`${
                        selectedMember?.lastClicked === "fta" ? "clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.fta -
                          selectedMember?.stats?.ftm}
                      </span>
                    </Button>
                  </div>
                </div>
                {/* <div className='score-type' /> */}
              </div>
              <div className="score-item">
                <div className="score-type">
                  <div className="score-h">REBOUNDS</div>
                  <div className="score-act reb">
                    <Button
                      onClick={(e) => onAddOtherDetails("oreb")}
                      className={`mr-1${
                        selectedMember?.lastClicked === "oreb" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      OREB
                      <br />
                      <span className="t-score">
                        {selectedMember?.stats?.oreb}
                      </span>
                    </Button>
                    <Button
                      onClick={(e) => onAddOtherDetails("dreb")}
                      className={`mr-1${
                        selectedMember?.lastClicked === "dreb" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      DREB
                      <br />
                      <span className="t-score">
                        {selectedMember?.stats?.dreb}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">ASSISTS</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      className={`mr-1${
                        selectedMember?.lastClicked === "ast" ? " clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("ast")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.ast}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">STEAL</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      className={`mr-1${
                        selectedMember?.lastClicked === "stl" ? " clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("stl")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.stl}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="score-item">
                <div className="score-type">
                  <div className="score-h">BLOCK</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      className={`mr-1${
                        selectedMember?.lastClicked === "blk" ? " clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("blk")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.blk}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">TURNOVER</div>
                  <div className="score-act">
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      className={`${
                        selectedMember?.lastClicked === "to" ? "clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("to")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.to}
                      </span>
                    </Button>
                  </div>
                </div>

                <div className="score-type">
                  <div className="score-h">FOUL</div>
                  <div className="score-act">
                    <Button
                      icon={
                        !scoreType ? (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            + PF
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            - PF
                          </span>
                        )
                      }
                      onClick={() => onUpdatePersonalFouls()}
                      type="ghost"
                    >
                      {/* PF<br /> */}
                      <span className="t-score" style={{ marginTop: "20px" }}>
                        {selectedMember?.personal_fouls}
                      </span>
                    </Button>
                    <span> &nbsp;</span>
                    <Button
                      icon={
                        !scoreType ? (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            + TF
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            - TF
                          </span>
                        )
                      }
                      onClick={() => onUpdateTechnicalFouls()}
                      type="ghost"
                    >
                      {/* PF<br /> */}
                      <span className="t-score" style={{ marginTop: "20px" }}>
                        {selectedMember?.technical_fouls}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  };

  const PopContent = ({ onSelect, type, hidePopup, hideswap = false }) => (
    <div className="team-list popover-list">
      {currentMatch?.[type]?.players
        ?.filter(
          (tt) =>
            !tt.is_starting_lineup &&
            tt.personal_fouls < 5 &&
            tt.technical_fouls < 2
        )
        .map((tt) => (
          <div
            onClick={() => {
              hideswap ? onSelectinBenchPlayer(tt, type) : onSelect(tt, type);
              if (hidePopup) {
                setOpen(false);
                setOpenaway(false);
              }
            }}
            className="team-item"
          >
            <div className="team-avatar">
              {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
              {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
              <div className="p-name">
                <span>{tt?.player?.first_name}</span>
                <span>{tt?.player?.last_name}</span>
              </div>
            </div>
            <div className="team-no" 
            style={(type==="home")?{backgroundColor:(currentMatch?.home?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}:{backgroundColor:(currentMatch?.away?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}}>
              {tt?.jersey_no}</div>
          </div>
        ))}
      {currentMatch?.[type]?.players
        ?.filter(
          (tt) =>
            !tt.is_starting_lineup &&
            (tt.personal_fouls >= 5 || tt.technical_fouls >= 2)
        )
        .map((tt) => (
          <div
            onClick={() => {
              hideswap
                ? getinfouledoutplayeronly(tt, type)
                : getinfouledoutplayer(tt, type);
              if (hidePopup) {
                setOpen(false);
                setOpenaway(false);
              }
            }}
            className="team-item"
          >
            <div style={{ backgroundColor: "white" }}>
              <div
                style={{
                  backgroundColor: "#AA0000",
                  color: "#fff",
                  padding: "3px 0px",
                  borderRadius: "10px 10px 0px 0px",
                  textAlign: "center",
                }}
              >
                Fouled Out
              </div>
            </div>
            <div className="team-avatar">
              <div className="p-name">
                <span>{tt?.player?.first_name}</span>
                <span>{tt?.player?.last_name}</span>
              </div>
            </div>
            <div style={{ backgroundColor: "grey", opacity: "0.2" }}>
              <div className="team-no" style={(type==="home")?{backgroundColor:(currentMatch?.home?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}:{backgroundColor:(currentMatch?.away?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}}>{tt?.jersey_no}</div>
            </div>
          </div>
        ))}
      {currentMatch?.[type]?.players?.filter(
        (tt) => !tt.is_starting_lineup && tt.personal_fouls < 5
      ).length === 0 &&
        !hideswap && (
          <>
            <div
              className="no-player-left"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span>No bench players available</span>
              {!hideswap && (
                <Button
                  type="primary"
                  onClick={() => {
                    onSelectBenchPlayeronly(type);
                    if (hidePopup) {
                      setOpen(false);
                      setOpenaway(false);
                    }
                  }}
                >
                  Foul Out
                </Button>
              )}
            </div>
          </>
        )}
    </div>
  );

  const SelectAssists = ({ onSelect, type }) => (
    <div className="ast-modal">
      <div class="bench-player-title mt-0">Active Players</div>
      <div className="team-list popover-list">
        {currentMatch?.[type]?.players
          ?.filter(
            (tt) =>
              tt.is_starting_lineup &&
              tt.personal_fouls < 5 &&
              selectedMember._id !== tt._id
          )
          .map((tt) => (
            <div onClick={() => onSelect(tt, type)} className="team-item">
              <div className="team-avatar">
                {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                <div className="p-name">
                  <span>{tt?.player?.first_name}</span>
                  <span>{tt?.player?.last_name}</span>
                </div>
              </div>
              <div className="team-no" style={(type==="home")?{backgroundColor:(currentMatch?.home?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}:{backgroundColor:(currentMatch?.away?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}}>{tt?.jersey_no}</div>
            </div>
          ))}
      </div>
      <div class="bench-player-title">Bench Players</div>
      <div className="team-list popover-list">
        {currentMatch?.[type]?.players
          ?.filter(
            (tt) =>
              !tt.is_starting_lineup &&
              tt.personal_fouls < 5 &&
              selectedMember._id !== tt._id
          )
          .map((tt) => (
            <div onClick={() => onSelect(tt, type)} className="team-item">
              <div className="team-avatar">
                {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                <div className="p-name">
                  <span>{tt?.player?.first_name}</span>
                  <span>{tt?.player?.last_name}</span>
                </div>
              </div>
              <div className="team-no" style={(type==="home")?{backgroundColor:(currentMatch?.home?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}:{backgroundColor:(currentMatch?.away?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":undefined}}>{tt?.jersey_no}</div>
            </div>
          ))}
      </div>
      <div className="btns-act">
        <Button onClick={() => setAstModalInfo({ visible: false })}>
          No Assists
        </Button>
      </div>
    </div>
  );

  const onSelectMember = (team, type, e) => {
    // if ($(e.target).closest('.score-popup-content').length > 0) return;
    if ($(e.target).closest(".popover-list").length > 0) return;
    if ($(e.target).closest(".swap-player").length > 0) return;
    console.log(team);
    currentMatch.selectedType = type;
    setScoreType(false);
    const idx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === team.jersey_no
    );
    if (idx >= 0 && !currentMatch[type]?.players[idx]?.selected) {
      currentMatch.away.players.forEach((ee) => {
        ee.selected = false;
        ee.swap = false;
      });
      currentMatch.home.players.forEach((ee) => {
        ee.selected = false;
        ee.swap = false;
      });
      if (team.swap) {
        currentMatch[type].players[idx].swap = false;
      } else {
        currentMatch[type].players[idx].selected = true;
      }
      // currentMatch[type].players[idx].selected = true
    }
    team.type = type;
    setSelectedMember(team);
    setCurrentMatch({ ...currentMatch });
  };

  const onSelectAstPlayer = (sPlayer, type) => {
    if (sPlayer) {
      sPlayer.type = type;
      const payload = {
        type: "ast",
        player: sPlayer?.player?._id,
        team: currentMatch[sPlayer.type]?.team?._id,
      };
      const idx = currentMatch[sPlayer.type].players.findIndex(
        (pl) => pl._id === sPlayer._id
      );
      if (idx >= 0 && currentMatch[sPlayer.type].players[idx].stats) {
        currentMatch[sPlayer.type].players[idx].stats.ast += 1;
      }
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores(payload);
    }
    setAstModalInfo({ visible: false });
  };

  const onSelectReboundsPlayer = (sPlayer, type) => {
    if (sPlayer) {
      sPlayer.type = type;
      const payload = {
        type: selectedMember.type === type ? "oreb" : "dreb",
        player: sPlayer?.player?._id,
        team: currentMatch[sPlayer.type]?.team?._id,
      };
      const idx = currentMatch[sPlayer.type].players.findIndex(
        (pl) => pl._id === sPlayer._id
      );
      if (idx >= 0 && currentMatch[sPlayer.type].players[idx].stats) {
        currentMatch[sPlayer.type].players[idx].stats[
          selectedMember.type === type ? "oreb" : "dreb"
        ] += 1;
      }
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores(payload);
    }
    setRebModalInfo({ visible: false });
  };

  const onSelectToPlayer = (sPlayer, type) => {
    if (sPlayer) {
      sPlayer.type = type;
      const payload = {
        type: "stl",
        player: sPlayer?.player?._id,
        team: currentMatch[sPlayer.type]?.team?._id,
      };
      const idx = currentMatch[sPlayer.type].players.findIndex(
        (pl) => pl._id === sPlayer._id
      );
      if (idx >= 0 && currentMatch[sPlayer.type].players[idx].stats) {
        currentMatch[sPlayer.type].players[idx].stats["stl"] += 1;
      }
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores(payload);
    }
    setToModalInfo({ visible: false });
  };

  const SelectReboundsPlayer = ({ onSelect, type }) => {
    return (
      <>
        <div class="bench-player-title mt-0" style={{ color: "#000" }}>
          Active Players
        </div>
        <div className="team-list popover-list">
          {currentMatch?.[type]?.players
            ?.filter(
              (tt) =>
                tt.is_starting_lineup &&
                tt.personal_fouls < 5 &&
                selectedMember._id !== tt._id
            )
            .map((tt) => (
              <div onClick={() => onSelect(tt, type)} className="team-item">
                <div className="team-avatar">
                  {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                  {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                  <div className="p-name">
                    <span>{tt?.player?.first_name}</span>
                    <span>{tt?.player?.last_name}</span>
                  </div>
                </div>
                <div
                  className="team-no"
                  style={(type==="home")?{backgroundColor:(currentMatch?.home?.changed_jersey_color),color:((currentMatch?.home?.changed_jersey_color)==='#000000')?"#fff":'black'}:{backgroundColor:(currentMatch?.away?.changed_jersey_color),color:((currentMatch?.away?.changed_jersey_color)==='#000000')?"#fff":'black'}}
                >
                  {tt?.jersey_no}
                </div>
              </div>
            ))}
        </div>
        <div class="bench-player-title" style={{ color: "#000" }}>
          Bench Players
        </div>
        <div className="team-list popover-list">
          {currentMatch?.[type]?.players
            ?.filter(
              (tt) =>
                !tt.is_starting_lineup &&
                tt.personal_fouls < 5 &&
                selectedMember._id !== tt._id
            )
            .map((tt) => (
              <div onClick={() => onSelect(tt, type)} className="team-item">
                <div className="team-avatar">
                  {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                  {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                  <div className="p-name">
                    <span>{tt?.player?.first_name}</span>
                    <span>{tt?.player?.last_name}</span>
                  </div>
                </div>
                <div
                  className="team-no"
                  style={(type==="home")?{backgroundColor:(currentMatch?.home?.changed_jersey_color),color:(currentMatch?.home?.changed_jersey_color)==='#000000'?"#fff":'#000000'}:{backgroundColor:(currentMatch?.away?.changed_jersey_color),color:(currentMatch?.away?.changed_jersey_color)==='#000000'?"#fff":'#000000'}}
                >
                  {tt?.jersey_no}
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  const SelectRebounds = ({ onSelect, type }) => {
    return (
      <Tabs>
        <Tabs.TabPane
          key="1"
          tab={
            selectedMember.type === "away"
              ? currentMatch?.home?.team?.name
              : currentMatch?.away?.team?.name
          }
        >
          <div
            className="ast-modal"
            style={
              currentMatch?.away?.changed_jersey_color
                ? { color: currentMatch?.away?.changed_jersey_color }
                : { color: currentMatch?.home?.changed_jersey_color }
            }
          >
            <SelectReboundsPlayer
              onSelect={onSelect}
              type={selectedMember.type === "away" ? "home" : "away"}
            />
            <div className="btns-act">
              {/* {(selectedMember.type===type) && 
              <Button //onClick={() => setRebModalInfo({ visible: false })}
                onClick={() => onSelect(selectedMember, type)}
              >
                Second chance
              </Button>} */}
              <Button onClick={() => setRebModalInfo({ visible: false })}>
                No Rebound
              </Button>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            selectedMember.type === "away"
              ? currentMatch?.away?.team?.name
              : currentMatch?.home?.team?.name
          }
        >
          <div
            className="ast-modal"
            style={
              currentMatch?.away?.changed_jersey_color
                ? { color: currentMatch?.home?.changed_jersey_color }
                : { color: currentMatch?.away?.changed_jersey_color }
            }
          >
            <SelectReboundsPlayer
              onSelect={onSelect}
              type={selectedMember.type === "away" ? "away" : "home"}
            />
            <div className="btns-act">
              {selectedMember.type === type && (
                <Button //onClick={() => setRebModalInfo({ visible: false })}
                  onClick={() => onSelect(selectedMember, type)}
                >
                  Second chance
                </Button>
              )}
              <Button onClick={() => setRebModalInfo({ visible: false })}>
                No Rebound
              </Button>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    );
  };

  const Selectto = ({ onSelect, type }) => {
    return (
      <>
        {type === "away" ? (
          <div className="ast-modal">
            <SelectReboundsPlayer onSelect={onSelect} type="home" />
            <div className="btns-act">
              <Button onClick={() => setToModalInfo({ visible: false })}>
                No Steal
              </Button>
            </div>
          </div>
        ) : (
          <div className="ast-modal">
            <SelectReboundsPlayer onSelect={onSelect} type="away" />
            <div className="btns-act">
              <Button onClick={() => setToModalInfo({ visible: false })}>
                No Steal
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  const onSwapPlayer = (team, type) => {
    console.log(team, type, "team, type");
    team.type = type;
    setSelectedMember(team);
    const idx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === team.jersey_no
    );
    if (idx >= 0 && !currentMatch[type]?.players[idx]?.swap) {
      currentMatch.away.players.forEach((ee) => {
        ee.swap = false;
        ee.selected = false;
      });
      currentMatch.home.players.forEach((ee) => {
        ee.swap = false;
        ee.selected = false;
      });
      currentMatch[type].players[idx].swap = true;
      // currentMatch[type].players[idx].selected = true
    } else {
      currentMatch[type].players[idx].swap = false;
      currentMatch[type].players[idx].selected = false;
      setSelectedMember(null);
    }
    setCurrentMatch({
      ...currentMatch,
    });
  };

  const onOpenPicker = (type) => {
    currentMatch[type].team.openPicker = !currentMatch[type].team.openPicker;
    setCurrentMatch({
      ...currentMatch,
    });
  };

  const onChangeColor = (e, type) => {
    currentMatch[type].team.openPicker = false;
    currentMatch[type].changed_jersey_color = e.hex;
    setCurrentMatch({
      ...currentMatch,
    });
    apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
      team: currentMatch[type].team._id,
      changed_jersey_color: e.hex,
    });
  };
  console.log(currentMatch, "currentMatchcurrentMatch");
  const getinfouledoutplayer = async (member, type) => {
    const bool = await ConfirmationModal(
      "Do you want to reduce foul and get in fouled out player?"
    );
    if (bool) {
      const idx = currentMatch[type]?.players?.findIndex(
        (ee) => ee._id === member?._id
      );
      const payload = {
        player: member?._id,
        team: currentMatch[type]?.team?._id,
      };
      //onUpdatePersonalFouls()
      if (currentMatch[type].players[idx].personal_fouls >= 5) {
        payload.type = "personal_fouls";
        payload.undo = true;
        const idx = currentMatch[type]?.players?.findIndex(
          (ee) => ee._id === member?._id
        );
        console.log(idx, "idx", type, member);
        if (idx >= 0) {
          currentMatch[type].players[idx].personal_fouls += payload.undo
            ? -1
            : 1;
          currentMatch[type].personal_fouls += payload.undo ? -1 : 1;
          if (currentMatch[type].personal_fouls < 0) {
            currentMatch[type].personal_fouls = 0;
          }
          console.log(
            currentMatch[type].players[idx].personal_fouls,
            "currentMatch[selectedtype].players[idx].personal_fouls"
          );

          //selectedMember.personal_fouls = currentMatch[type].players[idx].personal_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
      } else {
        payload.type = "technical_fouls";
        payload.undo = true;
        if (idx >= 0) {
          currentMatch[type].players[idx].technical_fouls += payload.undo
            ? -1
            : 1;
          //currentMatch[type].technical_fouls += payload.undo ? -1 : 1
          if (currentMatch[type].players[idx].technical_fouls < 0) {
            currentMatch[type].players[idx].technical_fouls = 0;
          }
          if (currentMatch[type].players[idx].technical_fouls >= 2) {
            currentMatch[type].players[idx].technical_fouls = 2;
            const ok = await ConfirmationModal(
              "This Action will foul out the player, Are you sure want to continue?"
            );
            if (!ok) {
              currentMatch[type].players[idx].technical_fouls = 1;
              return;
            } else {
              message.success(
                `${currentMatch[type].team.name} ${currentMatch[type].players[idx].player.first_name} ${currentMatch[type].players[idx].player.last_name} has FOULED OUT`
              );
            }
            selectedMember.selected = false;
            setPfModalInfo({
              visible: true,
              player: currentMatch[type].players[idx],
              type: type,
            });
          }
          //selectedMember.technical_fouls = currentMatch[type].players[idx].technical_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
      }
      const tidx = currentMatch[type]?.players?.findIndex(
        (ee) => ee.jersey_no === selectedMember.jersey_no
      );
      const sidx = currentMatch[type]?.players?.findIndex(
        (ee) => ee.jersey_no === member.jersey_no
      );
      if (tidx >= 0 && sidx >= 0) {
        const order = currentMatch[type].players[tidx].order;
        currentMatch[type].players[tidx].is_starting_lineup = false;
        currentMatch[type].players[tidx].order =
          currentMatch[type].players[sidx].order;
        currentMatch[type].players[sidx].is_starting_lineup = true;
        currentMatch[type].players[sidx].order = order;
        setSelectedMember(null);
        setCurrentMatch({
          ...currentMatch,
        });
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
        apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
          team: currentMatch[type].team._id,
          swap: currentMatch[type].players[tidx]._id,
          player: currentMatch[type].players[sidx]._id,
        });
      }
      setPfModalInfo({
        visible: false,
      });
    }
  };

  const getinfouledoutplayeronly = async (member, type) => {
    const bool = await ConfirmationModal(
      "Do you want to reduce foul and get in fouled out player?"
    );
    if (bool) {
      const idx = currentMatch[type]?.players?.findIndex(
        (ee) => ee._id === member?._id
      );
      const payload = {
        player: member?._id,
        team: currentMatch[type]?.team?._id,
      };
      //onUpdatePersonalFouls()
      if (currentMatch[type].players[idx].personal_fouls >= 5) {
        payload.type = "personal_fouls";
        payload.undo = true;
        const idx = currentMatch[type]?.players?.findIndex(
          (ee) => ee._id === member?._id
        );
        console.log(idx, "idx", type, member);
        if (idx >= 0) {
          currentMatch[type].players[idx].personal_fouls += payload.undo
            ? -1
            : 1;
          currentMatch[type].personal_fouls += payload.undo ? -1 : 1;
          if (currentMatch[type].personal_fouls < 0) {
            currentMatch[type].personal_fouls = 0;
          }
          console.log(
            currentMatch[type].players[idx].personal_fouls,
            "currentMatch[selectedtype].players[idx].personal_fouls"
          );

          //selectedMember.personal_fouls = currentMatch[type].players[idx].personal_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
      } else {
        payload.type = "technical_fouls";
        payload.undo = true;
        if (idx >= 0) {
          currentMatch[type].players[idx].technical_fouls += payload.undo
            ? -1
            : 1;
          //currentMatch[type].technical_fouls += payload.undo ? -1 : 1
          if (currentMatch[type].players[idx].technical_fouls < 0) {
            currentMatch[type].players[idx].technical_fouls = 0;
          }
          if (currentMatch[type].players[idx].technical_fouls >= 2) {
            currentMatch[type].players[idx].technical_fouls = 2;
            const ok = await ConfirmationModal(
              "This Action will foul out the player, Are you sure want to continue?"
            );
            if (!ok) {
              currentMatch[type].players[idx].technical_fouls = 1;
              return;
            } else {
              message.success(
                `${currentMatch[type].team.name} ${currentMatch[type].players[idx].player.first_name} ${currentMatch[type].players[idx].player.last_name} has FOULED OUT`
              );
            }
            selectedMember.selected = false;
            setPfModalInfo({
              visible: true,
              player: currentMatch[type].players[idx],
              type: type,
            });
          }
          //selectedMember.technical_fouls = currentMatch[type].players[idx].technical_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
      }
      // const tidx = currentMatch[type]?.players?.findIndex(
      //   (ee) => ee.jersey_no === selectedMember.jersey_no
      // );
      const sidx = currentMatch[type]?.players?.findIndex(
        (ee) => ee.jersey_no === member.jersey_no
      );
      if (sidx >= 0) {
        //const order = currentMatch[type].players[tidx].order;
        //currentMatch[type].players[tidx].is_starting_lineup = false;
        // currentMatch[type].players[tidx].order =
        //   currentMatch[type].players[sidx].order;
        currentMatch[type].players[sidx].is_starting_lineup = true;
        //currentMatch[type].players[sidx].order = order;
        setSelectedMember(null);
        setCurrentMatch({
          ...currentMatch,
        });
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
        apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
          team: currentMatch[type].team._id,
          //swap: currentMatch[type].players[tidx]._id,
          player: currentMatch[type].players[sidx]._id,
          foulin: true,
        });
      }
      setPfModalInfo({
        visible: false,
      });
      setopenawayplayergetin(false);
      setopenhomeplayergetin(false);
    }
  };

  const onSelectBenchPlayer = (member, type) => {
    const tidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === selectedMember.jersey_no
    );
    const sidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === member.jersey_no
    );
    if (tidx >= 0 && sidx >= 0) {
      const order = currentMatch[type].players[tidx].order;
      currentMatch[type].players[tidx].is_starting_lineup = false;
      currentMatch[type].players[tidx].order =
        currentMatch[type].players[sidx].order;
      currentMatch[type].players[sidx].is_starting_lineup = true;
      currentMatch[type].players[sidx].order = order;
      setSelectedMember(null);
      setCurrentMatch({
        ...currentMatch,
      });
      apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
        team: currentMatch[type].team._id,
        swap: currentMatch[type].players[tidx]._id,
        player: currentMatch[type].players[sidx]._id,
      });
    }
    setPfModalInfo({
      visible: false,
    });
  };
  const onSelectinBenchPlayer = (member, type) => {
    const sidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === member.jersey_no
    );
    if (sidx >= 0) {
      //const order = currentMatch[type].players[tidx].order;
      //currentMatch[type].players[tidx].is_starting_lineup = false;
      // currentMatch[type].players[tidx].order =
      //   currentMatch[type].players[sidx].order;
      currentMatch[type].players[sidx].is_starting_lineup = true;
      //currentMatch[type].players[sidx].order = order;
      setSelectedMember(null);
      setCurrentMatch({
        ...currentMatch,
      });
      apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
        team: currentMatch[type].team._id,
        player: currentMatch[type].players[sidx]._id,
        foulin: true,
      });
    }
    setPfModalInfo({
      visible: false,
    });
    setopenhomeplayergetin(false);

    setopenawayplayergetin(false);
  };

  const onSelectBenchPlayeronly = (type) => {
    const tidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === selectedMember.jersey_no
    );
    if (tidx >= 0) {
      //const order = currentMatch[type].players[tidx].order;
      currentMatch[type].players[tidx].is_starting_lineup = false;
      setSelectedMember(null);
      setCurrentMatch({
        ...currentMatch,
      });
      apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
        team: currentMatch[type].team._id,
        swap: currentMatch[type].players[tidx]._id,
        foulout: true,
      });
    }
    setPfModalInfo({
      visible: false,
    });
  };

  const onUpdate = (val, key) => {
    if (key === "date") {
      loadMatches(val);
    }
    scoreboardDet[key] = val;
    setScoreboard({ ...scoreboardDet });
  };

  const MatchView = ({ home, away, date }) => {
    return (
      <div className="match-view">
        <Tag color="#12344d">{getDateFormat({ date, format: "hh:mm a" })}</Tag>
        <TeamNameWithLogo {...home} />
        <div className="match_vs">vs</div>
        <TeamNameWithLogo {...away} />
      </div>
    );
  };

  const checkScorerDetails = async () => {
    setLoading(true);
    const { data: scoreDet } = await apiClient.get(ApiConfig.matches.getScorer);
    setCurrentMatch(scoreDet);
    setLoading(false);
    if (scoreDet?.home) {
      const idx = scoreDet.home.players.findIndex(
        (pp) => pp.personal_fouls >= 5 && pp.is_starting_lineup
      );
      if (idx >= 0) {
        setPfModalInfo({
          visible: true,
          player: scoreDet.home.players[idx],
          type: "home",
        });
        setSelectedMember(scoreDet.home.players[idx]);
        return;
      }
    }
    if (scoreDet?.away) {
      const idx = scoreDet.away.players.findIndex(
        (pp) => pp.personal_fouls >= 5 && pp.is_starting_lineup
      );
      if (idx >= 0) {
        setPfModalInfo({
          visible: true,
          player: scoreDet.away.players[idx],
          type: "away",
        });
        setSelectedMember(scoreDet.away.players[idx]);
      }
    }
  };

  console.log("scoreboardDet", scoreboardDet);

  const loadMatches = async (date = scoreboardDet.date) => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(
        ApiConfig.matches.list +
          "?date=" +
          getDateFormat({ date, format: "YYYY-MM-DD" })
      );
      setCourtList(data.courts || []);
      // setSeasons(data.seasons || []);
      setMatches(data.matches || []);
      await checkScorerDetails();
    } catch (error) {
      setLoading(false);
    }
  };
  const getMatches = () => {
    const tmp = matches.filter((tt) => {
      // return tt.sports_event._id === scoreboardDet.season && tt.court._id === scoreboardDet.court;
      return tt.court._id === scoreboardDet.court && tt.status === "Scheduled";
    });
    console.log(tmp,'tmp25')
    return tmp;
  };

  const onProceed = async () => {
    setLoading(true);
    await apiClient.put(
      ApiConfig.matches.updateScorer + `/${scoreboardDet.match}`
    );
    checkScorerDetails();
  };

  const onStartMatch = async () => {
    const home_players = currentMatch?.home?.players
      ?.filter(
        (ee) => currentMatch?.home?.selectedPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
      }));
    const away_players = currentMatch?.away?.players
      ?.filter(
        (ee) => currentMatch?.away?.selectedPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
      }));
    let valid = true;
    if (home_players.length < 5) {
      notification.error({
        message: `${currentMatch?.home?.team?.name} has less than 5 players.`,
      });
      valid = false;
    }
    if (away_players.length < 5) {
      notification.error({
        message: `${currentMatch?.away?.team?.name} has less than 5 players.`,
      });
      valid = false;
    }
    if (
      home_players.length >= 5 &&
      home_players.filter((e) => e.is_starting_lineup).length !== 5
    ) {
      notification.error({
        message: `${currentMatch?.home?.team?.name} must have 5 starting lineup players.`,
      });
      valid = false;
    }
    if (
      away_players.length >= 5 &&
      away_players.filter((e) => e.is_starting_lineup).length !== 5
    ) {
      notification.error({
        message: `${currentMatch?.away?.team?.name} must have 5 starting lineup players.`,
      });
      valid = false;
    }
    const a1 = {};
    const v1 = currentMatch?.away?.players.forEach((item) => {
      if (Boolean(a1?.[item?.jersey_no])) {
        notification.error({
          message: `Two Players have same Jersey Number ${item?.jersey_no}.`,
        });
        valid = false;
      } else {
        a1[item?.jersey_no] = true;
      }
    });
    const a2 = {};
    const v2 = currentMatch?.home?.players.forEach((item) => {
      if (Boolean(a2?.[item?.jersey_no])) {
        notification.error({
          message: `Two Players have same Jersey Number ${item?.jersey_no}.`,
        });
        valid = false;
      } else {
        a2[item?.jersey_no] = true;
      }
    });
    console.log(v1, v2);
    if (valid) {
      setLoading(true);
      await apiClient.post(
        ApiConfig.matches.startMatch + `/${currentMatch._id}`,
        { home_players, away_players }
      );
      checkScorerDetails();
    }
  };

  const onCancelMatch = async () => {
    const ok = await ConfirmationModal(
      "Are you sure you want to leave this match?"
    );
    if (ok) {
      setLoading(true);
      await apiClient.put(
        ApiConfig.matches.updateDetails + `/${currentMatch._id}`,
        { is_leave: true }
      );
      checkScorerDetails();
    }
  };

  const onSaveTimeout = async (type) => {
    const payload = {
      team: currentMatch[type]?.team?._id,
    };
    let isValid = false;
    if (currentMatch.is_halftime_taken) {
      if (!(currentMatch[type].timeout_after_halftime >= 2)) {
        payload.timeout_after_halftime = true;
        currentMatch[type].timeout_after_halftime = currentMatch[type]
          .timeout_after_halftime
          ? currentMatch[type].timeout_after_halftime + 1
          : 1;
        isValid = true;
      } else {
        notification.error({
          message: "No timeout left",
        });
      }
    } else {
      if (!(currentMatch[type].timeout_before_halftime >= 2)) {
        payload.timeout_before_halftime = true;
        currentMatch[type].timeout_before_halftime = currentMatch[type]
          .timeout_before_halftime
          ? currentMatch[type].timeout_before_halftime + 1
          : 1;
        isValid = true;
      } else {
        notification.error({
          message: "No timeout left",
        });
      }
    }

    if (isValid) {
      setCurrentMatch({ ...currentMatch });
      await apiClient.put(
        ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
        payload
      );
    }
  };

  const onClickTimeout = () => {
    ConfirmationModal(
      "Which team you want to take a timeout?",
      null,
      teamSwap
        ? currentMatch?.home?.team?.name
        : currentMatch?.away?.team?.name,
      teamSwap
        ? currentMatch?.away?.team?.name
        : currentMatch?.home?.team?.name,
      () => {
        onSaveTimeout(teamSwap ? "home" : "away");
      },
      null,
      () => {
        onSaveTimeout(teamSwap ? "away" : "home");
      },
      "timeout_popup",
      teamSwap
        ? currentMatch?.home?.changed_jersey_color
        : currentMatch?.away?.changed_jersey_color,
      teamSwap
        ? currentMatch?.away?.changed_jersey_color
        : currentMatch?.home?.changed_jersey_color,
      "150px"
    );
  };

  const onClickHalftimeOrComplete = async () => {
    const bool = await ConfirmationModal(
      currentMatch.is_halftime_taken
        ? "Are you sure you want to end the match?"
        : `Are you sure you want to take a halftime?`
    );
    if (bool) {
      const payload = {};
      if (!currentMatch.is_halftime_taken) {
        currentMatch.is_halftime_taken = true;
        payload.is_halftime_taken = true;
        payload.resetfouls = true;
        currentMatch.home.personal_fouls = 0;
        currentMatch.away.personal_fouls = 0;
        setCurrentMatch({ ...currentMatch });
      } else {
        currentMatch.status = "Completed";
        loadMatches();
        payload.is_completed = true;
        setCurrentMatch(null);
        setSelectedMember(null);
        setteamawayfoul(0);
        setteamawayfoul(0);
      }
      await apiClient.put(
        ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
        payload
      );
    }
  };

  const onSwapTeam = () => {
    // const home = JSON.parse(JSON.stringify(currentMatch.home));
    // const away = JSON.parse(JSON.stringify(currentMatch.away));
    // currentMatch.away = home;
    // currentMatch.home = away;
    // setCurrentMatch({ ...currentMatch });
    setTeamSwap(!teamSwap);
  };
  console.log(
    currentMatch?.[selectedMember?.type],
    "currentMatch[selectedMember.type].personal_fouls"
  );
  const onUpdatePersonalFouls = async (scoreTyp = false) => {
    console.log(scoreTyp, "scoreTyp = false");
    const payload = {
      player: selectedMember?._id,
      team: currentMatch[selectedMember.type]?.team?._id,
      type: "personal_fouls",
    };
    if (scoreType) {
      const ok = await ConfirmationModal(
        "Are you sure you want to undo the last action?"
      );
      if (ok) {
        payload.undo = true;
      } else {
        return;
      }
    }
    if (scoreTyp) {
      payload.undo = true;
    }
    const idx = currentMatch[selectedMember.type]?.players?.findIndex(
      (ee) => ee._id === selectedMember?._id
    );
    if (idx >= 0) {
      currentMatch[selectedMember.type].players[idx].personal_fouls +=
        payload.undo ? -1 : 1;
      currentMatch[selectedMember.type].personal_fouls += payload.undo ? -1 : 1;
      if (currentMatch[selectedMember.type].personal_fouls < 0) {
        currentMatch[selectedMember.type].personal_fouls = 0;
      }
      if (currentMatch[selectedMember.type].players[idx].personal_fouls >= 5) {
        currentMatch[selectedMember.type].players[idx].personal_fouls = 5;
        const ok = await ConfirmationModal(
          "This Action will Foul Out the player, Are you sure want to continue?"
        );
        if (!ok) {
          currentMatch[selectedMember.type].players[idx].personal_fouls = 4;
          return;
        } else {
          message.success(
            `${currentMatch[selectedMember.type].team.name} ${
              currentMatch[selectedMember.type].players[idx].player.first_name
            } ${
              currentMatch[selectedMember.type].players[idx].player.last_name
            } has FOULED OUT`
          );
        }
        selectedMember.selected = false;
        setPfModalInfo({
          visible: true,
          player: currentMatch[selectedMember.type].players[idx],
          type: selectedMember.type,
        });
      }
      selectedMember.personal_fouls =
        currentMatch[selectedMember.type].players[idx].personal_fouls;
      setSelectedMember({ ...selectedMember });
      setCurrentMatch({ ...currentMatch });
    }
    await apiClient.put(
      ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
      payload
    );
  };
  const onUpdateTechnicalFouls = async () => {
    const payload = {
      player: selectedMember?._id,
      team: currentMatch[selectedMember.type]?.team?._id,
      type: "technical_fouls",
    };
    if (scoreType) {
      const ok = await ConfirmationModal(
        "Are you sure you want to undo the last action?"
      );
      if (ok) {
        payload.undo = true;
      } else {
        return;
      }
    }
    const idx = currentMatch[selectedMember.type]?.players?.findIndex(
      (ee) => ee._id === selectedMember?._id
    );
    if (idx >= 0) {
      currentMatch[selectedMember.type].players[idx].technical_fouls +=
        payload.undo ? -1 : 1;
      //currentMatch[selectedMember.type].technical_fouls += payload.undo ? -1 : 1
      if (currentMatch[selectedMember.type].players[idx].technical_fouls < 0) {
        currentMatch[selectedMember.type].players[idx].technical_fouls = 0;
      }
      if (currentMatch[selectedMember.type].players[idx].technical_fouls >= 2) {
        currentMatch[selectedMember.type].players[idx].technical_fouls = 2;
        const ok = await ConfirmationModal(
          "This Action will Foul Out the player, Are you sure want to continue?"
        );
        if (!ok) {
          currentMatch[selectedMember.type].players[idx].technical_fouls = 1;
          return;
        } else {
          message.success(
            `${currentMatch[selectedMember.type].team.name} ${
              currentMatch[selectedMember.type].players[idx].player.first_name
            } ${
              currentMatch[selectedMember.type].players[idx].player.last_name
            } has FOULED OUT`
          );
        }
        selectedMember.selected = false;
        setPfModalInfo({
          visible: true,
          player: currentMatch[selectedMember.type].players[idx],
          type: selectedMember.type,
        });
      }
      selectedMember.technical_fouls =
        currentMatch[selectedMember.type].players[idx].technical_fouls;
      setSelectedMember({ ...selectedMember });
      setCurrentMatch({ ...currentMatch });
    }
    await apiClient.put(
      ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
      payload
    );
  };

  // const onCheckPlayer = (type, selectedKeys) => {
  //   console.log(type, selectedKeys);
  //   console.log(currentMatch[type].selectedPlayers,'currentMatch[type].selectedPlayers')
  //   currentMatch[type].selectedPlayers = selectedKeys;
  //   setCurrentMatch({ ...currentMatch });
  // };
  const onCheckPlayerone = (type, selectedKeys) => {
    console.log(type, selectedKeys);
    console.log(
      currentMatch[type].selectedPlayers,
      "currentMatch[type].selectedPlayers"
    );
    if (!Boolean(currentMatch[type].selectedPlayers?.includes(selectedKeys))) {
      if (Boolean(currentMatch[type].selectedPlayers?.length)) {
        currentMatch[type].selectedPlayers.push(selectedKeys);
        setCurrentMatch({ ...currentMatch });
      } else {
        currentMatch[type].selectedPlayers = [selectedKeys];
        setCurrentMatch({ ...currentMatch });
      }
    }
    //currentMatch[type].selectedPlayers = selectedKeys;
  };

  const onCheckPlayeronein = (type, selectedKeys) => {
    console.log(type, selectedKeys);
    console.log(
      currentMatch[type].selectedPlayers,
      "currentMatch[type].selectedPlayers"
    );
    if (Boolean(currentMatch[type].selectedPlayers?.includes(selectedKeys))) {
      if (Boolean(currentMatch[type].selectedPlayers?.length)) {
        const temp = currentMatch[type].selectedPlayers.filter(
          (i) => i !== selectedKeys
        );
        currentMatch[type].selectedPlayers = [...temp];
        setCurrentMatch({ ...currentMatch });
      }
    }
    //currentMatch[type].selectedPlayers = selectedKeys;
  };
  const [duplicatesh, setduplicatesh] = useState([]);
  const [duplicatesa, setduplicatesa] = useState([]);
  const onChangeJerseyNo = (value, type, key) => {
    currentMatch[type].players[key].jersey_no = value;
    // const duplicatearray = []
    // let i=0;
    // let j=0;
    // for(i=0;i<=currentMatch[type].players?.length??0;i++){
    //   for(j=0;j<=currentMatch[type].players?.length??0;j++){
    //     if(j!==i){
    //       if(currentMatch[type].players[i]?.jersey_no === currentMatch[type].players[j]?.jersey_no){
    //         duplicatearray.push(i)
    //         duplicatearray.push(j)
    //       }
    //     }
    //   }
    // }
    // if(type==='home') {
    //   setduplicatesh([...duplicatearray])
    // } else {
    //   setduplicatesa([...duplicatearray])
    // }
    setCurrentMatch({ ...currentMatch });
  };

  const onBlurJerseyNo = (value, type, key) => {
    currentMatch[type].players[key].jersey_no = value;
    const duplicatearray = [];
    let i = 0;
    let j = 0;
    for (i = 0; i <= currentMatch[type].players?.length ?? 0; i++) {
      for (j = 0; j <= currentMatch[type].players?.length ?? 0; j++) {
        if (j !== i) {
          if (
            currentMatch[type].players[i]?.jersey_no ===
            currentMatch[type].players[j]?.jersey_no
          ) {
            duplicatearray.push(i);
            duplicatearray.push(j);
            message.error(
              `${currentMatch[type].players[i].player.first_name} ${currentMatch[type].players[i].player.last_name} has duplicate Jersey`
            );
          }
        }
      }
    }

    if (type === "home") {
      setduplicatesh([...duplicatearray]);
    } else {
      setduplicatesa([...duplicatearray]);
    }
    setCurrentMatch({ ...currentMatch });
  };

  const onCheckRemPlayer = (type, selectedKeys) => {
    console.log(type, selectedKeys);
    currentMatch[type].selectedRemPlayers = selectedKeys;
    setCurrentMatch({ ...currentMatch });
  };

  const onChangeRemJerseyNo = (value, type, key) => {
    currentMatch[type].remaining_players[key].jersey_no = value;
    setCurrentMatch({ ...currentMatch });
  };

  const onChangeStartingLineup = (value, type, key) => {
    const home_players = currentMatch?.home?.players
      ?.filter(
        (ee) => currentMatch?.home?.selectedPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
      }));
    const away_players = currentMatch?.away?.players
      ?.filter(
        (ee) => currentMatch?.away?.selectedPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
      }));
    const home_players_length = home_players?.filter(
      (x) => x?.is_starting_lineup === true
    )?.length;
    const away_players_length = away_players?.filter(
      (x) => x?.is_starting_lineup === true
    )?.length;
    console.log("got here", home_players_length, type);
    if (value === true) {
      if (
        (type === "home" && home_players_length > 4) ||
        (type === "away" && home_players_length > 5)
      ) {
        notification.error({
          message: `${currentMatch?.home?.team?.name} must not have not more than 5 starting lineup players.`,
        });
        currentMatch[type].players[key].is_starting_lineup = false;
      } else {
        currentMatch[type].players[key].is_starting_lineup = value;
        setCurrentMatch({ ...currentMatch });
      }
      if (
        (type === "away" && away_players_length > 4) ||
        (type === "home" && away_players_length > 5)
      ) {
        notification.error({
          message: `${currentMatch?.away?.team?.name} must not have not more than 5 starting lineup players.`,
        });
        currentMatch[type].players[key].is_starting_lineup = false;
      } else {
        currentMatch[type].players[key].is_starting_lineup = value;
        setCurrentMatch({ ...currentMatch });
      }
    } else if (value === false) {
      currentMatch[type].players[key].is_starting_lineup = value;
    }
  };

  const onClickPage = (e) => {
    if ($(e.target).closest(".swap-player").length > 0) {
      return;
    }
    if (
      $(e.target).closest(
        ".scoreboard-left, .scoreboard-right, .score-block, .score-popup-content, .chng-pl-no, .pop-score, .reb_popup, .ant-drawer, .mobile-autocomplete .sketch-picker-comp"
      ).length > 0
    ) {
      return;
    }
    // console.log(e);
    if (currentMatch?.home?.players) {
      currentMatch.home.team.openPicker = false;
      currentMatch.home.players.forEach((pp) => {
        pp.swap = false;
      });
    }
    if (currentMatch?.away?.players) {
      currentMatch.away.team.openPicker = false;
      currentMatch.away.players.forEach((pp) => {
        pp.swap = false;
      });
    }
    if (currentMatch?.home?.players || currentMatch?.away?.players) {
      setCurrentMatch({ ...currentMatch });
    }
  };

  const ScoreUpdate = ({ score = 0, type }) => {
    const [cScore, setcScore] = useState(score);
    const setScore = () => {
      currentMatch[type].score = cScore;
      currentMatch[type].scorePopVisible = false;
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores({ team: currentMatch[type]?.team?._id, score: cScore });
    };

    return (
      <div className="upd-score">
        <InputNumber
          maxLength={3}
          controls={false}
          onChange={(e) => {
            if (e <= 0) {
              e = 0;
            }
            setcScore(e);
          }}
          value={cScore}
        />
        <Button onClick={() => setScore()} icon={<CheckOutlined />} />
      </div>
    );
  };

  const onHideTeam = (type) => {
    currentMatch[type].hide = !currentMatch[type].hide;
    setCurrentMatch({ ...currentMatch });
  };

  const onAddMatchPlayer = (type, visible) => {
    setRemainingPlayerInfo({ type, visible });
  };

  const onSelectOwner = (value = "") => {
    const tmp = players.filter((e) => value.indexOf(e.email) >= 0)[0];
    console.log(tmp);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      player: tmp._id,
    });
  };

  const geOptions = (options = []) => {
    return options.map((opt) => {
      return {
        value: `${getFullName(opt)}<${opt.email}>`,
        label: (
          <List.Item>
            <List.Item.Meta
              avatar={
                !opt.profile_pic ? (
                  <UserOutlined />
                ) : (
                  <img style={{ width: "30px" }} src={opt.profile_pic} alt="" />
                )
              }
              title={getFullName(opt)}
              description={opt.email}
            />
          </List.Item>
        ),
      };
    });
  };

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((nextValue, type) => handleSearch(nextValue), 500),
    [] // will be created only once initially
  );

  const handleSearch = async (value = "") => {
    if (value.length < 2) {
      setPlayers([...[]]);
      return;
    }
    try {
      setSearching(true);
      const { data } = await apiClient.get(
        `${ApiConfig.player.playerSearch}?q=${value}`
      );
      setPlayers([...data]);
      setSearching(false);
    } catch (error) {
      setSearching(false);
    }
  };

  const onAddPlayerTeam = async (values) => {
    try {
      if (playerAddInfo?.type) {
        setAdding(true);
        const payload = {
          team: currentMatch[playerAddInfo?.type]?.team?._id,
          ...values,
        };
        console.log("payload", payload);
        await apiClient.post(
          ApiConfig.matches.addPlayerToTeam + `/${currentMatch._id}`,
          payload
        );
        setPlayerAddInfo({
          visible: false,
        });
        setLoading(true);
        checkScorerDetails();
      }
    } catch (error) {
      setAdding(false);
      setLoading(false);
    }
  };

  const onSubmitMatchPlayers = async () => {
    const type = remainingPlayerInfo.type;
    const players = currentMatch[type]?.remaining_players
      ?.filter(
        (ee) => currentMatch[type]?.selectedRemPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee.player._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
        order: ee.order,
      }));
    const payload = {};
    if (type && players.length > 0) {
      payload[`${type}_players`] = players;
      setRemainingPlayerInfo({ type: "", visible: false });
      setLoading(true);
      await apiClient.post(
        ApiConfig.matches.addPlayers + `/${currentMatch._id}`,
        payload
      );
      checkScorerDetails();
    }
  };

  useEffect(() => {
    loadMatches();
    window.$ = $;
    dispatch({
      type: "SET_CONFIG_KEY",
      key: "collapsed",
      value: !collapsed,
    });
    // eslint-disable-next-line
  }, []);

  console.log("currentMatch", currentMatch);

  return (
    <div onClick={onClickPage} className="scorekeeper-page">
      {/* <SectionTitle title="Manage the scores of the teams in a real-time" subtitle="Scorekeeper" /> */}
      {loading && <PageLoader className="score-loader1" />}
      {!loading && !currentMatch && (
        <div className="form-block">
          <div className="form-items">
            <div className="form-item">
              <div className="form-label">Date</div>
              <DatePicker
                allowClear={false}
                disabled={!scoreboardDet.date}
                format="ddd, DD MMM YYYY"
                onChange={(e) => onUpdate(e, "date")}
                value={scoreboardDet.date}
              />
            </div>
            <div className="form-item">
              <div className="form-label">Court/Gym</div>
              <Select
                onChange={(e) => onUpdate(e, "court")}
                value={scoreboardDet.court}
                options={courtList.map((ee) => ({
                  label: ee.name,
                  value: ee._id,
                }))}
                placeholder="Select court/gym"
              />
            </div>
            {/* <div className='form-item'>
            <div className='form-label'>Season</div>
            <Select onChange={(e) => onUpdate(e, 'season')} value={scoreboardDet.season} options={seasons.map(ee => ({ label: ee.name, value: ee._id }))} placeholder="Select season" />
          </div> */}
            <div className="form-item">
              <div className="form-label">Available Matches</div>
              <Select
                disabled={!scoreboardDet.court}
                onChange={(e) => onUpdate(e, "match")}
                value={scoreboardDet.match}
                options={getMatches().sort((a,b)=>moment(a?.date).format("H:mm") > moment(b?.date).format("H:mm")?1:-1)
                  .map((ee) => ({
                    label: (
                      <MatchView
                        date={ee.date}
                        home={ee?.home?.team}
                        away={ee?.away?.team}
                      />
                    ),
                    value: ee._id,
                  }))}
                placeholder="Select match"
              />
            </div>
            {/* <div className='form-item'>
            <div className='form-label'>Available Slots</div>
            <Select showSearch notFoundContent="No slots available" disabled={!(scoreboardDet.court && scoreboardDet.date)} onChange={(e) => onUpdate(e, 'slot')} value={scoreboardDet.slot} options={["1:30 PM", "2:30 PM", "5:30 PM"].map(ee => ({ label: ee, value: ee }))} placeholder="Select slot" />
          </div> */}
            <div className="form-action">
              <Button
                onClick={() => onProceed()}
                disabled={!(scoreboardDet.court && scoreboardDet.match)}
                type="primary"
              >
                Start Game
              </Button>
            </div>
          </div>
        </div>
      )}

      {!loading && currentMatch?.status === "Assigned" && (
        <>
          <div className="select-players">
            <div className="home-players">
              <div className="h-p-title">
                {currentMatch?.home?.team?.name}
                <Button
                  onClick={() =>
                    setPlayerAddInfo({ visible: true, type: "home" })
                  }
                  type="primary"
                  className="ml-2"
                  icon={<UsergroupAddOutlined />}
                >
                  Add Player
                </Button>
              </div>
              <Table
                // rowSelection={{
                //   type: "checkbox",
                //   onChange: (selectedRowKeys, selectedRows) =>
                //     onCheckPlayer("home", selectedRowKeys, selectedRows),
                // }}
                columns={[
                  {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    render: (text, record) => (
                      <div className="pl-name">
                        {" "}
                        {record.is_captain && (
                          <Tag color="#12344d">Manager</Tag>
                        )}{" "}
                        {getFullName(record?.player)}{" "}
                      </div>
                    ),
                  },
                  {
                    title: "Jersey N.",
                    key: "jersey_no",
                    width: "200px",
                    dataIndex: "jersey_no",
                    render: (text, record, key) => (
                      <Form.Item
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            onChangeJerseyNo(e.target.value, "home", key)
                          }
                          onBlur={(e) =>
                            onBlurJerseyNo(e.target.value, "home", key)
                          }
                          className="jersey_edit"
                          key={record._id}
                          value={record.jersey_no}
                          type="number"
                          suffix={
                            duplicatesh.includes(key) ? (
                              <Tooltip title="Duplicate Jersey">
                                <InfoCircleOutlined
                                  style={{ color: "#AA4A44" }}
                                />
                              </Tooltip>
                            ) : undefined
                          }
                        />
                      </Form.Item>
                    ),
                  },
                  {
                    title: "Player Lineup",
                    key: "lineup",
                    width: "150px",
                    dataIndex: "lineup",
                    render: (text, record, key) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <span>
                          <Button
                            style={
                              (
                                currentMatch?.home?.selectedPlayers || []
                              ).indexOf(record._id) !== -1
                                ? { borderRadius: "0px" }
                                : {
                                    borderRadius: "0px",
                                    backgroundColor: "#12344d",
                                    color: "#fff",
                                  }
                            }
                            onClick={(e) => {
                              if (
                                !(
                                  (
                                    currentMatch?.home?.selectedPlayers || []
                                  ).indexOf(record._id) === -1
                                )
                              ) {
                                onChangeStartingLineup(false, "home", key);
                                onCheckPlayeronein("home", record.key);
                              }
                            }}
                            // disabled={(currentMatch?.home?.selectedPlayers || []).indexOf(
                            //   record._id
                            // ) === -1}
                          >
                            DNP
                          </Button>
                        </span>

                        <span>
                          <Button
                            style={
                              (
                                currentMatch?.home?.selectedPlayers || []
                              ).indexOf(record._id) !== -1 &&
                              !record.is_starting_lineup
                                ? {
                                    borderRadius: "0px",
                                    backgroundColor: "#12344d",
                                    color: "#fff",
                                  }
                                : { borderRadius: "0px" }
                            }
                            onClick={(e) => {
                              if (
                                !(
                                  !record.is_starting_lineup &&
                                  (
                                    currentMatch?.home?.selectedPlayers || []
                                  ).indexOf(record._id) !== -1
                                )
                              ) {
                                onChangeStartingLineup(false, "home", key);
                                onCheckPlayerone("home", record.key);
                              }
                            }}
                            // disabled={
                            //   !(record.is_starting_lineup) && (currentMatch?.home?.selectedPlayers || []).indexOf(
                            //     record._id
                            //   ) !== -1}
                          >
                            Bench
                          </Button>
                        </span>

                        <span>
                          <Button
                            style={
                              (
                                currentMatch?.home?.selectedPlayers || []
                              ).indexOf(record._id) !== -1 &&
                              record.is_starting_lineup
                                ? {
                                    borderRadius: "0px",
                                    backgroundColor: "#12344d",
                                    color: "#fff",
                                  }
                                : { borderRadius: "0px" }
                            }
                            onClick={(e) => {
                              if (
                                !(
                                  currentMatch?.home?.players
                                    ?.filter(
                                      (ee) =>
                                        currentMatch?.home?.selectedPlayers?.indexOf(
                                          ee._id
                                        ) >= 0
                                    )
                                    .map((ee) => ({
                                      player: ee._id,
                                      jersey_no: ee.jersey_no,
                                      is_starting_lineup: ee.is_starting_lineup,
                                    }))
                                    ?.filter(
                                      (x) => x?.is_starting_lineup === true
                                    )?.length >= 5 && !record.is_starting_lineup
                                )
                              ) {
                                onChangeStartingLineup(true, "home", key);
                                onCheckPlayerone("home", record.key);
                              }
                            }}
                            // disabled={
                            //     (
                            //     (currentMatch?.home?.players
                            //       ?.filter(
                            //         (ee) =>
                            //           currentMatch?.home?.selectedPlayers?.indexOf(
                            //             ee._id
                            //           ) >= 0
                            //       )
                            //       .map((ee) => ({
                            //         player: ee._id,
                            //         jersey_no: ee.jersey_no,
                            //         is_starting_lineup: ee.is_starting_lineup,
                            //       }))
                            //       ?.filter((x) => x?.is_starting_lineup === true)
                            //       ?.length >= 5 &&
                            //       !record.is_starting_lineup))
                            //   }
                          >
                            Starting
                          </Button>
                        </span>
                      </div>
                    ),
                  },
                ]}
                dataSource={currentMatch?.home?.players?.map((ee, index) => ({
                  ...ee,
                  key: ee._id,
                  index: index,
                }))}
                pagination={false}
              />
            </div>
            {console.log(currentMatch,'currentMatch2222')}
            <div className="away-players">
              <div className="h-p-title">
                {currentMatch?.away?.team?.name}
                <Button
                  onClick={() =>
                    setPlayerAddInfo({ visible: true, type: "away" })
                  }
                  type="primary"
                  className="ml-2"
                  icon={<UsergroupAddOutlined />}
                >
                  Add Player
                </Button>
              </div>
              <Table
                // rowSelection={{
                //   type: "checkbox",
                //   onChange: (selectedRowKeys, selectedRows) =>
                //     onCheckPlayer("away", selectedRowKeys, selectedRows),
                // }}
                columns={[
                  {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    render: (text, record) => (
                      <div className="pl-name">
                        {" "}
                        {record.is_captain && (
                          <Tag color="#12344d">Manager</Tag>
                        )}{" "}
                        {getFullName(record?.player)}{" "}
                      </div>
                    ),
                  },
                  {
                    title: "Jersey No.",
                    key: "jersey_no",
                    width: "200px",
                    dataIndex: "jersey_no",
                    render: (text, record, key) => (
                      <Input
                        onChange={(e) =>
                          onChangeJerseyNo(e.target.value, "away", key)
                        }
                        onBlur={(e) =>
                          onBlurJerseyNo(e.target.value, "away", key)
                        }
                        className="jersey_edit"
                        key={record._id}
                        value={record.jersey_no}
                        type="number"
                        suffix={
                          duplicatesa.includes(key) ? (
                            <Tooltip title="Duplicate Jersey">
                              <InfoCircleOutlined
                                style={{ color: "#AA4A44" }}
                              />
                            </Tooltip>
                          ) : undefined
                        }
                      />
                    ),
                  },
                  // {
                  //   title: "Starting Lineup",
                  //   key: "lineup",
                  //   width: "150px",
                  //   dataIndex: "lineup",
                  //   render: (text, record, key) => (
                  //     <Checkbox
                  //       onChange={(e) =>
                  //         onChangeStartingLineup(e.target.checked, "away", key)
                  //       }
                  //       disabled={
                  //         (currentMatch?.away?.selectedPlayers || []).indexOf(
                  //           record._id
                  //         ) === -1 ||
                  //         (currentMatch?.away?.players
                  //           ?.filter(
                  //             (ee) =>
                  //               currentMatch?.away?.selectedPlayers?.indexOf(
                  //                 ee._id
                  //               ) >= 0
                  //           )
                  //           .map((ee) => ({
                  //             player: ee._id,
                  //             jersey_no: ee.jersey_no,
                  //             is_starting_lineup: ee.is_starting_lineup,
                  //           }))
                  //           ?.filter((x) => x?.is_starting_lineup === true)
                  //           ?.length >= 5 &&
                  //           !record.is_starting_lineup)
                  //       }
                  //       className="c-b-t"
                  //       key={record._id}
                  //       checked={record.is_starting_lineup}
                  //     />
                  //   ),
                  // },

                  {
                    title: "Player Lineup",
                    key: "lineup",
                    width: "150px",
                    dataIndex: "lineup",
                    render: (text, record, key) => (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <span>
                          <Button
                            style={
                              (
                                currentMatch?.away?.selectedPlayers || []
                              ).indexOf(record._id) !== -1
                                ? { borderRadius: "0px" }
                                : {
                                    borderRadius: "0px",
                                    backgroundColor: "#12344d",
                                    color: "#fff",
                                  }
                            }
                            onClick={(e) => {
                              if (
                                !(
                                  (
                                    currentMatch?.away?.selectedPlayers || []
                                  ).indexOf(record._id) === -1
                                )
                              ) {
                                onChangeStartingLineup(false, "away", key);
                                onCheckPlayeronein("away", record.key);
                              }
                            }}
                            // disabled={(currentMatch?.away?.selectedPlayers || []).indexOf(
                            //   record._id
                            // ) === -1}
                          >
                            DNP
                          </Button>
                        </span>

                        <span>
                          <Button
                            style={
                              (
                                currentMatch?.away?.selectedPlayers || []
                              ).indexOf(record._id) !== -1 &&
                              !record.is_starting_lineup
                                ? {
                                    borderRadius: "0px",
                                    backgroundColor: "#12344d",
                                    color: "#fff",
                                  }
                                : { borderRadius: "0px" }
                            }
                            onClick={(e) => {
                              if (
                                !(
                                  !record.is_starting_lineup &&
                                  (
                                    currentMatch?.away?.selectedPlayers || []
                                  ).indexOf(record._id) !== -1
                                )
                              ) {
                                onChangeStartingLineup(false, "away", key);
                                onCheckPlayerone("away", record.key);
                              }
                            }}
                            // disabled={
                            //   !(record.is_starting_lineup) && (currentMatch?.away?.selectedPlayers || []).indexOf(
                            //     record._id
                            //   ) !== -1}
                          >
                            Bench
                          </Button>
                        </span>

                        <span>
                          <Button
                            style={
                              (
                                currentMatch?.away?.selectedPlayers || []
                              ).indexOf(record._id) !== -1 &&
                              record.is_starting_lineup
                                ? {
                                    borderRadius: "0px",
                                    backgroundColor: "#12344d",
                                    color: "#fff",
                                  }
                                : { borderRadius: "0px" }
                            }
                            onClick={(e) => {
                              if (
                                !(
                                  currentMatch?.away?.players
                                    ?.filter(
                                      (ee) =>
                                        currentMatch?.away?.selectedPlayers?.indexOf(
                                          ee._id
                                        ) >= 0
                                    )
                                    .map((ee) => ({
                                      player: ee._id,
                                      jersey_no: ee.jersey_no,
                                      is_starting_lineup: ee.is_starting_lineup,
                                    }))
                                    ?.filter(
                                      (x) => x?.is_starting_lineup === true
                                    )?.length >= 5 && !record.is_starting_lineup
                                )
                              ) {
                                onChangeStartingLineup(true, "away", key);
                                onCheckPlayerone("away", record.key);
                              }
                            }}
                            // disabled={
                            //     (
                            //     (currentMatch?.away?.players
                            //       ?.filter(
                            //         (ee) =>
                            //           currentMatch?.away?.selectedPlayers?.indexOf(
                            //             ee._id
                            //           ) >= 0
                            //       )
                            //       .map((ee) => ({
                            //         player: ee._id,
                            //         jersey_no: ee.jersey_no,
                            //         is_starting_lineup: ee.is_starting_lineup,
                            //       }))
                            //       ?.filter((x) => x?.is_starting_lineup === true)
                            //       ?.length >= 5 &&
                            //       !record.is_starting_lineup))
                            //   }
                          >
                            Starting
                          </Button>
                        </span>
                      </div>
                    ),
                  },
                ]}
                dataSource={currentMatch?.away?.players.map((ee) => ({
                  ...ee,
                  key: ee._id,
                }))}
                pagination={false}
              />
            </div>
          </div>
          <div className="p-form-action">
            <Button onClick={() => onCancelMatch()} type="primary">
              Leave
            </Button>
            <Button
              className="ml-2"
              onClick={() => onStartMatch()}
              type="primary"
            >
              Enter Stats
            </Button>
          </div>
        </>
      )}

      {!loading && currentMatch?.status === "Started" && (
        <div className="scoreboard">
          <div className={`scoreboard-top ${teamSwap ? " swap-team" : ""}`}>
            <div
              onClick={() => onOpenPicker("home")}
              style={{
                backgroundColor:
                  currentMatch?.home?.changed_jersey_color || "#ddd",
              }}
              className="scoreboard-left"
            >
              {currentMatch?.away?.personal_fouls > 6 && (
                <div
                  style={{
                    backgroundColor: "#003087",
                    color: "#fff",
                    marginTop: "-42px",
                    minHeight: "30px",
                    height: "30px",
                    paddingTop: "2px",
                    fontSize: "16px",
                    textAlign: "center",
                    transform: "scaleX(1.07)",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  {currentMatch?.away?.personal_fouls < 10
                    ? "Bonus"
                    : "Double Bonus"}
                </div>
              )}
              <div className="team-name">{currentMatch?.home?.team?.name}</div>
              <div className="team-logo">
                <img
                  src={currentMatch?.home?.team?.logo || "/logos/logo.png"}
                  alt=""
                />
              </div>
              {currentMatch?.home?.team?.openPicker && (
                <div className="sketch-picker-comp">
                  <SketchPicker
                    onChangeComplete={(e) => onChangeColor(e, "home")}
                    color={currentMatch?.home?.changed_jersey_color}
                  />
                </div>
              )}
            </div>
            <div className="scoreboard-center">
              <div className="scores-left">
                <div className="team-name">
                  {teamSwap
                    ? currentMatch?.away?.team?.name
                    : currentMatch?.home?.team?.name}
                </div>
                <div className="scores">
                  <Popover
                    overlayClassName="pop-score"
                    title={currentMatch?.home?.team?.name}
                    trigger="click"
                    visible={
                      currentMatch?.home?.hide === true &&
                      currentMatch?.home?.scorePopVisible
                    }
                    onVisibleChange={(e) => {
                      if (currentMatch?.home?.hide === true) {
                        currentMatch.home.scorePopVisible = e;
                      } else {
                        currentMatch.home.scorePopVisible = false;
                      }
                      setCurrentMatch(currentMatch);
                    }}
                    content={
                      <ScoreUpdate
                        score={currentMatch?.home?.score}
                        type="home"
                      />
                    }
                  >
                    <span>
                      {teamSwap
                        ? currentMatch?.away?.score
                        : currentMatch?.home?.score || 0}
                    </span>
                  </Popover>
                  <Tooltip title="Team Foul">
                    <Tag
                      color="cyan"
                      style={{
                        marginLeft: "15px",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        {teamSwap
                          ? currentMatch?.away?.personal_fouls
                          : currentMatch?.home?.personal_fouls}
                      </span>
                    </Tag>
                  </Tooltip>
                  {Boolean(currentMatch.is_halftime_taken) && (
                    <Tooltip title="Half Time Foul">
                      <Tag
                        color="yellow"
                        style={{
                          transform: "translate(-37px, -30px)",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {teamSwap
                            ? (Boolean(teamawayfoul) ? teamawayfoul : 0) -
                              (Boolean(currentMatch?.away?.personal_fouls)
                                ? currentMatch?.away?.personal_fouls
                                : 0)
                            : (Boolean(teamhomefoul) ? teamhomefoul : 0) -
                              (Boolean(currentMatch?.home?.personal_fouls)
                                ? currentMatch?.home?.personal_fouls
                                : 0)}
                        </span>
                      </Tag>
                    </Tooltip>
                  )}
                </div>

                <div className="timeout-list">
                  {!currentMatch.is_halftime_taken && (
                    <>
                      <span
                        className={`timeout-list-item mr-1${
                          currentMatch?.[teamSwap ? "away" : "home"]
                            ?.timeout_before_halftime >= 1
                            ? ""
                            : " selected"
                        }`}
                      />
                      <span
                        className={`timeout-list-item${
                          currentMatch?.[teamSwap ? "away" : "home"]
                            ?.timeout_before_halftime >= 2
                            ? ""
                            : " selected"
                        }`}
                      />
                    </>
                  )}
                  {currentMatch.is_halftime_taken && (
                    <>
                      <span
                        className={`timeout-list-item mr-1${
                          currentMatch?.[teamSwap ? "away" : "home"]
                            ?.timeout_after_halftime >= 1
                            ? ""
                            : " selected"
                        }`}
                      />
                      <span
                        className={`timeout-list-item${
                          currentMatch?.[teamSwap ? "away" : "home"]
                            ?.timeout_after_halftime >= 2
                            ? ""
                            : " selected"
                        }`}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="timings">
                <div className="m-swap" onClick={() => onSwapTeam()}>
                  <FaExchangeAlt size={20} />
                </div>
                <div className="m-time">10:00</div>
                <div className="m-list">
                  <Button
                    disabled={
                      currentMatch?.home?.timeout_before_halftime === 2 &&
                      currentMatch?.away?.timeout_before_halftime === 2 &&
                      currentMatch?.home?.timeout_after_halftime === 2 &&
                      currentMatch?.away?.timeout_after_halftime === 2
                    }
                    onClick={() => onClickTimeout()}
                    className="m-list-item"
                  >
                    Timeout
                  </Button>
                  <Button
                    disabled={currentMatch?.status === "Completed"}
                    onClick={() => onClickHalftimeOrComplete()}
                    className="m-list-item ml-3"
                  >
                    {currentMatch?.status !== "Completed" &&
                      currentMatch?.is_halftime_taken &&
                      "Final"}
                    {currentMatch?.status !== "Completed" &&
                      !currentMatch?.is_halftime_taken &&
                      "Halftime"}
                    {currentMatch?.status === "Completed" && "Completed"}
                  </Button>
                  <br />
                </div>
                {/* <div className='m-Leave m-list'>
                <Button onClick={() => onCancelMatch()} className='m-list-item'>Leave Game</Button>
              </div> */}
              </div>
              <div className="scores-right">
                <div className="team-name">
                  {teamSwap
                    ? currentMatch?.home?.team?.name
                    : currentMatch?.away?.team?.name}
                </div>
                <div className="scores">
                  {Boolean(currentMatch.is_halftime_taken) && (
                    <Tooltip title="Half Time Foul">
                      <Tag
                        color="yellow"
                        style={{
                          transform: "translate(37px, -30px)",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {teamSwap
                            ? (Boolean(teamhomefoul) ? teamhomefoul : 0) -
                              (Boolean(currentMatch?.home?.personal_fouls)
                                ? currentMatch?.home?.personal_fouls
                                : 0)
                            : (Boolean(teamawayfoul) ? teamawayfoul : 0) -
                              (Boolean(currentMatch?.away?.personal_fouls)
                                ? currentMatch?.away?.personal_fouls
                                : 0)}
                        </span>
                      </Tag>
                    </Tooltip>
                  )}
                  <Tooltip title="Team Foul">
                    <Tag
                      color="cyan"
                      style={{
                        marginRight: "15px",
                        width: "30px",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        {teamSwap
                          ? currentMatch?.home?.personal_fouls
                          : currentMatch?.away?.personal_fouls}
                      </span>
                    </Tag>
                  </Tooltip>
                  <Popover
                    overlayClassName="pop-score"
                    title={currentMatch?.away?.team?.name}
                    trigger="click"
                    visible={
                      currentMatch?.away?.hide === true &&
                      currentMatch?.away?.scorePopVisible
                    }
                    onVisibleChange={(e) => {
                      if (currentMatch?.away?.hide === true) {
                        currentMatch.away.scorePopVisible = e;
                      } else {
                        currentMatch.away.scorePopVisible = false;
                      }
                      setCurrentMatch(currentMatch);
                    }}
                    content={
                      <ScoreUpdate
                        score={currentMatch?.away?.score}
                        type="away"
                      />
                    }
                  >
                    <span>
                      {teamSwap
                        ? currentMatch?.home?.score
                        : currentMatch?.away?.score || 0}
                    </span>
                  </Popover>
                </div>
                <div className="timeout-list">
                  {!currentMatch.is_halftime_taken && (
                    <>
                      <span
                        className={`timeout-list-item mr-1${
                          currentMatch?.[teamSwap ? "home" : "away"]
                            ?.timeout_before_halftime >= 1
                            ? ""
                            : " selected"
                        }`}
                      />
                      <span
                        className={`timeout-list-item${
                          currentMatch?.[teamSwap ? "home" : "away"]
                            ?.timeout_before_halftime >= 2
                            ? ""
                            : " selected"
                        }`}
                      />
                    </>
                  )}
                  {currentMatch.is_halftime_taken && (
                    <>
                      <span
                        className={`timeout-list-item mr-1${
                          currentMatch?.[teamSwap ? "home" : "away"]
                            ?.timeout_after_halftime >= 1
                            ? ""
                            : " selected"
                        }`}
                      />
                      <span
                        className={`timeout-list-item${
                          currentMatch?.[teamSwap ? "home" : "away"]
                            ?.timeout_after_halftime >= 2
                            ? ""
                            : " selected"
                        }`}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => onOpenPicker("away")}
              style={{
                backgroundColor:
                  currentMatch?.away?.changed_jersey_color || "#ddd",
              }}
              className="scoreboard-right"
            >
              <div className="team-logo">
                {currentMatch?.home?.personal_fouls > 6 && (
                  <div
                    style={{
                      backgroundColor: "#003087",
                      color: "#fff",
                      marginTop: "-42px",
                      minHeight: "30px",
                      height: "30px",
                      paddingTop: "2px",
                      fontSize: "16px",
                      textAlign: "center",
                      transform: "scaleX(1.07)",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                    }}
                  >
                    {currentMatch?.home?.personal_fouls < 10
                      ? "Bonus"
                      : "Double Bonus"}
                  </div>
                )}
                <div className="team-name">
                  {currentMatch?.away?.team?.name}
                </div>
                <div className="team-logo">
                  <img src={currentMatch?.away?.team?.logo} alt="Team Name" />
                </div>
                {currentMatch?.away?.team?.openPicker && (
                  <div className="sketch-picker-comp">
                    <SketchPicker
                      onChangeComplete={(e) => onChangeColor(e, "away")}
                      color={currentMatch?.away?.changed_jersey_color}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`scoreboard-bottom ${
              teamSwap ? " swap-team-player" : ""
            }`}
          >
            <div className="scoreboard-teams-left">
              <div className="extra-block">
                <div onClick={() => onHideTeam("home")} className="hide-eye">
                  {!currentMatch?.home?.hide ? (
                    <AiFillEyeInvisible />
                  ) : (
                    <AiFillEye />
                  )}
                </div>
                <div
                  onClick={() => onAddMatchPlayer("home", true)}
                  className="add-p"
                >
                  <MdGroupAdd />
                </div>
                <div
                  onClick={() => setopenhomeplayergetin(true)}
                  className="add-p"
                  style={{ marginTop: "-15px" }}
                >
                  <UserAddOutlined style={{ fontSize: "20px" }} />
                </div>
                <Modal
                  visible={openhomeplayergetin}
                  cancelButtonProps={{ hidden: true }}
                  okButtonProps={{ hidden: true }}
                  onCancel={() => setopenhomeplayergetin(false)}
                  title={
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Get In a Player
                    </div>
                  }
                >
                  <PopContent
                    type="home"
                    onSelect={onSelectinBenchPlayer}
                    hidePopup={true}
                    hideswap={true}
                  />
                </Modal>
              </div>
              <div
                className={`team-list${
                  selectedMember && selectedMember?.type === "away"
                    ? " disabled1"
                    : ""
                }`}
              >
                {!currentMatch?.home?.hide &&
                  currentMatch.home?.players
                    ?.sort((a, b) => a.order - b.order)
                    .filter((team) => team.is_starting_lineup)
                    .map((team, k) => (
                      <div key={k.toString()}>
                        {teamSwap && (
                          <div style={{ maxWidth: "20px" }}>
                            {!team.selected && (
                              <Popover
                                title={
                                  <div
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Replace for {selectedMember?.jersey_no}
                                  </div>
                                }
                                content={
                                  <PopContent
                                    type="home"
                                    onSelect={onSelectBenchPlayer}
                                  />
                                }
                                trigger="click"
                                // placement={k > 3 ? "rightBottom" : 'right'}
                                placement={teamSwap ? "left" : "right"}
                                //visible={team?.swap}
                              >
                                <div
                                  onClick={(e) => {
                                    onSwapPlayer(team, "home", e);
                                  }}
                                  style={{
                                    transform: "translate(-30px,30px)",
                                    maxHeight: "0px",
                                  }}
                                >
                                  <FaExchangeAlt size={25} />
                                </div>
                              </Popover>
                            )}
                          </div>
                        )}
                        <div
                          onClick={(e) => onSelectMember(team, "home", e)}
                          className={`team-item${
                            team.selected || team.swap ? " selected" : ""
                          }`}
                        >
                          <div className="team-avatar">
                            {/* {team?.player?.profile_pic && <img src={team?.player?.profile_pic} alt='' />}
                      {!team?.player?.profile_pic && <span>{getShortName(team?.player)}</span>} */}
                            {team?.personal_fouls > 0 && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  left: "3px",
                                  borderRadius: "50%",
                                  fontSize: "10px",
                                  width: "16px",
                                  paddingTop: "1px",
                                  textAlign: "center",
                                  background: "#AA0000",
                                }}
                              >
                                {team?.personal_fouls}
                              </span>
                            )}
                            <div className="p-name">
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.first_name}
                              </span>
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.last_name}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              background:
                                currentMatch?.home?.changed_jersey_color ||
                                "#ddd",
                            }}
                            className="team-no"
                          >
                            <span style={{
                                background:
                                  currentMatch?.home?.changed_jersey_color ||
                                  "#ddd",
                                color:
                                  currentMatch?.home?.changed_jersey_color ===
                                  "#000000"
                                    ? "#fff"
                                    : undefined,
                              }}>{team?.jersey_no}</span>
                          </div>
                        </div>
                        {!teamSwap && (
                          <div style={{ maxWidth: "20px" }}>
                            <Modal
                              title={
                                <div
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Replace for {selectedMember?.jersey_no}
                                </div>
                              }
                              visible={open}
                              cancelButtonProps={{ hidden: true }}
                              okButtonProps={{ hidden: true }}
                              onCancel={() => setOpen(false)}
                            >
                              <PopContent
                                type="home"
                                onSelect={onSelectBenchPlayer}
                                hidePopup={true}
                              />
                            </Modal>
                            {!team.selected && (
                              <Popover
                                // title={<div style={{ fontSize: "16px", fontWeight: "600" }}>Replace fo {selectedMember?.jersey_no}</div>}
                                // content={<PopContent type="home" onSelect={onSelectBenchPlayer} />}
                                trigger="click"
                                //onOpenChange={setOpen}
                                // placement={k > 3 ? "rightBottom" : 'right'}
                                placement={teamSwap ? "left" : "right"}
                                //visible={team?.swap}
                              >
                                <div
                                  onClick={(e) => {
                                    onSwapPlayer(team, "home", e);
                                    setOpen(true);
                                  }}
                                  style={{
                                    transform: "translate(200px,-50px)",
                                    maxHeight: "0px",
                                  }}
                                >
                                  <FaExchangeAlt size={25} />
                                </div>
                              </Popover>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </div>
            <div className="scoreboard-teams-center">
              <PopoverContent
                popVisible={selectedMember?.selected && !selectedMember?.swap}
              />
            </div>
            <div className="scoreboard-teams-right">
              <div className="extra-block">
                <div
                  onClick={() => onHideTeam("away")}
                  aria-hidden
                  className="hide-eye"
                >
                  {!currentMatch?.away?.hide ? (
                    <AiFillEyeInvisible />
                  ) : (
                    <AiFillEye />
                  )}
                </div>
                <div
                  onClick={() => onAddMatchPlayer("away", true)}
                  className="add-p"
                >
                  <MdGroupAdd />
                </div>
                <div
                  onClick={() => setopenawayplayergetin(true)}
                  className="add-p"
                  style={{ marginTop: "-15px" }}
                >
                  <UserAddOutlined style={{ fontSize: "20px" }} />
                </div>
                <Modal
                  visible={openawayplayergetin}
                  cancelButtonProps={{ hidden: true }}
                  okButtonProps={{ hidden: true }}
                  onCancel={() => setopenawayplayergetin(false)}
                  title={
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Get In a Player
                    </div>
                  }
                >
                  <PopContent
                    type="away"
                    onSelect={onSelectinBenchPlayer}
                    hidePopup={true}
                    hideswap={true}
                  />
                </Modal>
              </div>
              <div
                className={`team-list${
                  selectedMember && selectedMember?.type === "home"
                    ? " disabled1"
                    : ""
                }`}
              >
                {!currentMatch?.away?.hide &&
                  currentMatch.away?.players
                    ?.sort((a, b) => a.order - b.order)
                    .filter((team) => team.is_starting_lineup)
                    .map((team, k) => (
                      <div>
                        {!teamSwap && (
                          <div style={{ maxWidth: "20px" }}>
                            <Modal
                              visible={openaway}
                              cancelButtonProps={{ hidden: true }}
                              okButtonProps={{ hidden: true }}
                              onCancel={() => setOpenaway(false)}
                              title={
                                <div
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Replace for {selectedMember?.jersey_no}
                                </div>
                              }
                            >
                              <PopContent
                                type="away"
                                onSelect={onSelectBenchPlayer}
                                hidePopup={true}
                              />
                            </Modal>
                            {!team.selected && (
                              <Popover
                                //title={<div style={{ fontSize: "16px", fontWeight: "600" }}>Replace for {selectedMember?.jersey_no}</div>}
                                //content={<PopContent type="away" onSelect={onSelectBenchPlayer} />}
                                trigger="click"
                                //visible={openaway}
                                // placement={k > 3 ? "leftBottom" : 'left'}
                                placement={teamSwap ? "right" : "left"}
                                //visible={team?.swap}
                              >
                                <div
                                  onClick={(e) => {
                                    onSwapPlayer(team, "away", e);
                                    setOpenaway(true);
                                  }}
                                  style={{
                                    transform: "translate(-30px,30px)",
                                    maxHeight: "0px",
                                  }}
                                >
                                  <FaExchangeAlt size={25} />
                                </div>
                              </Popover>
                            )}
                          </div>
                        )}
                        <div
                          onClick={(e) => onSelectMember(team, "away", e)}
                          key={k.toString()}
                          className={`team-item${
                            team.selected || team.swap ? " selected" : ""
                          }`}
                        >
                          <div className="team-avatar">
                            {/* {team?.player?.profile_pic && <img src={team?.player?.profile_pic} alt='' />}
                      {!team?.player?.profile_pic && <span>{getShortName(team?.player)}</span>} */}
                            {team?.personal_fouls > 0 && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "3px",
                                  borderRadius: "50%",
                                  fontSize: "10px",
                                  width: "16px",
                                  paddingTop: "1px",
                                  textAlign: "center",
                                  background: "#AA0000",
                                }}
                              >
                                {team?.personal_fouls}
                              </span>
                            )}
                            <div className="p-name">
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.first_name}
                              </span>
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.last_name}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              background:
                                currentMatch?.away?.changed_jersey_color ||
                                "#ddd",
                            }}
                            className="team-no"
                          >
                            <span style={{
                                background:
                                  currentMatch?.away?.changed_jersey_color ||
                                  "#ddd",
                                color:
                                  currentMatch?.away?.changed_jersey_color ===
                                  "#000000"
                                    ? "#fff"
                                    : undefined,
                              }}>{team?.jersey_no}</span>
                          </div>
                        </div>
                        {teamSwap && (
                          <div style={{ maxWidth: "20px" }}>
                            {!team.selected && (
                              <Popover
                                title={
                                  <div
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Replace for {selectedMember?.jersey_no}
                                  </div>
                                }
                                content={
                                  <PopContent
                                    type="away"
                                    onSelect={onSelectBenchPlayer}
                                  />
                                }
                                trigger="click"
                                // placement={k > 3 ? "leftBottom" : 'left'}
                                placement={teamSwap ? "right" : "left"}
                                //visible={team?.swap}
                              >
                                <div
                                  onClick={(e) => onSwapPlayer(team, "away", e)}
                                  style={{
                                    transform: "translate(200px,-50px)",
                                    maxHeight: "0px",
                                  }}
                                >
                                  <FaExchangeAlt size={25} />
                                </div>
                              </Popover>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        visible={pfModalInfo?.visible}
        // onCancel={()=>setPfModalInfo({
        //   visible: false,
        // })}
        closable={false}
        closeIcon={null}
        footer={null}
        centered
        title={
          <div className="replace-title">
            Replace a player or No.{pfModalInfo?.player?.jersey_no}
            <span>
              You can't continue with No.{pfModalInfo?.player?.jersey_no},
              because he exceeds a personal foul limit.
            </span>
          </div>
        }
      >
        <PopContent type={pfModalInfo?.type} onSelect={onSelectBenchPlayer} />
      </Modal>
      <Modal
        visible={astModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        centered
        title={
          <div className="replace-title">
            Who do you want to select for Assists?
          </div>
        }
      >
        <SelectAssists type={astModalInfo?.type} onSelect={onSelectAstPlayer} />
      </Modal>
      <Modal
        visible={toModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        className="reb_popup"
        centered
        title={
          <div className="replace-title">
            Who do you want to select for Steal?
          </div>
        }
      >
        <Selectto type={toModalInfo?.type} onSelect={onSelectToPlayer} />
      </Modal>
      <Modal
        visible={rebModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        className="reb_popup"
        centered
        title={
          <div className="replace-title">
            Who do you want to select for Rebound?
          </div>
        }
      >
        <SelectRebounds
          type={rebModalInfo?.type}
          onSelect={onSelectReboundsPlayer}
        />
      </Modal>
      <Drawer
        title={
          remainingPlayerInfo.visible
            ? currentMatch[remainingPlayerInfo.type]?.team?.name
            : ""
        }
        visible={remainingPlayerInfo.visible}
        onClose={() => onAddMatchPlayer("", false)}
        footer={
          <div
            style={{ textAlign: "center", paddingBottom: "12px" }}
            className="footer-form"
          >
            <Button
              disabled={
                remainingPlayerInfo.visible &&
                !(
                  currentMatch[remainingPlayerInfo.type]?.selectedRemPlayers
                    ?.length > 0
                )
              }
              onClick={onSubmitMatchPlayers}
              type="primary"
            >
              Add Players
            </Button>
          </div>
        }
      >
        {remainingPlayerInfo.visible && (
          <Table
            pagination={false}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) =>
                onCheckRemPlayer(
                  remainingPlayerInfo.type,
                  selectedRowKeys,
                  selectedRows
                ),
            }}
            columns={[
              {
                title: "Name",
                key: "name",
                dataIndex: "name",
                render: (text, record) => getFullName(record.player),
              },
              {
                title: "Jersey No.",
                key: "jersey_no",
                width: "200px",
                dataIndex: "jersey_no",
                render: (text, record, key) => (
                  <Input
                    onChange={(e) =>
                      onChangeRemJerseyNo(
                        e.target.value,
                        remainingPlayerInfo?.type,
                        key
                      )
                    }
                    className="jersey_edit"
                    key={record._id}
                    value={record.jersey_no}
                  />
                ),
              },
            ]}
            dataSource={(
              currentMatch[remainingPlayerInfo.type]?.remaining_players || []
            ).map((ee) => ({ ...ee, key: ee._id }))}
          />
        )}
      </Drawer>
      <Drawer
        title={
          playerAddInfo.visible
            ? currentMatch[playerAddInfo.type]?.team?.name
            : ""
        }
        visible={playerAddInfo.visible}
        onClose={() => {
          setPlayerAddInfo({ visible: false, type: "" });
          setPlayers([]);
          form.setFieldsValue({
            player: undefined,
            jersey_no: undefined,
          });
        }}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onAddPlayerTeam}
        >
          <Form.Item className="loader-form-block">
            <Form.Item
              name="player"
              label="Select Player"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {playerAddInfo.visible && (
                <AutoComplete
                  options={geOptions(players)}
                  onSelect={onSelectOwner}
                  onSearch={(e) => debouncedSearch(e)}
                  dropdownClassName="mobile-autocomplete"
                >
                  <Input placeholder="Search player by name or email" />
                </AutoComplete>
              )}
              {searching && (
                <Form.Item className="loader-form-item" noStyle>
                  <span className="input-loader">
                    <LoadingOutlined />
                  </span>
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="jersey_no"
            label="Jersey Number"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input maxLength={3} placeholder="Enter a jersey number" />
          </Form.Item>
          <div style={{ textAlign: "right", marginTop: "16px" }}>
            <Button loading={adding} type="primary" htmlType="submit">
              Add Player
            </Button>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};
export default ScoreKeeperPage;
