import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, Spin } from 'antd'
import ApiConfig from '../../../services/ApiConfig'
import apiClient from '../../../services/axios';
import apiFormDataClient from '../../../services/axios-formdata'
import { getDateFormat, tcblEmailList, pdexEmailList, recipientArray } from "../../../utils";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
// import PageLoader from "../../../components/page-loader";

const Option = Select

const DraftCampaign = ({ currentItem, onFinish }) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadedImage, setloadedImage] = useState(null);
  const [rawHtml, setrawHtml] = useState(null);
  const [isDraftDocs, setisDraftDocs] = useState(false);
  // const [currentState, setcurrentState] = useState();
  const [playersCount, setplayersCount] = useState(null);
  const [showEmailField, setshowEmailField] = useState(false);
  const [showEmailCount, setshowEmailCount] = useState(false);
  const [currentRecipient, setcurrentRecipient] = useState(null);
  const [currentDate] = useState(getDateFormat({ date: new Date(), format: "ddd, MMM DD, YYYY" }));

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const text = draftToHtml(convertToRaw(editorState?.getCurrentContent()))

  const imageUploadCallback = async (values) => {
    const formdata = new FormData();
    formdata.append('promotion_image', values);
    try {
      // setLoading(true);
      const response = await apiFormDataClient.post(ApiConfig.website.savePromotionImage, formdata);
      setloadedImage(response?.data?.imagePath)
      return new Promise((resolve, reject) => {
        resolve({ data: { link: response?.data?.imagePath } });
      })
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  }

  const onFinishApiCall = (values) => {
    const formdata = new FormData();
    formdata.append('date', currentDate);
    formdata.append('subject', values.subject);
    formdata.append('editor', text);
    formdata.append('recipient', currentRecipient);
    formdata.append('deleting_image_url', loadedImage);
    formdata.append('is_draft', isDraftDocs);
    onFinish(formdata)
  }

  useEffect(() => {
    const contentBlock = htmlToDraft(currentItem?.editor);
    const contentState = ContentState.createFromBlockArray(contentBlock);
    const raw = convertToRaw(contentState)
    setrawHtml(raw)
    form.setFieldsValue({
      subject: currentItem?.subject,
    })
    if (currentItem?.recipient === 1) {
      setcurrentRecipient(currentItem?.recipient)
      setshowEmailCount(true)
      setshowEmailField(false)
      loadUsers('player');
    } else if (currentItem?.recipient === 2) {
      setcurrentRecipient(currentItem?.recipient)
      setshowEmailField(true)
      setshowEmailCount(false)
      form.setFieldsValue({
        email: tcblEmailList,
      })
    }
    else if (currentItem?.recipient === 3) {
      setcurrentRecipient(currentItem?.recipient)
      setshowEmailField(true)
      setshowEmailCount(false)
      form.setFieldsValue({
        email: pdexEmailList,
      })
    }
    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, [currentItem, form])

  const onValuesChange = (changedValues) => {

    if (changedValues?.recipient === 1) {
      setcurrentRecipient(changedValues?.recipient)
      setshowEmailCount(true)
      setshowEmailField(false)
      loadUsers('player');
    } else if (changedValues?.recipient === 2) {
      setcurrentRecipient(changedValues?.recipient)
      setshowEmailField(true)
      setshowEmailCount(false)
      form.setFieldsValue({
        email: tcblEmailList,
      })
    }
    else if (changedValues?.recipient === 3) {
      setcurrentRecipient(changedValues?.recipient)
      setshowEmailField(true)
      setshowEmailCount(false)
      form.setFieldsValue({
        email: pdexEmailList,
      })
    }

  }

  const loadUsers = async (role) => {
    setLoading(true);
    const { data } = await apiClient.get(`${ApiConfig.userInfo.count}?role=${role}`)
    setplayersCount(data?.count)
    form.setFieldsValue({
      count: data?.count,
    })
    setLoading(false);
  }


  console.log("loading", loading)

  return (
    <>
      {/* {loading && <PageLoader />}
      {!loading && <> */}
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={
          {
            recipient: currentItem?.recipient
          }
        }
        onFinish={onFinishApiCall}
        onValuesChange={onValuesChange}
      >

        {/* <Form.Item
          name="email"
          label="Email Address"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a email address" />
        </Form.Item> */}
        <Form.Item
          name="recipient"
          label="Recipient"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select
            placeholder="Select a recipient"
            optionFilterProp="children"
          >
            {recipientArray?.map((mval, index) => (
              <Option key={mval?.value} value={mval?.value}>{mval?.label}</Option>
            ))}
          </Select>
        </Form.Item>
        {showEmailField && (
          <>
            <Form.Item
              name="email"
              label="Email Address"
            >
              <Input placeholder="Enter a email address" disabled />
            </Form.Item>
          </>
        )}
        {showEmailCount && (
          <>
            <Form.Item
              name="count"
              label="Player Email"
            >
              {!!playersCount ? <Input placeholder="Enter a email address" disabled /> : <div style={{ textAlign: "center" }}><Spin /></div>}
            </Form.Item>
          </>
        )}
        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a subject" />
        </Form.Item>
        <Form.Item
          name="editor"
          label="Text Editor"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Editor
            // initialEditorState={editorState}
            // defaultEditorState={convertFromRaw(JSON.parse(post.content))}
            editorState={editorState}
            contentState={rawHtml}
            onEditorStateChange={setEditorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            wrapperStyle={{ width: '100%', height: '60vh', border: "1px solid #d9d9d9" }}
            toolbar={{
              image: {
                uploadCallback: imageUploadCallback,
                previewImage: true,
              },
            }}
          />
        </Form.Item>
        <div className="form-footer">
          <Button type="primary" htmlType="submit" onClick={() => setisDraftDocs(true)}>Save</Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="primary" htmlType="submit" onClick={() => setisDraftDocs(false)}>Send</Button>
        </div>
      </Form>
      {/* </>
      } */}
    </>
  )
}
export default DraftCampaign
