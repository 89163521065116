export const ApiConfig = {
  auth: {
    login: "auth/login",
    resetPassword: "auth/reset-password",
    forgotPassword: "auth/forgot-password",
    register: "auth/player/register",
    verifyEmail: "auth/verify-account",
    logout: "auth/logout",
  },
  dashboard: {
    info: '/dashboard',
    stats: '/dashboard/player-stats',
    logs: '/dashboard/game-logs'
  },
  profile: {
    load: "profile",
    update: "profile/update",
    updatePic: 'profile/update-profile-pic',
    changePassword: "profile/change-password",
  },
  player: {
    teams: 'player/teams',
    leagues: 'leagues',
    playerSearch: 'player/teams/player-search',
    addPlayer: 'player/teams/team_id/add-player',
    updatePlayer: 'player/teams/team_id/update-player',
    removePlayer: 'player/teams/team_id/remove-player',
    invites: 'player/invites',
    registrations: {
      registered: 'player/registrations',
      registeredWithConfirm: 'player/registrations/register-confirm',
      upcoming: 'player/registrations/upcoming'
    }
  },
  courts: "/courts",
  matches: {
    list: 'matches',
    updatecomment: 'matches/updatecomment',
    updatecommenturl: 'matches/updatecommenturl',
    getcomment: 'matches/getcomment',
    newlist: 'matches/newlist',
    matchLogs: 'matches/logs',
    quickMatchCreateDetails: 'matches/quick-match-details',
    updateScorer: 'matches/update-scorer',
    updateScorerManager: 'matches/update-scorer-manager',
    updateDetails: 'matches/update-details',
    updateDetailsManager: 'matches/update-details-manager',
    updateMatch: 'matches/update-match',
    updateMatchManager: 'matches/update-match-manager',
    updateScheduledMatch: 'matches/update-scheduled-match',
    updateScheduledMatchNew: 'matches/update-scheduled-matchnew',
    startMatch: 'matches/start-match',
    startMatchManager: 'matches/start-match-manager',
    jerseyViolationManager: 'matches/jersey-violation-manager',
    addPlayers: 'matches/add-players',
    addPlayerToTeam: 'matches/add-player-to-team',
    addPlayerToMatch: 'matches/add-player-to-match',
    updateMatchScore: 'matches/update-score',
    updateMatchScoreManager: 'matches/update-score-manager',
    getScorer: 'matches/scorer',
    getScorermanager: 'matches/scorermanager',
    downloadSampleFile: 'matches/game-creation-file',
    lastUploadHistory: 'matches/last-upload-history',
    updatednp: 'matches/updatednp',
    resetStats: 'matches/reset-team-stats',
    sendMails: 'matches/send-mails',
    getnewmanagermatches: 'matches/getnewmanagermatches',
    unassignmatch: 'matches/unassignmatch',
    getManagers:"/users/getManagers"
  },
  users: '/users',
  userInfo: {
    count: 'users/count'
  },
  leagues: '/leagues',
  seasons: '/seasons',
  season: {
    updateTierForRegisteredTeam: '/seasons/update-registerd-team-tier',
    removeRegisteredTeamFromTier: '/seasons/remove-registerd-team-tier'
  },
  common: {
    statFields: '/settings/stat-fields',
    UpdateLeagueFees: '/settings/leaguefees',
    getInfo: '/settings/getinfo',
    getFilters: '/settings/getfilters',
    leaguesList: '/settings/leagueslist',
    getMatchesinList: '/matches/getMatchesinList',
    getMatchesinListAll: '/matches/getMatchesinListall',
    viewteam: '/settings/view'
  },
  website: {
    getWebsiteInfo: '/website/getwebsiteinfo',
    saveWebsiteInfo: '/website/savewebsiteinfo',
    updateWebsiteInfo: '/website/updatewebsiteinfo',
    deleteWebsiteInfo: '/website/deletewebsiteinfo',
    savePromotionImage: '/website/saveimage',
  },
  marketing: {
    sendEmailCampaign: '/marketing/create-campaign',
    list: '/marketing/getlist',
    deletecampaign: '/marketing/delete',
    deletedraftcampaign: '/marketing/deletedraft',
  }
}

export default ApiConfig;