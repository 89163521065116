import React, { useEffect } from "react"
import { Table, Tabs } from "antd"
import $ from 'jquery';
import { getFullName, getShortName } from "../../utils";

const { TabPane } = Tabs

const TeamsTab = ({ teams = [], columns, playTypes = [], loading }) => {

  useEffect(() => {
    console.log(teams);
  }, [teams, loading])

  const getTeams = (type) => {
    if (type === 'All') return teams;
    return teams.filter(ee => {
      return ee?.enrolled?.map(e => e.name)?.indexOf(type) >= 0
    });
  }

  const onChangeTab = (e) => {
    window.teamTabKey = e
    const tabNm = $('.tab-blk .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn').eq(parseInt(e)).text();
    console.log('tabNm', tabNm);
    setTimeout(() => {
      // const tabNm = $('.tab-blk .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn').eq(0).text()
      if (tabNm === 'All') {
        $('.play_types[data-key]').show();
      } else {
        $('.play_types[data-key]').hide();
        $(`.play_types[data-key="${tabNm}"]`).show()
      }
    }, 10)
  }

  const getPlayTypes = () => {
    const leagues = [];
    teams.forEach(tt => {
      tt.enrolled.forEach(ee => {
        if (leagues.indexOf(ee) === -1) {
          leagues.push(ee);
        }
      })
    });
    return playTypes.filter(pp => (pp === (leagues.length > 1 && 'All') || teams.findIndex(tt => tt?.enrolled?.findIndex(ee => ee.name === pp) >= 0) >= 0))
  }

  return (
    <>
      <Tabs onChange={onChangeTab} defaultActiveKey="0">
        {
          getPlayTypes().map((ee, k) => (
            <TabPane tab={ee} key={k.toString()}>
              <Table
                loading={loading}
                pagination={false}
                dataSource={getTeams(ee)}
                columns={ee === 'All' ? columns?.filter(c => c.title !== 'Starting Lineup') : columns}
              />
            </TabPane>
          ))
        }
      </Tabs>
    </>
  )
}

export const TeamNameWithLogo = (props) => {
  const { logo, name } = props || {};
  return <div className="team_name_logo">
    <img src={logo || '/logos/logo.png'} alt="" />
    <span className="ml-2">{name}</span>
  </div>
}

export const PlayerNameWithPicture = (props) => {
  const { profile_pic } = props || {};
  return <div className="team_name_logo">
    {profile_pic && <img src={profile_pic} alt="" />}
    {!profile_pic && <span className="pic-placeholder">{getShortName(props)}</span>}
    <span className="ml-2">{getFullName(props)}</span>
  </div>
}

export default TeamsTab