import axios from 'axios'
import { v4 as uuidV4 } from 'uuid'
import store from 'store'
import { notification } from 'antd'
// import UserAction from 'redux/user/actions'
// import { store as reduxStore } from 'index'
import { Domain } from '../index'

let clientId = localStorage.getItem('client-id')
if (!clientId) {
  const uniqueId = uuidV4()
  localStorage.setItem('client-id', uniqueId)
  clientId = uniqueId
}

const apiClient = axios.create({
  baseURL: Domain,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-Device-Type': 'TCBL',
      'X-Client-ID': clientId,
      'X-App-Version': '2.0.0',
      'Access-Control-Allow-Credentials': true,
    },
  },
})

apiClient.interceptors.request.use((request) => {
  request.params = request.params || {}
  const accessToken = store.get('user.accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(
  (response) => {
    if (response?.data?.message) {
      notification.success({
        message: response.data.message,
      })
    }
    return response
  },
  (error) => {
    console.log(error);
    if (error?.response?.status === 401) {
      // reduxStore.dispatch({
      //   type: UserAction.CLEAR_USER,
      // })
      store.clearAll();
      window.history.go('/')
    } else if (error?.response?.data) {
      const { message: errMsg, errors = {} } = error.response.data
      const { message = '', non_field_errors: nfErr } = errors
      if (message || errMsg) {
        notification.error({
          message: message || errMsg,
        })
      } else if (nfErr && nfErr[0]) {
        notification.error({
          message: nfErr[0],
        })
      }
    }
    throw error
  },
)

export default apiClient