import React from 'react'
import { useLocation } from 'react-router-dom'
import Centered from './centered'
import Empty from './empty'
import Layout1 from './layout-1'

const Layouts = ({ children }) => {
  let location = useLocation()
  let { pathname } = { ...location }
  if (
    [
      // '/login-1',
      // '/contact-us-1',
      // '/create-account',
      // '/email-confirmation',
      // '/logout',
      // '/reset-password',
      // '/forgot-password',
      // '/lock-screen',
      // '/subscribe',
      '/error-page',
      // '/coming-soon',
    ].includes(pathname)
  ) {
    return <Centered>{children}</Centered>
  } else if (['/',
    '/login',
    '/register',
    '/reset-password',
    '/set-password',
    '/forgot-password',
    '/verify-account',
    '/contact-us',
    '/terms-of-service',
    '/privacy-policy',
    '/game-schedules',
    '/schedule-game',
    '/tcbl-leagues',
    '/rules-and-regulations',
    '/support',
    '/live-scores',
  ].includes(pathname)) {
    return <Empty>{children}</Empty>
  } else {
    return <Layout1>{children}</Layout1>
  }
}

export default Layouts
