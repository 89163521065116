import React from 'react';
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal

const ConfirmationModal = (title, desc, okText = "Yes, Go ahead", cancelText = "No", onOk, onCancel, onClickCancel, className = '', okColor = undefined,cancelColor = undefined,buttonwidth = undefined) => {
  return new Promise(resolve => {
    confirm({
      title,
      content: desc,
      okCancel: true,
      className: "confirm_popup",
      bodyStyle: {
        zIndex: 99999999999
      },
      wrapClassName: `confirm_popup_wrapper ${className}`,
      style: {
        zIndex: 99999999999
      },
      maskStyle: {
        zIndex: 9999999999,
        perspective: "1000px"
      },
      okButtonProps:{
        style:{backgroundColor: okColor, minWidth: buttonwidth,color:okColor==="#000000"?"#fff":"#000000"}
      },
      cancelButtonProps:{
        style:{backgroundColor: cancelColor, minWidth: buttonwidth,color:cancelColor==="#000000"?"#fff":"#000000"}
      },
      onOk: () => {
        if (onOk) onOk()
        resolve(true);
      },
      onCancel: (close) => {
        if (!close.triggerCancel) {
          close(true)
          if (onClickCancel) onClickCancel()
          resolve(false)
        } else {
          if (onCancel) onCancel()
          resolve(false)
        }
      },

      okText,
      cancelText,
      closable: true,
      mask: true,
      maskClosable: true,
    })
  })
}

export const ModalConfirm = ({ visible, loading, cancelLoading, title, okText = "Yes, Go ahead", cancelText = "No", onOk, onCancel }) => {
  return (
    <>
      <Modal
        className="confirmation-modal"
        title={null}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={loading}
        okText={okText}
        cancelText={cancelText}
        cancelButtonProps={{
          loading: cancelLoading
        }}
      >
        <div className="font-size-16">
          <ExclamationCircleOutlined style={{ fontSize: "18px", color: "#faad15", paddingRight: "8px" }} color="#faad15" /> <span>{title}</span>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmationModal