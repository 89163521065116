import React from 'react'
import { FiMenu } from 'react-icons/fi'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'

const Logo = () => {
  const dispatch = useDispatch()
  const { config, leftSidebar } = useSelector(
    state => ({
      config: state.config,
      leftSidebar: state.leftSidebar
    }),
    shallowEqual
  )
  const {  collapsed } = { ...config }
  const { showLogo } = { ...leftSidebar }
  if (showLogo) {
    return (
      <div className="logo truncate">
        <Link
          to={{ pathname: process.env.REACT_APP_WEBSITE }} target="_blank" 
          className="flex flex-row items-center justify-start space-x-2">
          <img style={{ width: "42px" }} src={`/logos/${process.env.REACT_APP_BUSSINESS_NAME}.png`} alt='logo' />
          <span>{(process.env.REACT_APP_BUSSINESS_NAME)?.toUpperCase()}</span>
        </Link>
        <button
          onClick={() =>
            dispatch({
              type: 'SET_CONFIG_KEY',
              key: 'collapsed',
              value: !collapsed
            })
          }
          className="ml-auto mr-4 block lg:hidden">
          <FiMenu size={20} />
        </button>
      </div>
    )
  }
  return null
}

export default Logo
