import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { FiActivity, FiUsers, FiExternalLink, FiClock } from 'react-icons/fi'
import store from 'store';
import Widget1 from '../components/dashboard/widget-1'
import Section from '../components/dashboard/section'
import SectionTitle from '../components/dashboard/section-title'
import { Select, Table } from 'antd'
import apiClient from '../services/axios'
import ApiConfig from '../services/ApiConfig'
import { getDateFormat } from '../utils';

const Index = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [logsLoading, setLogsLoading] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState(null);
  const [playerStats, setPlayerStats] = useState([]);
  const [playerGameLogs, setPlayerGameLogs] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const user = store.get('user.data');
  const isAdmin = user?.role === 'admin';
  const isPlayer = user?.role === 'player';
  const isScorer = user?.role === 'scorer';

  const [logColumns, setLogColumns] = useState([
    {
      title: "Games",
      dataIndex: "games",
      name: "games",
      render: (text, gl) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <span class={`pl-team-name ${gl?.match?.home?.team?._id === gl?.team?._id ? 'pl-team' : ''}`}>{gl?.match?.home?.team?.name}</span>
            vs.
            <span class={`pl-team-name pl-away ${gl?.match?.away?.team?._id === gl?.team?._id ? 'pl-team' : ''}`}>{gl?.match?.away?.team?.name}</span>
          </div>
        )
      }
    }
  ])

  const loadPlayerStats = async (sportEvent, statFields = []) => {
    const { data } = await apiClient.get(ApiConfig.dashboard.stats + `${sportEvent ? `?season=${sportEvent}` : ''}`);
    const tTmp = [...statFields.slice(0, 3), ...statFields.slice(9, 12), ...statFields.slice(3, 9), ...statFields.slice(12, 99)].map(ee => ({ title: ee, value: data?.player_stats ? data.player_stats[ee] : 0 }));
    let player_stats = [tTmp.slice(0, 6), tTmp.slice(6, 12), tTmp.slice(12, 18)]
    setPlayerStats(player_stats)
  }

  const loadGameLogs = async (sportEvent, statFields = []) => {
    setLogsLoading(true);
    const tmp = statFields.map((ee = '') => ({
      title: ee.toUpperCase(),
      key: ee,
      dataIndex: ee
    }))
    const { data } = await apiClient.get(ApiConfig.dashboard.logs + `${sportEvent ? `?season=${sportEvent}` : ''}`);
    setLogsLoading(false);
    setLogColumns([...logColumns, ...tmp])
    const gameLogs = [];
    data.game_logs.forEach(gl => {
      const payload = { ...gl, ...gl.stats };
      gameLogs.push(payload);
    })
    setPlayerGameLogs(gameLogs);
  }

  const onClickMatch = (record) => {
    history.push({
      pathname: "/matches",
      state: {
        match_id: record?.match?._id
      }
    })
  }

  useEffect(() => {
    (async () => {
      try {
        if (isPlayer) {
          setLoading(true)
          let player_stats = [[{ "title": "pts", "value": 0 }, { "title": "fgm", "value": 0 }, { "title": "fga", "value": 0 }, { "title": "oreb", "value": 0 }, { "title": "dreb", "value": 0 }, { "title": "reb", "value": 0 }], [{ "title": "2ptm", "value": 0 }, { "title": "2pta", "value": 0 }, { "title": "3ptm", "value": 0 }, { "title": "3pta", "value": 0 }, { "title": "ftm", "value": 0 }, { "title": "fta", "value": 0 }], [{ "title": "ast", "value": 0 }, { "title": "stl", "value": 0 }, { "title": "blk", "value": 0 }, { "title": "to", "value": 0 }, { "title": "ef", "value": 0 }]];
          setDashboardInfo({ player_stats })
          const { data: fieldData } = await apiClient.get(ApiConfig.common.statFields);
          const { data } = await apiClient.get(ApiConfig.dashboard.info);
          setSeasons(data?.seasons || [])
          setDashboardInfo({ ...data, player_stats, statFields: fieldData?.stats || [] })
          if (fieldData.stats) {
            loadGameLogs(null, fieldData.stats);
            loadPlayerStats(null, fieldData.stats);
          }
          setLoading(false)
        } else if (isAdmin) {
          const { data } = await apiClient.get(ApiConfig.dashboard.info);
          console.log('data', data);
          setDashboardInfo({ ...data })
        }
      } catch (error) {
        console.log(error);
        setLoading(false)
      }
    })();
    console.log('loading', loading);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard-page">
      <SectionTitle title="Overview" subtitle="Dashboard" />
      {
        isPlayer && (
          <>
            <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Upcoming Game"
                  // description={getDateFormat({ date: new Date(), format: "ddd, MMM DD, YYYY" })}
                  description={dashboardInfo?.upcoming_match_date ? getDateFormat({ date: dashboardInfo?.upcoming_match_date, format: "ddd, MMM DD, YYYY" }) : "----"}
                  right={
                    <FiUsers size={24} className="stroke-current text-grey-500" />
                  }
                />
              </div>
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Upcoming Game Vs."
                  // description="PSG"
                  description={dashboardInfo?.upcoming_match_vs || "----"}
                  right={
                    <FiActivity size={24} className="stroke-current text-grey-500" />
                  }
                />
              </div>
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Previous Game"
                  description={dashboardInfo?.upcoming_match_date ? getDateFormat({ date: dashboardInfo?.prev_match_date, format: "ddd, MMM DD, YYYY" }) : "----"}
                  right={
                    <FiExternalLink
                      size={24}
                      className="stroke-current text-grey-500"
                    />
                  }
                />
              </div>
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Previous Game Vs."
                  // description="Torrento Venoms"
                  description={dashboardInfo?.prev_match_vs || "----"}
                  right={
                    <FiClock size={24} className="stroke-current text-grey-500" />
                  }
                />
              </div>
            </div>

            <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              <Section
                description={<span>Player information</span>}
                right={<Select defaultValue="" onChange={(e) => loadPlayerStats(e, dashboardInfo?.statFields)} placeholder="Select Season" options={[{ label: "Overall", value: "" }].concat(seasons.map(e => ({ label: e.name, value: e._id })))} />}
              >
                {
                  playerStats?.map(pp => (
                    <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                      {
                        pp.map(ee => (
                          <div className="w-full lg:w-1/6 m-w-w"> <Widget1 title={ee.title} description={ee.value} /> </div>
                        ))
                      }
                    </div>
                  ))
                }
              </Section>
            </div>

            <div className="w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              <Section
                description={<span>Game Logs</span>}
                right={<Select defaultValue="" onChange={(e) => loadGameLogs(e, dashboardInfo?.statFields)} placeholder="Select Season" options={[{ label: "Overall", value: "" }].concat(seasons.map(e => ({ label: e.name, value: e._id })))} />}
              >
                <div className="flex flex-col w-full">
                  <div className="overflow-x-scroll lg:overflow-hidden">
                    <Table
                      pagination={false}
                      loading={logsLoading}
                      className='teams_player_page'
                      dataSource={playerGameLogs}
                      onRow={(record) => ({
                        onClick: () => onClickMatch(record)
                      })}
                      columns={logColumns}
                    />
                  </div>
                </div>
              </Section>
            </div>
          </>
        )
      }
      {
        isAdmin && (
          <>
            <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Total Teams"
                  description={dashboardInfo?.team_count || 0}
                  right={
                    <FiUsers size={24} className="stroke-current text-grey-500" />
                  }
                />
              </div>
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Total Players"
                  description={dashboardInfo?.player_count || 0}
                  right={
                    <FiActivity size={24} className="stroke-current text-grey-500" />
                  }
                />
              </div>
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Current Season"
                  description={dashboardInfo?.current || '-'}
                  right={
                    <FiExternalLink
                      size={24}
                      className="stroke-current text-grey-500"
                    />
                  }
                />
              </div>
              <div className="w-full lg:w-1/4">
                <Widget1
                  title="Upcoming Season"
                  description={dashboardInfo?.upcoming || '-'}
                  right={
                    <FiClock size={24} className="stroke-current text-grey-500" />
                  }
                />
              </div>
            </div>
          </>
        )
      }
      {
        isScorer && (
          <></>
        )
      }
    </div>
  )
}
export default Index
