import React from 'react';
import { Link } from 'react-router-dom'
import { FiGrid, FiMonitor } from 'react-icons/fi'
import { AiOutlineTrophy, AiFillSchedule } from 'react-icons/ai'
import { BiBook, BiSupport } from 'react-icons/bi'

const App = ({ itemList, closeDrawer }) => {

  const iconArray = [
    {
      "url": "home",
      "icon": <> <FiGrid size={20} /></>
    },
    {
      "url": "game-schedules",
      "icon": <> <AiFillSchedule size={20} /></>
    },
    {
      "url": "schedule-game",
      "icon": <> <AiFillSchedule size={20} /></>
    },
    {
      "url": "league",
      "icon": <> <AiOutlineTrophy size={20} /></>
    },
    {
      "url": "game",
      "icon": <> <FiMonitor size={20} /></>
    },
    {
      "url": "coed",
      "icon": <> <AiOutlineTrophy size={20} /></>
    },
    {
      "url": "rules-and-regulations",
      "icon": <> <BiBook size={20} /></>
    }
    ,
    {
      "url": "support",
      "icon": <> <BiSupport size={20} /></>
    },
  ]

  const closeDrawerAction = (values) => {
    if (values === 'game') {
      window.open('https://www.amilia.com/store/en/tcyba/shop/programs/85296?subCategoryIds=4278015', '_blank', 'noopener,noreferrer')
    }
    else {
      closeDrawer(values)
    }
  }

  return (
    <div
      style={{
        width: 256,
      }}
    >
      {itemList.map((menu, i) => (
        <div key={i}>
          <Link
            onClick={() =>
              closeDrawerAction(menu?.key)
            }
            //refresh="true"
            to={menu?.key}
            className="left-sidebar-item"
          >
            {iconArray?.filter(x => x?.url === menu?.key)[0]?.icon}
            <span className="title">{menu?.label}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};
export default App;