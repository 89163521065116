import React, { useEffect, useState } from 'react'
import store from 'store'
import { Form, Button, Switch, Radio, Space, Select, Input, InputNumber, Tabs } from 'antd'
// import { MaskedInput } from 'antd-mask-input'
import { FiInstagram, FiTrash } from 'react-icons/fi'

import SectionTitle from '../../components/section-title'
// import { UnderlinedTabs } from '../../components/tabs'
// import AccountSettings from '../../components/sample-forms/account-settings'
// import EmailPreferences from '../components/sample-forms/email-preferences'
// import SecuritySettings from '../../components/sample-forms/security-settings'
// import Widget from '../../components/social-feed/widget'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import { getShortName } from '../../utils'
import apiFormDataClient from '../../services/axios-formdata'
import ConfirmationModal from '../../common/confirm-modal'

const FreeAgent = ({ form, user }) => {

  const [shareInfo, setShareInfo] = useState(null);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    const { experience, feet, inch, position, reason, weight, free_agent, share_player_info } = values;
    const height = (feet && inch) ? `${feet}'${inch}` : ''
    const payload = {
      is_free_agent: free_agent,
      is_share_player_info: share_player_info,
      player_info: {
        experience, height, position, weight, why_do_you_like: reason
      }
    }
    setLoading(true);
    try {
      const { data } = await apiClient.patch(ApiConfig.profile.update, payload);
      store.set('user.data', data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const onValuesChange = (changedValues) => {
    setShareInfo({
      ...shareInfo,
      ...changedValues
    })
  }

  useEffect(() => {
    const { free_agent, share_player_info } = user || {};
    console.log(free_agent, share_player_info, user);
    if (free_agent || share_player_info) {
      setShareInfo({
        ...shareInfo,
        free_agent,
        share_player_info,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="free-agent">
      <Form
        layout="vertical"
        requiredMark={false}
        onValuesChange={onValuesChange}
        initialValues={{ ...user }}
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Are you a free agent?"
          name="free_agent"
          valuePropName="checked"
          className="swith-item"
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
        {shareInfo?.free_agent && (
          <>
            <Form.Item
              label="Do we have your consent to share your player information on our website?"
              name="share_player_info"
              className="swith-item"
              valuePropName="checked"
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>

            {shareInfo?.share_player_info && (
              <>
                <Form.Item
                  label="Basketball Experience"
                  name="experience"
                  className="radio-item list"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="Recreational">Recreational</Radio>
                      <Radio value="High School Varsity">High School Varsity</Radio>
                      <Radio value="College">College</Radio>
                      <Radio value="D-1 University">D-1 University</Radio>
                      <Radio value="Professional">Professional</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Position"
                  name="position"
                  className="radio-item list"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="Recreational">Point Guard</Radio>
                      <Radio value="High School Varsity">Shooting Guard</Radio>
                      <Radio value="College">Small Forward</Radio>
                      <Radio value="D-1 University">Power Forward</Radio>
                      <Radio value="Professional">Center</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Form.Item className="flex-form-item">
                  <Form.Item
                    label="Height"
                    name="feet"
                    className="max-t"
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Select placeholder="Select Feet" options={["4", "5", "6", "7", "8"].map(e => ({ label: e, value: e }))} />
                  </Form.Item>
                  <Form.Item
                    // label="Height(Inch)"
                    name="inch"
                    className="max-t inch-item"
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Select placeholder="Select Inch" options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"].map(e => ({ label: e, value: e }))} />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label="Weight(lbs)"
                  name="weight"
                  className="max-t"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <InputNumber placeholder="Enter weight eg. 50" />
                </Form.Item>
                <Form.Item
                  label="Why do you like basketball?"
                  name="reason"
                  className="max-t"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Input.TextArea style={{ height: "80px" }} placeholder="Why do you like basketball?" />
                </Form.Item>
                {/* <Form.Item noStyle>
                  <Button loading={loading} style={{ marginTop: "12px" }} htmlType="submit" type="primary">Save</Button>
                </Form.Item> */}
              </>
            )}
          </>
        )}
        <Form.Item noStyle>
          <Button loading={loading} style={{ marginTop: "12px" }} htmlType="submit" type="primary">Save</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

const AccountSettings = ({ user, form }) => {

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      const { data } = await apiClient.patch(ApiConfig.profile.update, values);
      store.set('user.data', data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      initialValues={user}
      onFinish={onSubmit}
      form={form}
    >
      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your firstname" />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your lastname" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>
      <Form.Item
        label="Mobile Number"
        name="mobile"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <InputNumber maxLength={12} placeholder='Enter a mobile number' />
        {/* <MaskedInput
          mask="(111) 111-1111"
          // placeholder='Enter a mobile number'
          size="large"
        /> */}
      </Form.Item>
      {user?.role === 'player' && <Form.Item
        label="Instagram"
        name="instagram"
      >
        <Input addonBefore="@" placeholder="Enter your instagram" />
      </Form.Item>}
      {user?.role === 'player' && <Form.Item
        label="Block player invitation?"
        name="is_invite_blocked"
        valuePropName="checked"
      >
        <Switch checkedChildren="Yes" unCheckedChildren="No" />
      </Form.Item>}
      <Form.Item noStyle>
        <Button loading={loading} htmlType="submit" type="primary">Save</Button>
      </Form.Item>
    </Form>
  )
}

const SecuritySettings = () => {

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    delete values.confirm_password;
    setLoading(true);
    try {
      await apiClient.post(ApiConfig.profile.changePassword, values);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Current password"
        name="old_password"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <Input type="password" placeholder="Enter your current password" />
      </Form.Item>
      <Form.Item
        label="New password"
        name="new_password"
        rules={[
          // { required: true, message: "This field is required" },
          {
            validator: async (_, password) => {
              password = password.replace(/[^a-zA-Z0-9 ]/g, "");
              const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
              if (!regex.test(password)) {
                return Promise.reject(new Error('Password must be at least 8 characters, 1 uppercase, 1 lowercase and 1 number'));
              }
            }
          }
        ]}
      >
        <Input maxLength={20} type="password" placeholder="Enter your new password" />
      </Form.Item>
      <Form.Item
        label="Confirm password"
        name="confirm_password"
        dependencies={['new_password']}
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('The password that you entered do not match!');
            },
          }),
        ]}
      >
        <Input type="password" placeholder="Enter your new password again" />
      </Form.Item>
      <Form.Item noStyle>
        <Button loading={loading} htmlType="submit" type="primary">Save</Button>
      </Form.Item>
    </Form>
  )
}

const LeaguePricingForm = ({ form, feeInfo }) => {

  const [loading, setLoading] = useState(false);

  const currentUserInfo = store.get('user.data');
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await apiClient.patch(`${ApiConfig.common.UpdateLeagueFees}/${currentUserInfo?._id}`, values);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }






  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form}
      onFinish={onSubmit}
      initialValues={{ fee: Number(feeInfo?.fee / 100) }}
    >
      <Form.Item
        name="fee"
        label="Fees"
        rules={[{ required: true, message: "This field is required" }]}
      >
        <InputNumber controls={false} maxLength={4} placeholder="Enter a league fees" />
      </Form.Item>
      <Form.Item noStyle>
        <Button loading={loading} htmlType="submit" type="primary">{feeInfo?._id ? 'Update' : 'Add'}</Button>
      </Form.Item>
    </Form>
  )
}

const Widget = ({ children }) => {
  return (
    <div className="w-full p-4 mb-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890">
      {children}
    </div>
  )
}

const Index = () => {
  const [activeTab, setActiveTab] = useState("0");
  const [user, setUser] = useState({});
  const [form] = Form.useForm();

  const [feesRes, setfeesRes] = useState(null);
  const [leagueForm] = Form.useForm();


  useEffect(() => {
    (async () => {
      try {
        const response = await apiClient.get(`${ApiConfig.common.getInfo}`);
        setfeesRes(response?.data?.settingsInfo)
      } catch (error) {
      }
    })();

    // eslint-disable-next-line
  }, [])







  useEffect(() => {
    (async () => {
      const user = store.get('user.data');
      const payload = { ...user, feet: user?.player_info?.height?.split("'")[0], inch: user?.player_info?.height?.split("'")[1], free_agent: user.is_free_agent, share_player_info: user.is_share_player_info, ...user?.player_info, reason: user?.player_info?.why_do_you_like };
      if (!payload.feet) delete payload.feet;
      if (!payload.inch) delete payload.inch;
      setUser({ ...payload });
      const { data } = await apiClient.get(ApiConfig.profile.load);
      const payloadLatest = { ...data, feet: user?.player_info?.height?.split("'")[0], inch: user?.player_info?.height?.split("'")[1], free_agent: user.is_free_agent, share_player_info: user.is_share_player_info, ...user?.player_info, reason: user?.player_info?.why_do_you_like };
      if (!payloadLatest.feet) delete payloadLatest.feet;
      if (!payloadLatest.inch) delete payloadLatest.inch;
      setUser({ ...payloadLatest });
    })();

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      ...user
    })
    // eslint-disable-next-line
  }, [user, activeTab])

  const onChangeProfilePic = async (e) => {
    const file = e.target.files[0];
    // const url = URL.createObjectURL(file);
    const formdata = new FormData();
    formdata.append('avatar', file);
    const { data } = await apiFormDataClient.post(ApiConfig.profile.updatePic, formdata)
    setUser({
      ...data.data
    })
    store.set('user.data', data.data)
  }

  const onDeletePic = async (e) => {
    const bool = await ConfirmationModal("Are you sure you want to remove your picture?");
    if (!bool) return;
    const formdata = new FormData();
    formdata.append('is_removed_profile_pic', true);
    await apiFormDataClient.post(ApiConfig.profile.updatePic, formdata)
    setUser({
      ...user,
      profile_pic: ""
    })
  }

  const tabs = [
    {
      index: "0",
      title: 'Account settings',
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <AccountSettings user={user} form={form} />
        </div>
      )
    },
    {
      index: "1",
      title: 'Free Agent',
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <FreeAgent user={user} form={form} />
        </div>
      )
    },
    {
      index: "2",
      title: 'Security settings',
      content: (
        <div className="py-4 w-full lg:w-1/2">
          <SecuritySettings user={user} form={form} />
        </div>
      )
    },
    {
      index: "3",
      title: 'League Fees',
      content: (
        <div className="py-4 w-full lg:w-1/5">
          <LeaguePricingForm form={leagueForm} feeInfo={feesRes} />
        </div>
      )
    }
  ]

  return (
    <>
      <SectionTitle title="Pages" subtitle="User profile" />

      <Widget>
        <div className="flex flex-row items-center justify-start p-4">
          <div className="flex-shrink-0 w-24">
            {user?.profile_pic && <div className='d-pic shadow rounded-full h-20 w-20 shadow-outline mb-2'>
              <span onClick={onDeletePic} aria-hidden className='p-delete-icon'><FiTrash /></span>
              <img
                src={user?.profile_pic}
                alt="media"
                className="rounded-full h-20 w-20"
              />
              <input onChange={(e) => onChangeProfilePic(e)} type="file" />
            </div>}
            {!user?.profile_pic && <div className='d-pic shadow rounded-full h-20 w-20 shadow-outline mb-2'>
              {getShortName(user)}
              <input onChange={(e) => onChangeProfilePic(e)} type="file" />
            </div>}
          </div>
          <div className="py-2 px-2">
            <p className="text-base font-bold whitespace-no-wrap">{user?.first_name + ' ' + user?.last_name}</p>
            {/* <p className="text-sm text-grey-500 whitespace-no-wrap">
              Vital Database Dude
            </p> */}
            {user?.role === 'player' && <div className="flex flex-row items-center justify-start w-full py-1 space-x-2">
              <FiInstagram style={{ cursor: "pointer" }} onClick={() => window.open(`https://www.instagram.com/${window.instagramUrl || user?.instagram}`)} className="stroke-current text-xl text-instagram" />
            </div>}
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full p-4">
            {/* <UnderlinedTabs tabs={tabs} /> */}
            <Tabs onChange={e => setActiveTab(e)}>
              {
                tabs.filter(tt => (!(tt.index === '1' && user?.role !== 'player')) && !(tt.index === '3' && user?.role === 'player') && !(tt.index === '3' && user?.role === 'scorer')).map(tt => <Tabs.TabPane key={tt.index} tab={tt.title}> {(activeTab === tt.index) && tt.content} </Tabs.TabPane>)
              }
            </Tabs>
          </div>
        </div>
      </Widget>
    </>
  )
}

export default Index