import { Button, Drawer, Form, Input, Modal, notification, Select, Table, Tag } from "antd";
// import $ from "jquery";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useHistory } from "react-router-dom";
// import { FiPlus } from "react-icons/fi";
import ConfirmationModal from "../../../common/confirm-modal";
import PageLoader from "../../../components/page-loader";
import SectionTitle from "../../../components/section-title";
import { TeamNameWithLogo } from "../../../components/teams";
// import teams from '../../../json/data/teams.json';
import ApiConfig from "../../../services/ApiConfig";
import apiClient from "../../../services/axios";
import { getDateFormat, getFullName } from "../../../utils";

const RegistrationPage = () => {

  const history = useHistory();
  // const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [registeredList, setRegisteredList] = useState([]);
  // const [upcomingList, setUpcomingList] = useState([]);
  const [submiting, setSubmiting] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [payVisible, setPayVisible] = useState(false)
  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [values, setValues] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [feesRes, setfeesRes] = useState(null);


  // const [activeTab, setActiveTab] = useState("1");
  // const dateFormat = 'ddd, DD MMM, YYYY';
  // const playTypes = ["3 ON 3", 'Wednesday League', 'Saturday League', 'Sunday League'];

  // const upcomingcolumns = [
  //   {
  //     title: "S.No",
  //     dataIndex: "sno",
  //     key: "sno",
  //     render: (text, record, key) => key + 1
  //   },
  //   {
  //     title: "Sports Event",
  //     dataIndex: "name",
  //     key: "name",
  //     render: (text, record) => `${record?.name} - ${record?.league?.name}`
  //   },
  //   {
  //     title: "Season Tier",
  //     dataIndex: "tier",
  //     key: "tier",
  //     render: (text, record) => `Tier ${record?.tier?.tier}`
  //   },
  //   {
  //     title: "Available Teams",
  //     dataIndex: "team",
  //     key: "team",
  //     render: (text, record) => record?.available_teams?.map(e => e.name)?.join(', ') || ''
  //   },
  //   {
  //     title: "Start Date",
  //     dataIndex: "start_date",
  //     key: "start_date",
  //     render: (text, record) => getDateFormat(record?.tier?.start_date)
  //   },
  //   {
  //     title: "Actions",
  //     dataIndex: "actions",
  //     key: "actions",
  //     render: (text, record) => {
  //       return (
  //         <div className="action_btns">
  //           <Button className='btn_edit' onClick={() => onRegister(record)} type="primary">Register</Button>
  //         </div>
  //       )
  //     }
  //   }
  // ]

  const registeredColumns = [
    {
      title: "No.",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => key + 1
    },
    {
      title: "Season",
      dataIndex: "sports_event",
      key: "sports_event",
      render: (text, record) => record?.sports_event?.name || '-'
    },
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      render: (text, record) => record?.league?.name
    },
    {
      title: "Season Tier",
      dataIndex: "tier",
      key: "tier",
      render: (text) => text ? `Tier ${text}` : '-'
    },
    {
      title: "Registered Team",
      dataIndex: "team",
      key: "team",
      render: (text, record) => <TeamNameWithLogo {...record?.team} />
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => text ? getDateFormat(text) : '-'
    },
    {
      title: "Paid",
      dataIndex: "is_entry_fee_paid",
      key: "is_entry_fee_paid",
      render: (text) => <Tag color={text ? '#4BB543' : '#E40000'}>{text ? 'Yes' : 'No'}</Tag>
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <div className="action_btns">
            {!record?.is_cancelled && !record?.entry_fee_confirmation_code && !record?.confirmed_at && <>
              <Button className='btn_edit mr-2' onClick={() => onCancel(record)} type="ghost">Cancel</Button>
              <Button className='btn_edit' onClick={() => { setPayVisible(true); setSelectedItem(record); }} type="primary">Pay</Button>
            </>}
            {record?.is_cancelled && <Tag color="#e40">Cancelled</Tag>}
            {(!record?.confirmed_at && record?.entry_fee_confirmation_code) && <Tag color="#8e8e8e">Awaiting Confirmation</Tag>}
            {record?.confirmed_at && <Tag color="#4BB543">Confirmed</Tag>}
          </div>
        )
      }
    }
  ]

  const playerColumns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => key + 1
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => getFullName(record?.player)
    },
    {
      title: "Jersey No",
      dataIndex: "jersey_no",
      key: "jersey_no",
    }
  ]

  useEffect(() => {
    (async () => {
      try {
        const response = await apiClient.get(`${ApiConfig.common.getInfo}`);
        setfeesRes(response?.data?.settingsInfo)
      } catch (error) {
      }
    })();

    // eslint-disable-next-line
  }, [])

  const onChangeValue = (type, value) => {
    console.log('value', value);
    values[type] = value;
    setValues({
      ...values
    })
  }

  const onCancel = async (record) => {
    const bool = await ConfirmationModal('Are you sure want to cancel this registration?');
    if (bool) {
      await apiClient.put(`${ApiConfig.player.registrations.registered}/${record._id}/cancel`);
      loadRegistered();
    }
  }

  // const onRegister = async (record) => {
  //   console.log('record', record);
  //   setValues({
  //     sports_event_name: record.name,
  //     sports_event: record._id,
  //     tier_name: `Tier ${record?.tier?.tier}`,
  //     tier: record?.tier?.tier,
  //     entry_fee: record?.entry_fee,
  //     teams: record.available_teams || []
  //   })
  //   setVisible(true);
  // }

  const loadRegistered = async () => {
    const { data } = await apiClient.get(ApiConfig.player.registrations.registered);
    setRegisteredList([...data]);
  }

  // const loadUpcoming = async () => {
  //   const { data } = await apiClient.get(ApiConfig.player.registrations.upcoming);
  //   setUpcomingList([...data])
  // }

  const loadTeams = async () => {
    const { data } = await apiClient.get(ApiConfig.player.teams);
    setTeams([...data])
  }

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.player.leagues);
    setLeagues([...data])
  }

  const onConfirmCode = async (val) => {
    console.log('values', val);
    setSubmiting(true)
    await apiClient.put(`${ApiConfig.player.registrations.registered}/${selectedItem?._id}/confirm`, val);
    setSubmiting(false)
    loadRegistered();
    setPayVisible(false);
  }

  const onProceed = async () => {
    console.log('values', values);
    // const { sports_event, team, tier } = values;
    const { league, team } = values;
    try {
      setSubmiting(true);
      // const { data } = await apiClient.post(ApiConfig.player.registrations.registered, { sports_event, team, tier });
      const teamDetail = teams.filter(tt => tt._id === team)[0];
      if (teamDetail && teamDetail?.players?.length >= 5) {
        const { data } = await apiClient.post(ApiConfig.player.registrations.registered, { league, team });
        setSubmiting(false);
        setVisible(false);
        setPayVisible(true);
        setSelectedItem(data.data)
        loadRegistered();
        // loadUpcoming();
        // $('.ant-tabs-tab').eq(0).trigger('click')
      } else {
        setSubmiting(false);
        notification.error({
          message: "A team must have atleast 5 players to register."
        })
      }
    } catch (error) {
      setSubmiting(false);
    }
  }

  const onAdd = () => {
    setVisible(true);
    setSelectedItem(null);
    setValues({})
  }

  useEffect(() => {
    loadRegistered();
    // loadUpcoming();
    loadLeagues();
    setLoading(true);
    loadTeams().then(() => setLoading(false)).catch(() => setLoading(false));
  }, [])

  return (
    <div className="registration-page">
      <SectionTitle
        title="Registration"
        subtitle="Manage Registrations"
        right={(teams.length > 0 && <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
          <FiPlus className="stroke-current text-white" size={16} />
          <span>Register</span>
        </button>)}
      />
      {
        loading && (
          <PageLoader />
        )
      }
      {
        (teams.length === 0 && !loading) && (
          <>
            <div style={{ marginTop: "120px" }} className="w-full flex items-center justify-center bg-grey-50">
              <div className="flex flex-col w-full max-w-xl text-center">
                <img
                  className="object-contain w-auto h-64 mb-8"
                  src="/pages/error-page/illustration.svg"
                  alt="svg"
                />
                <h1 className="text-6xl text-blue-500 mb-4">Add Team</h1>

                <div className="mb-8 text-center text-grey-500">
                  Welcome to {(process.env.BUSSINESS_NAME)?.toUpperCase()}. We're very happy to have you. Add your team & participate our upcoming seasons.
                </div>
                <div className="flex w-full">
                  <button
                    onClick={() => history.push('/player/teams')}
                    className="btn btn-lg btn-rounded btn-block bg-blue-500 hover:bg-blue-600 text-white">
                    Go To Teams & Add Your First Team
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        (teams.length > 0 && !loading) && (

          // <Tabs defaultActiveKey="2">
          //   <Tabs.TabPane key="2" tab="Upcoming">
          //     {upcomingList.length > 0 && <Tabs>
          //       {leagues.map((le, k) => (
          //         <Tabs.TabPane key={k.toString()} tab={le.name}>
          //           <Table
          //             className="upcoming_table"
          //             dataSource={upcomingList}
          //             columns={upcomingcolumns}
          //           />
          //         </Tabs.TabPane>
          //       ))}
          //     </Tabs>}
          //     {upcomingList.length === 0 && <Table
          //       className="upcoming_table"
          //       dataSource={[]}
          //       columns={upcomingcolumns}
          //     />}
          //   </Tabs.TabPane>
          //   <Tabs.TabPane key="1" tab="Registered">
          //     <Table
          //       dataSource={registeredList}
          //       columns={registeredColumns}
          //     />
          //   </Tabs.TabPane>
          // </Tabs>

          <Table
            dataSource={registeredList}
            columns={registeredColumns}
            pagination={false}
          />
        )
      }

      <Drawer
        onClose={() => setVisible(false)}
        visible={visible}
        footer={null}
        className="register_form"
        title="Register your tournament/Season"
      >
        {visible && <>
          <div className="form-item-list">
            {/* <div className="form-items">
              <div className="form-label">Sport Event</div>
              <Select disabled value={values.sports_event_name} placeholder="Select Tournament/Season" options={[]} />
            </div>
            <div className="form-items">
              <div className="form-label">Tier</div>
              <Select disabled value={values.tier_name} placeholder="Select Tier" options={[]} />
            </div> */}
            <div className="form-items">
              <div className="form-label">Leagues</div>
              <Select value={values.league} onChange={e => onChangeValue('league', e)} placeholder="Select League" options={leagues.map(e => ({ label: e.name, value: e._id }))} />
            </div>
            <div className="form-items">
              <div className="form-label">Team</div>
              <Select value={values?.team} onChange={e => onChangeValue('team', e)} placeholder="Select Team" options={teams?.map(e => ({ label: e.name, value: e._id }))} />
            </div>
          </div>
          {values?.team && <div className="team-players-list">
            <div className="team-players-item">
              <div className="act-title">
                Team Players
                <div>Top 5 players are the starting lineup</div>
              </div>
              <Table
                dataSource={teams.filter(ee => ee._id === values?.team)[0]?.players}
                columns={playerColumns}
                pagination={false}
              />
            </div>
          </div>}
          <div className="form-footer">
            <Button loading={submiting} onClick={onProceed} disabled={!(values?.team && values.league)} type="primary">Proceed to Payment</Button>
          </div>
        </>}
      </Drawer>
      <Modal
        visible={payVisible}
        className="pay-modal"
        title="Pay & Register"
        confirmLoading={submiting}
        onOk={() => form.submit()}
        onCancel={() => setPayVisible(false)}
      >
        <div className="pay-modal-content">
          {/* <span className="sym lt">&lt;</span><span className="fees">FEEs</span><span className="sym gt">&gt;</span> */}
          <div className="pay-desc">Please e-Transfer ${values?.entry_fee || selectedItem?.sports_event?.entry_fee || (feesRes?.fee/100)} to tcblbasketball@gmail.com</div>
          <div className="pay-input">
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              onFinish={onConfirmCode}
            >
              <Form.Item
                name="confirmation_code"
                label="Confirmation Code"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Input placeholder="Enter the confirmation code" />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default RegistrationPage;