import { Drawer, Table, Form, Input, Select, DatePicker, Button } from 'antd';
import React, { useState } from 'react'
import moment from 'moment';
import { FiEdit, FiPlus, FiTrash } from 'react-icons/fi';

import SectionTitle from '../../components/section-title'
import { getTimingsByIntervals } from '../../utils';

import data from '../../json/data/tournaments.json';
import courts from '../../json/data/courts.json';
import RemoveModal from '../../components/common/RemoveModal';

const Tournaments = () => {
  const dateFormat = 'ddd, DD MMM, YYYY';
  const [form] = Form.useForm();
  const timeIntervals = getTimingsByIntervals(5);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      name: "name"
    },
    {
      title: "Type",
      dataIndex: "type",
      name: "type"
    },
    {
      title: "Date",
      dataIndex: "date",
      name: "date"
    },
    {
      title: "Time",
      dataIndex: "time",
      name: "time"
    }, {
      title: "Location",
      dataIndex: "court",
      name: "court"
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />
            <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} />
          </div>
        )
      }
    }
  ];

  const onFinish = (values) => {
    console.log(values);
  }

  const onSelect = (item) => {
    item.date = moment(item.date);
    setSelectedItem({
      ...item
    });
    setVisible(true)
  }

  const onDelete = () => {
    setDeletePopupVisibe(false);
  }

  const FormElements = ({ initialValues }) => {
    return (
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select options={["1 ON 1", "3 ON 3"].map(ee => ({ label: ee, value: ee }))} placeholder="Select type" />
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <DatePicker format={dateFormat} />
        </Form.Item>
        <Form.Item
          className="flex-container"
          name="time"
          label="Time"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Form.Item
            name="from"
          >
            <Select options={timeIntervals.map(ee => ({ label: ee, value: ee }))} placeholder="From" />
          </Form.Item>
          <Form.Item
            name="to"
          >
            <Select options={timeIntervals.map(ee => ({ label: ee, value: ee }))} placeholder="To" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="court"
          label="Location"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select options={courts.map(e => ({ label: e.name, value: e.name }))} placeholder="Select location" />
        </Form.Item>
        <div className="form-footer">
          <Button type="primary" htmlType="submit">{selectedItem?.id ? 'Update' : 'Add'} Tournament</Button>
        </div>
      </Form>
    )
  }



  const onAdd = () => {
    setVisible(true)
  }

  return (
    <>
      <RemoveModal description="Are you sure you want to delete?" visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
      <SectionTitle
        title="Tournaments"
        subtitle="List of One-Day Tournaments"
        right={
          <>
            <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add Tournament</span>
            </button>
          </>
        } />
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
      />
      <Drawer
        title="Add Tournament"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {visible && <FormElements initialValues={selectedItem} />}
      </Drawer>
    </>
  )
}
export default Tournaments
