import { Drawer, Form, Input, Select, Button, Tabs, Checkbox, AutoComplete, List, Tooltip, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import $ from 'jquery';
import { debounce } from 'lodash'
import { FiEdit, FiPlus, FiTrash } from 'react-icons/fi';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';

import SectionTitle from '../../../components/section-title'
import RemoveModal from '../../../components/common/RemoveModal';
import ColorPickerInput from '../../../common/color-picker-input';
import UploderInput from '../../../common/uploader-input';
import PlayerTab from '../../../components/players';
import TeamsTab from '../../../components/teams';
import apiClient from '../../../services/axios'
import apiFormDataClient from '../../../services/axios-formdata'
import ApiConfig from '../../../services/ApiConfig';
import { getDateFormat, getFullName, getShortName } from '../../../utils';
import PageLoader from '../../../components/page-loader';

const { TabPane } = Tabs;

const Teams = () => {
  // const dateFormat = 'ddd, DD MMM, YYYY';
  // const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [loading, setLoading] = useState(true);
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [pVisible, setpVisible] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [tabKey, setTabKey] = useState("1");
  const [playTypes, setPlayTypes] = useState(["All"]);
  const [teams, setTeams] = useState([]);
  const [tooltipText, setTooltipText] = useState({ disabled: false, text: "" })
  // const [allTeamPlayers, setAllTeamPlayers] = useState([]);
  const [leagues, setLeagues] = useState([]);

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      name: "name"
    },
    {
      title: "Logo",
      dataIndex: "logo",
      name: "logo",
      render: (text, record) => {
        return <div className="team_logo"><img src={text || '/logos/logo.png'} alt={record.name} /></div>
      }
    },
    {
      title: "Enrolled",
      dataIndex: "enrolled",
      name: "enrolled",
      render: (text, record) => {
        // return record?.enrolled?.map(ee => ee.name)?.join(', ')
        return record?.enrolled?.map((ee, k) => (
          <div key={k.toString()} data-key={ee.name} onClick={() => onSelectLeague(record, ee)} aria-hidden className="play_types"><Tooltip visible={false} arrowPointAtCenter={false} className='t-tip' title="Manage Players"><span className='p-name'>{ee.name}</span> <FiEdit className='ml-2' /></Tooltip></div>
        ))
      }
    },
    {
      title: "Role",
      dataIndex: "role",
      name: "role",
      // render: (text) => !text ? 'Owner' : 'Member'
    },
    {
      title: "Total Players",
      dataIndex: "players",
      name: "players",
      render: (text, record) => record?.players?.length || '-'
    },
    {
      title: "Starting Lineup",
      dataIndex: "players",
      name: "players",
      render: (text, record) => {
        if (record?.players?.length > 0) {
          return (
            <div className='flex flex-row items-center justify-start players_list'>
              {
                record?.players?.filter(ee => ee.is_starting_lineup).map(ee => (
                  <>
                    {ee.profile_pic && <img src={ee.profile_pic} alt={getShortName(ee?.player)} className='player_img h-8 w-8 shadow-outline rounded-full -ml-3' />}
                    {!ee.profile_pic && <span className='player_img h-8 w-8 shadow-outline rounded-full -ml-3'>{getShortName(ee?.player)}</span>}
                  </>
                ))
              }
            </div>
          )
        } else {
          return '-'
        }
      }
    },
    {
      title: "Jersey Color",
      dataIndex: "jersey_color",
      name: "jersey_color",
      render: (text, record) => {
        return <div style={{ background: text }} className='jersey_colors h-8 w-8 shadow-outline rounded-full -ml-3' />
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <>
            {(!record.is_member || record.role === 'Captain' || record.role === 'Owner') && <div className="action_btns">
              {!currentTeam?.is_member && <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />}
              <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} />
            </div>}
            {(record.role !== 'Captain' && record.role !== 'Owner' && record.is_member) && '-'}
          </>
        )
      }
    }
  ];

  const playerColumns = [
    {
      title: "Profile",
      dataIndex: "avatar",
      name: "avatar",
      render: (text, record) => {
        return <div className="team_logo"><img src={record?.player?.profile_pic || '/images/user.svg'} alt={record.name} /></div>
      }
    },
    {
      title: "Player Name",
      dataIndex: "name",
      name: "name",
      render: (text, record) => {
        return <div className="pl-name">
          {/* {record.is_captain && <img alt='captain' src='/images/captain-band.png' />} */}
          {record.is_captain && <Tag color="#12344d">Manager</Tag>}
          {getFullName(record?.player)}
        </div>;
      }
    },
    // {
    //   title: "Enrolled",
    //   dataIndex: "enrolled",
    //   name: "enrolled",
    //   render: (text, record) => {
    //     const enrollName = $('.tab-blk .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn').eq(tabKey).text();
    //     console.log('enrollName', enrollName);
    //     return <div onClick={() => onSelectLeague(record, { name: enrollName })} aria-hidden className="play_types"><Tooltip visible={false} arrowPointAtCenter={false} className='t-tip' title="Manage Players"><span className='p-name'>{enrollName}</span> <FiEdit className='ml-2' /></Tooltip></div>
    //   }
    // },
    // {
    //   title: "Sports Event",
    //   dataIndex: "play_types",
    //   name: "play_types",
    //   render: (text, record) => {
    //     return record?.enrolled?.map(ee => (
    //       <div className="play_types">{ee.name} <FiEdit className='ml-2' /></div>
    //     ))
    //   }
    // },
    {
      title: "Jersey No",
      dataIndex: "jersey_no",
      name: "jersey_no"
    },
    {
      title: "Starting Lineup",
      dataIndex: "is_starting_lineup",
      name: "is_starting_lineup",
      render: (text) => text ? "Yes" : "No"
    },
    {
      title: "Status",
      dataIndex: "is_player_approved",
      name: "is_player_approved",
      render: (text) => <Tag color={text ? "#12344d" : "#e40"}>{text ? 'Confirmed' : 'Pending'}</Tag>
    },
    {
      title: "Joined At",
      dataIndex: "created_at",
      name: "created_at",
      render: (text) => getDateFormat({ date: text })
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <>
            {(currentTeam?.is_member && currentTeam.role !== 'Captain' && currentTeam.role !== 'Owner') && '-'}
            {(!currentTeam?.is_member || currentTeam.role === 'Captain' || currentTeam.role === 'Owner') && <div className="action_btns">
              {((currentTeam?.is_member && currentTeam.role === 'Captain') || currentTeam.role === 'Owner') && <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} />}
              <Button onClick={() => onSelectPlayer(record)} className='btn_edit' type="link" icon={<FiEdit />} />
            </div>}
          </>
        )
      }
    }
  ]

  const onSelectLeague = (item, enroll) => {
    console.log('enroll', enroll);
    $('.tab-blk .ant-tabs-tab').eq(1).trigger('click');
    setCurrentTeam(item)
    setSelectedTeam(item._id)
    setVisible(false);
    setTimeout(() => {
      $('.player-tabs .ant-tabs-tab .ant-tabs-tab-btn').filter((k, e) => $(e).text() === enroll.name).trigger('click')
    }, 100)
  }

  const onSelect = (item) => {
    setSelectedItem({
      ...item
    });
    setVisible(true)
  }

  const onSelectPlayer = (item) => {
    setSelectedItem({
      ...item
    });
    setpVisible(true)
  }

  const onDelete = async () => {
    setDeletePopupVisibe(false);
    if (tabKey === '1') {
      await apiClient.delete(ApiConfig.player.teams + `/${selectedItem._id}`);
    } else {
      await apiClient.delete(ApiConfig.player.removePlayer.replace('team_id', selectedTeam) + `/${selectedItem._id}`);
    }
    const teamDatas = await loadTeams();
    const idx = teamDatas.findIndex(ee => ee._id === selectedTeam);
    if (idx >= 0) {
      setCurrentTeam(teamDatas[idx]);
    }
  }

  const FormElements = ({ initialValues }) => {
    const [loading, setLoading] = useState(false);
    const [logo, setLogo] = useState(null);
    const [isLogoDeleted, setIsLogoDeleted] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
      const enrolled = [];
      if (values.enrolled.indexOf('All') === -1) {
        for (let cnt in values.enrolled) {
          enrolled.push(leagues.filter(ee => ee.name === values.enrolled[cnt])[0]?._id);
        }
      } else {
        leagues.forEach(e => {
          enrolled.push(e._id);
        })
      }
      console.log(values);
      const formdata = new FormData();
      formdata.append('name', values.name);
      formdata.append('jersey_color', values.jersey_color);
      formdata.append('enrolled', JSON.stringify(enrolled));
      if (logo && typeof logo !== 'string') formdata.append('logo', logo);
      if (isLogoDeleted) formdata.append('is_logo_deleted', "true");
      try {
        setLoading(true);
        if (selectedItem?._id) {
          await apiFormDataClient.put(ApiConfig.player.teams + `/${selectedItem._id}`, formdata);
        } else {
          await apiFormDataClient.post(ApiConfig.player.teams, formdata);
        }
        setLoading(false);
        form.setFieldsValue({
          ...{}
        });
        setVisible(false);
        loadTeams();
      } catch (error) {
        setLoading(false);
      }
    }

    const onFileChange = (file) => {
      console.log('file', file);
      if (file) {
        setLogo(file)
      } else {
        setIsLogoDeleted(true)
      }
    }

    const onChangeEnrolled = (values) => {
      console.log(values);
      if (values.indexOf('All') >= 0) {
        const tmp = [];
        leagues.forEach(e => {
          tmp.push(e.name);
        })
        form.setFieldsValue({
          ...form.getFieldsValue(),
          enrolled: tmp
        })
        console.log(tmp);
      }
    }

    useEffect(() => {
      console.log('initialValues', initialValues);
      if (initialValues) {
        form.setFieldsValue({
          name: initialValues.name,
          jersey_color: initialValues.jersey_color,
          enrolled: initialValues?.enrolled?.map(e => e.name) || []
        })
        setLogo(initialValues.logo)
      } else {
        // if (!window.teamTabKey) window.teamTabKey = '0';
        // const entrolledArr = window.teamTabKey === '0' ? playTypes.filter(e => e !== 'All') : [$('.tab-blk .ant-tabs .ant-tabs-tab-btn').eq(parseInt(window.teamTabKey)).text()]
        // form.setFieldsValue({
        //   enrolled: entrolledArr
        // })

      }
      // eslint-disable-next-line
    }, [initialValues])

    return (
      <>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            ...initialValues,
            jersey_color: "#e40000"
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="Team Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter a name" />
          </Form.Item>
          <Form.Item
            name="enrolled"
            label="Enroll"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select allowClear onChange={onChangeEnrolled} mode="multiple" placeholder="Select enroll" options={playTypes.map(ee => ({ label: ee, value: ee }))} />
          </Form.Item>
          <Form.Item
            label="Jersey Color"
            name="jersey_color"
          >
            <ColorPickerInput placeholder="Select Color" />
          </Form.Item>
          <Form.Item
            name="logo"
            label="Team Logo"
            className="upload-img-input"
            help="Recommended size: 128px x 128px and max file size: 500KB"
          >
            <UploderInput url={logo} accept="image/*" onChange={onFileChange} />
          </Form.Item>
          <div className="form-footer">
            <Button loading={loading} type="primary" htmlType="submit">{selectedItem?._id ? 'Update' : 'Add'} Team</Button>
          </div>
        </Form>
        {
          initialValues?.enrolled?.length > 0 && <div className="leagues-list">
            {
              initialValues?.enrolled?.map(ee => (
                <div key={ee.name} onClick={() => onSelectLeague(currentTeam, ee)} className='league-item'>{ee.name} <FiEdit className='ml-2' /></div>
              ))
            }
          </div>
        }
      </>
    )
  }

  const FormPlayerElements = ({ initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);
    const [players, setPlayers] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [playerInfo, setPlayerInfo] = useState({ checked: false })

    const geOptions = (options = []) => {
      return options.map(opt => {
        return {
          value: `${getFullName(opt)}<${opt.email}>`,
          label: (
            <List.Item>
              <List.Item.Meta
                avatar={!opt.profile_pic ? <UserOutlined /> : <img style={{ width: "30px" }} src={opt.profile_pic} alt="" />}
                title={getFullName(opt)}
                description={opt.email}
              />
            </List.Item>
          )
        }
      })
    }

    // eslint-disable-next-line
    const debouncedSearch = useCallback(
      debounce((nextValue, type) => handleSearch(nextValue), 500),
      [], // will be created only once initially
    );

    const onSubmitPlayer = async (values) => {
      try {
        console.log(values);
        const enrolled = [];
        if (values.enrolled.indexOf('All') === -1) {
          for (let cnt in values.enrolled) {
            enrolled.push(leagues.filter(ee => ee.name === values.enrolled[cnt])[0]?._id);
          }
        } else {
          leagues.forEach(e => {
            enrolled.push(e._id);
          })
        }
        values.enrolled = enrolled;
        const payload = {
          players: [values]
        }
        setLoading(true);
        if (selectedItem?._id) {
          delete values.player;
          delete values.team;
          await apiFormDataClient.put(ApiConfig.player.updatePlayer.replace('team_id', selectedTeam) + `/${selectedItem._id}`, values);
        } else {
          await apiFormDataClient.post(ApiConfig.player.addPlayer.replace('team_id', selectedTeam), payload);
        }
        setLoading(false);
        form.setFieldsValue({
          ...{}
        });
        setpVisible(false);
        const teamDatas = await loadTeams();
        const idx = teamDatas.findIndex(ee => ee._id === selectedTeam);
        if (idx >= 0) {
          console.log('teamDatas[idx]', teamDatas[idx]);
          setCurrentTeam(teamDatas[idx]);
        }
      } catch (error) {
        setLoading(false);
      }
    }

    const onSelect = (value = '') => {
      const tmp = players.filter(e => value.indexOf(e.email) >= 0)[0];
      console.log('tmp', tmp);
      setIsOwner(!tmp.is_member);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        player: tmp._id
      });
    };

    const handleSearch = async (value = '') => {
      if (value.length < 2) {
        setPlayers([...[]])
        return;
      };
      try {
        setSearching(true);
        const { data } = await apiClient.get(`${ApiConfig.player.playerSearch}?q=${value}`);
        setPlayers([...data]);
        setSearching(false);
      } catch (error) {
        setSearching(false);
      }
    }

    const onValuesChange = (changedValues) => {
      if (changedValues.is_captain) {
        form.setFieldsValue({
          ...form.getFieldValue(),
          is_starting_lineup: true
        })
      }
    }

    useEffect(() => {
      console.log('initialValues', initialValues);
      if (initialValues) {
        form.setFieldsValue({
          ...initialValues,
          player: getFullName(initialValues?.player),
          enrolled: initialValues?.enrolled?.map(e => e.name)
        })
      } else {
        const tabnm = $('.player-tabs .ant-tabs-tab-active .ant-tabs-tab-btn').text();
        form.setFieldsValue({
          enrolled: [tabnm]
        })
      }
      // eslint-disable-next-line
    }, [initialValues])

    return (
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          ...initialValues,
          is_captain: false,
          is_starting_lineup: false,
          team: selectedTeam
        }}
        onValuesChange={onValuesChange}
        onFinish={onSubmitPlayer}
      >
        <Form.Item
          name="team"
          label="Team"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select disabled options={teams.map(e => ({ label: e.name, value: e?._id }))} placeholder="Select Team" />
        </Form.Item>
        <Form.Item
          name="enrolled"
          label="League"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select mode="multiple" options={playTypes.map(e => ({ label: e, value: e }))} placeholder="Select league" />
        </Form.Item>
        {(!selectedItem?._id && !playerInfo.checked) && <Form.Item className='loader-form-block'>
          <Form.Item
            name="player"
            label="Select Player"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <AutoComplete
              options={geOptions(players)}
              onSelect={onSelect}
              onSearch={(e) => debouncedSearch(e)}
              dropdownClassName="mobile-autocomplete"
            >
              <Input placeholder="Search player by name or email" />
            </AutoComplete>
            {searching && (
              <Form.Item className='loader-form-item' noStyle>
                <span className="input-loader">
                  <LoadingOutlined />
                </span>
              </Form.Item>
            )}
          </Form.Item>
        </Form.Item>}
        {(selectedItem?._id && !playerInfo.checked) && <Form.Item
          name="player"
          label="Player Name"
        >
          <Input disabled />
        </Form.Item>}
        {playerInfo.checked && <Form.Item
          name="existing_players"
          label="Select Players"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select mode="multiple" options={[]} placeholder="Select players" />
        </Form.Item>}
        {!selectedItem?._id && <Form.Item>
          <Checkbox onChange={(e) => setPlayerInfo({ checked: e.target.checked })}>Add players from other leagues</Checkbox>
        </Form.Item>}
        {!playerInfo.checked && <Form.Item
          name="jersey_no"
          label="Jersey Number"
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Input maxLength={3} placeholder="Enter a jersey number" />
        </Form.Item>}
        <Form.Item
          name="is_starting_lineup"
          valuePropName='checked'
        >
          <Checkbox>Add to Starting Lineup</Checkbox>
        </Form.Item>
        <Form.Item
          name="is_captain"
          valuePropName='checked'
        >
          <Checkbox>Team Manager</Checkbox>
        </Form.Item>
        <div className="form-footer">
          <Button loading={loading} type="primary" htmlType="submit">{selectedItem?._id ? 'Update' : `${isOwner ? 'Add you as a player' : 'Send Player Request'}`}</Button>
        </div>
      </Form>
    )
  }

  const onAdd = () => {
    setSelectedItem(null);
    if (tabKey === '1') {
      setVisible(true)
    } else {
      setpVisible(true);
    }
  }

  const onChangeTab = (key) => {
    setTabKey(key);
  }

  const onChangeTeam = (e) => {
    setSelectedTeam(e);
    setCurrentTeam(teams.filter(ee => ee._id === e)[0]);
  }

  const FilterBlock = () => (
    <div className='top-filter-block'>
      <div className='filter-label'>Select Team</div>
      <Select value={selectedTeam} onChange={onChangeTeam} options={teams.map(e => ({ label: e.name, value: e._id }))} placeholder="Select Team" />
    </div>
  )

  const loadTeams = async () => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(ApiConfig.player.teams);
      setTeams([...data])
      if (!selectedTeam && data.length > 0) {
        setSelectedTeam(data[0]._id);
        setCurrentTeam({ ...data[0] });
      }
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      return [];
    }
  }

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.player.leagues);
    setLeagues([...data]);
    setPlayTypes([...playTypes, ...data.map(e => e.name)])
  }

  const getPlayers = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        const type = $('.tab-blk .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn').eq(1).text()
        const temp = currentTeam?.players || [];
        const tTmp = [];
        temp.forEach(ee => {
          const enrolled = currentTeam?.enrolled?.filter(et => {
            if (typeof ee?.enrolled[0] === 'string') {
              return ee?.enrolled?.indexOf(et._id) >= 0
            }
            return ee?.enrolled?.findIndex(tt => et._id === tt._id) >= 0
          });
          ee.enrolled = enrolled;
          tTmp.push(ee);
        })
        console.log('tTmp', type, tTmp.filter(ee => ee?.enrolled?.map(e => e.name)?.indexOf(type) >= 0));
        // return tTmp.filter(ee => ee?.enrolled?.map(e => e.name)?.indexOf(type) >= 0)
        resolve(tTmp.filter(ee => ee?.enrolled?.map(e => e.name)?.indexOf(type) >= 0))
      }, 200);
    })
  }

  const getTooltipText = async () => {
    let payload = { disabled: false, text: '' };
    if ((currentTeam?.role !== 'Captain' && currentTeam?.role !== 'Owner')) {
      payload.text = "You are not the captain of this team";
      payload.disabled = true;
    } else {
      const pp = await getPlayers();
      if (pp?.length >= 12) {
        payload.text = "You can't add player. Maximum 12 players per league."
        payload.disabled = true;
      }
    }
    setTooltipText({ ...payload })
    return payload
  }

  useEffect(() => {
    window.$ = $;
    loadTeams().then(data => {
      setSelectedTeam(data[0]?._id)
      setCurrentTeam(data[0]);
    });
    loadLeagues();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (selectedItem) {
      // players
    }
  }, [selectedItem])

  return (
    <>
      <SectionTitle
        title="Teams & Players"
        subtitle="Manage Teams & Players"
        right={
          teams.length > 0 && (
            <>
              {tabKey === '1' && <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
                <FiPlus className="stroke-current text-white" size={16} />
                <span>Add Team</span>
              </button>}

              {tabKey === '2' && <Tooltip placement="left" title={tooltipText.text}>
                <div>
                  {/* disabled={(tabKey === '2' && (currentTeam?.role !== 'Captain' && currentTeam?.role !== 'Owner'))} */}
                  <button onClick={() => { if (!tooltipText.disabled) onAdd() }} className={`btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1${tooltipText.disabled ? ' disabled' : ''}`}>
                    <FiPlus className="stroke-current text-white" size={16} />
                    <span>Add Player</span>
                  </button>
                </div>
              </Tooltip>}
            </>
          )
        }
      />
      {
        loading && (
          <PageLoader />
        )
      }
      {
        (teams.length === 0) && (
          <div style={{ marginTop: "120px" }} className="w-full flex items-center justify-center bg-grey-50">
            <div className="flex flex-col w-full max-w-xl text-center">
              <img
                className="object-contain w-auto h-64 mb-8"
                src="/pages/error-page/illustration.svg"
                alt="svg"
              />
              <h1 className="text-6xl text-blue-500 mb-4">Add Team</h1>

              <div className="mb-8 text-center text-grey-500">
                Welcome to {(process.env.BUSSINESS_NAME)?.toUpperCase()}. We're very happy to have you. Add your team & participate our upcoming seasons.
              </div>
              <div className="flex w-full">
                <button
                  onClick={onAdd}
                  className="btn btn-lg btn-rounded btn-block bg-blue-500 hover:bg-blue-600 text-white">
                  Add Your First Team
                </button>
              </div>
            </div>
          </div>
        )
      }
      {
        (teams.length > 0) && (
          <div className='teams_player_page'>
            <RemoveModal description="Are you sure you want to delete?" visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
            <Tabs className={`tab-blk${tabKey === '1' ? ' team-tab' : ''}`} defaultActiveKey="1" onChange={onChangeTab} tabBarExtraContent={tabKey === '2' && <FilterBlock />}>
              <TabPane tab="Teams" key="1">
                <TeamsTab playTypes={playTypes} teams={teams} columns={columns} />
              </TabPane>
              <TabPane tab="Players" key="2">
                <PlayerTab playTypes={playTypes} onChangePlayerTab={(tab) => {
                  console.log('tab', tab);
                  getTooltipText()
                }} currentTeam={currentTeam} playerColumns={playerColumns} />
              </TabPane>
            </Tabs>
          </div>
        )
      }
      <Drawer
        title={`${selectedItem?._id ? 'Update' : 'Add'} Team`}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {visible && <FormElements initialValues={selectedItem} />}
      </Drawer>
      <Drawer
        title={`${selectedItem?._id ? 'Update' : 'Add'} Player`}
        visible={pVisible}
        onClose={() => setpVisible(false)}
      >
        {pVisible && <FormPlayerElements initialValues={selectedItem} />}
      </Drawer>
    </>
  )
}
export default Teams