import moment from "moment";

export const getDateFormat = (props, flag) => {
  const dFormat = "MMM DD, YYYY";
  if (typeof props === 'object') {
    const { date, format = dFormat, isTime } = props || {}
    if (!date) return;
    if (isTime) return moment(date).format(`${format} hh:mm a`)
    return moment(date).format(format)
  } else {
    if (flag) return props ? moment(props) : moment();
    return props ? moment(props).format(`${dFormat}`) : moment();
  }
}

export const getDateFormat2 = (props, flag) => {
  const dFormat = "hh:mm A";
  if (typeof props === 'object') {
    const { date, format = dFormat, isTime } = props || {}
    if (!date) return;
    if (isTime) return moment(date).format(`${format}`)
    return moment(date).format(format)
  } else {
    if (flag) return props ? moment(props) : moment();
    return props ? moment(props).format(`${dFormat}`) : moment();
  }
}


export function getTimingsByIntervals(interval = 5, from = 0, to = 24) {
  const result = [];
  const start = new Date();
  start.setHours(from, 0, 0); // 00 AM
  const end = new Date();
  end.setHours(to, 0, 0); // 12 AM

  while (start <= end) {
    result.push(start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' }));
    start.setMinutes(start.getMinutes() + interval);
  }
  return result.filter(e => !!e).filter((x, i, a) => a.indexOf(x) === i);
}

export const hoursToMinutes = (hour = '') => {
  if (!hour) return null
  let hours = parseInt(hour.split(':')[0], 10)
  const mins = parseInt(hour.split(':')[1] || '00', 10) / 60
  const meridiem = hour.split(' ')[1]
  if (hour === '12:00 AM') {
    return 0
  }
  if (meridiem === 'PM' && hours < 12) {
    hours += 12
  }
  if (meridiem === 'AM' && hours >= 12) {
    hours -= 12
  }
  return Math.round((hours + mins) * 60)
}

export const minutesToHours = mins => {
  let hours = Math.trunc(mins / 60)
  if (hours > 12) {
    hours -= 12
  } else if (hours === 0) {
    hours = 12
  }
  const minutes = mins % 60
  return hours
    .toString()
    .concat(':')
    .concat(minutes.toString().length === 1 ? '0'.concat(minutes.toString()) : minutes.toString())
    .concat(mins >= 720 ? ' PM' : ' AM')
}

export const getShortName = (props) => {
  if (!props) return;
  const { first_name = '', last_name = '' } = props
  if (first_name && last_name) {
    return first_name[0].toUpperCase() + last_name[0].toUpperCase()
  } else if (first_name) {
    return first_name[0].toUpperCase() + first_name[1]?.toUpperCase()
  }
  return ''
}

export const getFullName = (props) => {
  if (!props) return;
  const { first_name = '', last_name = '' } = props
  if (first_name && last_name) {
    return first_name + ' ' + last_name;
  }
  return first_name || ""
}

export const getQueryParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const query = {};
  urlParams.forEach((e, k) => {
    console.log(e, k);
    query[k] = e;
  })
  return query;
}


export const recipientArray = [
  {
    "label": "All Players",
    "value": 1
  },
  {
    "label": "Test - TCBL",
    "value": 2
  },
  {
    "label": "Test - PDEX",
    "value": 3
  },
  {
    "label": "Custom",
    "value": 4
  }
]

export const pdexEmailList = ['reeta@pdex.ca', 'gajeevan@pdex.ca', 'divakar@pdex.ca']


export const tcblEmailList = ['tcblbasketball@gmail.com']