import React, { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import store from 'store'
// import Index from './pages/index'
import Dashboard from './pages/dashboard'
import ContactUs from './pages/contact-us'
import ErrorPage from './pages/error-page'
import ForgotPassword from './pages/auth/forgot-password'
import PrivacyPolicy from './pages/privacy-policy'
import ResetPassword from './pages/auth/reset-password'
import SetPassword from './pages/auth/set-password'
import VerifyEmail from './pages/auth/verify-email'
import TermsOfService from './pages/terms-of-service'
import UserProfile from './pages/profile/user-profile'
import Courts from './pages/courts'
import Users from './pages/users'
import Tournaments from './pages/tournaments'
import Scorekeeper from './pages/scorekeeper'
import Scorekeepermanager from './pages/scorekeepermanager'
import Scorekeepermanagernew from './pages/scorekeepermanagernew'
import Scorekeepermanagerlive from './pages/scorekeepermanagerlive'
import Teams from './pages/player/teams'
import RegistrationPage from './pages/player/registration'
import SchedulePage from './pages/schedule'
import LoginPage from './pages/auth/login'
import RegisterPage from './pages/auth/register'
import InvitePage from './pages/player/invites'
import LeagueAndSeasonPage from './pages/leagues'
import WebsitePage from './pages/website'
import ScheduleGamePage from './pages/schedule-game'
import ManageTeams from './pages/manage-teams'
import ManageRegistrationPage from './pages/registrations'
import WebsiteManager from './pages/website-manager'
import MatchesPage from './pages/matches'
import MatchesPagenewlogs from './pages/scorekeepermanagernewlogs'
import MarketingPage from './pages/marketing'

const Routes = () => {
  const history = useHistory();
  useEffect(() => {
    const token = store.get('user.accessToken');
    if (
      [
        '/',
        'login/',
        'register/',
        '/forgot-password',
        '/reset-password',
        '/set-password',
        '/verify-account',
        '/contact-us',
        '/terms-of-service',
        '/privacy-policy',
        '/game-schedules',
        '/schedule-game',
        '/tcbl-leagues',
        '/rules-and-regulations',
        '/support',
        '/live-scores',
      ].findIndex(ee => ee === window.location.pathname) === -1
    ) {
      if (!token && window.location.pathname !== '/') {
        history.replace('/');
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/register">
        <RegisterPage />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/set-password">
        <SetPassword />
      </Route>
      <Route path="/verify-account">
        <VerifyEmail />
      </Route>

      <Route path="/dashboard">
        <Dashboard />
      </Route>
      <Route path="/courts">
        <Courts />
      </Route>
      <Route path="/tournaments">
        <Tournaments />
      </Route>
      <Route path="/leagues">
        <LeagueAndSeasonPage />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/scorekeeper">
        <Scorekeeper />
      </Route>
      <Route path="/scorekeepermn">
        <Scorekeepermanager />
      </Route>
      <Route path="/scorekeepermanagernew">
        <Scorekeepermanagernew />
      </Route>
      <Route path="/scorekeepermanagerlive">
        <Scorekeepermanagerlive />
      </Route>
      <Route path="/schedules">
        <SchedulePage />
      </Route>
      <Route path="/manage-teams">
        <ManageTeams />
      </Route>
      <Route path="/registrations">
        <ManageRegistrationPage />
      </Route>
      <Route path="/marketing">
        <MarketingPage />
      </Route>
      <Route path="/manage-website">
        <WebsiteManager />
      </Route>
      <Route path="/matches">
        <MatchesPage />
      </Route>
      <Route path="/matchesmn">
        <MatchesPagenewlogs />
      </Route>
      <Route path="/player/teams">
        <Teams />
      </Route>
      <Route path="/player/registration">
        <RegistrationPage />
      </Route>
      <Route path="/user-profile">
        <UserProfile />
      </Route>
      <Route path="/player/invitations">
        <InvitePage />
      </Route>

      <Route path="/contact-us">
        <ContactUs />
      </Route>
      <Route path="/error-page">
        <ErrorPage />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-service">
        <TermsOfService />
      </Route>
      <Route path="/game-schedules">
        <WebsitePage />
      </Route>
      <Route path="/schedule-game">
        <ScheduleGamePage />
      </Route>
      <Route path="/tcbl-leagues">
        <WebsitePage />
      </Route>
      <Route path="/rules-and-regulations">
        <WebsitePage />
      </Route>
      <Route path="/support">
        <WebsitePage />
      </Route>
      <Route path="/live-scores">
        <WebsitePage />
      </Route>
      <Route exact path="/">
        <LoginPage />
      </Route>
      <Route component={WebsitePage} />
    </Switch>
  )
}
export default Routes
