import React, { useEffect, useState } from 'react'
import $ from 'jquery'
// import { useSelector, shallowEqual } from 'react-redux'
import store from 'store'
import Item from './item'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import apiClient from '../../services/axios'
import getMenuItems from '../../services/menu'
import ApiConfig from '../../services/ApiConfig'

const Sidebar = () => {
  // const { navigation } = useSelector(
  //   (state) => ({
  //     navigation: state.navigation
  //   }),
  //   shallowEqual
  // )
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const data = store.get('user.data');
    console.log('data', data);
    // const tmp = navigation[0]?.items?.filter(e => e?.roles?.indexOf(data?.role) >= 0);
    // navigation[0].items = tmp;
    const menus = getMenuItems();
    const tmp = menus[0]?.items?.filter(e => e?.roles?.indexOf(data?.role) >= 0);
    menus[0].items = tmp;
    setMenuItems([...menus])
    apiClient.get(ApiConfig.profile.load).then(resp => {
      store.set('user.data', resp.data);
      if (resp?.data?.pending_invites > 0) {
        $('a[href="/player/invitations"] .title').append(`<b>${resp.data?.pending_invites}</b>`);
      }
    });
    // eslint-disable-next-line
  }, [])

  return (
    <div className="left-sidebar left-sidebar-1">
      <Logo />
      <div className="sidbar-items">
        {menuItems.map((menu, i) => (
          <div key={i}>
            {/* <div className="left-sidebar-title">
              <span>{menu.title}</span>
            </div> */}
            <ul>
              {menu.items.map((l0, a) => (
                <li key={a} className="l0">
                  <Item {...l0} />
                  <ul>
                    {l0.items.map((l1, b) => (
                      <li key={b} className="l1">
                        <Item {...l1} />
                        <ul>
                          {l1.items.map((l2, c) => (
                            <li key={c} className="l2">
                              <Item {...l2} />
                              <ul>
                                {l2.items.map((l3, d) => (
                                  <li key={d} className="l3">
                                    <Item {...l3} />
                                    <ul>
                                      {l3.items.map((l4, e) => (
                                        <li key={e} className="l4">
                                          <Item {...l4} />
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sidebar
