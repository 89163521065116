import { Button, Checkbox, Drawer, Input, Select, Table, Tabs } from "antd";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../common/confirm-modal";
import PageLoader from "../../components/page-loader";
import SectionTitle from "../../components/section-title";
import { PlayerNameWithPicture, TeamNameWithLogo } from "../../components/teams";
import ApiConfig from "../../services/ApiConfig";
import apiClient from "../../services/axios";
import { getDateFormat } from "../../utils";

const ManageRegistrationPage = () => {

  const history = useHistory();
  const [registeredList, setRegisteredList] = useState([]);
  const [upcomingList, setUpcomingList] = useState([]);
  const [submiting, setSubmiting] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [leagues, setLeagues] = useState([]);
  const [teams, setTeams] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [values, setValues] = useState({});

  const upcomingcolumns = [
    {
      title: "No.",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, key) => key + 1
    },
    {
      title: "League",
      dataIndex: "name",
      key: "name",
      render: (text, record) => `${record?.league?.name}`
    },
    // {
    //   title: "Season Tier",
    //   dataIndex: "tier",
    //   key: "tier",
    //   render: (text, record) => `Tier ${record?.tier?.tier}`
    // },
    // {
    //   title: "Available Teams",
    //   dataIndex: "team",
    //   key: "team",
    //   render: (text, record) => record?.available_teams?.map(e => e.name)?.join(', ') || ''
    // },
    {
      title: "Registered Team",
      dataIndex: "team",
      key: "team",
      render: (text, record) => <TeamNameWithLogo {...record.team} />
    },
    {
      title: "Registered by",
      dataIndex: "team",
      key: "team",
      render: (text, record) => <PlayerNameWithPicture {...record.created_by} />
    },
    // {
    //   title: "Start Date",
    //   dataIndex: "start_date",
    //   key: "start_date",
    //   render: (text, record) => getDateFormat(record?.tier?.start_date)
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <div className="action_btns">
            <Button className='btn_edit' onClick={() => onRegister(record)} type="primary">Register</Button>
          </div>
        )
      }
    }
  ]

  const registeredColumns = [
    // {
    //   title: "S.No",
    //   dataIndex: "sno",
    //   key: "sno",
    //   render: (text, record, key) => key + 1
    // },
    {
      title: "Sports Event",
      dataIndex: "sports_event",
      key: "sports_event",
      render: (text, record) => `${record?.sports_event?.name}`
    },
    {
      title: "Season Tier",
      dataIndex: "tier",
      key: "tier",
      render: (text) => text ? `Tier ${text}` : '-'
    },
    {
      title: "Registered Team",
      dataIndex: "team",
      key: "team",
      render: (text, record) => <TeamNameWithLogo {...record?.team} />
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => getDateFormat(text)
    },
    {
      title: "Registered By",
      dataIndex: "registered_by",
      key: "registered_by",
      render: (text, record) => <PlayerNameWithPicture {...record.created_by} />
    },
    {
      title: "Confirmed By",
      dataIndex: "confirmed_by",
      key: "sports_event",
      render: (text, record) => <PlayerNameWithPicture {...record.confirmed_by} />
    },
    // {
    //   title: "Paid",
    //   dataIndex: "is_entry_fee_paid",
    //   key: "is_entry_fee_paid",
    //   render: (text) => <Tag color={text ? '#4BB543' : '#E40000'}>{text ? 'Yes' : 'No'}</Tag>
    // },
    {
      title: "Confirmed At",
      dataIndex: "confirmed_at",
      key: "confirmed_at",
      render: (text) => getDateFormat(text)
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   key: "actions",
    //   render: (text, record) => {
    //     return (
    //       <div className="action_btns">
    //         <Button className='btn_edit mr-2' onClick={() => onCancel(record)} type="ghost">Cancel</Button>
    //       </div>
    //     )
    //   }
    // }
  ]

  const onChangeValue = (type, value) => {
    console.log('value', value);
    values[type] = value;
    if (type === 'sports_event') {
      const season = seasons.filter(ee => ee._id === value)[0];
      values.sports_event_name = season?.name;
      values.tiers = season?.leagues?.filter(e => e.league._id === values?.league?._id)[0]?.tiers?.filter(e => e?.tier);
      values.tier = undefined;
    } else if (type === 'confirmation_code') {
      values.is_submitted = true;
    }
    setValues({
      ...values
    })
  }

  // const onCancel = async (record) => {
  //   const bool = await ConfirmationModal('Are you sure want to cancel this registration?');
  //   if (bool) {
  //     await apiClient.put(`${ApiConfig.player.registrations.registered}/${record._id}/cancel`);
  //     loadRegistered();
  //   }
  // }

  const onRegister = async (record) => {
    setValues({
      _id: record._id,
      entry_fee_confirmation_code: record.entry_fee_confirmation_code,
      tiers: [],
      team: record?.team,
      league: record?.league,
      seasons: seasons.filter(e => e?.leagues?.findIndex(ee => ee.league._id === record.league._id) >= 0),
    })
    setVisible(true);
  }

  const loadRegistered = async () => {
    const { data } = await apiClient.get(ApiConfig.player.registrations.registered);
    setRegisteredList([...data]);
  }

  const loadUpcoming = async () => {
    const { data } = await apiClient.get(ApiConfig.player.registrations.upcoming);
    setUpcomingList([...data])
  }

  const loadTeams = async () => {
    const { data } = await apiClient.get(ApiConfig.player.teams);
    setTeams([...data])
  }

  const loadSeasons = async () => {
    const { data } = await apiClient.get(`${ApiConfig.seasons}?status=Started`);
    setSeasons([...data])
  }

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.player.leagues);
    setLeagues([...data])
  }

  const onProceed = async () => {
    console.log('values', values);
    setValues({
      ...values,
      is_submitted: true
    })
    const { sports_event, team, tier, confirmation_code, proceed_without_code, entry_fee_confirmation_code, league, _id } = values;
    if (confirmation_code !== entry_fee_confirmation_code && !proceed_without_code) return;
    const bool = await ConfirmationModal(proceed_without_code ? "Are you sure you want to continue without confirm the code?" : "Are you sure you want to register the season?")
    if (!bool) return;
    try {
      setSubmiting(true);
      await apiClient.post(`${ApiConfig.player.registrations.registeredWithConfirm}/${_id}`, { sports_event, team: team._id, league: league._id, tier, confirmation_code, proceed_without_code });
      setSubmiting(false);
      setVisible(false);
      loadRegistered();
      loadUpcoming();
      $('.ant-tabs-tab').eq(1).trigger('click')
    } catch (error) {
      setSubmiting(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    loadRegistered();
    loadLeagues();
    loadSeasons();
    loadTeams().then(() => setLoading(false)).catch(() => setLoading(false));
    loadUpcoming() //.then(() => setLoading(false)).catch(() => setLoading(false));;
  }, [])

  return (
    <div className="registration-page">
      <SectionTitle
        title="Registration"
        subtitle="Manage Registrations"
      />
      {
        loading && (
          <PageLoader />
        )
      }
      {
        (!loading && teams.length === 0) && (
          <>
            <div style={{ marginTop: "120px" }} className="w-full flex items-center justify-center bg-grey-50">
              <div className="flex flex-col w-full max-w-xl text-center">
                <img
                  className="object-contain w-auto h-64 mb-8"
                  src="/pages/error-page/illustration.svg"
                  alt="svg"
                />
                <h1 className="text-6xl text-blue-500 mb-4">Add Team</h1>

                <div className="mb-8 text-center text-grey-500">
                  Welcome to {(process.env.BUSSINESS_NAME)?.toUpperCase()}
                </div>
                <div className="flex w-full">
                  <button
                    onClick={() => history.push('/manage-teams')}
                    className="btn btn-lg btn-rounded btn-block bg-blue-500 hover:bg-blue-600 text-white">
                    Go To Teams & Add Teams
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        (teams.length > 0 && !loading) && (
          <Tabs defaultActiveKey="2">
            <Tabs.TabPane key="2" tab="Upcoming">
              {upcomingList.length > 0 && <Tabs>
                {leagues.filter(lt => upcomingList.findIndex(up => up.league._id === lt._id) >= 0).map((le, k) => (
                  <Tabs.TabPane key={k.toString()} tab={le.name}>
                    <Table
                      className="upcoming_table"
                      dataSource={upcomingList.filter(e => e?.league?._id === le._id)}
                      columns={upcomingcolumns}
                      pagination={false}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>}
              {/* {upcomingList.length === 0 && <div className="no_data_found">No upcoming seasons found...</div>} */}
              {upcomingList.length === 0 && <Table
                className="upcoming_table"
                dataSource={[]}
                pagination={false}
                columns={upcomingcolumns}
              />}
            </Tabs.TabPane>
            <Tabs.TabPane key="1" tab="Registered">
              {registeredList.length > 0 && <Tabs>
                {leagues.filter(lt => registeredList.findIndex(up => up.league._id === lt._id) >= 0).map((le, k) => (
                  <Tabs.TabPane key={k.toString()} tab={le.name}>
                    <Table
                      dataSource={registeredList.filter(e => e?.league?._id === le._id)}
                      columns={registeredColumns}
                      pagination={false}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>}
              {registeredList.length === 0 && <Table
                dataSource={[]}
                pagination={false}
                columns={registeredColumns}
              />}
            </Tabs.TabPane>
          </Tabs>
        )
      }

      <Drawer
        onClose={() => setVisible(false)}
        visible={visible}
        footer={null}
        className="register_form"
        title="Register the Season"
      >
        {visible && <>
          <div className="form-item-list">
            <div className="form-items">
              <div className="form-label">League</div>
              <Select disabled value={values?.league?.name} onChange={e => onChangeValue('league', e)} placeholder="Select League" options={[]} />
            </div>
            <div className="form-items">
              <div className="form-label">Sport Event</div>
              <Select value={values.sports_event_name} onChange={e => onChangeValue('sports_event', e)} placeholder="Select Tournament/Season" options={values?.seasons?.map(se => ({ label: se.name, value: se._id }))} />
            </div>
            {(!values.sports_event_name || values?.tiers?.length > 0) && <div className="form-items">
              <div className="form-label">Tier</div>
              <Select value={values.tier} onChange={e => onChangeValue('tier', e)} placeholder="Select Tier" options={values?.tiers?.map(se => ({ label: `Tier ${se.tier}`, value: se.tier }))} />
            </div>}
            <div className="form-items">
              <div className="form-label">Team</div>
              <Select disabled value={values?.team?.name} onChange={e => onChangeValue('team', e)} placeholder="Select Team" options={[]} />
            </div>
            {!values?.proceed_without_code && <div className="form-items">
              <div className="form-label">Payment confirmation code</div>
              <Input maxLength={15} value={values?.confirmation_code} onChange={e => onChangeValue('confirmation_code', e.target.value)} placeholder="Enter a code" />
              {(values?.is_submitted && values.entry_fee_confirmation_code !== values?.confirmation_code) && <div className="error-msg">Confirmation code doesn't match</div>}
            </div>}
            <div className="form-items">
              <Checkbox checked={values.proceed_without_code} onChange={e => onChangeValue('proceed_without_code', e.target.checked)}>Proceed without confirmation code</Checkbox>
            </div>
          </div>
          <div className="form-footer">
            <Button loading={submiting} onClick={onProceed} disabled={!values?.tier && !(values?.tiers?.length === 0 && values.sports_event_name)} type="primary">Register</Button>
          </div>
        </>}
      </Drawer>
    </div>
  )
}

export default ManageRegistrationPage;