/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { getDateFormat2 } from "../../../utils";


const DesktopViewComponent = ({ itemsApi, teamsApi }) => {

  const [maxNumber, setmaxNumber] = useState(null);

  const teamsNameLogo = (values) => {
    const filterlogo = teamsApi?.filter(x => x?._id === values)[0]?.logo
    const filtername = teamsApi?.filter(x => x?._id === values)[0]?.name
    return (
      <div className="team_name_logo">
        <img src={filterlogo || '/logos/logo.png'} alt="" />
        <span className="ml-2">{filtername}</span>
      </div>
    )
  }

  useEffect(() => {
    if (itemsApi !== null) {
      callsorting(itemsApi?.teams[0].score, itemsApi?.teams[1].score)
    }
  }, [itemsApi])


  const callsorting = (values1, values2) => {
    const arrayinfo = [{ scores: values1 }, { scores: values2 }]
    let objMax = arrayinfo.reduce((max, curren) => max.scores > curren.scores ? max : curren);
    // console.log("objMax", objMax)
    setmaxNumber(objMax?.scores)
    // return objMax?.scores
  }

  return (
    <>
      {itemsApi?.status ===
        "Completed" && (
          <>
            <table className="scores-width scores-hr-line">
              <tr>
                <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.team)}</td>
                <td className="scores-td-home-score-width" >{itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.score}</td>
                <td className="scores-td-won-score-width">{itemsApi?.status === 'Completed' && (<>Final</>)}</td>
              </tr>
              <tr>
                <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0]?.team)}</td>
                <td className="scores-td-home-score-width">{itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0]?.score}</td>
                <td className="scores-td-won-score-width"></td>
              </tr>
            </table>
          </>
        )}

      {itemsApi?.status ===
        "Scheduled" && (
          <>
            <table className="scores-width scores-hr-line">
              <tr>
                <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.team)}</td>
                <td className="scores-td-home-score-width"></td>
                <td className="scores-td-won-score-width">{getDateFormat2(itemsApi?.date)}</td>
              </tr>
              <tr>
                <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0]?.team)}</td>
                <td className="scores-td-home-score-width"></td>
                <td className="scores-td-won-score-width"></td>
              </tr>
            </table>
          </>
        )}

      {itemsApi?.status === "Started" && (
        <>
          <table className="scores-width scores-hr-line">
            <tr>
              <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(x => x.score === maxNumber)[0]?.team)}</td>
              <td className="scores-td-home-score-width">{itemsApi?.teams.filter(x => x.score === maxNumber)[0]?.score}</td>
              <td className="scores-td-won-score-width"> Half Time</td>
            </tr>
            <tr>
              <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(x => x.score !== maxNumber)[0]?.team)}</td>
              <td className="scores-td-home-score-width">{itemsApi?.teams.filter(x => x.score !== maxNumber)[0]?.score}</td>
              <td className="scores-td-won-score-width"></td>
            </tr>
          </table>
        </>
      )}

      {itemsApi?.status === "Assigned" && (
        <>
          <table className="scores-table-width">
            <tr>
              <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(a => a?.team === itemsApi?.won_by?.team)[0]?.team)}</td>
              <td className="scores-td-home-score-width"></td>
              <td className="scores-td-won-score-width">
                <>
                  Starting
                </>
              </td>
            </tr>
            <tr className="scores-hr-line">
              <td className="scores-td-logo-width">{teamsNameLogo(itemsApi?.teams.filter(a => a?.team !== itemsApi?.won_by?.team)[0]?.team)}</td>
              <td className="scores-td-home-score-width"></td>
              <td className="scores-td-won-score-width">
                <>
                </>
              </td>
            </tr>
          </table>
        </>
      )}

    </>
  )
}

export default DesktopViewComponent;