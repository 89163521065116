import { Drawer, Table, Form, DatePicker, Button, Checkbox, Tabs, Select, Input, Switch, Tag, Tooltip, Menu, Dropdown, List, Divider } from 'antd';
import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { FiEdit, FiMinus, FiPlus, FiTrash } from 'react-icons/fi';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import SectionTitle from '../../components/section-title'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'
import RemoveModal from '../../components/common/RemoveModal';
import ConfirmationModal from '../../common/confirm-modal';
import { TeamNameWithLogo } from '../../components/teams';
import { getDateFormat } from '../../utils';

const LeagueAndSeasonPage = () => {
  const dateFormat = 'DD MMM, YYYY';
  const tiers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];
  const statusMap = {
    "Draft": "#85C1E9",
    "Started": "#4BB543",
    "Published": "#4BB543",
    "Cancelled": "#E40",
    "Completed": "#12344D"
  }
  const [form] = Form.useForm();
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const [deletePopupVisible, setDeletePopupVisibe] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [adding, setAdding] = useState(false);
  const [listing, setListing] = useState(false);
  const [regTeamsDet, setRegTeamsDet] = useState({ visible: false, teams: [] });
  const [leagues, setLeagues] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTab, setSelectedTab] = useState("2");

  const columns = [
    {
      title: "League Day",
      dataIndex: "day",
      key: "day",
      render: (text) => weekDays[text]
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => getDateFormat({ data: text?.start_date, format: "MMM DD, YYYY hh:mm a" })
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, idx) => {
        return (
          <div className="action_btns">
            <Switch checked={record.is_active} onChange={(e, event) => onToggleLeague(e, record, event)} />
            {/* <Button onClick={() => { setSelectedItem(record); setDeletePopupVisibe(true); }} type="link" icon={<FiTrash />} /> */}
            {/* <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} /> */}
          </div>
        )
      }
    }
  ];

  const SeasonStatus = ({ selected, onClick }) => {
    const [popVisible, setPopVisible] = useState(false);

    const MenuDiv = () => (
      <Menu onClick={(key) => {
        if (onClick) onClick(key.key);
        setPopVisible(false)
      }}>
        {selected !== 'Draft' && <Menu.Item key="Draft">Draft</Menu.Item>}
        {selected !== 'Cancelled' && <Menu.Item key="Cancelled">Cancelled</Menu.Item>}
        {selected !== 'Completed' && <Menu.Item key="Completed">Completed</Menu.Item>}
      </Menu>
    )

    return (
      <Dropdown visible={popVisible} onVisibleChange={e => setPopVisible(e)} overlayClassName="season_status" overlay={<MenuDiv />} trigger={['click']}><FiEdit size={20} /></Dropdown>
    )
  }

  const TierStatus = ({ selected, onClick }) => {
    const [popVisible, setPopVisible] = useState(false);

    const MenuDiv = () => (
      <Menu onClick={(key) => {
        if (onClick) onClick(key.key);
        setPopVisible(false)
      }}>
        {selected !== 'Draft' && <Menu.Item key="Draft">Draft</Menu.Item>}
        {selected !== 'Cancelled' && <Menu.Item key="Cancelled">Cancelled</Menu.Item>}
        {selected !== 'Completed' && <Menu.Item key="Completed">Completed</Menu.Item>}
      </Menu>
    )

    return (
      <Dropdown visible={popVisible} onVisibleChange={e => setPopVisible(e)} overlayClassName="season_status" overlay={<MenuDiv />} trigger={['click']}><FiEdit size={20} /></Dropdown>
    )
  }

  const SeasonColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "League",
      dataIndex: "league",
      key: "league",
      render: (text, record) => {
        return record?.league?.name
      }
    },
    {
      title: "Tiers",
      dataIndex: "tiers",
      key: "tiers",
      render: (text, record) => {
        return record?.tiers?.filter(e => e.type === 'Regular')?.map(e => e.tier)?.join(', ') || '-'
      }
    },
    {
      title: "COED Tiers",
      dataIndex: "tiers",
      key: "tiers",
      render: (text, record) => {
        return record?.tiers?.filter(e => e.type === 'CO-ED')?.map(e => e.tier)?.join(', ') || '-'
      }
    },
    {
      title: "Registered Teams",
      dataIndex: "registered_team_count",
      key: "registered_team_count",
      render: (text) => `${text} team(s)`
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <div className='status-div'><Tag color={statusMap[text]}>{text}</Tag> {text !== 'Draft' && <SeasonStatus selected={text} onClick={(k) => onChangeSeasonStatus(k, record)} />}</div>
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => getDateFormat(text)
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <div className="action_btns">
            {record?.registered_team_count === 0 && <Button onClick={(e) => {
              onSeasonDelete(record, e.target)
            }} type="link" icon={<FiTrash />} />}
            {!(record?.status === 'Completed' || record?.status === 'Cancelled') && <Button onClick={() => onSelect(record)} className='btn_edit' type="link" icon={<FiEdit />} />}
            {record?.status === 'Draft' && <Button onClick={() => onStart(record)} type="primary" className='btn_publish ml-2'>Start</Button>}
            {/* <Button onClick={() => onView(record)} className='btn_edit' type="link" icon={<FiInfo />} /> */}
          </div>
        )
      }
    }
  ];

  // const onView = (record) => {
  //   history.push(`/season/view?name=${record.name}`);
  // }

  const onToggleLeague = (e, record) => {
    setSelectedItem(record);
    setDeletePopupVisibe(true);
  }

  const loadTeams = async () => {
    const { data = [] } = await apiClient.get(ApiConfig.player.teams);
    const tmp = data.sort((a, b) => a?.name?.localeCompare(b?.name));
    setTeams([...tmp])
  }

  const onStart = async (item) => {
    const bool = await ConfirmationModal('Are you sure want to start the season?');
    if (bool) {
      await apiClient.patch(`${ApiConfig.seasons}/${item.season_id}/status/${item._id}`, { status: "Started" });
      loadSeasons();
    }
  }

  const onPublishTier = async (item, league, season) => {
    const bool = await ConfirmationModal('Are you sure want to publish the tier?');
    if (bool) {
      await apiClient.patch(`${ApiConfig.seasons}/${season._id}/league/${league._id}`, { tier: item.tier_id, status: "Published", type: tierKey === '1' ? 'Regular' : 'CO-ED' });
      loadSeasons();
    }
  }

  const onSelect = (item) => {
    setSelectedItem({
      ...item
    });
    setVisible(true)
  }

  const onChangeSeasonStatus = async (key, league) => {
    const bool = await ConfirmationModal("Are you sure you want to change the status?")
    if (bool) {
      const idx = seasons.findIndex(e => e._id === league.season_id);
      if (idx >= 0) {
        const lIdx = seasons[idx].leagues.findIndex(ee => ee._id === league._id)
        if (lIdx >= 0) {
          seasons[idx].leagues[lIdx].status = key
          setSeasons([...seasons])
          await apiClient.patch(`${ApiConfig.seasons}/${league.season_id}/status/${league._id}`, { status: key });
        }
      }
    }
  }

  const onChangeTierStatus = async (key, season, league, tier) => {
    const bool = await ConfirmationModal("Are you sure you want to change the status?")
    if (bool) {
      const idx = seasons.findIndex(e => e._id === season._id);
      if (idx >= 0) {
        const lIdx = seasons[idx].leagues.findIndex(ee => ee._id === league._id)
        if (lIdx >= 0) {
          const tIdx = seasons[idx].leagues[lIdx].tiers.findIndex(ee => (ee.tier === tier.tier_id) && (ee.type === (tierKey === '1' ? 'Regular' : 'CO-ED')))
          if (tIdx >= 0) {
            seasons[idx].leagues[lIdx].tiers[tIdx].status = key
            setSeasons([...seasons])
            await apiClient.patch(`${ApiConfig.seasons}/${season._id}/league/${league._id}`, { tier: tier.tier_id, status: key, type: tierKey === '1' ? 'Regular' : 'CO-ED' });
          }
        }
      }
    }
  }

  const onSeasonDelete = async (record, target) => {
    const ok = await ConfirmationModal('Are you want to delete this season permanently?');
    if (ok) {
      await apiClient.delete(`${ApiConfig.seasons}/${record.season_id}/league/${record._id}`);
      if ($(target).closest('.ant-table-row').length > 0) {
        if ($(target).closest('.ant-table-row').next().hasClass('ant-table-expanded-row')) {
          $(target).closest('.ant-table-row').next().remove();
        }
        $(target).closest('.ant-table-row').remove();
      }
      // seasons.forEach(se => {
      //   const tidx = se.leagues.findIndex(le => le._id === record._id);
      //   if (tidx >= 0) {
      //     se.leagues.splice(tidx, 1);
      //   }
      // })
      // setSeasons([...seasons])
      // loadSeasons();
    }
  }
  const onDelete = async () => {
    await apiClient.patch(`${ApiConfig.leagues}/${selectedItem._id}/status`);
    setDeletePopupVisibe(false);
    loadLeagues();
  }

  const FormElements = ({ initialValues }) => {
    const [filteredDays, setFilteredDays] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values) => {
      try {
        console.log(values);
        setLoading(true);
        await apiClient.post(ApiConfig.leagues, values);
        loadLeagues();
        setLoading(false);
        setVisible(false);
      } catch (error) {
        setLoading(false);
      }
    }

    useEffect(() => {
      const tmp = weekDays.map((e, k) => ({ label: e, value: k, disabled: leagues.findIndex(ee => ee.day === k) >= 0 })).filter(e => !e.disabled)
      setFilteredDays([...tmp])
    }, []);

    return (
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={initialValues}
        onFinish={onSubmit}
      >
        <Form.Item
          name="day"
          className='with-helper'
          label={<>League Day <div className='helper-text'>All the matches will play only in this day</div></>}
          rules={[{ required: true, message: "This field is required" }]}
        >
          <Select options={filteredDays} placeholder="Select Day" />
        </Form.Item>
        <div className="form-footer">
          <Button loading={loading} type="primary" htmlType="submit">{selectedItem?.id ? 'Update' : 'Add'} League</Button>
        </div>
      </Form>
    )
  }

  const FormSeasonElements = ({ initialValues }) => {
    // const [tiers, setTiers] = useState(totalTiers)
    const [tierTypes, setTierTypes] = useState(["Regular"]);
    const [leagueCoEdTiers, setLeagueCoEdTiers] = useState([]);
    const [noCoEdTier, setCoEdNoTier] = useState(null);
    const [isTierCoEdNeeded, setIsTierCoEdNeeded] = useState(false);
    const [noTier, setNoTier] = useState(null);
    const [leagueTiers, setLeagueTiers] = useState([]);
    const [isTierNeeded, setIsTierNeeded] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [isNewSeason, setIsNewSeason] = useState(false);
    const [feesRes, setfeesRes] = useState(null);

    const updateRound = (round, idx, type = 'regular') => {
      if (idx >= 0) {
        if (type === 'coed') {
          leagueCoEdTiers[idx].rounds = round;
          setLeagueCoEdTiers([...leagueCoEdTiers])
        } else if (type === 'regular') {
          leagueTiers[idx].rounds = round;
          setLeagueTiers([...leagueTiers])
        }
      } else {
        if (type === 'coed') {
          noCoEdTier.rounds = round;
          setCoEdNoTier({ ...noCoEdTier })
        } else if (type === 'regular') {
          noTier.rounds = round;
          setNoTier({ ...noTier })
        }
      }
    }

    useEffect(() => {
      (async () => {
        try {
          const response = await apiClient.get(`${ApiConfig.common.getInfo}`);
          setfeesRes(response?.data?.settingsInfo)
        } catch (error) {
        }
      })();
      // eslint-disable-next-line
    }, [])

    const onFinish = async (values) => {
      console.log(values);
      try {
        setSubmiting(true);
        const payload = {
          league: values.league,
          entry_fee: values.entry_fee || (feesRes?.fee / 100),
        }
        if (values.is_tier_needed) {
          noTier.type = 'Regular';
          payload.tiers = JSON.parse(JSON.stringify([noTier]));
        } else {
          payload.tiers = JSON.parse(JSON.stringify(leagueTiers))
        }
        if (values.is_tier_coed_needed) {
          noCoEdTier.type = 'CO-ED';
          payload.tiers.push(noCoEdTier);
        } else {
          leagueCoEdTiers.forEach(val => {
            val.type = 'CO-ED';
          })
          payload.tiers = payload.tiers.concat(leagueCoEdTiers);
        }
        if (values.name) payload.name = values.name;
        if (values.season) payload.season = values.season;
        console.log('payload', payload);
        if (initialValues?.season_id) {
          await apiClient.put(`${ApiConfig.seasons}/${initialValues?.season_id}`, payload);
        } else {
          await apiClient.post(ApiConfig.seasons, payload);
        }
        setSubmiting(false);
        loadSeasons();
        setVisible(false);
      } catch (error) {
        setSubmiting(false);
      }
    }

    const onValuesChange = (changedValues) => {
      if ('tiers' in changedValues) {
        console.log('changedValues.tiers', changedValues.tiers);
        const tmp = changedValues.tiers?.map(tt => {
          const tTmp = leagueTiers.filter(lt => lt.tier === tt)[0];
          // return { tier: tt, start_date: tTmp?.start_date, rounds: tTmp?.rounds || 7, disabled: tTmp?.disabled }
          form.setFieldsValue({
            ...form.getFieldsValue(),
            [`tier${tt}`]: tTmp?.start_date ? getDateFormat(tTmp?.start_date, true) : undefined
          })
          return { ...tTmp, type: "Regular", tier: tt, start_date: tTmp?.start_date || undefined, rounds: tTmp?.rounds || 7, disabled: tTmp?.disabled }
        }) || []
        setLeagueTiers([...tmp])
      }
      if ('coedTiers' in changedValues) {
        console.log('changedValues.tiers', changedValues.coedTiers);
        const tmp = changedValues.coedTiers?.map(tt => {
          const tTmp = leagueCoEdTiers.filter(lt => lt.tier === tt)[0];
          form.setFieldsValue({
            ...form.getFieldsValue(),
            [`tier_coed${tt}`]: tTmp?.start_date ? getDateFormat(tTmp?.start_date, true) : undefined
          })
          return { ...tTmp, type: "CO-ED", tier: tt, start_date: tTmp?.start_date || undefined, rounds: tTmp?.rounds || 7, disabled: tTmp?.disabled }
        }) || []
        setLeagueCoEdTiers([...tmp])
      }

      if ('is_tier_needed' in changedValues) {
        setIsTierNeeded(changedValues.is_tier_needed)
        if (changedValues.is_tier_needed) {
          setNoTier({
            tier: null, start_date: undefined, rounds: 7, disabled: false, type: "Regular"
          })
        } else {
          setNoTier(null)
        }
      }
      if ('is_tier_coed_needed' in changedValues) {
        setIsTierCoEdNeeded(changedValues.is_tier_coed_needed)
        if (changedValues.is_tier_coed_needed) {
          setCoEdNoTier({
            tier: null, start_date: undefined, rounds: 7, disabled: false, type: "CO-ED"
          })
        } else {
          setCoEdNoTier(null)
        }
      }
      if ('tier_type' in changedValues) {
        setTierTypes(changedValues.tier_type);
      }
    }

    const onChangeDate = (date, key, type = 'regular') => {
      if (type === 'regular') {
        if (key >= 0) {
          if (leagueTiers[key]) {
            leagueTiers[key].start_date = date;
          }
        } else {
          noTier.start_date = date;
        }
      } else if (type === 'coed') {
        if (key >= 0) {
          if (leagueCoEdTiers[key]) {
            leagueCoEdTiers[key].start_date = date;
          }
        } else {
          noCoEdTier.start_date = date;
        }
      }
    }

    function getTabName(key) {
      return $('.tab-season.ant-tabs .ant-tabs-tab-btn').eq(parseInt(key)).text() || null;
    }

    const onDisabledDate = (current, key) => {
      const dayName = getTabName(window.leagueTabKey || '0')?.slice(0, 3);
      // return current && (current < moment().endOf('day') || moment(current).format('ddd') !== dayName)
      return current && (getDateFormat({ date: current, format: "ddd" }) !== dayName)
    }

    const getFilteredSeasons = () => {
      if (!window.leagueTabKey) window.leagueTabKey = '0';
      return seasons.filter(e => {
        return e.is_active;
        // }).map(e => ({ label: e.name, value: e._id, disabled: e.leagues.findIndex(le => le.league.name === $('.tab-season.ant-tabs .ant-tabs-tab-btn').eq(parseInt(window.leagueTabKey)).text()) >= 0 }))
      }).map(e => ({ label: e.name, value: e._id, disabled: e.leagues.findIndex(le => le.league.name === $('.tab-season.ant-tabs .ant-tabs-tab-btn').eq(parseInt(window.leagueTabKey)).text()) >= 0 }))
    }

    const getReqTiers = (type = 'Regular') => {
      if (initialValues?._id && initialValues?.tiers) {
        return tiers.map(ee => {
          const ttmp = { label: `${ee === 'CO-ED' ? ee : `Tier ${ee}`}`, value: ee, disabled: false }
          const tidx = initialValues?.tiers?.findIndex(te => te.tier === ee && te.type === type);
          if (tidx >= 0 && initialValues?.tiers[tidx]?.registrations?.length > 0) {
            ttmp.disabled = true;
          }
          return ttmp;
        })
      }
      return tiers.map(ee => ({ label: `${ee === 'CO-ED' ? ee : `Tier ${ee}`}`, value: ee, disabled: false }));
    }

    useEffect(() => {
      if (initialValues && initialValues?._id) {
        console.log('initialValues', initialValues);
        const tiersList = {};
        if (initialValues.tiers) {
          initialValues.tiers.filter(e => e.type === 'Regular').forEach(tt => {
            if (tt.tier) {
              tiersList[`tier${tt.tier}`] = getDateFormat(tt.start_date, true);
            }
          })
          if (initialValues.tiers.filter(e => e.type === 'CO-ED').length > 0) {
            tierTypes.push('CO-ED');
            setTierTypes([...tierTypes])
            initialValues.tiers.filter(e => e.type === 'CO-ED').forEach(tt => {
              if (tt.tier) {
                tiersList[`tier_coed${tt.tier}`] = getDateFormat(tt.start_date, true);
              }
            })
          }
        }
        console.log('tiersList', tiersList);
        form.setFieldsValue({
          season: initialValues?.season_id,
          entry_fee: initialValues?.entry_fee,
          league: initialValues?.league._id || undefined,
          tiers: initialValues?.tiers?.filter(e => e.type === 'Regular').map(e => e.tier),
          coedTiers: initialValues?.tiers?.filter(e => e.type === 'CO-ED').map(e => e.tier),
          tier_type: tierTypes,
          ...tiersList
        })
        if (initialValues.tiers.filter(e => e.type === 'Regular')[0]?.tier === null) {
          setIsTierNeeded(true);
          const tt = initialValues.tiers.filter(e => e.type === 'Regular')[0];
          form.setFieldsValue({
            is_tier_needed: true,
            no_tier: getDateFormat(tt?.start_date, true)
          })
          setNoTier({
            rounds: tt?.rounds,
            start_date: getDateFormat(tt?.start_date, true)
          })
        }
        if (initialValues?.tiers?.filter(e => e.type === 'Regular').length > 0) {
          setLeagueTiers(initialValues?.tiers?.filter(e => e.type === 'Regular'));
        } else {
          setLeagueTiers([]);
        }
        if (initialValues.tiers.filter(e => e.type === 'CO-ED')[0]?.tier === null) {
          setIsTierCoEdNeeded(true);
          const tt = initialValues.tiers.filter(e => e.type === 'CO-ED')[0];
          form.setFieldsValue({
            is_tier_coed_needed: true,
            no_tier_coed: getDateFormat(tt?.start_date, true)
          })
          setCoEdNoTier({
            rounds: tt?.rounds,
            start_date: getDateFormat(tt?.start_date, true)
          })
        }
        if (initialValues?.tiers?.filter(e => e.type === 'CO-ED').length > 0) {
          setLeagueCoEdTiers(initialValues?.tiers?.filter(e => e.type === 'CO-ED'));
        } else {
          setLeagueCoEdTiers([]);
        }
      } else {
        if (!window.leagueTabKey) window.leagueTabKey = '0';
        const entrolledArr = leagues.filter(ee => {
          return ee.name === $('.tab-season.ant-tabs .ant-tabs-tab-btn').eq(parseInt(window.leagueTabKey)).text()
        }).map(ee => ee._id);
        const tiersList = {};
        tiers.forEach(tt => {
          tiersList[`tier${tt}`] = undefined;
        })
        form.setFieldsValue({
          league: entrolledArr[0],
          season: undefined,
          name: '',
          entry_fee: '',
          tiers: [],
          coedTiers: [],
          tier_type: ["Regular"],
          ...tiersList
        })
      }
      // eslint-disable-next-line
    }, [initialValues])

    return (
      <>
        {visible && <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            ...initialValues,
            is_tier_needed: false
          }}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <Form.Item
            name="league"
            label="League"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select disabled options={leagues.filter(e => e.is_active).map(e => ({ label: e.name, value: e._id }))} placeholder="Select League" />
          </Form.Item>
          {!isNewSeason && <Form.Item
            name="season"
            label={!initialValues?._id ? 'Existing Seasons' : 'Season'}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select disabled={!!initialValues?._id} options={getFilteredSeasons()} placeholder="Select Season" />
          </Form.Item>}
          {(isNewSeason && !initialValues?._id) && <Form.Item
            name="name"
            label="New Season Name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder='Enter season name' />
          </Form.Item>}

          {!initialValues?._id && <Form.Item
            // name="is_new_season"
            valuePropName="checked"
          >
            <Checkbox onChange={(e) => setIsNewSeason(e.target.checked)}>Create new season</Checkbox>
          </Form.Item>}
          <Form.Item
            label="Tier Type"
            name="tier_type"
            // valuePropName="checked"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Checkbox.Group options={["Regular", "CO-ED"]} />
          </Form.Item>
          {/* <Form.Item
            name="entry_fee"
            label="Entry Fee"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input addonBefore="$" placeholder="Enter a season entry fee" />
          </Form.Item> */}

          {
            tierTypes.indexOf('Regular') >= 0 && (
              <>
                {!isTierNeeded && <Form.Item
                  name="tiers"
                  label="Regular Season Tiers"
                  className={`league_tier chip-box${initialValues?._id ? ' update-form' : ''}`}
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  {/* <Checkbox.Group options={tiers.map(ee => ({ label: `Tier ${ee}`, value: ee, disabled: initialValues?.tiers?.findIndex(et => et.tier === ee && et.disabled) >= 0 }))} /> */}
                  <Checkbox.Group options={getReqTiers()} />
                </Form.Item>}
                {
                  !isTierNeeded && (
                    leagueTiers?.map((tt, key) => (
                      <Form.Item
                        key={key.toString()}
                        name={`tier-list-${key}`}
                        className="tiers-block"
                      >
                        <div className="tiers-list mx-t">
                          <div className="tier-name">{`${tt.tier === 'CO-ED' ? tt.tier : `Tier ${tt.tier}`}`}</div>
                        </div>
                        <Form.Item
                          name={`tier${tt.tier}`}
                          key={key.toString()}
                          rules={[{ required: true, message: "This field is required" }]}
                          className="tiers-list"
                        >
                          {/* <DatePicker disabled={tt.disabled} disabledDate={(e) => onDisabledDate(e)} onChange={(e) => onChangeDate(e, key)} format={dateFormat} placeholder="Start Date" /> */}
                          <DatePicker disabledDate={(e) => onDisabledDate(e)} onChange={(e) => onChangeDate(e, key)} format={dateFormat} placeholder="Start Date" />
                        </Form.Item>
                        <Form.Item
                          // name={`tier${key + 1}`}
                          key={key.toString()}
                          className="tiers-list"
                        >
                          <div className="rounds-tier">
                            <div className="t-act">
                              <div className="t-act-list">
                                <Button disabled={tt.disabled} onClick={() => updateRound(tt.rounds - 1, key)} icon={<FiMinus />} />
                                <Input disabled={tt.disabled} readOnly value={`${tt.rounds} Rounds`} placeholder="Rounds" />
                                <Button disabled={tt.disabled} className='t-plus' onClick={() => updateRound(tt.rounds + 1, key)} icon={<FiPlus />} />
                              </div>
                            </div>
                          </div>
                        </Form.Item>
                      </Form.Item>
                    ))
                  )
                }
                {(leagueTiers.length === 0 || isTierNeeded) && <Form.Item
                  name="is_tier_needed"
                  label="Create regular season without tiers?"
                  valuePropName="checked"
                >
                  <Switch disabled={isTierNeeded} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>}

                {isTierNeeded &&
                  <Form.Item
                    name="tier-list-none"
                    className="tiers-block"
                  >
                    {/* <div className="tiers-list mx-t">
                <div className="tier-name"></div>
              </div> */}
                    <Form.Item
                      name="no_tier"
                      rules={[{ required: true, message: "This field is required" }]}
                      className="tiers-list"
                    >
                      <DatePicker disabledDate={(e) => onDisabledDate(e)} onChange={(e) => onChangeDate(e)} format={dateFormat} placeholder="Start Date" />
                    </Form.Item>
                    <Form.Item
                      className="tiers-list"
                    >
                      <div className="rounds-tier">
                        <div className="t-act">
                          <div className="t-act-list">
                            <Button onClick={() => updateRound(noTier?.rounds - 1)} icon={<FiMinus />} />
                            <Input readOnly value={`${noTier?.rounds} Rounds`} placeholder="Rounds" />
                            <Button className='t-plus' onClick={() => updateRound(noTier?.rounds + 1)} icon={<FiPlus />} />
                            {/* <div className='delete-tier'><DeleteOutlined /></div> */}
                          </div>
                        </div>
                      </div>
                    </Form.Item>
                  </Form.Item>
                }
              </>
            )
          }
          {
            tierTypes.indexOf('CO-ED') >= 0 && (
              <>
                <Divider />
                {!isTierCoEdNeeded && <Form.Item
                  name="coedTiers"
                  label="CO-ED Season Tiers"
                  className="league_tier chip-box"
                  rules={[{ required: true, message: "This field is required" }]}
                >
                  <Checkbox.Group options={getReqTiers("CO-ED")} />
                </Form.Item>}
                {
                  !isTierCoEdNeeded && (
                    leagueCoEdTiers?.map((tt, key) => (
                      <Form.Item
                        key={key.toString()}
                        name={`tier-list-coed-${key}`}
                        className="tiers-block"
                      >
                        <div className="tiers-list mx-t">
                          <div className="tier-name">{`${tt.tier === 'CO-ED' ? tt.tier : `Tier ${tt.tier}`}`}</div>
                        </div>
                        <Form.Item
                          name={`tier_coed${tt.tier}`}
                          key={key.toString()}
                          rules={[{ required: true, message: "This field is required" }]}
                          className="tiers-list"
                        >
                          {/* <DatePicker disabled={tt.disabled} disabledDate={(e) => onDisabledDate(e)} onChange={(e) => onChangeDate(e, key)} format={dateFormat} placeholder="Start Date" /> */}
                          <DatePicker disabledDate={(e) => onDisabledDate(e)} onChange={(e) => onChangeDate(e, key, 'coed')} format={dateFormat} placeholder="Start Date" />
                        </Form.Item>
                        <Form.Item
                          key={key.toString()}
                          className="tiers-list"
                        >
                          <div className="rounds-tier">
                            <div className="t-act">
                              <div className="t-act-list">
                                <Button disabled={tt.disabled} onClick={() => updateRound(tt.rounds - 1, key, 'coed')} icon={<FiMinus />} />
                                <Input disabled={tt.disabled} readOnly value={`${tt.rounds} Rounds`} placeholder="Rounds" />
                                <Button disabled={tt.disabled} className='t-plus' onClick={() => updateRound(tt.rounds + 1, key, 'coed')} icon={<FiPlus />} />
                              </div>
                            </div>
                          </div>
                        </Form.Item>
                      </Form.Item>
                    ))
                  )
                }
                {(leagueCoEdTiers.length === 0 || isTierCoEdNeeded) && <Form.Item
                  name="is_tier_coed_needed"
                  label="Create CO-ED season without tiers?"
                  valuePropName="checked"
                >
                  <Switch disabled={isTierCoEdNeeded} checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>}

                {isTierCoEdNeeded &&
                  <Form.Item
                    name="tier-list-none"
                    className="tiers-block"
                  >
                    <Form.Item
                      name="no_tier_coed"
                      rules={[{ required: true, message: "This field is required" }]}
                      className="tiers-list"
                    >
                      <DatePicker disabledDate={(e) => onDisabledDate(e)} onChange={(e) => onChangeDate(e, -1, 'coed')} format={dateFormat} placeholder="Start Date" />
                    </Form.Item>
                    <Form.Item
                      className="tiers-list"
                    >
                      <div className="rounds-tier">
                        <div className="t-act">
                          <div className="t-act-list">
                            <Button onClick={() => updateRound(noCoEdTier?.rounds - 1, -1, 'coed')} icon={<FiMinus />} />
                            <Input readOnly value={`${noCoEdTier?.rounds} Rounds`} placeholder="Rounds" />
                            <Button className='t-plus' onClick={() => updateRound(noCoEdTier?.rounds + 1, -1, 'coed')} icon={<FiPlus />} />
                          </div>
                        </div>
                      </div>
                    </Form.Item>
                  </Form.Item>
                }
              </>
            )
          }
          <div className="form-footer">
            <Button loading={submiting} type="primary" htmlType="submit">{selectedItem?._id ? 'Update' : 'Add'} Season</Button>
          </div>
        </Form>}
      </>
    )
  }

  const [tierKey, setTierKey] = useState("1");

  const TierView = ({ season, league, tiers = [] }) => {
    // console.log('tiers', tiers);
    const tierColumns = [
      { title: "Tier", dataIndex: "tier", key: "tier", render: text => text || '-' },
      {
        title: "Registered Teams",
        dataIndex: "registered_teams",
        key: "registered_teams",
        render: (text, record) => {
          const tmpTeams = record?.registrations?.map(ee => ee.team) || [];
          return (
            tmpTeams.length > 0 ? (
              <div onClick={() => {
                const tmp = { visible: true, tiers, teams: tmpTeams.filter(ee => ee), season: { ...{ _id: season._id, name: season.name }, league: { ...league.league, tier: record.tier } } };
                console.log('tmp', tmp);
                setRegTeamsDet({ ...tmp });
              }} className='team_logos_list'>
                {tmpTeams.slice(0, 5).map(tt => (
                  <div className='team_logo' key={tt?._id}><Tooltip title={tt?.name}><img alt='' src={tt?.logo || '/logos/logo.png'} /></Tooltip></div>
                ))}
              </div>
            ) : <Button className='btn_small' type='primary' onClick={() => setRegTeamsDet({ visible: true, tiers, addVisible: true, teams: [], season: { ...{ _id: season._id, name: season.name }, league: { ...league.league, tier: record.tier } } })}>Add Team</Button>
          )
        }
      },
      { title: "Start Date", dataIndex: "start_date", key: "start_date", render: (text) => getDateFormat(text) },
      { title: "Status", dataIndex: "status", key: "status", render: (text, record) => <div className='status-div'><Tag color={statusMap[text]}>{text}</Tag> {text !== 'Draft' && <TierStatus selected={text} onClick={(key) => onChangeTierStatus(key, season, league, record)} />}</div> },
      { title: "Created At", dataIndex: "created_at", key: "created_at", render: (text) => getDateFormat(text) },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        render: (text, record, idx) => {
          return (<>
            {record.status === 'Draft' ?
              <div key={idx.toString()} className="action_btns">
                <Button onClick={() => onPublishTier(record, league, season)} type="primary" className='btn_publish ml-2'>Publish</Button>
              </div> : '-'}
          </>)
        }
      }
    ]
    return (
      <div className="tier-view">
        <Tabs activeKey={tierKey} onChange={(e) => setTierKey(e)} defaultActiveKey='1'>
          <Tabs.TabPane key="1" tab="Regular">
            <Table
              className="tier-table"
              pagination={false}
              dataSource={tiers.filter(e => e.type === 'Regular').map((e, kk) => ({ ...e, key: kk.toString() }))}
              columns={tierColumns}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="CO-ED">
            <Table
              className="tier-table"
              pagination={false}
              dataSource={tiers.filter(e => e.type === 'CO-ED').map((e, kk) => ({ ...e, key: kk.toString() }))}
              columns={tierColumns}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    )
  }

  const getSeasons = (day) => {
    // return seasons
    //   .filter(e => e?.league?.day === day)
    //   .map((ee, kk) => ({ ...ee, key: kk.toString(), tierView: <TierView key={ee.id} tiers={ee.tiers.map(tt => ({ ...tt, tier: `Tier ${tt.tier}` }))} /> }));
    const temp = [];
    seasons.forEach(st => {
      st.leagues.forEach((lt, key) => {
        if (lt.league.day === day) {
          lt.tiers.forEach(ti => {
            // const ttidx = lt.registrations.findIndex(re => re.tier === ti.tier);
            // if (ttidx >= 0) ti.disabled = true;
            if (ti.registrations.length > 0) ti.disabled = true;
          })
          temp.push({
            key: st.name,
            name: st.name,
            season_id: st._id,
            ...lt,
            tierView: <TierView key={lt.id} season={st} league={lt} tiers={lt.tiers.map(tt => ({ ...tt, tier_id: tt.tier, tier: tt.tier ? `${tt.tier === 'CO-ED' ? tt.tier : `Tier ${tt.tier}`}` : null }))} />,
          })
        }
      })
    })
    // console.log('temp', temp);
    return temp;
  }

  const onAdd = () => {
    setSelectedItem(null);
    setVisible(true)
  }

  const loadLeagues = async () => {
    const { data } = await apiClient.get(ApiConfig.leagues);
    setLeagues([...data]);
  }

  const loadSeasons = async () => {
    setListing(true);
    const { data } = await apiClient.get(ApiConfig.seasons);
    setSeasons([...data]);
    setListing(false);
  }

  const onRegisterTeam = async (values) => {
    values.tier = values.tier.replace('Tier ', '')
    if (values.tier === 'N/A') values.tier = null;
    console.log('values', values);
    try {
      values.type = tierKey === '1' ? 'Regular' : 'CO-ED';
      if (regTeamsDet.team) {
        setAdding(true);
        await apiClient.patch(ApiConfig.season.updateTierForRegisteredTeam, values);
        setAdding(false);
        const tTeamIdx = regTeamsDet?.teams?.findIndex(ee => ee._id === values.team);
        if (tTeamIdx >= 0) {
          regTeamsDet.teams.splice(tTeamIdx, 1);
        }
        regTeamsDet.addVisible = false;
        setRegTeamsDet({ ...regTeamsDet })
        loadSeasons();
      } else {
        setAdding(true);
        await apiClient.post(ApiConfig.seasons + '/register-team', values);
        setAdding(false);
        const tTeam = teams.filter(ee => ee._id === values.team)[0];
        if (tTeam) {
          regTeamsDet.addVisible = false;
          regTeamsDet.teams.push({
            name: tTeam?.name,
            logo: tTeam?.logo,
            _id: tTeam?._id
          })
          setRegTeamsDet({ ...regTeamsDet })
        }
        // setRegTeamsDet({
        //   visible: false,
        //   addVisible: false,
        //   teams: []
        // })
        loadSeasons();
      }
    } catch (error) {
      setAdding(false);
    }
  }

  const onChangeTab = (tab) => {
    window.leagueTabKey = tab;
    const league = leagues.filter(ee => ee.name === $('.tab-season.ant-tabs .ant-tabs-tab-btn').eq(parseInt(tab)).text())[0];
    if (league && getSeasons(league.day).length === 0) {
      $('.btn-add-league').show();
    } else {
      $('.btn-add-league').hide();
    }
  }

  // const onAddSeasonLeague = () => {
  //   setSelectedItem({
  //     is_add: true
  //   })
  //   setVisible(true)
  // }

  const getTeams = () => {
    const type = $('.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn').eq(1).text();
    return teams.filter(ee => {
      return ee?.enrolled?.findIndex(en => en.name === type) >= 0
    })
  }

  const onDeleteTeamFromTier = async (item) => {
    console.log(item);
    const ok = await ConfirmationModal('Are you sure you want to remove this team in this tier?');
    if (ok) {
      await apiClient.patch(ApiConfig.season.removeRegisteredTeamFromTier, {
        team: item._id,
        type: tierKey === '1' ? 'Regular' : 'CO-ED',
        sports_event: regTeamsDet?.season?._id,
        league: regTeamsDet?.season?.league?._id,
        tier: regTeamsDet?.season?.league?.tier?.replace('Tier ', '') || null,
      });
      const tTeamIdx = regTeamsDet?.teams?.findIndex(ee => ee._id === item._id);
      if (tTeamIdx >= 0) {
        regTeamsDet.teams.splice(tTeamIdx, 1);
      }
      regTeamsDet.addVisible = false;
      setRegTeamsDet({ ...regTeamsDet })
      loadSeasons();
    }
  }

  useEffect(() => {
    loadLeagues();
    loadSeasons();
    loadTeams();
    window.$ = $;
  }, [])

  return (
    <>
      <RemoveModal description={selectedTab === '1' ? 'Are you sure you want to change the status?' : 'Are you want to delete this season permanently?'} visible={deletePopupVisible} onSubmit={onDelete} onCancel={() => setDeletePopupVisibe(false)} />
      <SectionTitle
        title="Leagues & Seasons"
        subtitle={`Manage ${selectedTab === '1' ? 'Leagues' : 'Seasons'}`}
        right={
          <>
            <button onClick={onAdd} className="btn btn-default btn-rounded btn-icon bg-blue-500 hover:bg-blue-600 text-white space-x-1">
              <FiPlus className="stroke-current text-white" size={16} />
              <span>Add {selectedTab === '1' ? "League" : "New Season"}</span>
            </button>
          </>
        }
      />

      <Tabs onChange={(e) => setSelectedTab(e)} defaultActiveKey='2'>
        <Tabs.TabPane key="1" tab="Leagues">
          <Table
            loading={listing}
            pagination={false}
            dataSource={leagues}
            columns={columns}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab="Seasons">
          {/* tabBarExtraContent={seasons?.length > 0 && <Button className='btn-add-league' onClick={onAddSeasonLeague} style={{ marginTop: '-4px', display: 'none' }} type='primary' icon={<PlusOutlined />}>Add</Button>} */}
          <Tabs className='tab-season' onChange={onChangeTab}>
            {
              leagues.filter(e => e.is_active).map((le, k) => (
                <Tabs.TabPane tab={le.name} key={k.toString()}>
                  <Table
                    loading={listing}
                    pagination={false}
                    dataSource={getSeasons(le.day)}
                    columns={SeasonColumns}
                    expandable={{
                      rowExpandable: (record) => record?.tiers?.length > 0,
                      expandedRowRender: (record, key) => <div key={key}>{record.tierView}</div>
                    }}
                  />
                </Tabs.TabPane>
              ))
            }
          </Tabs>
        </Tabs.TabPane>
      </Tabs>
      <Drawer
        title={`Add ${selectedTab === '1' ? 'League' : 'Season'}`}
        visible={visible}
        destroyOnClose
        className={`${selectedTab === '1' ? '' : 'big-d'}`}
        onClose={() => setVisible(false)}
      >
        {(visible && selectedTab === '1') && <FormElements initialValues={selectedItem} />}
        {(visible && selectedTab === '2') && <FormSeasonElements initialValues={selectedItem} />}
      </Drawer>
      <Drawer
        title="Registered Teams"
        visible={regTeamsDet.visible}
        destroyOnClose
        footer={null}
        onClose={() => setRegTeamsDet({ visible: false, teams: [] })}
      >
        <div className='reg_team'>
          <Button onClick={() => { setRegTeamsDet({ ...regTeamsDet, addVisible: true }); console.log(regTeamsDet) }} icon={<PlusOutlined />} type="ghost">Add Team</Button>
        </div>
        <List
          dataSource={regTeamsDet?.teams}
          renderItem={item => (
            <List.Item className='lt-team'>
              <TeamNameWithLogo {...item} />
              <div className='btn-tt'>
                {regTeamsDet?.tiers?.filter(ee => ee.tier)?.length > 0 && <EditOutlined onClick={() => {
                  setRegTeamsDet({ ...regTeamsDet, addVisible: true, team: item })
                }} />}
                <DeleteOutlined onClick={() => onDeleteTeamFromTier(item)} className='ml-3' />
              </div>
            </List.Item>
          )}
        />
        <Drawer
          title="Register Team"
          visible={regTeamsDet.addVisible}
          destroyOnClose
          footer={null}
          className="s-drawer"
          onClose={() => setRegTeamsDet({ ...regTeamsDet, team: undefined, addVisible: false })}
        >
          <Form
            // form={form}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              sports_event: regTeamsDet?.season?._id,
              league: regTeamsDet?.season?.league?._id,
              tier: regTeamsDet?.season?.league?.tier || "N/A",
              team: regTeamsDet?.team?._id
            }}
            onFinish={onRegisterTeam}
          >
            <Form.Item
              name="sports_event"
              label="Season"
            >
              <Select disabled options={[{ label: regTeamsDet?.season?.name, value: regTeamsDet?.season?._id }]} />
            </Form.Item>
            <Form.Item
              name="league"
              label="League"
            >
              <Select disabled options={[{ label: regTeamsDet?.season?.league?.name, value: regTeamsDet?.season?.league?._id }]} />
            </Form.Item>
            <Form.Item
              name="tier"
              label="Tier"
            >
              <Select disabled={!regTeamsDet?.team} options={regTeamsDet?.tiers?.filter(e => e.type === (tierKey === '1' ? 'Regular' : 'CO-ED'))?.map(e => ({ label: e.tier, value: e.tier }))} />
            </Form.Item>
            <Form.Item
              name="team"
              label="Select Team"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                disabled={!!regTeamsDet?.team}
                options={getTeams().filter(ee => regTeamsDet?.teams?.findIndex(rt => rt?._id === ee?._id) === -1 || regTeamsDet?.team?._id === ee?._id)?.map(e => ({ label: e?.name, value: e?._id }))}
                placeholder="Select Team"
              />
            </Form.Item>
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <Button loading={adding} type="primary" htmlType="submit">{regTeamsDet?.team ? 'Update' : 'Register'}</Button>
            </div>
          </Form>
        </Drawer>
      </Drawer>
    </>
  )
}
export default LeagueAndSeasonPage
