import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, InputNumber } from 'antd'
import store from 'store'

import Logo from '../../components/login-2/logo'
import Footer from '../../components/login-2/footer'
import apiClient from '../../services/axios'
import ApiConfig from '../../services/ApiConfig'

const RegisterPage = () => {

  let history = useHistory()

  const RegisterForm = () => {
    const [loading, setLoading] = useState(false);
    const onSubmit = async (values) => {
      setLoading(true);
      try {
        delete values.confirm_password;
        const response = await apiClient.post(ApiConfig.auth.register, values);
        const { email } = response.data;
        localStorage.setItem('entered-email', email)
        history.push('/verify-account')
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    return (
      <div className="flex flex-col">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={onSubmit}
        >
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter your firstname" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter your lastname" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <InputNumber controls={false} placeholder="Enter your mobile number" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              // { required: true, message: "This field is required" },
              {
                validator: async (_, password) => {
                  password = password.replace(/[^a-zA-Z0-9 ]/g, "");
                  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
                  if (!regex.test(password)) {
                    return Promise.reject(new Error('Password must be at least 8 characters, 1 uppercase, 1 lowercase and 1 number'));
                  }
                }
              }
            ]}
          >
            <Input maxLength={20} type="password" placeholder="Enter your password" />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The password that you entered do not match!');
                },
              }),
            ]}
          >
            <Input type="password" placeholder="Enter your password again" />
          </Form.Item>
          <Form.Item noStyle>
            <Button loading={loading} htmlType="submit" type="primary">Register</Button>
          </Form.Item>
        </Form>
      </div>
    )
  }

  useEffect(() => {
    const token = store.get('user.accessToken');
    if (token) history.replace('/dashboard');
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="w-full flex flex-row h-screen overflow-hidden auth-page-2">
        <div className="hidden lg:flex lg:flex-col w-3/4 text-white p-8 items-start justify-between relative bg-login-2">
          <Logo />
        </div>
        <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
          <p className="text-2xl font-bold text-blue-500 mb-4">
            Register to {(process.env.BUSSINESS_NAME)?.toUpperCase()}
          </p>
          <RegisterForm />
          <div className="flex flex-row w-full mt-5">
            <span className="text-secondary mr-1">Already have an account?</span>
            <span>
              <Link className="link" to="/login">Login here</Link>
            </span>
          </div>
          <div className="w-full">
            <span>
              <Link className="link" to="/forgot-password">
                Forgot password?
              </Link>
            </span>
          </div>
          <div className='footer-elem'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterPage
