/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  List,
  AutoComplete,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Select,
  Table,
  Tabs,
  Tag,
  DatePicker,
  Tooltip,
  message,
  Spin,
  Switch,
} from "antd";
import { debounce } from "lodash";
import SectionTitle from "../../components/section-title";
import PageLoader from "../../components/page-loader";
import $ from "jquery";
import { FiX } from "react-icons/fi";
import { MdChat, MdGroupAdd } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaExchangeAlt, FaCheck, FaTimes, FaRetweet } from "react-icons/fa";
import { SketchPicker } from "react-color";
import {
  CheckOutlined,
  PlusOutlined,
  MinusOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  LoadingOutlined,
  UserAddOutlined,
  SendOutlined,
  CheckCircleOutlined,
  EditOutlined
} from "@ant-design/icons";
import { TeamNameWithLogo } from "../../components/teams";
import apiClient from "../../services/axios";
import ApiConfig from "../../services/ApiConfig";
import { getDateFormat, getFullName, getShortName } from "../../utils";
import ConfirmationModal from "../../common/confirm-modal";
import axios from "axios";
import moment from "moment";
import store from 'store'
// import moment from "moment-timezone";

const ScoreKeeperPage = () => {
  const [loading, setLoading] = useState(false);
  const [teamSwap, setTeamSwap] = useState(false);
  const [scoreType, setScoreType] = useState(false);
  const [playerAddInfo, setPlayerAddInfo] = useState({ visible: false });
  const [pfModalInfo, setPfModalInfo] = useState({ visible: false });
  const [rebModalInfo, setRebModalInfo] = useState({ visible: false });
  const [toModalInfo, setToModalInfo] = useState({ visible: false });
  const [astModalInfo, setAstModalInfo] = useState({ visible: false });
  const [courtList, setCourtList] = useState([]);
  const [matches, setMatches] = useState([]);
  const [remainingPlayerInfo, setRemainingPlayerInfo] = useState({
    type: "",
    visible: false,
  });

  const [open, setOpen] = useState(false);
  const [openaway, setOpenaway] = useState(false);
  const [openawayplayergetin, setopenawayplayergetin] = useState(false);
  const [openhomeplayergetin, setopenhomeplayergetin] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [numberChange, setNumberChange] = useState(false);
  const [scoreboardDet, setScoreboard] = useState({
    court: null,
    date: getDateFormat(),
    slot: null,
    start: false,
  });

  const [adding, setAdding] = useState(false);
  const [searching, setSearching] = useState(false);
  const [players, setPlayers] = useState([]);
  //
  const [homeoraway, sethomeoraway] = useState("");
  const [inmatch, setinmatch] = useState("");
  const [openjerseyviolation, setopenjerseyviolation] = useState(false);
  const [violationtype, setviolationtype] = useState("");

  const [jerseyViolation, setJerseyViolation] = useState({
    home: false,
    away: false,
    homemessage: "",
    awaymessage: "",
  });
  const [form] = Form.useForm();
  const [formz] = Form.useForm();

  const onClosePopup = (type) => {
    setSelectedMember(null);
    const idx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === selectedMember.jersey_no
    );
    if (idx >= 0) {
      currentMatch[type].players[idx].selected = false;
      setCurrentMatch({ ...currentMatch });
    }
  };
  const [openloader, setopenloader] = useState(false);

  const onSaveMatchScores = async (payload) => {
    setopenloader(true);
    const resp = await apiClient.put(
      ApiConfig.matches.updateMatchScoreManager + `/${currentMatch._id}`,
      payload
    ); //.then(()=>{if(resp.status===200) {setopenloader(false)}})
    // setopenloader(false)
    if (resp.status === 200) {
      setopenloader(false);
    }
    // console.log(resp.status,openloader,'respresp')
  };
  console.log(openloader, "respresp");
  const setLastClickedAction = (ptsType) => {
    const idx = currentMatch[selectedMember.type].players.findIndex(
      (pl) => pl._id === selectedMember._id
    );
    if (idx >= 0) {
      currentMatch[selectedMember.type].players[idx].lastClicked = ptsType;
      selectedMember.lastClicked = ptsType;
      setSelectedMember({ ...selectedMember });
    }
  };

  const onPlusScore = async (points, ptsType = "") => {
    if (scoreType) {
      onMinusScore(points, ptsType);
      return;
    }
    console.log(points, ptsType, "(points, ptsType");
    setLastClickedAction(ptsType);
    if (currentMatch[currentMatch.selectedType]) {
      //currentMatch[currentMatch.selectedType].score += points;
      const idx = currentMatch[selectedMember.type].players.findIndex(
        (pl) => pl._id === selectedMember._id
      );
      if (idx >= 0 && currentMatch[selectedMember.type].players[idx].stats) {
        if (ptsType === "ftm") {
          selectedMember.stats.fta += 1;
        }
        if (ptsType === "2ptm") {
          selectedMember.stats["2pta"] += 1;
        }
        if (ptsType === "3ptm") {
          selectedMember.stats["3pta"] += 1;
        }
        selectedMember.stats[ptsType] += 1;
      }
      setCurrentMatch({ ...currentMatch });
      setSelectedMember({ ...selectedMember });
      if (ptsType === "2ptm" || ptsType === "3ptm") {
        setAstModalInfo({
          visible: true,
          type: selectedMember.type,
        });
      }
      const payload = {
        type: ptsType,
        player: selectedMember?.player?._id,
        team: currentMatch[selectedMember.type]?.team?._id,
      };
      onSaveMatchScores(payload);
    }
  };
  const [awaystatsscore, setawaystatsscore] = useState(0);
  const [homestatsscore, sethomestatsscore] = useState(0);
  const [enablepublish, setenablepublish] = useState(false);
  const [videoModal, setVideoModal] = useState(false);

  const calculatescores = () => {
    let awayscore = 0;
    let homescore = 0;
    if (currentMatch) {
      let v2 = currentMatch?.home?.players?.forEach((item) => {
        homescore +=
          2 * item?.stats?.["2ptm"] +
          3 * item?.stats?.["3ptm"] +
          item?.stats?.["ftm"];
      });
      let v3 = currentMatch?.away?.players?.forEach((item) => {
        awayscore +=
          2 * item?.stats?.["2ptm"] +
          3 * item?.stats?.["3ptm"] +
          item?.stats?.["ftm"];
      });
    }
    setawaystatsscore(awayscore);
    sethomestatsscore(homescore);
  };
  useEffect(() => {
    calculatescores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMatch]);
  console.log(currentMatch, "currentMatchcurrentMatch");
  useEffect(() => {
    if (
      (currentMatch?.away?.score === (awaystatsscore+currentMatch?.away?.violation) ||
        currentMatch?.away?.hide) &&
      (currentMatch?.home?.score === (homestatsscore+currentMatch?.home?.violation) || currentMatch?.home?.hide)
    ) {
      setenablepublish(true);
    } else {
      setenablepublish(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awaystatsscore,homestatsscore,currentMatch?.away?.hide,currentMatch?.home?.hide]);

  const onMinusScore = async (points, ptsType = "") => {
    setLastClickedAction(ptsType);
    const idx = currentMatch[selectedMember.type].players.findIndex(
      (pl) => pl._id === selectedMember._id
    );
    if (idx >= 0) {
      if (
        ptsType === "fta" &&
        currentMatch[selectedMember.type].players[idx].stats["fta"] -
          currentMatch[selectedMember.type].players[idx].stats["ftm"] ===
          0
      )
        return;
      if (
        ptsType === "2pta" &&
        currentMatch[selectedMember.type].players[idx].stats["2pta"] -
          currentMatch[selectedMember.type].players[idx].stats["2ptm"] ===
          0
      )
        return;
      if (
        ptsType === "3pta" &&
        currentMatch[selectedMember.type].players[idx].stats["3pta"] -
          currentMatch[selectedMember.type].players[idx].stats["3ptm"] ===
          0
      )
        return;
    }
    const ok = await ConfirmationModal(
      "Are you sure you want to undo the last action?"
    );
    if (ok) {
      if (currentMatch[currentMatch.selectedType]) {
        //currentMatch[currentMatch.selectedType].score -= points;
        if (currentMatch[currentMatch.selectedType].score < 0) {
          currentMatch[currentMatch.selectedType].score = 0;
        }
        if (idx >= 0 && currentMatch[selectedMember.type].players[idx].stats) {
          if (ptsType === "ftm") {
            selectedMember.stats.fta -= 1;
          }
          if (ptsType === "2ptm") {
            selectedMember.stats["2pta"] -= 1;
          }
          if (ptsType === "3ptm") {
            selectedMember.stats["3pta"] -= 1;
          }
          selectedMember.stats[ptsType] -= 1;
        }
        setCurrentMatch({ ...currentMatch });
        setSelectedMember({ ...selectedMember });
        const payload = {
          type: ptsType,
          player: selectedMember?.player?._id,
          team: currentMatch[selectedMember.type]?.team?._id,
          undo: true,
        };
        onSaveMatchScores(payload);
      }
    }
  };
  const onUpdatePersonalFouls = async () => {
    const payload = {
      player: selectedMember?._id,
      team: currentMatch[selectedMember.type]?.team?._id,
      type: "personal_fouls",
    };
    if (scoreType) {
      const ok = await ConfirmationModal(
        "Are you sure you want to undo the last action?"
      );
      if (ok) {
        payload.undo = true;
      } else {
        return;
      }
    }
    const idx = currentMatch[selectedMember.type]?.players?.findIndex(
      (ee) => ee._id === selectedMember?._id
    );
    if (idx >= 0) {
      currentMatch[selectedMember.type].players[idx].personal_fouls +=
        payload.undo ? -1 : 1;
      currentMatch[selectedMember.type].personal_fouls += payload.undo ? -1 : 1;
      if (currentMatch[selectedMember.type].personal_fouls < 0) {
        currentMatch[selectedMember.type].personal_fouls = 0;
      }
      if (currentMatch[selectedMember.type].players[idx].personal_fouls >= 5) {
        currentMatch[selectedMember.type].players[idx].personal_fouls = 5;
        const ok = await ConfirmationModal(
          "This Action will Foul Out the player, Are you sure want to continue?"
        );
        if (!ok) {
          currentMatch[selectedMember.type].players[idx].personal_fouls = 4;
          return;
        } else {
          message.success(
            `${currentMatch[selectedMember.type].team.name} ${
              currentMatch[selectedMember.type].players[idx].player.first_name
            } ${
              currentMatch[selectedMember.type].players[idx].player.last_name
            } has FOULED OUT`
          );
        }
        selectedMember.selected = false;
        setPfModalInfo({
          visible: true,
          player: currentMatch[selectedMember.type].players[idx],
          type: selectedMember.type,
        });
      }
      selectedMember.personal_fouls =
        currentMatch[selectedMember.type].players[idx].personal_fouls;
      setSelectedMember({ ...selectedMember });
      setCurrentMatch({ ...currentMatch });
    }
    await apiClient.put(
      ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
      payload
    );
  };
  const onUpdateTechnicalFouls = async () => {
    const payload = {
      player: selectedMember?._id,
      team: currentMatch[selectedMember.type]?.team?._id,
      type: "technical_fouls",
    };
    if (scoreType) {
      const ok = await ConfirmationModal(
        "Are you sure you want to undo the last action?"
      );
      if (ok) {
        payload.undo = true;
      } else {
        return;
      }
    }
    const idx = currentMatch[selectedMember.type]?.players?.findIndex(
      (ee) => ee._id === selectedMember?._id
    );
    if (idx >= 0) {
      currentMatch[selectedMember.type].players[idx].technical_fouls +=
        payload.undo ? -1 : 1;
      currentMatch[selectedMember.type].technical_fouls += payload.undo
        ? -1
        : 1;
      if (currentMatch[selectedMember.type].players[idx].technical_fouls < 0) {
        currentMatch[selectedMember.type].players[idx].technical_fouls = 0;
      }
      if (currentMatch[selectedMember.type].players[idx].technical_fouls >= 2) {
        currentMatch[selectedMember.type].players[idx].technical_fouls = 2;
        const ok = await ConfirmationModal(
          "This Action will Foul Out the player, Are you sure want to continue?"
        );
        if (!ok) {
          currentMatch[selectedMember.type].players[idx].technical_fouls = 2;
          return;
        } else {
          message.success(
            `${currentMatch[selectedMember.type].team.name} ${
              currentMatch[selectedMember.type].players[idx].player.first_name
            } ${
              currentMatch[selectedMember.type].players[idx].player.last_name
            } has FOULED OUT`
          );
        }
        selectedMember.selected = false;
        setPfModalInfo({
          visible: true,
          player: currentMatch[selectedMember.type].players[idx],
          type: selectedMember.type,
        });
      }
      selectedMember.technical_fouls =
        currentMatch[selectedMember.type].players[idx].technical_fouls;
      setSelectedMember({ ...selectedMember });
      setCurrentMatch({ ...currentMatch });
    }
    await apiClient.put(
      ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
      payload
    );
  };
  const awayid = currentMatch?.away?.team?._id;
  const homeid = currentMatch?.home?.team?._id;
  const [hometeamplayers, sethometeamplayers] = useState([]);
  const [awayteamplayers, setawayteamplayers] = useState([]);
  const [hometeamplayersinfo, sethometeamplayersinfo] = useState([]);
  const [awayteamplayersinfo, setawayteamplayersinfo] = useState([]);
  const playerslist = async (id, type) => {
    if (Boolean(id)) {
      const teamplayers = await apiClient.get(
        ApiConfig.common.viewteam + `/${id}`
      );
      const temp = currentMatch[type]?.players.map((item) => item?.player?._id);
      const addfromteam = teamplayers?.data?.players
        ?.filter((item) => !temp.includes(item?.player?._id))
        .map((item) => {
          return { label: item.player.first_name, value: item.player._id };
        });
      const addfromteaminfo = teamplayers?.data?.players?.filter(
        (item) => !temp.includes(item?.player?._id)
      );
      console.log(
        teamplayers?.data?.players,
        "teamplayers",
        temp,
        currentMatch[type],
        addfromteam
      );
      if (type === "home") {
        sethometeamplayers(addfromteam);
        sethometeamplayersinfo(addfromteaminfo);
      } else {
        setawayteamplayers(addfromteam);
        setawayteamplayersinfo(addfromteaminfo);
      }
    }
  };
  useEffect(() => {
    playerslist(homeid, "home");
    playerslist(awayid, "away");
    // eslint-disable-next-line
  }, [awayid, homeid]);

  const onAddOtherDetails = async (type) => {
    // currentMatch[currentMatch.selectedType].score = currentMatch[currentMatch.selectedType].score;
    // setCurrentMatch({ ...currentMatch });
    setLastClickedAction(type);
    const payload = {
      type,
      player: selectedMember?.player?._id,
      team: currentMatch[selectedMember.type]?.team?._id,
    };
    const idx = currentMatch[selectedMember.type].players.findIndex(
      (pl) => pl._id === selectedMember._id
    );
    if (idx >= 0 && scoreType) {
      if (
        type === "fta" &&
        currentMatch[selectedMember.type].players[idx].stats["fta"] -
          currentMatch[selectedMember.type].players[idx].stats["ftm"] ===
          0
      )
        return;
      if (
        type === "2pta" &&
        currentMatch[selectedMember.type].players[idx].stats["2pta"] -
          currentMatch[selectedMember.type].players[idx].stats["2ptm"] ===
          0
      )
        return;
      if (
        type === "3pta" &&
        currentMatch[selectedMember.type].players[idx].stats["3pta"] -
          currentMatch[selectedMember.type].players[idx].stats["3ptm"] ===
          0
      )
        return;
    }
    if ((type === "2pta" || type === "3pta") && !scoreType) {
      setRebModalInfo({
        visible: true,
        type: selectedMember.type,
      });
    }

    if (type === "to" && !scoreType) {
      setToModalInfo({
        visible: true,
        type: selectedMember.type,
      });
    }
    if (scoreType) {
      if (
        idx >= 0 &&
        currentMatch[selectedMember.type].players[idx].stats[type] === 0
      )
        return;
      const ok = await ConfirmationModal(
        "Are you sure you want to undo the last action?"
      );
      if (ok) {
        payload.undo = true;
      } else {
        return;
      }
    }

    if (idx >= 0 && currentMatch[selectedMember.type].players[idx].stats) {
      // currentMatch[selectedMember.type].players[idx].stats[type] += 1
      selectedMember.stats[type] += payload.undo ? -1 : 1;
    }
    setSelectedMember({ ...selectedMember });
    onSaveMatchScores(payload);
  };

  const PopoverContent = ({ popVisible }) => {
    const [no, setNo] = useState(selectedMember?.jersey_no);
    const onSubmitNum = async () => {
      const payload = {
        player: selectedMember?.player?._id,
        team: currentMatch[selectedMember.type]?.team?._id,
      };
      const jIdx = currentMatch[selectedMember.type]?.players?.findIndex(
        (pl) => pl.jersey_no === parseInt(no)
      );
      if (jIdx >= 0) {
        return notification.error({
          message: "The player with this jersey_no is already exists",
        });
      }
      setSelectedMember({
        ...selectedMember,
        jersey_no: no,
      });
      const idx = currentMatch[selectedMember.type]?.players?.findIndex(
        (pl) => pl._id === selectedMember._id
      );
      if (idx >= 0) {
        currentMatch[selectedMember.type].players[idx].jersey_no = no;
        payload.jersey_no = no;
        try {
          await apiClient.put(
            ApiConfig.matches.updateMatchManager + `/${currentMatch._id}`,
            payload
          );
          setNumberChange(false);
          setTimeout(() => {
            setCurrentMatch({
              ...currentMatch,
            });
          }, 10);
        } catch (error) {}
      }
    };

    useEffect(() => {
      if (popVisible) {
        $(".score-popup-content").fadeOut(0);
        $(".score-popup-content").fadeIn(200);
      }
    }, [popVisible]);

    if (popVisible) {
      return (
        <>
          <div className="score-popup-content">
            <div className="top-block">
              <Button
                onClick={() => setScoreType(!scoreType)}
                className={`plus-minus-btn${
                  !scoreType ? " plus-btn" : " minus-btn"
                }`}
                icon={!scoreType ? <PlusOutlined /> : <MinusOutlined />}
              />
              <div className="avatar-block">
                <Popover
                  title="Change Player Number"
                  overlayClassName="chng-pl-no"
                  visible={numberChange}
                  trigger="none"
                  onVisibleChange={(v) => setNumberChange(v)}
                  placement="left"
                  content={
                    <div className="change-player-no">
                      {
                        <Input
                          maxLength={3}
                          onChange={(e) => setNo(e.target.value)}
                          suffix={
                            <CheckOutlined onClick={() => onSubmitNum()} />
                          }
                          value={no}
                        />
                      }
                    </div>
                  }
                >
                  <div className="image-block">
                    {selectedMember?.player?.profile_pic && (
                      <img src={selectedMember?.player?.profile_pic} alt="" />
                    )}
                    {!selectedMember?.player?.profile_pic && (
                      <span>{getShortName(selectedMember?.player)}</span>
                    )}
                    <div className="jersey-no">{selectedMember?.jersey_no}</div>
                  </div>
                </Popover>
                <div
                  className="close_icon"
                  onClick={() => onClosePopup(selectedMember.type)}
                >
                  <FiX size={20} />
                </div>
              </div>
            </div>
            <div className="score-block">
              <div className="score-item">
                <div className="score-type">
                  <div className="score-h">2 PTS</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      onClick={(e) => onPlusScore(2, "2ptm", 1, e)}
                      className={`mr-1${
                        selectedMember?.lastClicked === "2ptm" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["2ptm"]
                          : 0}
                      </span>
                    </Button>
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      onClick={(e) => onAddOtherDetails("2pta")}
                      className={`${
                        selectedMember?.lastClicked === "2pta" ? "clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["2pta"] -
                            selectedMember?.stats["2ptm"]
                          : 0}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">3 PTS</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      onClick={(e) => onPlusScore(3, "3ptm", 1, e)}
                      className={`mr-1${
                        selectedMember?.lastClicked === "3ptm" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["3ptm"]
                          : 0}
                      </span>
                    </Button>
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      onClick={(e) => onAddOtherDetails("3pta")}
                      className={`${
                        selectedMember?.lastClicked === "3pta" ? "clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats
                          ? selectedMember?.stats["3pta"] -
                            selectedMember?.stats["3ptm"]
                          : 0}
                      </span>
                    </Button>
                  </div>
                </div>

                <div className="score-type">
                  <div className="score-h">FREE THROW</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      onClick={(e) => onPlusScore(1, "ftm", e)}
                      className={`mr-1${
                        selectedMember?.lastClicked === "ftm" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.ftm}
                      </span>
                    </Button>
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      onClick={(e) => onAddOtherDetails("fta")}
                      className={`${
                        selectedMember?.lastClicked === "fta" ? "clicked" : ""
                      }`}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.fta -
                          selectedMember?.stats?.ftm}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="score-item">
                <div className="score-type">
                  <div className="score-h">REBOUNDS</div>
                  <div className="score-act reb">
                    <Button
                      onClick={(e) => onAddOtherDetails("oreb")}
                      className={`mr-1${
                        selectedMember?.lastClicked === "oreb" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      OREB
                      <br />
                      <span className="t-score">
                        {selectedMember?.stats?.oreb}
                      </span>
                    </Button>
                    <Button
                      onClick={(e) => onAddOtherDetails("dreb")}
                      className={`mr-1${
                        selectedMember?.lastClicked === "dreb" ? " clicked" : ""
                      }`}
                      type="ghost"
                    >
                      DREB
                      <br />
                      <span className="t-score">
                        {selectedMember?.stats?.dreb}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">ASSISTS</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      className={`mr-1${
                        selectedMember?.lastClicked === "ast" ? " clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("ast")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.ast}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">STEAL</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      className={`mr-1${
                        selectedMember?.lastClicked === "stl" ? " clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("stl")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.stl}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="score-item">
                <div className="score-type">
                  <div className="score-h">BLOCK</div>
                  <div className="score-act">
                    <Button
                      icon={<FaCheck style={{ fontSize: "24px" }} />}
                      className={`mr-1${
                        selectedMember?.lastClicked === "blk" ? " clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("blk")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.blk}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">TURNOVER</div>
                  <div className="score-act">
                    <Button
                      icon={<FaTimes style={{ fontSize: "24px" }} />}
                      className={`${
                        selectedMember?.lastClicked === "to" ? "clicked" : ""
                      }`}
                      onClick={(e) => onAddOtherDetails("to")}
                      type="ghost"
                    >
                      <span className="t-score">
                        {selectedMember?.stats?.to}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="score-type">
                  <div className="score-h">FOUL</div>
                  <div className="score-act">
                    <Button
                      icon={
                        !scoreType ? (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            + PF
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            - PF
                          </span>
                        )
                      }
                      onClick={() => onUpdatePersonalFouls()}
                      type="ghost"
                    >
                      {/* PF<br /> */}
                      <span className="t-score" style={{ marginTop: "20px" }}>
                        {selectedMember?.personal_fouls}
                      </span>
                    </Button>
                    <span> &nbsp;</span>
                    <Button
                      icon={
                        !scoreType ? (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            + TF
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              marginTop: "5px",
                            }}
                          >
                            -
                          </span>
                        )
                      }
                      onClick={() => onUpdateTechnicalFouls()}
                      type="ghost"
                    >
                      {/* PF<br /> */}
                      <span className="t-score" style={{ marginTop: "20px" }}>
                        {selectedMember?.technical_fouls}
                      </span>
                    </Button>
                  </div>
                </div>
                {/* <div className='score-type'>
                  <div className='score-h'>PERSONAL FOULS</div>
                  <div className='score-act'>
                    <Button onClick={() => onUpdatePersonalFouls()} type='ghost'>PF<br />{!scoreType ? '+' : '-'}1<span className='t-score'>{selectedMember?.personal_fouls}</span></Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  };
  const getinfouledoutplayeronly = async (member, type) => {
    const bool = await ConfirmationModal(
      "Do you want to reduce foul and get in fouled out player?"
    );
    if (bool) {
      const idx = currentMatch[type]?.players?.findIndex(
        (ee) => ee._id === member?._id
      );
      const payload = {
        player: member?._id,
        team: currentMatch[type]?.team?._id,
      };
      //onUpdatePersonalFouls()
      if (currentMatch[type].players[idx].personal_fouls >= 5) {
        payload.type = "personal_fouls";
        payload.undo = true;
        const idx = currentMatch[type]?.players?.findIndex(
          (ee) => ee._id === member?._id
        );
        console.log(idx, "idx", type, member);
        if (idx >= 0) {
          currentMatch[type].players[idx].personal_fouls += payload.undo
            ? -1
            : 1;
          currentMatch[type].personal_fouls += payload.undo ? -1 : 1;
          if (currentMatch[type].personal_fouls < 0) {
            currentMatch[type].personal_fouls = 0;
          }
          console.log(
            currentMatch[type].players[idx].personal_fouls,
            "currentMatch[selectedtype].players[idx].personal_fouls"
          );

          //selectedMember.personal_fouls = currentMatch[type].players[idx].personal_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
      } else {
        payload.type = "technical_fouls";
        payload.undo = true;
        if (idx >= 0) {
          currentMatch[type].players[idx].technical_fouls += payload.undo
            ? -1
            : 1;
          //currentMatch[type].technical_fouls += payload.undo ? -1 : 1
          if (currentMatch[type].players[idx].technical_fouls < 0) {
            currentMatch[type].players[idx].technical_fouls = 0;
          }
          if (currentMatch[type].players[idx].technical_fouls >= 2) {
            currentMatch[type].players[idx].technical_fouls = 2;
            const ok = await ConfirmationModal(
              "This Action will foul out the player, Are you sure want to continue?"
            );
            if (!ok) {
              currentMatch[type].players[idx].technical_fouls = 1;
              return;
            } else {
              message.success(
                `${currentMatch[type].team.name} ${currentMatch[type].players[idx].player.first_name} ${currentMatch[type].players[idx].player.last_name} has FOULED OUT`
              );
            }
            selectedMember.selected = false;
            setPfModalInfo({
              visible: true,
              player: currentMatch[type].players[idx],
              type: type,
            });
          }
          //selectedMember.technical_fouls = currentMatch[type].players[idx].technical_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
      }
      // const tidx = currentMatch[type]?.players?.findIndex(
      //   (ee) => ee.jersey_no === selectedMember.jersey_no
      // );
      const sidx = currentMatch[type]?.players?.findIndex(
        (ee) => ee.jersey_no === member.jersey_no
      );
      if (sidx >= 0) {
        //const order = currentMatch[type].players[tidx].order;
        //currentMatch[type].players[tidx].is_starting_lineup = false;
        // currentMatch[type].players[tidx].order =
        //   currentMatch[type].players[sidx].order;
        currentMatch[type].players[sidx].is_starting_lineup = true;
        //currentMatch[type].players[sidx].order = order;
        setSelectedMember(null);
        setCurrentMatch({
          ...currentMatch,
        });
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
        apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
          team: currentMatch[type].team._id,
          //swap: currentMatch[type].players[tidx]._id,
          player: currentMatch[type].players[sidx]._id,
          foulin: true,
        });
      }
      setPfModalInfo({
        visible: false,
      });
      setopenawayplayergetin(false);
      setopenhomeplayergetin(false);
    }
  };
  const getinfouledoutplayer = async (member, type) => {
    const bool = await ConfirmationModal(
      "Do you want to reduce foul and get in fouled out player?"
    );
    if (bool) {
      const idx = currentMatch[type]?.players?.findIndex(
        (ee) => ee._id === member?._id
      );
      const payload = {
        player: member?._id,
        team: currentMatch[type]?.team?._id,
      };
      //onUpdatePersonalFouls()
      if (currentMatch[type].players[idx].personal_fouls >= 5) {
        payload.type = "personal_fouls";
        payload.undo = true;
        const idx = currentMatch[type]?.players?.findIndex(
          (ee) => ee._id === member?._id
        );
        console.log(idx, "idx", type, member);
        if (idx >= 0) {
          currentMatch[type].players[idx].personal_fouls += payload.undo
            ? -1
            : 1;
          currentMatch[type].personal_fouls += payload.undo ? -1 : 1;
          if (currentMatch[type].personal_fouls < 0) {
            currentMatch[type].personal_fouls = 0;
          }
          console.log(
            currentMatch[type].players[idx].personal_fouls,
            "currentMatch[selectedtype].players[idx].personal_fouls"
          );

          //selectedMember.personal_fouls = currentMatch[type].players[idx].personal_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
      } else {
        payload.type = "technical_fouls";
        payload.undo = true;
        if (idx >= 0) {
          currentMatch[type].players[idx].technical_fouls += payload.undo
            ? -1
            : 1;
          //currentMatch[type].technical_fouls += payload.undo ? -1 : 1
          if (currentMatch[type].players[idx].technical_fouls < 0) {
            currentMatch[type].players[idx].technical_fouls = 0;
          }
          if (currentMatch[type].players[idx].technical_fouls >= 2) {
            currentMatch[type].players[idx].technical_fouls = 2;
            const ok = await ConfirmationModal(
              "This Action will foul out the player, Are you sure want to continue?"
            );
            if (!ok) {
              currentMatch[type].players[idx].technical_fouls = 1;
              return;
            } else {
              message.success(
                `${currentMatch[type].team.name} ${currentMatch[type].players[idx].player.first_name} ${currentMatch[type].players[idx].player.last_name} has FOULED OUT`
              );
            }
            selectedMember.selected = false;
            setPfModalInfo({
              visible: true,
              player: currentMatch[type].players[idx],
              type: type,
            });
          }
          //selectedMember.technical_fouls = currentMatch[type].players[idx].technical_fouls;
          // setSelectedMember({ ...selectedMember });
          // setCurrentMatch({ ...currentMatch })
        }
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
      }
      const tidx = currentMatch[type]?.players?.findIndex(
        (ee) => ee.jersey_no === selectedMember.jersey_no
      );
      const sidx = currentMatch[type]?.players?.findIndex(
        (ee) => ee.jersey_no === member.jersey_no
      );
      if (tidx >= 0 && sidx >= 0) {
        const order = currentMatch[type].players[tidx].order;
        currentMatch[type].players[tidx].is_starting_lineup = false;
        currentMatch[type].players[tidx].order =
          currentMatch[type].players[sidx].order;
        currentMatch[type].players[sidx].is_starting_lineup = true;
        currentMatch[type].players[sidx].order = order;
        setSelectedMember(null);
        setCurrentMatch({
          ...currentMatch,
        });
        await apiClient.put(
          ApiConfig.matches.updateMatch + `/${currentMatch._id}`,
          payload
        );
        apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
          team: currentMatch[type].team._id,
          swap: currentMatch[type].players[tidx]._id,
          player: currentMatch[type].players[sidx]._id,
        });
      }
      setPfModalInfo({
        visible: false,
      });
    }
  };

  const PopContent = ({ onSelect, type, hidePopup, hideswap = false }) => (
    <div className="team-list popover-list" style={{ display: "block" }}>
      {currentMatch?.[type]?.players
        ?.filter(
          (tt) =>
            !tt.is_starting_lineup &&
            tt.personal_fouls < 5 &&
            tt.technical_fouls < 2
        )
        .map((tt) => (
          <div
            onClick={() => {
              hideswap ? onSelectinBenchPlayer(tt, type) : onSelect(tt, type);
              if (hidePopup) {
                setOpen(false);
                setOpenaway(false);
              }
            }}
            className="team-item"
            style={{ marginBottom: "20px" }}
          >
            <div className="team-avatar">
              {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
              {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
              <div className="p-name">
                <span>{tt?.player?.first_name}</span>
                <span>{tt?.player?.last_name}</span>
              </div>
            </div>
            <div
              className="team-no"
              style={
                type === "home"
                  ? {
                      backgroundColor: currentMatch?.home?.changed_jersey_color,
                      color:
                        currentMatch?.home?.changed_jersey_color === "#000000"
                          ? "#fff"
                          : undefined,
                    }
                  : {
                      backgroundColor: currentMatch?.away?.changed_jersey_color,
                      color:
                        currentMatch?.away?.changed_jersey_color === "#000000"
                          ? "#fff"
                          : undefined,
                    }
              }
            >
              {tt?.jersey_no}
            </div>
          </div>
        ))}
      {currentMatch?.[type]?.players
        ?.filter(
          (tt) =>
            !tt.is_starting_lineup &&
            (tt.personal_fouls >= 5 || tt.technical_fouls >= 2)
        )
        .map((tt) => (
          <div
            onClick={() => {
              hideswap
                ? getinfouledoutplayeronly(tt, type)
                : getinfouledoutplayer(tt, type);
              if (hidePopup) {
                setOpen(false);
                setOpenaway(false);
              }
            }}
            className="team-item"
          >
            <div style={{ backgroundColor: "white" }}>
              <div
                style={{
                  backgroundColor: "#AA0000",
                  color: "#fff",
                  padding: "3px 0px",
                  borderRadius: "10px 10px 0px 0px",
                  textAlign: "center",
                }}
              >
                Fouled Out
              </div>
            </div>
            <div className="team-avatar">
              <div className="p-name">
                <span>{tt?.player?.first_name}</span>
                <span>{tt?.player?.last_name}</span>
              </div>
            </div>
            <div style={{ backgroundColor: "grey", opacity: "0.2" }}>
              <div
                className="team-no"
                style={
                  type === "home"
                    ? {
                        backgroundColor:
                          currentMatch?.home?.changed_jersey_color,
                        color:
                          currentMatch?.home?.changed_jersey_color === "#000000"
                            ? "#fff"
                            : undefined,
                      }
                    : {
                        backgroundColor:
                          currentMatch?.away?.changed_jersey_color,
                        color:
                          currentMatch?.away?.changed_jersey_color === "#000000"
                            ? "#fff"
                            : undefined,
                      }
                }
              >
                {tt?.jersey_no}
              </div>
            </div>
          </div>
        ))}
      {currentMatch?.[type]?.players?.filter(
        (tt) => !tt.is_starting_lineup && tt.personal_fouls < 5
      ).length === 0 &&
        !hideswap && (
          <>
            <div
              className="no-player-left"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <span>No bench players available</span>
              {!hideswap && (
                <Button
                  type="primary"
                  onClick={() => {
                    onSelectBenchPlayeronly(type);
                    if (hidePopup) {
                      setOpen(false);
                      setOpenaway(false);
                    }
                  }}
                >
                  Foul Out
                </Button>
              )}
            </div>
          </>
        )}
    </div>
  );

  const SelectAssists = ({ onSelect, type }) => (
    <div className="ast-modal">
      <div class="bench-player-title mt-0">Active Players</div>
      <div className="team-list popover-list" style={{ display: "block" }}>
        {currentMatch?.[type]?.players
          ?.filter(
            (tt) =>
              tt.is_starting_lineup &&
              tt.personal_fouls < 5 &&
              selectedMember._id !== tt._id
          )
          .map((tt) => (
            <div
              onClick={() => onSelect(tt, type)}
              className="team-item"
              style={{ marginBottom: "20px" }}
            >
              <div className="team-avatar">
                {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                <div className="p-name">
                  <span>{tt?.player?.first_name}</span>
                  <span>{tt?.player?.last_name}</span>
                </div>
              </div>
              <div
                className="team-no"
                style={
                  type === "home"
                    ? {
                        backgroundColor:
                          currentMatch?.home?.changed_jersey_color,
                        color:
                          currentMatch?.home?.changed_jersey_color === "#000000"
                            ? "#fff"
                            : undefined,
                      }
                    : {
                        backgroundColor:
                          currentMatch?.away?.changed_jersey_color,
                        color:
                          currentMatch?.away?.changed_jersey_color === "#000000"
                            ? "#fff"
                            : undefined,
                      }
                }
              >
                {tt?.jersey_no}
              </div>
            </div>
          ))}
      </div>
      <div class="bench-player-title">Bench Players</div>
      <div className="team-list popover-list" style={{ display: "block" }}>
        {currentMatch?.[type]?.players
          ?.filter(
            (tt) =>
              !tt.is_starting_lineup &&
              tt.personal_fouls < 5 &&
              selectedMember._id !== tt._id
          )
          .map((tt) => (
            <div
              onClick={() => onSelect(tt, type)}
              className="team-item"
              style={{ marginBottom: "20px" }}
            >
              <div className="team-avatar">
                {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                <div className="p-name">
                  <span>{tt?.player?.first_name}</span>
                  <span>{tt?.player?.last_name}</span>
                </div>
              </div>
              <div
                className="team-no"
                style={
                  type === "home"
                    ? {
                        backgroundColor:
                          currentMatch?.home?.changed_jersey_color,
                        color:
                          currentMatch?.home?.changed_jersey_color === "#000000"
                            ? "#fff"
                            : undefined,
                      }
                    : {
                        backgroundColor:
                          currentMatch?.away?.changed_jersey_color,
                        color:
                          currentMatch?.away?.changed_jersey_color === "#000000"
                            ? "#fff"
                            : undefined,
                      }
                }
              >
                {tt?.jersey_no}
              </div>
            </div>
          ))}
      </div>
      <div className="btns-act">
        <Button onClick={() => setAstModalInfo({ visible: false })}>
          No Assists
        </Button>
      </div>
    </div>
  );

  const onSelectMember = (team, type, e) => {
    // if ($(e.target).closest('.score-popup-content').length > 0) return;
    if ($(e.target).closest(".popover-list").length > 0) return;
    if ($(e.target).closest(".swap-player").length > 0) return;
    currentMatch.selectedType = type;
    setScoreType(false);
    const idx = currentMatch[type]?.players?.findIndex(
      (ee) => ee._id === team._id
    );
    if (idx >= 0 && !currentMatch[type]?.players[idx]?.selected) {
      currentMatch.away.players.forEach((ee) => {
        ee.selected = false;
        ee.swap = false;
      });
      currentMatch.home.players.forEach((ee) => {
        ee.selected = false;
        ee.swap = false;
      });
      console.log(team, type, "team,type,");
      if (team.swap) {
        currentMatch[type].players[idx].swap = false;
      } else {
        currentMatch[type].players[idx].selected = true;
      }
      // currentMatch[type].players[idx].selected = true
    }
    team.type = type;
    setSelectedMember(team);
    setCurrentMatch({ ...currentMatch });
  };

  const onSelectAstPlayer = (sPlayer, type) => {
    if (sPlayer) {
      sPlayer.type = type;
      const payload = {
        type: "ast",
        player: sPlayer?.player?._id,
        team: currentMatch[sPlayer.type]?.team?._id,
      };
      const idx = currentMatch[sPlayer.type].players.findIndex(
        (pl) => pl._id === sPlayer._id
      );
      if (idx >= 0 && currentMatch[sPlayer.type].players[idx].stats) {
        currentMatch[sPlayer.type].players[idx].stats.ast += 1;
      }
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores(payload);
    }
    setAstModalInfo({ visible: false });
  };

  const onSelectToPlayer = (sPlayer, type) => {
    if (sPlayer) {
      sPlayer.type = type;
      const payload = {
        type: "stl",
        player: sPlayer?.player?._id,
        team: currentMatch[sPlayer.type]?.team?._id,
      };
      const idx = currentMatch[sPlayer.type].players.findIndex(
        (pl) => pl._id === sPlayer._id
      );
      if (idx >= 0 && currentMatch[sPlayer.type].players[idx].stats) {
        currentMatch[sPlayer.type].players[idx].stats["stl"] += 1;
      }
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores(payload);
    }
    setToModalInfo({ visible: false });
  };

  const onSelectReboundsPlayer = (sPlayer, type) => {
    if (sPlayer) {
      sPlayer.type = type;
      const payload = {
        type: selectedMember.type === type ? "oreb" : "dreb",
        player: sPlayer?.player?._id,
        team: currentMatch[sPlayer.type]?.team?._id,
      };
      const idx = currentMatch[sPlayer.type].players.findIndex(
        (pl) => pl._id === sPlayer._id
      );
      if (idx >= 0 && currentMatch[sPlayer.type].players[idx].stats) {
        currentMatch[sPlayer.type].players[idx].stats[
          selectedMember.type === type ? "oreb" : "dreb"
        ] += 1;
      }
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores(payload);
    }
    setRebModalInfo({ visible: false });
  };

  const SelectReboundsPlayer = ({ onSelect, type }) => {
    return (
      <>
        <div class="bench-player-title mt-0">Active Players</div>
        <div className="team-list popover-list" style={{ display: "block" }}>
          {currentMatch?.[type]?.players
            ?.filter(
              (tt) =>
                tt.is_starting_lineup &&
                tt.personal_fouls < 5 &&
                selectedMember._id !== tt._id
            )
            .map((tt) => (
              <div
                onClick={() => onSelect(tt, type)}
                className="team-item"
                style={{ marginBottom: "20px" }}
              >
                <div className="team-avatar">
                  {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                  {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                  <div className="p-name">
                    <span>{tt?.player?.first_name}</span>
                    <span>{tt?.player?.last_name}</span>
                  </div>
                </div>
                <div
                  className="team-no"
                  style={
                    type === "home"
                      ? {
                          backgroundColor:
                            currentMatch?.home?.changed_jersey_color,
                          color:
                            currentMatch?.home?.changed_jersey_color ===
                            "#000000"
                              ? "#fff"
                              : undefined,
                        }
                      : {
                          backgroundColor:
                            currentMatch?.away?.changed_jersey_color,
                          color:
                            currentMatch?.away?.changed_jersey_color ===
                            "#000000"
                              ? "#fff"
                              : undefined,
                        }
                  }
                >
                  {tt?.jersey_no}
                </div>
              </div>
            ))}
        </div>
        <div class="bench-player-title">Bench Players</div>
        <div className="team-list popover-list" style={{ display: "block" }}>
          {currentMatch?.[type]?.players
            ?.filter(
              (tt) =>
                !tt.is_starting_lineup &&
                tt.personal_fouls < 5 &&
                selectedMember._id !== tt._id
            )
            .map((tt) => (
              <div
                onClick={() => onSelect(tt, type)}
                className="team-item"
                style={{ marginBottom: "20px" }}
              >
                <div className="team-avatar">
                  {/* {tt?.player?.profile_pic && <img src={tt?.player?.profile_pic} alt='' />}
                  {!tt?.player?.profile_pic && <span>{getShortName(tt?.player)}</span>} */}
                  <div className="p-name">
                    <span>{tt?.player?.first_name}</span>
                    <span>{tt?.player?.last_name}</span>
                  </div>
                </div>
                <div
                  className="team-no"
                  style={
                    type === "home"
                      ? {
                          backgroundColor:
                            currentMatch?.home?.changed_jersey_color,
                          color:
                            currentMatch?.home?.changed_jersey_color ===
                            "#000000"
                              ? "#fff"
                              : undefined,
                        }
                      : {
                          backgroundColor:
                            currentMatch?.away?.changed_jersey_color,
                          color:
                            currentMatch?.away?.changed_jersey_color ===
                            "#000000"
                              ? "#fff"
                              : undefined,
                        }
                  }
                >
                  {tt?.jersey_no}
                </div>
              </div>
            ))}
        </div>
      </>
    );
  };

  const SelectRebounds = ({ onSelect, type }) => {
    return (
      <Tabs>
        <Tabs.TabPane
          key="1"
          tab={
            selectedMember.type === "away"
              ? currentMatch?.home?.team?.name
              : currentMatch?.away?.team?.name
          }
        >
          <div
            className="ast-modal"
            // style={
            //   currentMatch?.away?.changed_jersey_color
            //     ? { color: currentMatch?.away?.changed_jersey_color }
            //     : { color: currentMatch?.home?.changed_jersey_color }
            // }
          >
            <SelectReboundsPlayer
              onSelect={onSelect}
              type={selectedMember.type === "away" ? "home" : "away"}
            />
            <div className="btns-act">
              <Button onClick={() => setRebModalInfo({ visible: false })}>
                No Rebound
              </Button>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            selectedMember.type === "away"
              ? currentMatch?.away?.team?.name
              : currentMatch?.home?.team?.name
          }
        >
          <div
            className="ast-modal"
            // style={
            //   currentMatch?.away?.changed_jersey_color
            //     ? { color: currentMatch?.home?.changed_jersey_color }
            //     : { color: currentMatch?.away?.changed_jersey_color }
            // }
          >
            <SelectReboundsPlayer
              onSelect={onSelect}
              type={selectedMember.type === "away" ? "away" : "home"}
            />
            <div className="btns-act">
              {selectedMember.type === type && (
                <Button //onClick={() => setRebModalInfo({ visible: false })}
                  onClick={() => onSelect(selectedMember, type)}
                >
                  Second chance
                </Button>
              )}
              <Button onClick={() => setRebModalInfo({ visible: false })}>
                No Rebound
              </Button>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    );
  };
  const Selectto = ({ onSelect, type }) => {
    return (
      <>
        {type === "away" ? (
          <div className="ast-modal">
            <SelectReboundsPlayer onSelect={onSelect} type="home" />
            <div className="btns-act">
              <Button onClick={() => setToModalInfo({ visible: false })}>
                No Steal
              </Button>
            </div>
          </div>
        ) : (
          <div className="ast-modal">
            <SelectReboundsPlayer onSelect={onSelect} type="away" />
            <div className="btns-act">
              <Button onClick={() => setToModalInfo({ visible: false })}>
                No Steal
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  const onSwapPlayer = (team, type) => {
    team.type = type;
    setSelectedMember(team);
    const idx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === team.jersey_no
    );
    if (idx >= 0 && !currentMatch[type]?.players[idx]?.swap) {
      currentMatch.away.players.forEach((ee) => {
        ee.swap = false;
        ee.selected = false;
      });
      currentMatch.home.players.forEach((ee) => {
        ee.swap = false;
        ee.selected = false;
      });
      currentMatch[type].players[idx].swap = true;
      // currentMatch[type].players[idx].selected = true
    } else {
      currentMatch[type].players[idx].swap = false;
      currentMatch[type].players[idx].selected = false;
      setSelectedMember(null);
    }
    setCurrentMatch({
      ...currentMatch,
    });
  };

  const onOpenPicker = (type) => {
    currentMatch[type].team.openPicker = !currentMatch[type].team.openPicker;
    setCurrentMatch({
      ...currentMatch,
    });
  };

  const onChangeColor = (e, type) => {
    currentMatch[type].team.openPicker = false;
    currentMatch[type].changed_jersey_color = e.hex;
    setCurrentMatch({
      ...currentMatch,
    });
    apiClient.put(
      ApiConfig.matches.updateMatchManager + `/${currentMatch._id}`,
      { team: currentMatch[type].team._id, changed_jersey_color: e.hex }
    );
  };

  const onSelectBenchPlayer = (member, type) => {
    const tidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === selectedMember.jersey_no
    );
    const sidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === member.jersey_no
    );
    if (tidx >= 0 && sidx >= 0) {
      const order = currentMatch[type].players[tidx].order;
      currentMatch[type].players[tidx].is_starting_lineup = false;
      currentMatch[type].players[tidx].order =
        currentMatch[type].players[sidx].order;
      currentMatch[type].players[sidx].is_starting_lineup = true;
      currentMatch[type].players[sidx].order = order;
      setSelectedMember(null);
      setCurrentMatch({
        ...currentMatch,
      });
      apiClient.put(
        ApiConfig.matches.updateMatchManager + `/${currentMatch._id}`,
        {
          team: currentMatch[type].team._id,
          swap: currentMatch[type].players[tidx]._id,
          player: currentMatch[type].players[sidx]._id,
        }
      );
    }
    setPfModalInfo({
      visible: false,
    });
  };
  const onSelectinBenchPlayer = (member, type) => {
    const sidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === member.jersey_no
    );
    if (sidx >= 0) {
      //const order = currentMatch[type].players[tidx].order;
      //currentMatch[type].players[tidx].is_starting_lineup = false;
      // currentMatch[type].players[tidx].order =
      //   currentMatch[type].players[sidx].order;
      currentMatch[type].players[sidx].is_starting_lineup = true;
      //currentMatch[type].players[sidx].order = order;
      setSelectedMember(null);
      setCurrentMatch({
        ...currentMatch,
      });
      apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
        team: currentMatch[type].team._id,
        player: currentMatch[type].players[sidx]._id,
        foulin: true,
      });
    }
    setPfModalInfo({
      visible: false,
    });
    setopenhomeplayergetin(false);

    setopenawayplayergetin(false);
  };
  const onSelectBenchPlayeronly = (type) => {
    const tidx = currentMatch[type]?.players?.findIndex(
      (ee) => ee.jersey_no === selectedMember.jersey_no
    );
    if (tidx >= 0) {
      //const order = currentMatch[type].players[tidx].order;
      currentMatch[type].players[tidx].is_starting_lineup = false;
      setSelectedMember(null);
      setCurrentMatch({
        ...currentMatch,
      });
      apiClient.put(ApiConfig.matches.updateMatch + `/${currentMatch._id}`, {
        team: currentMatch[type].team._id,
        swap: currentMatch[type].players[tidx]._id,
        foulout: true,
      });
    }
    setPfModalInfo({
      visible: false,
    });
  };

  // const onUpdate = (val, key) => {
  //   if (key === 'date') {
  //     loadMatches(val)
  //   }
  //   scoreboardDet[key] = val;
  //   setScoreboard({ ...scoreboardDet })
  // }

  // /
  const colorfinder = (value) => {
    let color = "green";
    switch (value) {
      case "Published":
        color = "green";
        break;
      case "Active":
      case "Available":
      case "Assigned":
        color = "blue";
        break;
      case "Started":
        color = "cyan";
        break;
      case "Paused":
        color = "orange";
        break;
      default:
        color = "green";
        break;
    }
    return color;
  };
  const MatchView = ({ values, date, status }) => {
    //const won = values?.teams?.[0]?.team === values?.won_by?.team ? 0 : 1;
    //let name ="",logo=""
    const home = teamsInfo?.[values?.home?.team];

    const away = teamsInfo?.[values?.away?.team];
    return (
      <>
        <div className="match-view">
          <Tag color="#12344d" style={{ width: "150px" }}>
            {getDateFormat({ date, format: "YYYY-MM-DD hh:mm a" })}
          </Tag>
          {/* <Tag color="#12344d">{formattedTimeZone(date)}</Tag> */}
          <TeamNameWithLogo {...home} style={{ width: "150px" }} />
          <div className="match_vs">vs</div>
          <TeamNameWithLogo {...away} />
        </div>
        <span
          style={{ position: "relative", float: "right", marginTop: "-35px" }}
        >
          <Tag
            color={colorfinder(status)}
            style={{ width: "100px", textAlign: "center" }}
          >
            {" "}
            {status === "Active" || status === "Assigned"
              ? "Available"
              : status}
          </Tag>
        </span>
      </>
    );
  };

  const checkScorerDetails = async () => {
    setLoading(true);
    const { data: scoreDet } = await apiClient.get(
      `${ApiConfig.matches.getScorermanager}/${match}`
    );
    scoreDet.away.hide = scoreDet?.jerseyviolation.away;
    scoreDet.home.hide = scoreDet?.jerseyviolation.home;
    setJerseyViolation({ ...scoreDet?.jerseyviolation });
    if (
      (currentMatch?.away?.score === (awaystatsscore+currentMatch?.away?.violation) || jerseyViolation?.away) &&
      (currentMatch?.home?.score === (homestatsscore+currentMatch?.home?.violation) || jerseyViolation?.home)
    ) {
      setenablepublish(true);
    } else {
      setenablepublish(false);
    }
    setCurrentMatch(scoreDet);
    setLoading(false);
    if (scoreDet?.home) {
      const idx = scoreDet.home.players.findIndex(
        (pp) => pp.personal_fouls >= 5 && pp.is_starting_lineup
      );
      if (idx >= 0) {
        setPfModalInfo({
          visible: true,
          player: scoreDet.home.players[idx],
          type: "home",
        });
        setSelectedMember(scoreDet.home.players[idx]);
        return;
      }
    }
    if (scoreDet?.away) {
      const idx = scoreDet.away.players.findIndex(
        (pp) => pp.personal_fouls >= 5 && pp.is_starting_lineup
      );
      if (idx >= 0) {
        setPfModalInfo({
          visible: true,
          player: scoreDet.away.players[idx],
          type: "away",
        });
        setSelectedMember(scoreDet.away.players[idx]);
      }
    }
  };
  const checkScorerDetailsUpdate = async () => {
    setLoading(true);
    const { data: scoreDet } = await apiClient.get(
      `${ApiConfig.matches.getScorermanager}/${match}`
    );
    scoreDet.away.hide = scoreDet?.jerseyviolation.away;
    scoreDet.home.hide = scoreDet?.jerseyviolation.home;
    setJerseyViolation({ ...scoreDet?.jerseyviolation });
    if (
      (currentMatch?.away?.score === (awaystatsscore+currentMatch?.away?.violation) ||
        scoreDet?.jerseyviolation?.away) &&
      (currentMatch?.home?.score === (homestatsscore+currentMatch?.home?.violation) ||
        scoreDet?.jerseyviolation?.home)
    ) {
      setenablepublish(true);
    } else {
      setenablepublish(false);
    }
    console.log(scoreDet, "scoreDetscoreDet", jerseyViolation);
    setCurrentMatch(scoreDet);
    setLoading(false);
    if (scoreDet?.home) {
      const idx = scoreDet.home.players.findIndex(
        (pp) => pp.personal_fouls >= 5 && pp.is_starting_lineup
      );
      if (idx >= 0) {
        setPfModalInfo({
          visible: true,
          player: scoreDet.home.players[idx],
          type: "home",
        });
        setSelectedMember(scoreDet.home.players[idx]);
        return;
      }
    }
    if (scoreDet?.away) {
      const idx = scoreDet.away.players.findIndex(
        (pp) => pp.personal_fouls >= 5 && pp.is_starting_lineup
      );
      if (idx >= 0) {
        setPfModalInfo({
          visible: true,
          player: scoreDet.away.players[idx],
          type: "away",
        });
        setSelectedMember(scoreDet.away.players[idx]);
      }
    }
    console.log(scoreDet?.publishstatus, "scoreDet?.publishstatus");
    if (scoreDet?.publishstatus === "Assigned") {
      onStartMatch(scoreDet);
    }
  };

  // const loadMatches = async (date = scoreboardDet.date) => {
  //   try {
  //     setLoading(true);
  //     const { data } = await apiClient.get(ApiConfig.matches.list + '?date=' + getDateFormat({ date, format: 'YYYY-MM-DD' }));
  //     setCourtList(data.courts || []);
  //     // setSeasons(data.seasons || []);
  //     setMatches(data.matches || []);
  //     await checkScorerDetails();
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }

  const getMatches = () => {
    const tmp = matches.filter((tt) => {
      // return tt.sports_event._id === scoreboardDet.season && tt.court._id === scoreboardDet.court;
      return tt.court._id === scoreboardDet.court;
    });
    return tmp;
  };

  const onProceed = async () => {
    const matchdata = matchesList?.find((item) => item?._id === match);
    console.log(matchdata, "currentMatch");
    let bool = true;
    if (matchdata?.publishstatus === "Published") {
      bool = await ConfirmationModal(
        `Are you sure want to update a ${matchdata?.publishstatus} Match`
      );
    }
    if (bool) {
      setLoading(true);
      await apiClient.put(ApiConfig.matches.updateScorerManager + `/${match}`);
      checkScorerDetailsUpdate();
    }
  };

  const onStartMatch = async (currentMatch) => {
    const home_players = currentMatch?.home?.players?.map((ee) => ({
      player: ee._id,
      jersey_no: ee.jersey_no,
      is_starting_lineup: true,
    }));
    const away_players = currentMatch?.away?.players?.map((ee) => ({
      player: ee._id,
      jersey_no: ee.jersey_no,
      is_starting_lineup: true,
    }));
    let valid = true;
    console.log(valid, "validvalid1");
    let violation = { ...jerseyViolation };
    if (home_players.length < 5 && !jerseyViolation?.home) {
      // notification.error({
      //   message: `${currentMatch?.home?.team?.name} has less than 5 players.`
      // })
      //valid = false
      violation = {
        ...violation,
        home: true,
        homemessage:
          "Insufficient Players: The team roster lacks the minimum requirement of 5 players.",
      };
      setJerseyViolation({
        ...violation,
        home: true,
        homemessage:
          "Insufficient Players: The team roster lacks the minimum requirement of 5 players.",
      });
      //onHideTeam('home')
    }
    //{home:false,away:false,homemessage:"",awaymessage:''}
    if (away_players.length < 5 && !jerseyViolation?.away) {
      // notification.error({
      //   message: `${currentMatch?.away?.team?.name} has less than 5 players.`
      // })
      //valid = false
      violation = {
        ...violation,
        away: true,
        awaymessage:
          "Insufficient Players: The team roster lacks the minimum requirement of 5 players.",
      };
      setJerseyViolation({
        ...violation,
        away: true,
        awaymessage:
          "Insufficient Players: The team roster lacks the minimum requirement of 5 players.",
      });
      //onHideTeam('away')
    }
    // if (home_players.length >= 5 && home_players.filter(e => e.is_starting_lineup).length !== 5 && !(jerseyViolation?.home)) {
    //   notification.error({
    //     message: `${currentMatch?.home?.team?.name} must have 5 starting lineup players.`
    //   })
    //   valid = false
    // }
    // if (away_players.length >= 5 && away_players.filter(e => e.is_starting_lineup).length !== 5 && !(jerseyViolation?.away)) {
    //   notification.error({
    //     message: `${currentMatch?.away?.team?.name} must have 5 starting lineup players.`
    //   })
    //   valid = false
    // }

    const arryhome = home_players?.map((item) => item?.jersey_no) ?? [];
    const duplicateElementshome = arryhome.filter(
      (item, index) => arryhome.indexOf(item) !== index
    );
    if (duplicateElementshome?.length && !jerseyViolation?.home) {
      // notification.error({
      //   message: `${currentMatch?.home?.team?.name} has less than 5 players.`
      // })
      //valid = false
      violation = {
        ...violation,
        home: true,
        homemessage: `Duplicate Jersey Number: Multiple players in the system are assigned the same jersey number #${duplicateElementshome?.[0]}`,
      };
      setJerseyViolation({
        ...violation,
        home: true,
        homemessage: `Duplicate Jersey Number: Multiple players in the system are assigned the same jersey number #${duplicateElementshome?.[0]}`,
      });
      //onHideTeam('home')
    }
    const arryaway = away_players?.map((item) => item?.jersey_no) ?? [];
    const duplicateElementsaway = arryaway.filter(
      (item, index) => arryaway.indexOf(item) !== index
    );
    if (duplicateElementsaway?.length && !jerseyViolation?.away) {
      // notification.error({
      //   message: `${currentMatch?.home?.team?.name} has less than 5 players.`
      // })
      //valid = false
      violation = {
        ...violation,
        away: true,
        awaymessage: `Duplicate Jersey Number: Multiple players in the system are assigned the same jersey number #${duplicateElementsaway?.[0]}`,
      };
      setJerseyViolation({
        ...violation,
        away: true,
        awaymessage: `Duplicate Jersey Number: Multiple players in the system are assigned the same jersey number #${duplicateElementsaway?.[0]}`,
      });
      //onHideTeam('home')
    }
    if (valid) {
      setLoading(true);
      await apiClient.post(
        ApiConfig.matches.startMatchManager + `/${currentMatch._id}`,
        { home_players, away_players, jerseyViolation: violation }
      );
      checkScorerDetails();
    }
  };

  const onCancelMatch = async () => {
    const ok = await ConfirmationModal(
      "Are you sure you want to leave this match?"
    );
    if (ok) {
      //setLoading(true);
      setCurrentMatch(null);
      setJerseyViolation({
        home: false,
        away: false,
        homemessage: "",
        awaymessage: "",
      });
      //await apiClient.put(ApiConfig.matches.updateDetailsManager + `/${currentMatch._id}`, { is_leave: true });
      //setLoading(false);
      //checkScorerDetails();
    }
  };

  const onSaveTimeout = async (type) => {
    const payload = {
      team: currentMatch[type]?.team?._id,
    };
    let isValid = false;
    if (currentMatch.is_halftime_taken) {
      if (!(currentMatch[type].timeout_after_halftime >= 2)) {
        payload.timeout_after_halftime = true;
        currentMatch[type].timeout_after_halftime = currentMatch[type]
          .timeout_after_halftime
          ? currentMatch[type].timeout_after_halftime + 1
          : 1;
        isValid = true;
      } else {
        notification.error({
          message: "No timeout left",
        });
      }
    } else {
      if (!(currentMatch[type].timeout_before_halftime >= 2)) {
        payload.timeout_before_halftime = true;
        currentMatch[type].timeout_before_halftime = currentMatch[type]
          .timeout_before_halftime
          ? currentMatch[type].timeout_before_halftime + 1
          : 1;
        isValid = true;
      } else {
        notification.error({
          message: "No timeout left",
        });
      }
    }

    if (isValid) {
      setCurrentMatch({ ...currentMatch });
      await apiClient.put(
        ApiConfig.matches.updateMatchManager + `/${currentMatch._id}`,
        payload
      );
    }
  };

  const onClickTimeout = () => {
    ConfirmationModal(
      "Which team you want to take a timeout?",
      null,
      currentMatch?.away?.team?.name,
      currentMatch?.home?.team?.name,
      () => {
        onSaveTimeout("away");
      },
      null,
      () => {
        onSaveTimeout("home");
      },
      "timeout_popup",
      currentMatch?.away?.changed_jersey_color,
      currentMatch?.home?.changed_jersey_color
    );
  };

  const onClickHalftimeOrComplete = async () => {
    const bool = await ConfirmationModal(
      "Are you sure you want to publish the Stats?"
    );
    if (bool) {
      const payload = {};

      currentMatch.status = "Completed";
      payload.is_completed = true;
      payload.restrict_publish = false;
      payload.publishstatus = "Published";
      setCurrentMatch(null);
      setJerseyViolation({
        home: false,
        away: false,
        homemessage: "",
        awaymessage: "",
      });

      await apiClient.put(
        ApiConfig.matches.updateMatchManager + `/${currentMatch._id}`,
        payload
      );
    }
  };

  const onClickLeave = async () => {
    const bool = await ConfirmationModal(
      "Are you sure you want to leave the Match?"
    );
    if (bool) {
      const payload = {};

      currentMatch.status = "Completed";
      currentMatch.publishstatus = "Paused";
      payload.publishstatus = "Paused";
      payload.scorekeeper = null;
      payload.is_completed = true;
      payload.restrict_publish = true;
      setCurrentMatch(null);
      setJerseyViolation({
        home: false,
        away: false,
        homemessage: "",
        awaymessage: "",
      });

      await apiClient.put(
        ApiConfig.matches.updateMatchManager + `/${currentMatch._id}`,
        payload
      );
    }
  };

  const onSwapTeam = () => {
    setTeamSwap(!teamSwap);
  };

  const onCheckPlayer = (type, selectedKeys, selectedRows) => {
    currentMatch[type].selectedPlayers = selectedKeys;
    setCurrentMatch({ ...currentMatch });
  };

  const onChangeJerseyNo = (value, type, key) => {
    if (type === "home") {
      const checkExistingJersey = currentMatch[type].players?.filter(
        (x) => x.jersey_no === value
      );
      if (checkExistingJersey?.length > 0) {
        notification.error({
          message: `Cannot enter same jersey number for ${currentMatch[type].players[key]?.player.first_name} ${currentMatch[type].players[key]?.player.last_name}.`,
        });
      } else {
        currentMatch[type].players[key].jersey_no = value;
        setCurrentMatch({ ...currentMatch });
      }
    }

    if (type === "away") {
      const checkExistingJersey = currentMatch[type].players?.filter(
        (x) => x.jersey_no === value
      );
      if (checkExistingJersey?.length > 0) {
        notification.error({
          message: `Cannot enter same jersey number for ${currentMatch[type].players[key]?.player.first_name} ${currentMatch[type].players[key]?.player.last_name}.`,
        });
      } else {
        currentMatch[type].players[key].jersey_no = value;
        setCurrentMatch({ ...currentMatch });
      }
    }
  };

  const onCheckRemPlayer = (type, selectedKeys) => {
    currentMatch[type].selectedRemPlayers = selectedKeys;
    setCurrentMatch({ ...currentMatch });
  };

  const onChangeRemJerseyNo = (value, type, key) => {
    currentMatch[type].remaining_players[key].jersey_no = value;
    setCurrentMatch({ ...currentMatch });
  };

  const onChangeStartingLineup = (value, type, key) => {
    const home_players = currentMatch?.home?.players
      ?.filter(
        (ee) => currentMatch?.home?.selectedPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
      }));
    const away_players = currentMatch?.away?.players
      ?.filter(
        (ee) => currentMatch?.away?.selectedPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
      }));
    const home_players_length = home_players?.filter(
      (x) => x?.is_starting_lineup === true
    )?.length;
    const away_players_length = away_players?.filter(
      (x) => x?.is_starting_lineup === true
    )?.length;
    if (value === true) {
      if (type === "home") {
        if (home_players_length > 4) {
          notification.error({
            message: `Cannot select more than 5 starting lineup players for ${currentMatch?.home?.team?.name}.`,
          });
          currentMatch[type].players[key].is_starting_lineup = false;
        } else {
          currentMatch[type].players[key].is_starting_lineup = value;
          setCurrentMatch({ ...currentMatch });
        }
      }
      if (type === "away") {
        if (away_players_length > 4) {
          notification.error({
            message: `Cannot select more than 5 starting lineup players for ${currentMatch?.away?.team?.name}.`,
          });
          currentMatch[type].players[key].is_starting_lineup = false;
        } else {
          currentMatch[type].players[key].is_starting_lineup = value;
          setCurrentMatch({ ...currentMatch });
        }
      }
    } else if (value === false) {
      currentMatch[type].players[key].is_starting_lineup = value;
    }
  };

  const onClickPage = (e) => {
    if ($(e.target).closest(".swap-player").length > 0) {
      return;
    }
    if (
      $(e.target).closest(
        ".scoreboard-left, .scoreboard-right, .score-block, .score-popup-content, .chng-pl-no, .pop-score, .reb_popup, .ant-drawer, .mobile-autocomplete .sketch-picker-comp"
      ).length > 0
    ) {
      return;
    }
    if (currentMatch?.home?.players) {
      currentMatch.home.team.openPicker = false;
      currentMatch.home.players.forEach((pp) => {
        pp.swap = false;
      });
    }
    if (currentMatch?.away?.players) {
      currentMatch.away.team.openPicker = false;
      currentMatch.away.players.forEach((pp) => {
        pp.swap = false;
      });
    }
    if (currentMatch?.home?.players || currentMatch?.away?.players) {
      setCurrentMatch({ ...currentMatch });
    }
  };

  const ScoreUpdate = ({ score = 0, type }) => {
    const [cScore, setcScore] = useState(score);
    const setScore = () => {
      currentMatch[type].score = cScore;
      currentMatch[type].scorePopVisible = false;
      setCurrentMatch({ ...currentMatch });
      onSaveMatchScores({ team: currentMatch[type]?.team?._id, score: cScore });
    };

    return (
      <div className="upd-score">
        <InputNumber
          maxLength={3}
          controls={false}
          onChange={(e) => {
            if (e <= 0) {
              e = 0;
            }
            setcScore(e);
          }}
          value={cScore}
        />
        <Button onClick={() => setScore()} icon={<CheckOutlined />} />
      </div>
    );
  };

  const onHideTeam = (type) => {
    currentMatch[type].hide = !currentMatch[type].hide;
    setCurrentMatch({ ...currentMatch });
  };

  const onAddMatchPlayer = (type, visible) => {
    setRemainingPlayerInfo({ type, visible });
  };

  const onSelectOwner = (value = "") => {
    const tmp = players.filter((e) => value.indexOf(e.email) >= 0)[0];
    form.setFieldsValue({
      ...form.getFieldsValue(),
      player: tmp._id,
    });
  };

  const geOptions = (options = []) => {
    return options.map((opt) => {
      return {
        value: `${getFullName(opt)}<${opt.email}>`,
        label: (
          <List.Item>
            <List.Item.Meta
              avatar={
                !opt.profile_pic ? (
                  <UserOutlined />
                ) : (
                  <img style={{ width: "30px" }} src={opt.profile_pic} alt="" />
                )
              }
              title={getFullName(opt)}
              description={opt.email}
            />
          </List.Item>
        ),
      };
    });
  };

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((nextValue, type) => handleSearch(nextValue), 500),
    [] // will be created only once initially
  );

  const handleSearch = async (value = "") => {
    if (value.length < 2) {
      setPlayers([...[]]);
      return;
    }
    try {
      setSearching(true);
      const { data } = await apiClient.get(
        `${ApiConfig.player.playerSearch}?q=${value}`
      );
      setPlayers([...data]);
      setSearching(false);
    } catch (error) {
      setSearching(false);
    }
  };

  const onAddPlayerTeam = async (values) => {
    try {
      console.log(values, playerAddInfo, "playerAddInfo");
      if (playerAddInfo?.type) {
        setAdding(true);
        const payload = {
          team: currentMatch[playerAddInfo?.type]?.team?._id,
          ...values,
        };
        await apiClient.post(
          ApiConfig.matches.addPlayerToTeam + `/${currentMatch._id}`,
          payload
        );
        setPlayerAddInfo({
          visible: false,
        });
        setAdding(false);
        setLoading(true);
        checkScorerDetails();
        form.setFieldsValue({
          player: undefined,
          jersey_no: undefined,
        });
      }
    } catch (error) {
      setAdding(false);
      setLoading(false);
    }
  };
  const onAddPlayerfromcurrentTeam = async (values, type) => {
    try {
      const info =
        type === "home"
          ? hometeamplayersinfo?.find(
              (item) => item?.player?._id === values?.player
            )
          : awayteamplayersinfo?.find(
              (item) => item?.player?._id === values?.player
            );
      if (values) {
        setAdding(true);
        const payload = {
          //team: currentMatch[playerAddInfo?.type]?.team?._id,
          ...info,
          jersey_no: values?.jersey_no,
          player: values?.player,
          team: currentMatch?.[type]?.team?._id,
        };
        await apiClient.post(
          ApiConfig.matches.addPlayerToMatch + `/${currentMatch._id}`,
          payload
        );
        setPlayerAddInfo({
          visible: false,
        });
        setAdding(false);
        setLoading(true);
        checkScorerDetails();
        form.setFieldsValue({
          player: undefined,
          jersey_no: undefined,
        });
      }
    } catch (error) {
      setAdding(false);
      setLoading(false);
    }
  };

  const onSubmitMatchPlayers = async () => {
    const type = remainingPlayerInfo.type;
    const players = currentMatch[type]?.remaining_players
      ?.filter(
        (ee) => currentMatch[type]?.selectedRemPlayers?.indexOf(ee._id) >= 0
      )
      .map((ee) => ({
        player: ee.player._id,
        jersey_no: ee.jersey_no,
        is_starting_lineup: ee.is_starting_lineup,
        order: ee.order,
      }));
    const payload = {};
    if (type && players.length > 0) {
      payload[`${type}_players`] = players;
      setRemainingPlayerInfo({ type: "", visible: false });
      setLoading(true);
      await apiClient.post(
        ApiConfig.matches.addPlayers + `/${currentMatch._id}`,
        payload
      );
      checkScorerDetails();
    }
  };

  // useEffect(() => {
  //   loadMatches();
  //   window.$ = $;
  //   // eslint-disable-next-line
  // }, [])
  //Various List of Filters
  const [totalleaguedata, setTotalleaguedata] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seasonList, setSeasonList] = useState([]);
  const [tiersList, setTiersList] = useState([]);
  const [matchesList, setMatchesList] = useState([]);

  //Various Selected Filter
  const [league, setLeague] = useState("Sunday League");
  const [tier, setTier] = useState("1");
  const [season, setSeason] = useState("");
  const [match, setMatch] = useState("");

  console.log(match, "matchmatch");
  //   const getleagues = () => {
  //     axios
  //       .get(`https://tcblapi.poindexters.ca/api/v1/settings/get-leagues?type=Regular`)
  //       .then((res) => {
  //         setLeaguesList(res?.data);
  //         setLeague(res?.data?.[res?.data?.length - 1]?.name ?? "");
  //         form.setFieldsValue({
  //           league: res?.data?.[res?.data?.length - 1]?.name ?? "",
  //         });
  //       });
  //   };
  //   const getseasonstandings = (name, seasonname) => {
  //     let url = `https://tcblapi.poindexters.ca/api/v1/settings/get-seasons?league=${
  //       name ?? "sunday league"
  //     }`;
  //     if (Boolean(seasonname)) {
  //       url += `&season=${seasonname}`;
  //     }
  //     axios
  //       .get(url)
  //       .then((res) => {
  //         setSeasonList(res?.data?.seasons ?? []);
  //         const tierslist = res?.data?.data?.[0]?.tiers?.map(
  //           (item) => item?.tier
  //         );
  //         setTiersList(tierslist ?? []);
  //         setTier(tierslist?.[0] ?? null);
  //         form.setFieldsValue({
  //           tier: tierslist?.[0],
  //           season: res?.data?.seasons?.[0]?.value,
  //         });
  //         setSeason(res?.data?.seasons?.[0]?.value);
  //       })
  //       .catch((err) => console.log(err, "error Occured"));
  //   };
  //   const getseasonscores = (name, seasonname) => {
  //     let url = `https://tcblapi.poindexters.ca/api/v1/settings/get-matches-mobile-with-tier?season=${
  //       season ?? seasonList?.[0]?.value
  //     }&league=${league ?? "sunday league"}&tier_type=Regular&tier=${tier}`;

  //     // axios.get(url).then((res) => {
  //     //   setSeasonList(res?.data?.seasons ?? []);
  //     //   const tierslist = res?.data?.data?.[0]?.tiers?.map((item) => item?.tier)
  //     //   setTiersList(tierslist??[]);
  //     //   setTier(tierslist?.[0]??null)
  //     //   settableData(res?.data?.data?.[0]?.tiers);
  //     //   setTotalleaguedata(res?.data?.data)
  //     //   form.setFieldsValue({tier:tierslist?.[0],season:res?.data?.seasons?.[0]?.value})
  //     //   setSeason(res?.data?.seasons?.[0]?.value)
  //     // }).catch((err)=>console.log(err,'error Occured'));
  //     axios
  //       .get(url)
  //       .then((res) => {
  //         setMatchesList([...res?.data]);
  //       })
  //       .catch((err) => console.log(err, "error Occured"));
  //   };
  const { Option } = Select;
  const [teamsInfo, setteamsInfo] = useState({});
  const [leaguesMapping, setLeaguesMapping] = useState({});
  const loadTeamsInfo = async () => {
    const response = await apiClient.get(`/settings/get-teams-logo`);
    const teamInfoTemp = {};
    const v1 = response?.data?.teamsInfo?.forEach((item) => {
      teamInfoTemp[item?._id] = item;
    });
    setteamsInfo({ ...teamInfoTemp });
  };
  useEffect(() => {
    loadTeamsInfo();
  }, []);
  const [filtersData, setFiltersData] = useState([]);
  const getFiltersApi = async () => {
    const response = await apiClient.get(`${ApiConfig.common.getFilters}`);
    const leaguesResponse = await apiClient.get(
      `${ApiConfig.common.leaguesList}`
    );
    const leaguesMappingTemp = {};
    const v1 = leaguesResponse?.data?.forEach((item) => {
      leaguesMappingTemp[item?._id] = item?.name;
    });
    setLeaguesMapping({ ...leaguesMappingTemp });
    const seasonListTemp = response?.data?.filtersInfo?.map((item) => {
      return { value: item?._id, label: item?.name };
    });
    const leagueListTemp = response?.data?.filtersInfo?.[0]?.leagues?.map(
      (item) => {
        return { value: item?.league, name: leaguesMappingTemp[item?.league] };
      }
    );
    const tierListTemp =
      response?.data?.filtersInfo?.[0]?.leagues?.[0]?.tiers?.map(
        (item) => item?.tier
      );
    const matchesListTemp = await apiClient.post(
      `${ApiConfig.common.getMatchesinList}`,
      {
        matchesList:
          response?.data?.filtersInfo?.[0]?.leagues?.[0]?.tiers?.[0]?.matches ??
          [],manager:true
      }
    );
    setFiltersData([...response?.data?.filtersInfo]);
    setSeasonList([...seasonListTemp]);
    setLeaguesList([...leagueListTemp]);
    setTiersList([...tierListTemp]);
    setMatchesList([
      ...matchesListTemp?.data?.matchesInfo?.filter(
        (item) => (item?.status === "Completed" && !(!item?.videoBoard?.startTime && item?.jerseyviolation.home && item?.jerseyviolation.away))
      ),
    ]);
    setMatch(
      [
        ...matchesListTemp?.data?.matchesInfo?.filter(
          (item) => item?.status === "Completed"
        ),
      ]?.[0]?._id
    );
    setSeason(seasonListTemp?.[0]);
    //setLeague(leagueListTemp?.[0])
    setTier(tierListTemp?.[0]);
  };
  const [datas,setdatas]=useState([])
  const getnewmanagermatches = async()=>{
    const v1 = await apiClient.post(`${ApiConfig.matches.getnewmanagermatches}`, {
    });
    setdatas([...v1?.data?.matchesInfo])
  }
  useEffect(() => {
    getFiltersApi();
    getnewmanagermatches();
  }, []);
  const resetTeamStats = async (type) => {
    const ok = await ConfirmationModal(
      "Are you sure you want to reset the stats for the team"
    );
    if (!ok) return;
    const matchid = currentMatch?._id;
    const teamid = currentMatch?.[type]?._id;
    const stats = {
      pts: 0,
      fgm: 0,
      fga: 0,
      ftm: 0,
      fta: 0,
      "2ptm": 0,
      "2pta": 0,
      "3ptm": 0,
      "3pta": 0,
      oreb: 0,
      dreb: 0,
      reb: 0,
      ast: 0,
      stl: 0,
      blk: 0,
      to: 0,
      ef: 0,
      dnp: false,
    };
    console.log(teamid, matchid, "teamid,matchid");
    const v2 = currentMatch[type].players.map((player) => {
      return { ...player, stats: { ...stats } };
    });
    // for(let i=0;i<v2.length;i++){
    //   v2[i].stats=stats
    // }
    currentMatch[type].players = [...v2];
    setCurrentMatch({ ...currentMatch });

    const v1 = await apiClient.post(`${ApiConfig.matches.resetStats}`, {
      id: matchid,
      type,
    });
    console.log(v1.data.data, "v11");
    if (v1.status === 200) {
      message.success("Stats has been Reset");
      //setCurrentMatch({...v1.data.data})
    }
  };
  console.log(matchesList, "matchesList");
  const tierChange = async () => {
    let seasonindex = 0;
    let leagueindex = 0;
    let tierindex = 0;
    const v1 = filtersData?.forEach((item, index) => {
      if (item?._id === season) {
        seasonindex = index;
      }
    });
    const v2 = filtersData?.[seasonindex]?.leagues?.forEach((item, indexx) => {
      if (leaguesMapping?.[item?.league] === league) {
        leagueindex = indexx;
      }
    });
    const v3 = filtersData?.[seasonindex]?.leagues?.[
      leagueindex
    ]?.tiers?.forEach((item, indexy) => {
      if (item?.tier === tier) {
        tierindex = indexy;
      }
    });
    const matchesListTemp = await apiClient.post(
      `${ApiConfig.common.getMatchesinList}`,
      {
        matchesList:
          filtersData?.[seasonindex]?.leagues?.[leagueindex]?.tiers?.[tierindex]
            ?.matches ?? [],manager:true
      }
    );
    setMatchesList([
      ...matchesListTemp?.data?.matchesInfo?.filter(
        (item) => (item?.status === "Completed" && !(!item?.videoBoard?.startTime && item?.jerseyviolation.home && item?.jerseyviolation.away))
      ),
    ]);
    setMatch(
      [
        ...matchesListTemp?.data?.matchesInfo?.filter(
          (item) => item?.status === "Completed"
        ),
      ]?.[0]?._id
    );
  };

  const leagueChange = async () => {
    let seasonindex = 0;
    let leagueindex = 0;
    const v1 = filtersData?.forEach((item, index) => {
      if (item?._id === season) {
        seasonindex = index;
      }
    });
    const v2 = filtersData?.[seasonindex]?.leagues?.forEach((item, indexx) => {
      if (leaguesMapping?.[item?.league] === league) {
        leagueindex = indexx;
      }
    });
    const tierListTemp = filtersData?.[seasonindex]?.leagues?.[
      leagueindex
    ]?.tiers?.map((item) => item?.tier);
    setTiersList([...tierListTemp]);
    setTier(tierListTemp?.[0]);
    const matchesListTemp = await apiClient.post(
      `${ApiConfig.common.getMatchesinList}`,
      {
        matchesList:
          filtersData?.[seasonindex]?.leagues?.[leagueindex]?.tiers?.[0]
            ?.matches ?? [],manager:true
      }
    );
    setMatchesList([
      ...matchesListTemp?.data?.matchesInfo?.filter(
        (item) => (item?.status === "Completed" && !(!item?.videoBoard?.startTime && item?.jerseyviolation.home && item?.jerseyviolation.away))
      ),
    ]);
    setMatch(
      [
        ...matchesListTemp?.data?.matchesInfo?.filter(
          (item) => item?.status === "Completed"
        ),
      ]?.[0]?._id
    );
  };

  const seasonChange = async () => {
    let seasonindex = 0;
    const v1 = filtersData?.forEach((item, index) => {
      if (item?._id === season) {
        seasonindex = index;
      }
    });
    const leagueListTemp = filtersData?.[seasonindex]?.leagues?.map((item) => {
      return { value: item?.league, name: leaguesMapping[item?.league] };
    });
    const tierListTemp = filtersData?.[seasonindex]?.leagues?.[0]?.tiers?.map(
      (item) => item?.tier
    );
    setLeaguesList([...leagueListTemp]);
    setTiersList([...tierListTemp]);
    setLeague("Sunday League");
    setTier(tierListTemp?.[0]);
    const matchesListTemp = await apiClient.post(
      `${ApiConfig.common.getMatchesinList}`,
      {
        matchesList:
          filtersData?.[seasonindex]?.leagues?.[0]?.tiers?.[0]?.matches ?? [],manager:true
      }
    );
    setMatchesList([
      ...matchesListTemp?.data?.matchesInfo?.filter(
        (item) => (item?.status === "Completed"  && !(!item?.videoBoard?.startTime && item?.jerseyviolation.home && item?.jerseyviolation.away))
      ),
    ]);
    setMatch(
      [
        ...matchesListTemp?.data?.matchesInfo?.filter(
          (item) => item?.status === "Completed"
        ),
      ]?.[0]?._id
    );
  };
  useEffect(() => {
    if (season && league && tier) {
      tierChange();
    }
    // eslint-disable-next-line
  }, [tier]);
  useEffect(() => {
    if (season && league) {
      leagueChange();
    }
    // eslint-disable-next-line
  }, [league]);
  useEffect(() => {
    if (season) {
      seasonChange();
    }
    // eslint-disable-next-line
  }, [season]);
  //{() => setJerseyViolation({...jerseyViolation,home:!(jerseyViolation?.home)})}
  const handlejerseyviolation = async (homeoraway, inmatch) => {
    if (!currentMatch[homeoraway].hide) {
      setopenjerseyviolation(true);
    } else {
      onHideTeam(homeoraway);
      if (homeoraway === "home") {
        if (inmatch === "inmatch") {
          setJerseyViolation({
            ...jerseyViolation,
            home: currentMatch[homeoraway].hide,
            homemessage: "",
          });
          await apiClient.post(
            ApiConfig.matches.jerseyViolationManager + `/${currentMatch._id}`,
            {
              jerseyViolation: {
                ...jerseyViolation,
                home: currentMatch[homeoraway].hide,
                homemessage: "",
              },
            }
          );
        } else {
          setJerseyViolation({
            ...jerseyViolation,
            home: !jerseyViolation?.home,
          });
        }
      } else if (homeoraway === "away") {
        if (inmatch === "inmatch") {
          setJerseyViolation({
            ...jerseyViolation,
            away: currentMatch[homeoraway].hide,
            awaymessage: "",
          });
          await apiClient.post(
            ApiConfig.matches.jerseyViolationManager + `/${currentMatch._id}`,
            {
              jerseyViolation: {
                ...jerseyViolation,
                away: currentMatch[homeoraway].hide,
                awaymessage: "",
              },
            }
          );
        } else {
          setJerseyViolation({
            ...jerseyViolation,
            away: !jerseyViolation?.away,
          });
        }
      }
    }
    sethomeoraway(homeoraway);
    setinmatch(inmatch);
  };
  const updatejerseyviolation = async (e) => {
    let message = "";
    const matchforfeited = {};
    switch (e?.type) {
      case "Insufficient Players":
        message =
          "Insufficient Players: The team roster lacks the minimum requirement of 5 players in the system.";
        break;
      case "Duplicate Jersey # in the system":
        message = `Duplicate Jersey Number: Multiple players in the system are assigned the same jersey number #${e?.jersey}`;
        break;
      case "Duplicate Jersey # in the game":
        message = `Duplicate Jersey Number: Multiple players in the game are wearing the same jersey number #${e?.jersey}`;
        break;
      case "Roster Incompleteness":
        message = `Roster Incompleteness: Player #${e?.jersey} is missing from the roster.`;
        break;
      case "Invalid Jersey":
        message = `Roster Incompleteness: A Player wearing Jersey without Jersey Number.`;
        break;
      case "The match has been forfeited":
        message = `The match has been forfeited.`;
        matchforfeited.home = !currentMatch["home"].hide;
        matchforfeited.homemessage = message;
        matchforfeited.away = !currentMatch["away"].hide;
        matchforfeited.awaymessage = message;
        break;
      case "missing video":
        message = `Video Issue: The video could not be fully viewed`;
        matchforfeited.home = !currentMatch["home"].hide;
        matchforfeited.homemessage = message;
        matchforfeited.away = !currentMatch["away"].hide;
        matchforfeited.awaymessage = message;
        break;
      case "No Stats":
        message = `Stats will not be available for this game`;
        break;
        case "Custom":
          message = e?.message;
          break;
      default:
        message =
          "Insufficient Players: The team roster lacks the minimum requirement of 5 players.";
    }
    if (homeoraway === "home") {
      if (inmatch === "inmatch") {
        setJerseyViolation({
          ...jerseyViolation,
          home: !currentMatch[homeoraway].hide,
          homemessage: message,
          homevideourl: e?.videourl,
          homeincidenttime: e?.incidenttime,
          ...matchforfeited,
        });
        await apiClient.post(
          ApiConfig.matches.jerseyViolationManager + `/${currentMatch._id}`,
          {
            jerseyViolation: {
              ...jerseyViolation,
              home: !currentMatch[homeoraway].hide,
              homemessage: message,
              homevideourl: e?.videourl,
              homeincidenttime: e?.incidenttime,
              ...matchforfeited,
            },
          }
        );
      } else {
        setJerseyViolation({
          ...jerseyViolation,
          home: !jerseyViolation?.home,
        });
      }
    } else if (homeoraway === "away") {
      setJerseyViolation({
        ...jerseyViolation,
        away: !currentMatch[homeoraway].hide,
        awaymessage: message,
        awayvideourl: e?.videourl,
        awayincidenttime: e?.incidenttime,
        ...matchforfeited,
      });
      if (inmatch === "inmatch") {
        await apiClient.post(
          ApiConfig.matches.jerseyViolationManager + `/${currentMatch._id}`,
          {
            jerseyViolation: {
              ...jerseyViolation,
              away: !currentMatch[homeoraway].hide,
              awaymessage: message,
              awayvideourl: e?.videourl,
              awayincidenttime: e?.incidenttime,
              ...matchforfeited,
            },
          }
        );
      } else {
        setJerseyViolation({
          ...jerseyViolation,
          away: !jerseyViolation?.away,
        });
      }
    }
    if(e?.type==="missing video" || e?.type==="The match has been forfeited") {
      onHideTeam("home");
      onHideTeam("away");
    } else {
      onHideTeam(homeoraway);
    }
    setopenjerseyviolation(false);
  };

  const [editmode,seteditmode]=useState(false)
  
  const [comments,setcomments]=useState([])
  const [comment,setcomment]=useState("")
  const [url,seturl]=useState("")
  const [editurl,setediturl]=useState("")
  const [matchid,setmatchid]=useState("")
  const getcomment = async(id)=>{
    setmatchid(id)
    const data = await apiClient.post(ApiConfig.matches.getcomment,{matchid:id})
    console.log(data?.data?.[0]?.comments,'data15')
    setcomments([...data?.data?.[0]?.comments??[]])
    seturl(data?.data?.[0]?.videoUrl??"")
  }
  const sendcomment = async()=>{
    const data = await apiClient.post(ApiConfig.matches.updatecomment,{comment:comment,matchid:matchid})
    setcomment("")
    getcomment(currentMatch?._id)
  }
  const sendurl = async()=>{
    const data = await apiClient.post(ApiConfig.matches.updatecommenturl,{videoUrl:url,matchid:matchid})
    setcomment("")
    setediturl(false)
    getcomment(currentMatch?._id)
  }
  
  const data = store.get('user.data');
  console.log(data,'data35')

  return (
    <div onClick={onClickPage} className="scorekeeper-page">
      <SectionTitle
        title="Manage the scores of the teams in a real-time"
        subtitle="Video Stats"
        right={Boolean(currentMatch?.publishstatus) && <MdChat size={30} onClick={()=>{getcomment(currentMatch?._id)}}/>}
      />
      <Modal footer={null} visible={Boolean(matchid)} onCancel={()=>{setmatchid("");seturl("");setcomments([])}}>
        
            <div style={{marginTop:"20px",minHeight:'300px',marginBottom:"5px",border:"1px solid #d9d9d9", borderRadius:'2px'}}>
            <Input placeholder="Enter video URL" disabled={!editurl} value={url} onChange={(e)=>seturl(e.target.value)} suffix={editurl?<CheckCircleOutlined style={{color:'green'}} onClick={sendurl}/>:<EditOutlined onClick={()=>setediturl(true)}/>}/>
              {comments?.map(item=><div style={{margin:"10px 5px 10px 5px"}}>
                {console.log(data,'data12',item)}
                <div style={{fontWeight:'700',fontSize:'12px',textAlign:(data?._id===item?.author)?"right":"left"}}>{item?.first_name} {item?.last_name}</div>
                <div style={{textAlign:(data?._id===item?.author)?"right":"left"}}>{item?.comment}</div>
                </div>)}
            </div>
            <Input placeholder="Enter your comments" value={comment} onChange={(e)=>setcomment(e.target.value)} suffix={<SendOutlined onClick={sendcomment}/>}/>
          </Modal>
      {loading && <PageLoader className="score-loader1" />}
      
      {!loading && !currentMatch && (
        <>
        {/* <Switch checkedChildren="Edit" unCheckedChildren="New" onChange={seteditmode} style={{float:'right',marginTop:'-85px'}}/> */}
        {editmode?<div className="form-block">
          <div className="form-items">
            <div className="form-item">
              <div className="form-label">Season</div>
              <Select
                placeholder="Select Season"
                onChange={setSeason}
                value={season}
              >
                {seasonList?.map((state) => (
                  <Option key={state?.value} value={state?.value}>
                    {state?.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="form-item">
              <div className="form-label">League</div>
              <Select
                placeholder="Select League"
                onChange={setLeague}
                value={league}
              >
                {leaguesList?.map((state) => (
                  <Option key={state?.name} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="form-item">
              <div className="form-label">Tier</div>
              <Select
                placeholder="Tier"
                onChange={setTier}
                value={tier}
                disabled={!tiersList?.length}
              >
                {tiersList?.map((state) => (
                  <Option key={state} value={state}>
                    {Boolean(state) ? state : "No Tier"}
                  </Option>
                ))}
              </Select>
            </div>
            {/* <div className='form-item'>
            <div className='form-label'>Date</div>
            <DatePicker allowClear={false} disabled={!scoreboardDet.date} format="ddd, DD MMM YYYY" onChange={(e) => onUpdate(e, 'date')} value={scoreboardDet.date} />
          </div> */}
            <div className="form-item">
              <div className="form-label">Matches</div>
              <Select
                placeholder="Tier"
                onChange={setMatch}
                value={match}
                disabled={!matchesList?.length}
              >
                {matchesList
                  ?.sort((a, b) => {
                    return moment(b?.date).format("YYYY-MM-DD") ===
                      moment(a?.date).format("YYYY-MM-DD")
                      ? moment(b?.date).format("ahh:mm") >
                        moment(a?.date).format("ahh:mm")
                        ? -1
                        : 1
                      : moment(b?.date).format("YYYY-MM-DD") >
                        moment(a?.date).format("YYYY-MM-DD")
                      ? 1
                      : -1;
                  })
                  ?.map((state) => (
                    <Option key={state?._id} value={state?._id}>
                      <MatchView
                        date={state.date}
                        values={state}
                        status={state?.publishstatus}
                      />
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="form-action">
              <Button
                onClick={() => onProceed()}
                disabled={!match}
                type="primary"
              >
                Start Game
              </Button>
            </div>
          </div>
        </div>:<div className="form-block">
          
        <div className="form-items">
          <div className="form-item">
              <div className="form-label">Matches</div>
              <Select
                placeholder="Tier"
                onChange={setMatch}
                value={match}
                disabled={!datas?.length}
              >
                {datas
                  ?.sort((a, b) => {
                    return moment(b?.date).format("YYYY-MM-DD") ===
                      moment(a?.date).format("YYYY-MM-DD")
                      ? moment(b?.date).format("ahh:mm") >
                        moment(a?.date).format("ahh:mm")
                        ? -1
                        : 1
                      : moment(b?.date).format("YYYY-MM-DD") >
                        moment(a?.date).format("YYYY-MM-DD")
                      ? 1
                      : -1;
                  })
                  ?.map((state) => (
                    <Option key={state?._id} value={state?._id}>
                      <MatchView
                        date={state.date}
                        values={state}
                        status={state?.publishstatus}
                      />
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="form-action">
              <Button
                onClick={() => onProceed()}
                disabled={!match}
                type="primary"
              >
                Start Game
              </Button>
            </div></div></div>}</>
      )}

      {!loading && currentMatch?.publishstatus === "Assigned" && (
        <>
          <div className="select-players">
            <div className="home-players">
              <div
                className="h-p-title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span
                  onClick={() => handlejerseyviolation("home", "outmatch")}
                  className="hide-eye"
                  style={{ marginRight: "10px" }}
                >
                  {!jerseyViolation?.home ? (
                    <Tooltip title="Mark Violation">
                      <AiFillEyeInvisible size={30} />{" "}
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Violation">
                      {" "}
                      <AiFillEye size={30} />
                    </Tooltip>
                  )}
                </span>
                {currentMatch?.home?.team?.name}
                <Button
                  onClick={() =>
                    setPlayerAddInfo({ visible: true, type: "home" })
                  }
                  type="primary"
                  className="ml-2"
                  icon={<UsergroupAddOutlined />}
                >
                  Add Player
                </Button>
              </div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) =>
                    onCheckPlayer("home", selectedRowKeys, selectedRows),
                  //onClick: (selectedRowKeys, selectedRows) => console.log(selectedRowKeys, selectedRows,'selectedRowKeys, selectedRows')
                }}
                columns={[
                  {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    render: (text, record) => (
                      <div className="pl-name">
                        {" "}
                        {record.is_captain && (
                          <Tag color="#12344d">Manager</Tag>
                        )}{" "}
                        {getFullName(record?.player)}{" "}
                      </div>
                    ),
                  },
                  {
                    title: "Jersey No.",
                    key: "jersey_no",
                    width: "200px",
                    dataIndex: "jersey_no",
                    render: (text, record, key) => (
                      <Input
                        onChange={(e) =>
                          onChangeJerseyNo(e.target.value, "home", key)
                        }
                        className="jersey_edit"
                        key={record._id}
                        value={record.jersey_no}
                      />
                    ),
                  },
                  {
                    title: "Starting Lineup",
                    key: "lineup",
                    width: "150px",
                    dataIndex: "lineup",
                    render: (text, record, key) => (
                      <Checkbox
                        onChange={(e) =>
                          onChangeStartingLineup(e.target.checked, "home", key)
                        }
                        disabled={
                          (currentMatch?.home?.selectedPlayers || []).indexOf(
                            record._id
                          ) === -1
                        }
                        className="c-b-t"
                        key={record._id}
                        checked={record.is_starting_lineup}
                      />
                    ),
                  },
                ]}
                dataSource={currentMatch?.home?.players?.map((ee) => ({
                  ...ee,
                  key: ee._id,
                }))}
                pagination={false}
              />
            </div>
            <div className="away-players">
              <div
                className="h-p-title"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <span
                  onClick={() => handlejerseyviolation("away", "outmatch")}
                  className="hide-eye"
                  style={{ marginRight: "10px" }}
                >
                  {!jerseyViolation?.away ? (
                    <Tooltip title="Mark Violation">
                      <AiFillEyeInvisible size={30} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Mark Violation">
                      <AiFillEye size={30} />
                    </Tooltip>
                  )}
                </span>
                {currentMatch?.away?.team?.name}
                <Button
                  onClick={() =>
                    setPlayerAddInfo({ visible: true, type: "away" })
                  }
                  type="primary"
                  className="ml-2"
                  icon={<UsergroupAddOutlined />}
                >
                  Add Player
                </Button>
              </div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) =>
                    onCheckPlayer("away", selectedRowKeys, selectedRows),
                }}
                columns={[
                  {
                    title: "Name",
                    key: "name",
                    dataIndex: "name",
                    render: (text, record) => (
                      <div className="pl-name">
                        {" "}
                        {record.is_captain && (
                          <Tag color="#12344d">Manager</Tag>
                        )}{" "}
                        {getFullName(record?.player)}{" "}
                      </div>
                    ),
                  },
                  {
                    title: "Jersey No.",
                    key: "jersey_no",
                    width: "200px",
                    dataIndex: "jersey_no",
                    render: (text, record, key) => (
                      <Input
                        onChange={(e) =>
                          onChangeJerseyNo(e.target.value, "away", key)
                        }
                        className="jersey_edit"
                        key={record._id}
                        value={record.jersey_no}
                      />
                    ),
                  },
                  {
                    title: "Starting Lineup",
                    key: "lineup",
                    width: "150px",
                    dataIndex: "lineup",
                    render: (text, record, key) => (
                      <Checkbox
                        onChange={(e) =>
                          onChangeStartingLineup(e.target.checked, "away", key)
                        }
                        disabled={
                          (currentMatch?.away?.selectedPlayers || []).indexOf(
                            record._id
                          ) === -1
                        }
                        className="c-b-t"
                        key={record._id}
                        checked={record.is_starting_lineup}
                      />
                    ),
                  },
                ]}
                dataSource={currentMatch?.away?.players.map((ee) => ({
                  ...ee,
                  key: ee._id,
                }))}
                pagination={false}
              />
            </div>
          </div>
          <div className="p-form-action">
            <Button onClick={() => onCancelMatch()} type="primary">
              Exit
            </Button>
            <Button
              className="ml-2"
              onClick={() => onStartMatch()}
              type="primary"
            >
              Enter Stats
            </Button>
          </div>
        </>
      )}
      {!loading &&
        (currentMatch?.publishstatus === "Started" ||
          currentMatch?.publishstatus === "Paused" ||
          currentMatch?.publishstatus === "Published") && (
          <div className="scoreboard">
            <div className={`scoreboard-top ${teamSwap ? " swap-team" : ""}`}>
              <div
                onClick={() => onOpenPicker("home")}
                style={{
                  backgroundColor:
                    currentMatch?.home?.changed_jersey_color || "#ddd",
                }}
                className="scoreboard-left"
              >
                <div className="team-name">
                  {currentMatch?.home?.team?.name}
                </div>
                <div className="team-logo">
                  <img
                    src={
                      (teamSwap
                        ? currentMatch?.away?.team?.logo
                        : currentMatch?.home?.team?.logo) || "/logos/logo.png"
                    }
                    alt=""
                  />
                </div>
                {currentMatch?.home?.team?.openPicker && (
                  <div className="sketch-picker-comp">
                    <SketchPicker
                      onChangeComplete={(e) => onChangeColor(e, "home")}
                      color={
                        teamSwap
                          ? currentMatch?.away?.changed_jersey_color
                          : currentMatch?.home?.changed_jersey_color
                      }
                    />
                  </div>
                )}
              </div>
              <div className="scoreboard-center">
                <div className="scores-left">
                  <div className="team-name">
                    {teamSwap
                      ? currentMatch?.away?.team?.name
                      : currentMatch?.home?.team?.name}
                  </div>
                  <div className="scores">
                    <Popover
                      overlayClassName="pop-score"
                      title={
                        teamSwap
                          ? currentMatch?.away?.team?.name
                          : currentMatch?.home?.team?.name
                      }
                      trigger="click"
                      visible={
                        currentMatch?.home?.hide === true &&
                        currentMatch?.home?.scorePopVisible
                      }
                      onVisibleChange={(e) => {
                        if (currentMatch?.home?.hide === true) {
                          currentMatch.home.scorePopVisible = e;
                        } else {
                          currentMatch.home.scorePopVisible = false;
                        }
                        setCurrentMatch(currentMatch);
                      }}
                      content={
                        <ScoreUpdate
                          score={
                            teamSwap
                              ? currentMatch?.away?.score
                              : currentMatch?.home?.score
                          }
                          type="home"
                        />
                      }
                    >
                      <span>
                        {(teamSwap
                          ? currentMatch?.away?.score
                          : currentMatch?.home?.score) || 0}
                        <Tooltip title="Half Time Score">
                          <Tag
                            color="yellow"
                            style={{
                              width: "20px",
                              height: "20px",
                              padding: "1px",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                          >
                            <span style={{ fontSize: "10px" }}>
                              {teamSwap
                                ? currentMatch?.away?.halfscore
                                : currentMatch?.home?.halfscore}
                            </span>
                          </Tag>
                        </Tooltip>
                      </span>
                    </Popover>
                    <Tooltip title={`Stats Score(${teamSwap?currentMatch?.away?.violation:currentMatch?.home?.violation})`}>
                      <Tag
                        color="cyan"
                        style={{
                          marginLeft: "15px",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {teamSwap ? (awaystatsscore+currentMatch?.away?.violation) : (homestatsscore+currentMatch?.home?.violation)}
                        </span>
                      </Tag>
                    </Tooltip>
                    {/* {Boolean(currentMatch?.home?.halfscore) && (
                      <Tooltip title="Half Time Score">
                        <Tag
                          color="yellow"
                          style={{
                            transform: "translate(-37px, -30px)",
                            width: "30px",
                            textAlign: "center",
                          }}
                        >
                          <span>{currentMatch?.home?.halfscore}</span>
                        </Tag>
                      </Tooltip>
                    )} */}
                    <Tooltip title="Team Foul">
                      <Tag
                        color="cyan"
                        style={{
                          marginLeft: "15px",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {teamSwap
                            ? currentMatch?.away?.personal_fouls
                            : currentMatch?.home?.personal_fouls}
                        </span>
                      </Tag>
                    </Tooltip>
                  </div>

                  <div className="timeout-list">
                    {!currentMatch.is_halftime_taken && (
                      <>
                        <span
                          className={`timeout-list-item mr-1${
                            currentMatch?.home?.timeout_before_halftime >= 1
                              ? ""
                              : " selected"
                          }`}
                        />
                        <span
                          className={`timeout-list-item${
                            currentMatch?.home?.timeout_before_halftime >= 2
                              ? ""
                              : " selected"
                          }`}
                        />
                      </>
                    )}
                    {currentMatch.is_halftime_taken && (
                      <>
                        <span
                          className={`timeout-list-item mr-1${
                            currentMatch?.home?.timeout_after_halftime >= 1
                              ? ""
                              : " selected"
                          }`}
                        />
                        <span
                          className={`timeout-list-item${
                            currentMatch?.home?.timeout_after_halftime >= 2
                              ? ""
                              : " selected"
                          }`}
                        />
                        scores
                      </>
                    )}
                  </div>
                </div>
                <div className="timings">
                  <div className="m-swap" onClick={() => onSwapTeam()}>
                    <FaExchangeAlt size={20} />
                  </div>
                  <div className="m-time">10:00</div>
                  <div className="m-list">
                    <Button
                      onClick={() => onClickLeave()}
                      className="m-list-item ml-3"
                    >
                      Leave
                    </Button>
                    <Button
                      disabled={!enablepublish}
                      onClick={() => onClickHalftimeOrComplete()}
                      className="m-list-item ml-3"
                    >
                      Publish
                    </Button>
                    <br />
                  </div>
                  
                </div>
                <div className="scores-right">
                  <div className="team-name">
                    {teamSwap
                      ? currentMatch?.home?.team?.name
                      : currentMatch?.away?.team?.name}
                  </div>
                  <div className="scores">
                    {/* {Boolean(currentMatch?.away?.halfscore) && (
                      <Tooltip title="Half Time Score">
                        <Tag
                          color="yellow"
                          style={{
                            transform: "translate(37px, -30px)",
                            width: "30px",
                            textAlign: "center",
                          }}
                        >
                          <span>{currentMatch?.away?.halfscore}</span>
                        </Tag>
                      </Tooltip>
                    )} */}
                    <Tooltip title="Team Foul">
                      <Tag
                        color="cyan"
                        style={{
                          marginRight: "15px",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {teamSwap
                            ? currentMatch?.home?.personal_fouls
                            : currentMatch?.away?.personal_fouls}
                        </span>
                      </Tag>
                    </Tooltip>
                    <Tooltip title={`Stats Score(${teamSwap?currentMatch?.home?.violation:currentMatch?.away?.violation})`}>
                      <Tag
                        color="cyan"
                        style={{
                          marginRight: "15px",
                          width: "30px",
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {teamSwap ? (homestatsscore+currentMatch?.home?.violation) : (awaystatsscore+currentMatch?.away?.violation)}
                        </span>
                      </Tag>
                    </Tooltip>
                    <Popover
                      overlayClassName="pop-score"
                      title={
                        teamSwap
                          ? currentMatch?.team?.home?.name
                          : currentMatch?.away?.team?.name
                      }
                      trigger="click"
                      visible={
                        currentMatch?.away?.hide === true &&
                        currentMatch?.away?.scorePopVisible
                      }
                      onVisibleChange={(e) => {
                        if (currentMatch?.away?.hide === true) {
                          currentMatch.away.scorePopVisible = e;
                        } else {
                          currentMatch.away.scorePopVisible = false;
                        }
                        setCurrentMatch(currentMatch);
                      }}
                      content={
                        <ScoreUpdate
                          score={
                            teamSwap
                              ? currentMatch?.home?.score
                              : currentMatch?.away?.score
                          }
                          type="away"
                        />
                      }
                    >
                      <Tooltip title="Half Time Score">
                        <Tag
                          color="yellow"
                          style={{
                            width: "20px",
                            height: "20px",
                            padding: "1px",
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          <span style={{ fontSize: "10px" }}>
                            {teamSwap
                              ? currentMatch?.home?.halfscore
                              : currentMatch?.away?.halfscore}
                          </span>
                        </Tag>
                      </Tooltip>
                      <span>
                        {(teamSwap
                          ? currentMatch?.home?.score
                          : currentMatch?.away?.score) || 0}
                      </span>
                    </Popover>
                  </div>
                  <div className="timeout-list">
                    {!currentMatch.is_halftime_taken && (
                      <>
                        <span
                          className={`timeout-list-item mr-1${
                            currentMatch?.away?.timeout_before_halftime >= 1
                              ? ""
                              : " selected"
                          }`}
                        />
                        <span
                          className={`timeout-list-item${
                            currentMatch?.away?.timeout_before_halftime >= 2
                              ? ""
                              : " selected"
                          }`}
                        />
                      </>
                    )}
                    {currentMatch.is_halftime_taken && (
                      <>
                        <span
                          className={`timeout-list-item mr-1${
                            currentMatch?.away?.timeout_after_halftime >= 1
                              ? ""
                              : " selected"
                          }`}
                        />
                        <span
                          className={`timeout-list-item${
                            currentMatch?.away?.timeout_after_halftime >= 2
                              ? ""
                              : " selected"
                          }`}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => onOpenPicker("away")}
                style={{
                  backgroundColor:
                    currentMatch?.away?.changed_jersey_color || "#ddd",
                }}
                className="scoreboard-right"
              >
                <div className="team-logo">
                  <div className="team-name">
                    {currentMatch?.away?.team?.name}
                  </div>
                  <div className="team-logo">
                    <img src={currentMatch?.away?.team?.logo} alt="Team Name" />
                  </div>
                  {currentMatch?.away?.team?.openPicker && (
                    <div className="sketch-picker-comp">
                      <SketchPicker
                        onChangeComplete={(e) => onChangeColor(e, "away")}
                        color={currentMatch?.away?.changed_jersey_color}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`scoreboard-bottom ${
                teamSwap ? " swap-team-player" : ""
              }`}
            >
              <div className="scoreboard-teams-left">
                <div className="extra-block">
                  {(Boolean(
                    currentMatch.home?.players.filter(
                      (team) => team.is_starting_lineup
                    )?.length
                  ) ||
                    true) && (
                    <div
                      onClick={() => {
                        handlejerseyviolation("home", "inmatch");
                      }}
                      className="hide-eye"
                    >
                      {!currentMatch?.home?.hide ? (
                        <Tooltip title="Mark Violation">
                          <AiFillEyeInvisible />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Mark Violation">
                          <AiFillEye />
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {/* <div onClick={() => onAddMatchPlayer('home', true)} className="add-p"><MdGroupAdd /></div> */}
                  <div
                    onClick={() => setopenhomeplayergetin(true)}
                    className="add-p"
                    style={{ marginTop: "-15px" }}
                  >
                    <UserAddOutlined style={{ fontSize: "20px" }} />
                  </div>
                  <div
                    onClick={() => resetTeamStats("home")}
                    //className="add-p"
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                  >
                    <FaRetweet style={{ fontSize: "20px" }} />
                  </div>
                  <Modal
                    visible={openhomeplayergetin}
                    cancelButtonProps={{ hidden: true }}
                    okButtonProps={{ hidden: true }}
                    onCancel={() => setopenhomeplayergetin(false)}
                    title={
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Get In a Player
                      </div>
                    }
                  >
                    <PopContent
                      type="home"
                      onSelect={onSelectinBenchPlayer}
                      hidePopup={true}
                      hideswap={true}
                    />
                    <Form
                      layout="vertical"
                      requiredMark={false}
                      form={formz}
                      onFinish={(e) => onAddPlayerfromcurrentTeam(e, "home")}
                    >
                      <Form.Item
                        name="player"
                        label="Select Player"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        {/* {(playerAddInfo.visible) && (
                <AutoComplete
                  options={geOptions(players)}
                  onSelect={onSelectOwner}
                  onSearch={(e) => debouncedSearch(e)}
                  dropdownClassName="mobile-autocomplete"
                >
                  <Input placeholder="Search player by name or email" />
                </AutoComplete>
              )} */}
                        <Select
                          //mode="multiple"
                          //disabled
                          style={{
                            width: "100%",
                          }}
                          placeholder="Please select"
                          //defaultValue={['a10', 'c12']}
                          //onChange={handleChange}
                          onChange={(e) => {
                            const v1 = hometeamplayersinfo.forEach((item) => {
                              if (
                                item?.player?._id?.toString() === e?.toString()
                              ) {
                                formz.setFieldsValue({
                                  jersey_no: item?.jersey_no,
                                });
                              }
                            });
                          }}
                          options={hometeamplayers}
                        />
                      </Form.Item>
                      <Form.Item
                        name="jersey_no"
                        label="Jersey Number"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        <Input
                          maxLength={3}
                          placeholder="Enter a jersey number"
                        />
                      </Form.Item>
                      <div style={{ textAlign: "right", marginTop: "16px" }}>
                        <Button
                          loading={adding}
                          type="primary"
                          htmlType="submit"
                        >
                          Add Player
                        </Button>
                      </div>
                    </Form>
                  </Modal>
                </div>
                <div
                  className={`team-list${
                    selectedMember && selectedMember?.type === "away"
                      ? " disabled1"
                      : ""
                  }`}
                >
                  {currentMatch?.home?.hide && (
                    <>
                      <div style={{ textAlign: "center" }}>
                        {jerseyViolation?.homemessage ??
                          currentMatch?.jerseyviolation?.homemessage}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {jerseyViolation?.homeVideourl ??
                          currentMatch?.jerseyviolation?.homevideourl}{" "}
                        -{" "}
                        {jerseyViolation?.homeincidenttime ??
                          currentMatch?.jerseyviolation?.homeincidenttime}
                      </div>
                    </>
                  )}
                  {!currentMatch?.home?.hide &&
                    currentMatch.home?.players
                      ?.sort((a, b) => a.order - b.order)
                      .filter((team) => team.is_starting_lineup)
                      .map((team, k) => (
                        <div
                          onClick={(e) => onSelectMember(team, "home", e)}
                          key={k.toString()}
                          className={`team-item${
                            team.selected || team.swap ? " selected" : ""
                          }`}
                        >
                          <div className="team-avatar">
                            {/* {team?.player?.profile_pic && <img src={team?.player?.profile_pic} alt='' />}
                      {!team?.player?.profile_pic && <span>{getShortName(team?.player)}</span>} */}
                            {team?.personal_fouls > 0 && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "3px",
                                  borderRadius: "50%",
                                  fontSize: "10px",
                                  width: "16px",
                                  paddingTop: "1px",
                                  textAlign: "center",
                                  background: "#AA0000",
                                }}
                              >
                                {team?.personal_fouls}
                              </span>
                            )}
                            <div className="p-name">
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.first_name}
                              </span>
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.last_name}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              background:
                                currentMatch?.home?.changed_jersey_color ||
                                "#ddd",
                              color:
                                currentMatch?.home?.changed_jersey_color ===
                                "#000000"
                                  ? "#fff"
                                  : undefined,
                            }}
                            className="team-no"
                          >
                            <span
                              style={{
                                background:
                                  currentMatch?.home?.changed_jersey_color ||
                                  "#ddd",
                                color:
                                  currentMatch?.home?.changed_jersey_color ===
                                  "#000000"
                                    ? "#fff"
                                    : undefined,
                              }}
                            >
                              {team?.jersey_no}
                            </span>
                          </div>
                          {!team.selected && (
                            <Popover
                              title={
                                <div
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Replace for {selectedMember?.jersey_no}
                                </div>
                              }
                              content={
                                <PopContent
                                  type="home"
                                  onSelect={onSelectBenchPlayer}
                                />
                              }
                              trigger="click"
                              // placement={k > 3 ? "rightBottom" : 'right'}
                              placement={teamSwap ? "left" : "right"}
                              visible={team?.swap}
                            >
                              {/* <div onClick={(e) => onSwapPlayer(team, 'home', e)} className='swap-player'><FaExchangeAlt size={16} /></div> */}
                            </Popover>
                          )}
                        </div>
                      ))}
                </div>
              </div>
              <div className="scoreboard-teams-center">
                <PopoverContent
                  popVisible={selectedMember?.selected && !selectedMember?.swap}
                />
              </div>
              <div className="scoreboard-teams-right">
                <div className="extra-block">
                  {Boolean(
                    currentMatch.away?.players.filter(
                      (team) => team.is_starting_lineup
                    )?.length
                  ) && (
                    <div
                      onClick={() => {
                        handlejerseyviolation("away", "inmatch");
                      }}
                      aria-hidden
                      className="hide-eye"
                    >
                      {!currentMatch?.away?.hide ? (
                        <Tooltip title="Mark Violation">
                          <AiFillEyeInvisible />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Mark Violation">
                          <AiFillEye />
                        </Tooltip>
                      )}
                    </div>
                  )}
                  {/* <div onClick={() => onAddMatchPlayer('away', true)} className="add-p"><MdGroupAdd /></div> */}
                  <div
                    onClick={() => setopenawayplayergetin(true)}
                    className="add-p"
                    style={{ marginTop: "-15px" }}
                  >
                    <UserAddOutlined style={{ fontSize: "20px" }} />
                  </div>
                  <div
                    onClick={() => resetTeamStats("away")}
                    //className="add-p"
                    style={{ marginTop: "5px", marginLeft: "10px" }}
                  >
                    <FaRetweet style={{ fontSize: "20px" }} />
                  </div>
                  <Modal
                    visible={openawayplayergetin}
                    cancelButtonProps={{ hidden: true }}
                    okButtonProps={{ hidden: true }}
                    onCancel={() => setopenawayplayergetin(false)}
                    title={
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Get In a Player
                      </div>
                    }
                  >
                    <PopContent
                      type="away"
                      onSelect={onSelectinBenchPlayer}
                      hidePopup={true}
                      hideswap={true}
                    />
                    <Form
                      layout="vertical"
                      requiredMark={false}
                      form={formz}
                      onFinish={(e) => onAddPlayerfromcurrentTeam(e, "away")}
                    >
                      <Form.Item
                        name="player"
                        label="Select Player"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        {/* {(playerAddInfo.visible) && (
                <AutoComplete
                  options={geOptions(players)}
                  onSelect={onSelectOwner}
                  onSearch={(e) => debouncedSearch(e)}
                  dropdownClassName="mobile-autocomplete"
                >
                  <Input placeholder="Search player by name or email" />
                </AutoComplete>
              )} */}
                        <Select
                          //mode="multiple"
                          //disabled
                          style={{
                            width: "100%",
                          }}
                          placeholder="Please select"
                          //defaultValue={['a10', 'c12']}
                          //onChange={handleChange}
                          options={awayteamplayers}
                          onChange={(e) => {
                            const v1 = awayteamplayersinfo.forEach((item) => {
                              if (
                                item?.player?._id?.toString() === e?.toString()
                              ) {
                                formz.setFieldsValue({
                                  jersey_no: item?.jersey_no,
                                });
                              }
                            });
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="jersey_no"
                        label="Jersey Number"
                        rules={[
                          { required: true, message: "This field is required" },
                        ]}
                      >
                        <Input
                          maxLength={3}
                          placeholder="Enter a jersey number"
                        />
                      </Form.Item>
                      <div style={{ textAlign: "right", marginTop: "16px" }}>
                        <Button
                          loading={adding}
                          type="primary"
                          htmlType="submit"
                        >
                          Add Player
                        </Button>
                      </div>
                    </Form>
                  </Modal>
                </div>
                <div
                  className={`team-list${
                    selectedMember && selectedMember?.type === "home"
                      ? " disabled1"
                      : ""
                  }`}
                >
                  {currentMatch?.away?.hide && (
                    <>
                      <div style={{ textAlign: "center" }}>
                        {jerseyViolation?.awaymessage ??
                          currentMatch?.jerseyviolation?.awaymessage}
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {jerseyViolation?.awayVideourl ??
                          currentMatch?.jerseyviolation?.awayvideourl}{" "}
                        -{" "}
                        {jerseyViolation?.awayincidenttime ??
                          currentMatch?.jerseyviolation?.awayincidenttime}
                      </div>
                    </>
                  )}
                  {!currentMatch?.away?.hide &&
                    currentMatch.away?.players
                      ?.sort((a, b) => a.order - b.order)
                      .filter((team) => team.is_starting_lineup)
                      .map((team, k) => (
                        <div
                          onClick={(e) => onSelectMember(team, "away", e)}
                          key={k.toString()}
                          className={`team-item${
                            team.selected || team.swap ? " selected" : ""
                          }`}
                        >
                          <div className="team-avatar">
                            {/* {team?.player?.profile_pic && <img src={team?.player?.profile_pic} alt='' />}
                      {!team?.player?.profile_pic && <span>{getShortName(team?.player)}</span>} */}
                            {team?.personal_fouls > 0 && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "3px",
                                  borderRadius: "50%",
                                  fontSize: "10px",
                                  width: "16px",
                                  paddingTop: "1px",
                                  textAlign: "center",
                                  background: "#AA0000",
                                }}
                              >
                                {team?.personal_fouls}
                              </span>
                            )}
                            <div className="p-name">
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.first_name}
                              </span>
                              <span
                                style={
                                  team?.personal_fouls >= 3
                                    ? { color: "#AA0000" }
                                    : undefined
                                }
                              >
                                {team?.player?.last_name}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              background:
                                currentMatch?.away?.changed_jersey_color ||
                                "#ddd",
                              color:
                                currentMatch?.away?.changed_jersey_color ===
                                "#000000"
                                  ? "#fff"
                                  : undefined,
                            }}
                            className="team-no"
                          >
                            {console.log(
                              currentMatch?.away?.changed_jersey_color,
                              "currentMatch?.away?.changed_jersey_color"
                            )}
                            <span
                              style={{
                                background:
                                  currentMatch?.away?.changed_jersey_color ||
                                  "#ddd",
                                color:
                                  currentMatch?.away?.changed_jersey_color ===
                                  "#000000"
                                    ? "#fff"
                                    : undefined,
                              }}
                            >
                              {team?.jersey_no}
                            </span>
                          </div>
                          {!team.selected && (
                            <Popover
                              title={
                                <div
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Replace for {selectedMember?.jersey_no}
                                </div>
                              }
                              content={
                                <PopContent
                                  type="away"
                                  onSelect={onSelectBenchPlayer}
                                />
                              }
                              trigger="click"
                              // placement={k > 3 ? "leftBottom" : 'left'}
                              placement={teamSwap ? "right" : "left"}
                              visible={team?.swap}
                            >
                              {/* <div onClick={(e) => onSwapPlayer(team, 'away', e)} className='swap-player'><FaExchangeAlt size={16} /></div> */}
                            </Popover>
                          )}
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        )}
      <Modal
        visible={openjerseyviolation}
        onCancel={() => setopenjerseyviolation(false)}
        //closable={false}
        //closeIcon={null}
        footer={null}
        destroyOnClose={true}
        //centered
        title={
          <div
            style={{
              textAlign: "center",
              fontSize: "22px",
              fontColor: "#000",
              fontWeight: "700",
            }}
          >
            Select Type of Violation
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Form layout="vertical" onFinish={updatejerseyviolation}>
            <Form.Item label="Violation Type" name="type">
              <Select
                onChange={(e) => setviolationtype(e)}
                style={{ width: "250px" }}
                options={[
                  {
                    value: "Insufficient Players",
                    label: "Insufficient players",
                  },
                  {
                    value: "Duplicate Jersey # in the system",
                    label: "Duplicate jersey # in the system",
                  },
                  {
                    value: "Duplicate Jersey # in the game",
                    label: "Duplicate jersey # in the game",
                  },
                  {
                    value: "Roster Incompleteness",
                    label: "Roster incompleteness",
                  },
                  { value: "Invalid Jersey", label: "Invalid jersey" },
                  {
                    value: "The match has been forfeited",
                    label: "Match forfeited",
                  },
                  {
                    value: "missing video",
                    label: "Video Issue: The video could not be fully viewed",
                  },
                  {
                    value: "No Stats",
                    label: "No stats",
                  },
                  {
                    value: "Custom",
                    label: "Custom",
                  },
                ]}
              />
            </Form.Item>
            {(violationtype === "Duplicate Jersey # in the system" ||
              violationtype === "Duplicate Jersey # in the game" ||
              violationtype === "Roster Incompleteness") && (
              <Form.Item label="Jersey Number" name="jersey">
                <Input
                  type="number"
                  style={{ width: "250px" }}
                  options={[
                    {
                      value: "Insufficient Players",
                      label: "Insufficient Players",
                    },
                    {
                      value: "Duplicate Jersey Number",
                      label: "Duplicate Jersey Number",
                    },
                    {
                      value: "Roster Incompleteness",
                      label: "Duplicate Jersey Number",
                    },
                  ]}
                />
              </Form.Item>
            )}
            {(violationtype === "Duplicate Jersey # in the game" ||
              violationtype === "Invalid Jersey" ||
              violationtype === "Roster Incompleteness") && (
              <>
                <Form.Item label="Video Url" name="videourl">
                  <Input
                    //type="text"
                    style={{ width: "250px" }}
                  />
                </Form.Item>
                <Form.Item label="Time of Incident" name="incidenttime">
                  <Input
                    //type="number"
                    style={{ width: "250px" }}
                  />
                </Form.Item>
              </>
            )}
            {(violationtype === "Custom") && (
              <>
                <Form.Item label="Custom Message" name="message">
                  <Input
                    //type="text"
                    style={{ width: "250px" }}
                  />
                </Form.Item>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "50px",
                marginBottom: "-10px",
              }}
            >
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => setopenjerseyviolation(false)}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={pfModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        centered
        title={
          <div className="replace-title">
            Replace a player for No.{pfModalInfo?.player?.jersey_no}
            <span>
              You can't continue with No.{pfModalInfo?.player?.jersey_no},
              because he exceeds a personal foul limit.
            </span>
          </div>
        }
      >
        <PopContent type={pfModalInfo?.type} onSelect={onSelectBenchPlayer} />
      </Modal>
      <Modal
        visible={astModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        centered
        title={
          <div className="replace-title">
            Who do you want to select for Assists?
          </div>
        }
      >
        <SelectAssists type={astModalInfo?.type} onSelect={onSelectAstPlayer} />
      </Modal>
      <Modal
        visible={toModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        className="reb_popup"
        centered
        title={
          <div className="replace-title">
            Who do you want to select for Steal?
          </div>
        }
      >
        <Selectto type={toModalInfo?.type} onSelect={onSelectToPlayer} />
      </Modal>
      <Modal
        visible={rebModalInfo?.visible}
        closable={false}
        closeIcon={null}
        footer={null}
        className="reb_popup"
        centered
        title={
          <div className="replace-title">
            Who do you want to select for Rebound?
          </div>
        }
      >
        <SelectRebounds
          type={rebModalInfo?.type}
          onSelect={onSelectReboundsPlayer}
        />
      </Modal>
      <Drawer
        title={
          remainingPlayerInfo.visible
            ? currentMatch[remainingPlayerInfo.type]?.team?.name
            : ""
        }
        visible={remainingPlayerInfo.visible}
        onClose={() => onAddMatchPlayer("", false)}
        footer={
          <div
            style={{ textAlign: "center", paddingBottom: "12px" }}
            className="footer-form"
          >
            <Button
              disabled={
                remainingPlayerInfo.visible &&
                !(
                  currentMatch[remainingPlayerInfo.type]?.selectedRemPlayers
                    ?.length > 0
                )
              }
              onClick={onSubmitMatchPlayers}
              type="primary"
            >
              Add Players
            </Button>
          </div>
        }
      >
        {remainingPlayerInfo.visible && (
          <Table
            pagination={false}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) =>
                onCheckRemPlayer(
                  remainingPlayerInfo.type,
                  selectedRowKeys,
                  selectedRows
                ),
            }}
            columns={[
              {
                title: "Name",
                key: "name",
                dataIndex: "name",
                render: (text, record) => getFullName(record.player),
              },
              {
                title: "Jersey No.",
                key: "jersey_no",
                width: "200px",
                dataIndex: "jersey_no",
                render: (text, record, key) => (
                  <Input
                    onChange={(e) =>
                      onChangeRemJerseyNo(
                        e.target.value,
                        remainingPlayerInfo?.type,
                        key
                      )
                    }
                    className="jersey_edit"
                    key={record._id}
                    value={record.jersey_no}
                  />
                ),
              },
            ]}
            dataSource={(
              currentMatch[remainingPlayerInfo.type]?.remaining_players || []
            ).map((ee) => ({ ...ee, key: ee._id }))}
          />
        )}
      </Drawer>
      <Drawer
        title={
          playerAddInfo.visible
            ? currentMatch[playerAddInfo.type]?.team?.name
            : ""
        }
        visible={playerAddInfo.visible}
        onClose={() => {
          setPlayerAddInfo({ visible: false, type: "" });
          setPlayers([]);
          form.setFieldsValue({
            player: undefined,
            jersey_no: undefined,
          });
        }}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onAddPlayerTeam}
        >
          <Form.Item className="loader-form-block">
            <Form.Item
              name="player"
              label="Select Player"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {playerAddInfo.visible && (
                <AutoComplete
                  options={geOptions(players)}
                  onSelect={onSelectOwner}
                  onSearch={(e) => debouncedSearch(e)}
                  dropdownClassName="mobile-autocomplete"
                >
                  <Input placeholder="Search player by name or email" />
                </AutoComplete>
              )}
              {searching && (
                <Form.Item className="loader-form-item" noStyle>
                  <span className="input-loader">
                    <LoadingOutlined />
                  </span>
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item
            name="jersey_no"
            label="Jersey Number"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input maxLength={3} placeholder="Enter a jersey number" />
          </Form.Item>
          <div style={{ textAlign: "right", marginTop: "16px" }}>
            <Button loading={adding} type="primary" htmlType="submit">
              Add Player
            </Button>
          </div>
        </Form>
      </Drawer>

      <Modal
        visible={openloader}
        footer={false}
        centered={true}
        closable={false}
        width="0px"
      >
        {" "}
        <Spin />
      </Modal>
      <Modal
        visible={videoModal}
        //footer={false}
        centered={true}
        //closable={false}
        width="0px"
      >
        <Input placeholder="Video URl" />
      </Modal>
    </div>
  );
};
export default ScoreKeeperPage;
